import * as React from "react";
import { AlertColor, Stack } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import FlexGrid from "../../../../layouts/flexGrid";
import { useGetAllContractsByProfileKeyQuery } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import ContractsDataGrid from "./contractsDataGrid";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import SignContractBanner from "./signContractBanner";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import CclDownloadWarningAlert from "../../../../components/common/cclDownloadWarningAlert";

export interface ContractsProps {
    pKey: string;
    name: string;
    imKey: string;
    email: string;
}

export const Contracts: React.FC<ContractsProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isUsersRecord = claimsService.GetCurrentResourceId() === props.pKey;
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [eventInProgress, setEventInProgress] = React.useState<string>("");
    const {
        data: contracts,
        isLoading,
        isSuccess,
    } = useGetAllContractsByProfileKeyQuery(props.pKey);
    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleEvents = (evt: string, ids: number[]) => {
        if (ids.length <= 0) return;
        if (evt === "download") {
            if (contracts === undefined || contracts.length <= 0) {
                ShowSnackbarElement("Download Not Possible", "error");
                return;
            }
            const selectedcontracts = contracts.filter((c) =>
                ids.some((i) => i === c.profileContractId)
            );
            let aikeys: number[] = selectedcontracts.flatMap((c) => {
                if (c.status === 2) return [];
                if (c.aiKey === undefined || c.aiKey === null) {
                    if (
                        c.contract.aiKey === undefined ||
                        c.contract.aiKey === null ||
                        c.contract.aiKey === 0
                    ) {
                        return [];
                    }
                    return c.contract.aiKey;
                }
                if (c.aiKey !== 0) return c.aiKey;
                return [];
            });

            if (aikeys.length <= 0) {
                ShowSnackbarElement("Download Not Possible", "error");
                return;
            }
            setEventInProgress(evt);
            let filename = `${props.name} Contracts.zip`;
            if (aikeys.length === 1) {
                const c = contracts.find(
                    (c) => c.aiKey === aikeys[0] || c.contract.aiKey === aikeys[0]
                );
                filename = c != null ? c.contract.title : `${props.name} Contract.pdf`;
            }

            downloadService
                .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: true })
                .then(() => {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: props.imKey,
                        email: props.email,
                    };

                    logEvent("UserDownloadedContract", evtData);
                    ShowSnackbarElement("Download Completed", "success");
                    setEventInProgress("");
                })
                .catch((error) => {
                    ShowSnackbarElement("Download Failed", "error");
                    setEventInProgress("");
                });
        }
    };

    if (isLoading) {
        return <ComponentLoader msg={"Loading"} />;
    }

    if (isSuccess) {
        return (
            <Stack width={1} direction={"column"} spacing={3} height={1}>
                <SignContractBanner isUsersRecord={isUsersRecord} pKey={props.pKey} />
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                <CclDownloadWarningAlert />
                <FlexGrid>
                    <ContractsDataGrid
                        data={contracts}
                        isLoading={isLoading}
                        handleEvent={handleEvents}
                        eventInProgress={eventInProgress}
                        readonly={!isUsersRecord && claimsService.IsReadOnly()}
                    />
                </FlexGrid>
            </Stack>
        );
    }
    return <span>Failed to load contracts.</span>;
};
export default Contracts;
