import * as React from "react";
import { Stack, Typography, Card, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAssignmentSearchParams } from "../../app/slices/assignmentListConfigSlice";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import { SessionDetailResourceAppointment } from "../../services/types/sessionDetailTypes";
import SessionAssignmentListDataGridPro from "./sessionAssignmentsList";
import {
    useGetResourceAssignmentsByEmailQuery,
    useGetResourceAssignmentsByDateMutation,
} from "../../services/cclTokenedResourceApi";
import { getErrorMsg } from "../../utilities/rtkQueryErrorHelpers";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";

const Assignments: React.FC = (props) => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.assignmentListConfig);

    const [isEmailSearchActive, setIsEmailSearchActive] = React.useState<boolean>(
        config.IsEmailSearchActive
    );
    const [skipEmailSearch, setSkipEmailSearch] = React.useState<boolean>(
        !config.IsEmailSearchActive
    );
    const [searchEmail, setSearchEmail] = React.useState<string>(
        config.IsEmailSearchActive ? config.Email : ""
    );
    const [startDate, setStartDate] = React.useState<Date | null>(new Date(config.StartDate));
    const [endDate, setEndDate] = React.useState<Date | null>(new Date(config.EndDate));
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);
    const [
        runSearch,
        { data: dateAssignments, isLoading: paxIsLoading, isError: paxIsError, error: paxError },
    ] = useGetResourceAssignmentsByDateMutation();
    const { data, isLoading, isError, error } = useGetResourceAssignmentsByEmailQuery(
        searchEmail.trim(),
        { skip: skipEmailSearch }
    );
    const [searchResultString, setSearchResultString] = React.useState<string>("");

    let assignments: SessionDetailResourceAppointment[] = [];

    if (isEmailSearchActive) {
        if (data !== undefined) assignments = data;
    } else if (dateAssignments !== undefined) {
        assignments = dateAssignments;
    }

    const clearDateSearch = () => {
        // this is a hack to overcome an error in the mui datepicker that won't allow us to set an invalid date to null.
        // We have to first set a valid date, then go to null
        if (startDateError) {
            setStartDate(new Date());
            setTimeout(() => setStartDate(null), 100);
        } else {
            setStartDate(null);
        }
        if (endDateError) {
            setEndDate(new Date());
            setTimeout(() => setEndDate(null), 100);
        } else {
            setEndDate(null);
        }
        setEndDateError(false);
        setStartDateError(false);
    };

    React.useEffect(() => {
        // the config date times are set to generic dates when searching by keyword
        //  here we make sure the dates are cleared if the keyword search is active
        //  this is really only for when we return to the page after leaving
        if (isEmailSearchActive) {
            setStartDate(null);
            setEndDate(null);
        } else {
            runSearch({
                start: config.StartDate,
                end: config.EndDate,
            });
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setSkipEmailSearch(true);
        setSearchResultString(
            `${data?.length} results for search by email ('${searchEmail.trim()}')`
        );
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        if (dateAssignments != null)
            setSearchResultString(
                `${
                    dateAssignments?.length
                } results for search by start date (from ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()})`
            );
        // eslint-disable-next-line
    }, [dateAssignments]);

    const getAssignmentsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            setStartDateError(startDate == null);
            setEndDateError(endDate == null);
            return;
        }
        setStartDateError(false);
        setEndDateError(false);
        setSearchEmail("");
        setIsEmailSearchActive(false);
        runSearch({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        });
        dispatch(
            updateAssignmentSearchParams({
                StartDate: startDate.toISOString(),
                EndDate: endDate.toISOString(),
                Email: searchEmail,
                IsEmailSearchActive: isEmailSearchActive,
            })
        );
    };

    const getAssignmentsByEmail = async () => {
        if (searchEmail == null || searchEmail.trim() === "") {
            return;
        }
        clearDateSearch();
        setSkipEmailSearch(false);
        setIsEmailSearchActive(true);
        dispatch(
            updateAssignmentSearchParams({
                StartDate: "",
                EndDate: "",
                Email: searchEmail.trim(),
                IsEmailSearchActive: isEmailSearchActive,
            })
        );
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Assignments
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (!isError && !paxIsError) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <Stack height={1} width={1}>
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{display:"flex", flexDirection:"row"}}>
                            <CclTextSearchBar
                                initialSearchTerm={searchEmail}
                                searchLabel="Enter Resource Email"
                                executeSearch={getAssignmentsByEmail}
                            />
                            <CclDateSearchBar
                                initialStartDate={startDate}
                                initialEndDate={endDate}
                                startDateLabel="Earliest Start Date"
                                endDateLabel="Latest Start Date"
                                executeSearch={getAssignmentsByDate}
                                allowNullDates={false}
                            />

                        </Box>
                        <Grid
                            sx={{
                                height: 1,
                                width: 1,
                                padding: 2,
                                paddingTop: 0,
                            }}
                        >
                            <SessionAssignmentListDataGridPro
                                loading={isLoading || paxIsLoading}
                                assignments={assignments}
                                showResultBar={!(isLoading || paxIsLoading)}
                                resultBarText={searchResultString}
                            />
                        </Grid>
                    </Card>
                </Stack>
            </StandardLayout>
        );
    }

    if (isError || paxIsError) {
        const errmsg = paxIsError ? getErrorMsg(paxError) : getErrorMsg(error);
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <span>{`Error Loading Registrations: ${errmsg}`}</span>
            </StandardLayout>
        );
    }

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <span>Unknown Error Loading Registrations</span>
        </StandardLayout>
    );
};

export default Assignments;
