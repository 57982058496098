import * as React from "react";
import BiographyBox from "../../../components/shared/biography/biographyBox";

interface BiosProps {
    pKey: string;
    email: string;
}

const Bios: React.FC<BiosProps> = (props) => {
    return <BiographyBox pKey={props.pKey} email={props.email} />;
};

export default Bios;
