import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface BiosListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: BiosListTableStateState = {
    TableState: JSON.stringify({
        sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
        },
        filter: {
            filterModel: {
                items: [
                    {
                        id: 1,
                        columnField: "status",
                        operatorValue: "startsWith",
                        value: "active",
                    },
                ],
            },
        },
    }),
};

export const BiosListTableStateSlice = createSlice({
    name: "BiosListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateBiosGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateBiosGridState } = BiosListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const BiosListTableState = (state: RootState) => state;
export default BiosListTableStateSlice.reducer;
