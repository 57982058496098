import { Box, Card, CardHeader } from '@mui/material';
import * as React from 'react';
import CclInfoTable, { CclInfoTableRowTemplate } from '../../../../components/common/cclInfoTable/cclInfoTable';
import { useLazyGetPartnerResourceByEmailQuery } from '../../../../services/cclTokenedEnterpriseNetworkPartnerApi';
import {CclInfoTblPartnerDetailTemplate} from "./cclInfoTblPartnerDetailTemplate";


interface PartnerGeneralProps {
    email: string;
}

const PartnerGeneral: React.FC<PartnerGeneralProps> = (props) => {

    const [modTemplate, setModTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);

    const [getPartnerByEmail, {data}] = useLazyGetPartnerResourceByEmailQuery();

    const [modifiedData, setModifiedData] = React.useState<any>({});

    React.useEffect(()=> {
        if (!props.email){return;}
        getPartnerByEmail(props.email);
        // eslint-disable-next-line
    },[props.email])

    React.useEffect(()=> {
        if (!data) {return;}
        let builder = {
            activeText: "",
            adminText: "",
            name: ""
        }
        let temp = {...data[0], ...builder};

        var newTemplate = CclInfoTblPartnerDetailTemplate;
        setModTemplate([...newTemplate]);

        temp.activeText = data[0].active ? "Yes" : "No";
        temp.adminText = data[0].isAdmin ? "Yes" : "No";
        temp.name = data[0].firstName && data[0].lastName ? data[0].firstName + " " + data[0].lastName : "";

        setModifiedData(temp);
        // eslint-disable-next-line
    }, [data])

    const onChange = () => {}
    const saveData = () => {}


    return (
        <Box sx={{ width: .4, height: 1 }}>
                <Card
                    sx={{
                        m: 2,
                        border: 1,
                        borderColor: "#EEEEEE",
                        borderRadius: 3,
                    }}
                >
                <CardHeader title="General Infomation" />
                    {modTemplate && data &&
                        <CclInfoTable
                            template={modTemplate}
                            data={modifiedData}
                            onSave={saveData}
                            onChange={onChange}
                        />
                    }
                </Card>
        </Box>
    );
}

export default PartnerGeneral;