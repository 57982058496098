import {
    Alert,
    AlertTitle,
    Box,
    CardContent,
    CircularProgress,
    FormControlLabel,
    Switch,
} from "@mui/material";
import * as React from "react";
import ComponentLoader from "../../../../components/common/componentLoader";
import EmailViewer from "../../../../components/common/emailViewer";
import { useGetCclNotificationQuery } from "../../../../services/cclTokenedNotificationApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventNotificationDetails } from "../../../../services/types/accessEventTypes";

interface NotificationPreviewPanelProps {
    notificationId: string;
    forceChecked?: boolean;
    onPreviewLoaded?: () => void;
}

const NotificationPreviewPanel: React.FC<NotificationPreviewPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclNotificationQuery(props.notificationId, {
        skip: !props.notificationId,
    });
    const [checked, setChecked] = React.useState(false);
    const userClaimsService = new UserClaimsService();
    const { logEvent } = useLogAccessEvent();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.forceChecked) {
            setChecked(false);
            return;
        }
        setChecked(event.target.checked);
        var allRecipients = data?.recipients.map((r) => r.identifier).join(",") ?? "";
        const evtData: AccessEventNotificationDetails = {
            subject: data?.subject ?? "unknown",
            notificationId: data?.notificationId ?? "unknown",
            recipient: allRecipients,
        };
        logEvent("EmailEmbeddedLinksAccessed", evtData);
    };

    React.useEffect(() => {
        if (data !== undefined && !isLoading && props.onPreviewLoaded !== undefined) {
            props.onPreviewLoaded();
        }
    }, [data, isLoading, props]);

    if (isLoading) {
        return props.onPreviewLoaded === undefined ? (
            <ComponentLoader msg={"Loading"} />
        ) : (
            <Box sx={{ m: 4 }}>
                <Alert severity={"info"} icon={<CircularProgress size={20} />}>
                    <AlertTitle>Loading Notification Preview...</AlertTitle>
                </Alert>
            </Box>
        );
    }

    if (isSuccess) {
        return (
            <CclGenericAccordian headingText={"Preview"}>
                <Box sx={{ m: 2, border: 1, borderColor: "#EEEEEE", borderRadius: 3 }}>
                    <CardContent sx={{ pt: 0, pb: 0 }}>
                        <div style={{ overflow: "auto" }}>
                            {userClaimsService.IsUserSuperAdmin() && !props.forceChecked && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={handleChange}
                                            color="primary"
                                        />
                                    }
                                    label="Enable Access To Embedded Links"
                                />
                            )}
                            <EmailViewer
                                content={
                                    data !== undefined && data !== null ? data?.content : "No Data"
                                }
                                disableLinks={!checked}
                            />
                        </div>
                    </CardContent>
                </Box>
            </CclGenericAccordian>
        );
    }

    return <span>Failed to load Notification Preview</span>;
};

export default NotificationPreviewPanel;
