import * as React from "react";
import {
    Alert,
    Card,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
// import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { GrandCentralConnection } from "../../../../services/types/cclGrandCentralApiTypes";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    useDeleteCclApplicationConnectionMutation,
    useUpdateCclApplicationConnectionMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import UpdateLoginApplicationConnectionDialog from "./updateLoginApplicationConnectionDialog";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";

export interface GcConnectionCardProps {
    systemId: string;
    systemName: string;
    applicationId: string;
    gcConnections: GrandCentralConnection[];
    readonly: boolean;
}

export const GcConnectionCard: React.FC<GcConnectionCardProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [errorTitle, setErrorTitle] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);
    const [showUpdateConnectionDialog, setShowUpdateConnectionDialog] =
        React.useState<boolean>(false);
    const [selectedConnection, setSelectedConnection] = React.useState<GrandCentralConnection>({
        identityProviderConnectionId: "",
        identityProviderId: "",
        connectionId: "",
        name: "",
    });
    const [deleteConnection, { isLoading: deleteLoading }] =
        useDeleteCclApplicationConnectionMutation();
    const [updateConnection, { isLoading: updateLoading }] =
        useUpdateCclApplicationConnectionMutation();

    const showError = (msg: string, title: string) => {
        setErrorMessage(msg);
        setErrorTitle(title);
        setShowErrorDialog(true);
    };

    const onUpdateConnection = (conn: GrandCentralConnection) => {
        setSelectedConnection(conn);
        setShowUpdateConnectionDialog(true);
    };

    const onConnectionUpdate = (conn: GrandCentralConnection) => {
        setLoading(true);
        updateConnection({
            applicationId: props.applicationId,
            loginApplicationConnectionId: conn.connectionId,
            identityProviderConnectionId: conn.identityProviderConnectionId,
        })
            .unwrap()
            .then((res) => {
                const evtData : AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                if (!res) {
                    showError("Failed to update connection", "Error Updating Connection");
                }
                setLoading(false);
            })
            .catch((err) => {
                showError(`Update Application error: ${err.data}`, "Error Updating Connection");
                setLoading(false);
            });
    };

    const onDeleteConnection = (conn: GrandCentralConnection) => {
        setSelectedConnection(conn);
        setShowDeleteConfirmation(true);
    };

    const tryDeleteConnection = () => {
        setLoading(true);
        setShowDeleteConfirmation(false);
        deleteConnection({
            applicationId: props.applicationId,
            loginApplicationConnectionId: selectedConnection.connectionId,
            identityProviderConnectionId: selectedConnection.identityProviderConnectionId,
        })
            .unwrap()
            .then((res) => {
                const evtData : AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                console.log("Delete Application result: ", res ? "Success" : "Failed");
                if (!res) {
                    showError("Failed to delete connection", "Error Deleting Connection");
                }
                setLoading(false);
            })
            .catch((err) => {
                showError(`Delete Application error: ${err.data}`, "Error Deleting Connection");
                setLoading(false);
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title={errorTitle}
            />
            <UpdateLoginApplicationConnectionDialog
                open={showUpdateConnectionDialog}
                onClose={() => setShowUpdateConnectionDialog(false)}
                conn={selectedConnection}
                onSave={onConnectionUpdate}
            />
            <CclGenericConfirmationDialog
                open={showDeleteConfirmation}
                onCancel={() => setShowDeleteConfirmation(false)}
                onOk={() => tryDeleteConnection()}
            />
            <Card variant="outlined" sx={{ height: 1 }}>
                <CardHeader title="Grand Central Connections" />
                {loading || deleteLoading || updateLoading ? (
                    <Typography>Updating Connections...</Typography>
                ) : props.gcConnections != null && props.gcConnections.length > 0 ? (
                    <Table>
                        <TableBody>
                            {props.gcConnections.map((conn) => (
                                <TableRow key={conn.connectionId}>
                                    <TableCell>{conn.name}</TableCell>
                                    <TableCell>{conn.connectionId}</TableCell>
                                    {props.readonly ? null : (
                                        <TableCell>
                                            {" "}
                                            <IconButton
                                                color="secondary"
                                                onClick={() => onUpdateConnection(conn)}
                                                aria-label="edit"
                                            >
                                                {" "}
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => onDeleteConnection(conn)}
                                                aria-label="delete"
                                            >
                                                {" "}
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Alert severity="info">No Grand Central Connections Found</Alert>
                )}
            </Card>
        </>
    );
};

export default GcConnectionCard;
