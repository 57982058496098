import * as React from "react";
import { Alert } from "@mui/material";

interface CompassOptionAlertProps {
    severity: "info" | "warning" | "error" | "success" | undefined;
    state: "activate" | "schedule" | "resource" | "deactivate" | null;
    needInvitations: boolean;
}

const CompassOptionAlert: React.FC<CompassOptionAlertProps> = (props) => {
    let alertMessage: string = "";

    switch (props.severity) {
        case "info":
            switch (props.state) {
                case "activate":
                    alertMessage = "Compass is activated.";
                    break;
                case "schedule":
                    alertMessage = "Compass activation is scheduled.";
                    break;
                case "resource":
                    alertMessage = "Compass activation handled by assigned resource";
                    break;
                case "deactivate":
                    alertMessage = "Compass is de-activated.";
                    break;
            }
            break;
        case "warning":
            if (props.needInvitations) {
                if (props.state === "resource") {
                    alertMessage =
                        "Compass invitations will be sent when assigned resource(s) activate Compass for their participant(s).";
                } else {
                    alertMessage =
                        "Activating Compass for this session will send invitation emails to all particitpants. This action cannot be undone.";
                }
            } else {
                if (props.state === "activate") {
                    alertMessage = "Activate Compass.";
                } else if (props.state === "schedule") {
                    alertMessage = "Schedule Compass activation.";
                } else if (props.state === "resource") {
                    alertMessage = "Assigned Resource Activates Compass.";
                } else {
                    alertMessage = "De-activate Compass.";
                }
            }
            break;
        case "error":
            alertMessage = "Compass state error";
            break;
        case "success":
            switch (props.state) {
                case "activate":
                    alertMessage = props.needInvitations
                        ? "Compass has been activated and invitations sent."
                        : "Compass has been activated.";
                    break;
                case "schedule":
                    alertMessage = props.needInvitations
                        ? "Compass activation and pending invitations are scheduled."
                        : "Compass activation is scheduled.";
                    break;
                case "resource":
                    alertMessage = "Compass activation done by assigned resource.";
                    break;
                case "deactivate":
                    alertMessage = props.needInvitations
                        ? "Compass activation and invitations are cancelled."
                        : "Compass has been de-activated.";
                    break;
            }
            break;
    }
    
    //Based on Andy's feedback and pbi 78788 ive set this to info. I am leaving the other code for now in case we need to revert back

    return (
        <Alert severity={"info"} sx={{ m: 2 }}>
            {alertMessage}
        </Alert>
    );
};

export default CompassOptionAlert;
