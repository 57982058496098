import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SelectOption } from "../../../../components/common/selectOptions/selectOption";
import { useLazyGetGroupsByTenantIdQuery } from "../../../../services/cclTokenedGrandCentralApi";

export interface TenantGroupSelectProps {
    tenantId: string;
    onChange: (selectedGroupIds: string[]) => void;
}

export const TenantGroupSelect: React.FC<TenantGroupSelectProps> = (props) => {
    const [getTenantGroups, { data: tenantGroups }] = useLazyGetGroupsByTenantIdQuery();
    const [groups, setGroups] = React.useState<SelectOption[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<SelectOption[]>([]);

    React.useEffect(() => {
        if (props.tenantId?.length > 0) getTenantGroups(props.tenantId);
    }, [props.tenantId, getTenantGroups]);

    React.useEffect(() => {
        setGroups(
            tenantGroups?.map((g) => {
                return { val: g.groupId, option: g.name };
            }) ?? []
        );
    }, [tenantGroups]);

    const onChange = (opts: SelectOption[]) => {
        const selGroups = [...opts];
        setSelectedGroups(selGroups);
        props.onChange(
            selGroups.map((g) => {
                return g.val;
            })
        );
    };

    return (
        <Autocomplete
            multiple
            id="groups-outlined"
            value={selectedGroups}
            options={groups}
            getOptionLabel={(option) => option.option}
            defaultValue={[]}
            filterSelectedOptions
            onChange={(evt, val) => onChange(val)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={groups.length <= 0 ? "No Groups Available" : "Select Groups"}
                    size="small"
                />
            )}
            disabled={groups.length <= 0}
        />
    );
};

export default TenantGroupSelect;
