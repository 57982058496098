import * as React from "react";
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@mui/material";
import { CheckCircle, ErrorOutline, Error } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import CachedIcon from "@mui/icons-material/Cached";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
    useFinalizeAssetChunkUploadMutation,
    useUploadAssetChunkMutation,
    useUploadAssetMutation,
} from "../../../../../services/cclTokenedAssetsApi";

export enum eUploadFileState {
    Idle = 0,
    Uploading = 1,
    FinalizingUpload = 2,
    UploadSuccess = 3,
    UploadFailed = 4,
    Unknown = 5,
}

interface UploadFileListItemProps {
    file: File;
    startUpload: boolean;
    imkeys: number[];
    properties?: { [key: string]: string };
    makeAvailable: boolean;
    onUploadResult: (file: File, status: boolean) => void;
    deleteFile: (file: File) => void;
}

const UploadFileListItem: React.FC<UploadFileListItemProps> = (props) => {
    const chunksize = 1024 * 512; // .5 MB chunks
    const [state, setState] = React.useState<eUploadFileState>(eUploadFileState.Idle);
    const [currentChunk, setCurrentChunk] = React.useState<number>(1);
    const [informParent, setInformParent] = React.useState<boolean>(true);
    const [uploadPercentage, setUploadPercentage] = React.useState<number>(50);
    const [
        uploadChunk,
        { data: chunkUploadResponse, isSuccess: chunkSuccess, isError: chunkIsError },
    ] = useUploadAssetChunkMutation();
    const [
        finalizeUpload,
        { data: finalizeResponse, isSuccess: finalizeSuccess, isError: finalizeIsError },
    ] = useFinalizeAssetChunkUploadMutation();
    const [
        uploadFile,
        { data: uploadFileResponse, isSuccess: uploadFileIsSuccess, isError: uploadFileIsError },
    ] = useUploadAssetMutation();

    const numChunks: number = props.file != null ? Math.ceil(props.file.size / chunksize) : 0;

    // eslint-disable-next-line
    const callFinalizeUpload = (id: string) => {
        setState(eUploadFileState.FinalizingUpload);
        finalizeUpload({
            id: id,
            fileName: props.file.name,
            contentType: props.file.type,
            ownerImKeys: props.imkeys,
            properties: props.properties ?? {},
            makeAvailable: props.makeAvailable,
            chunkCount: numChunks,
        });
    };

    // eslint-disable-next-line
    const getChunk = (chunknum: number): Blob => {
        const start = (chunknum - 1) * chunksize;
        let end = chunknum * chunksize;
        if (end > props.file.size) end = props.file.size;
        return props.file.slice(start, end);
    };

    React.useEffect(() => {
        if (chunkSuccess) {
            if (chunkUploadResponse && chunkUploadResponse.success) {
                setUploadPercentage(Math.ceil((currentChunk / numChunks) * 100));
                if (currentChunk >= numChunks) {
                    callFinalizeUpload(chunkUploadResponse.id);
                } else {
                    const newchunknum = currentChunk + 1;
                    setCurrentChunk(newchunknum);
                    let fd: FormData = new FormData();
                    fd.append("id", chunkUploadResponse?.id ?? "");
                    fd.append("chunkNumber", newchunknum.toString());
                    fd.append("chunk", getChunk(newchunknum));
                    uploadChunk(fd);
                }
            } else {
                setState(eUploadFileState.UploadFailed);
            }
        } else if (chunkIsError) {
            setState(eUploadFileState.UploadFailed);
            // add cancel upload here
        }
        // eslint-disable-next-line
    }, [chunkIsError, chunkSuccess]);

    React.useEffect(() => {
        if (finalizeSuccess) {
            setUploadPercentage(0);
            setCurrentChunk(1);
            if (finalizeResponse && finalizeResponse.success) {
                setState(eUploadFileState.UploadSuccess);
                if (informParent) props.onUploadResult(props.file, true);
            } else {
                setState(eUploadFileState.UploadFailed);
                if (informParent) props.onUploadResult(props.file, false);
            }
        } else if (finalizeIsError) {
            setState(eUploadFileState.UploadFailed);
            if (informParent) props.onUploadResult(props.file, false);
        }
        // eslint-disable-next-line
    }, [finalizeSuccess, finalizeIsError]);

    React.useEffect(() => {
        if (uploadFileIsSuccess) {
            setUploadPercentage(0);
            setCurrentChunk(1);
            if (uploadFileResponse && uploadFileResponse.success) {
                setState(eUploadFileState.UploadSuccess);
                if (informParent) props.onUploadResult(props.file, true);
            } else {
                setState(eUploadFileState.UploadFailed);
                if (informParent) props.onUploadResult(props.file, false);
            }
        } else if (finalizeIsError) {
            setState(eUploadFileState.UploadFailed);
            if (informParent) props.onUploadResult(props.file, false);
        }
        // eslint-disable-next-line
    }, [uploadFileIsSuccess, uploadFileIsError]);

    const startUpload = (newInformParent: boolean) => {
        if (props.imkeys.length <= 0) return;
        setInformParent(newInformParent);
        setCurrentChunk(1);
        setState(eUploadFileState.Uploading);
        setUploadPercentage(0);
        let fd: FormData = new FormData();
        if (numChunks === 1) {
            fd.append("ownerImKeys", props.imkeys.join(",")); // upload id starts as invalid, but need a string
            fd.append("cType", props.file.type);
            fd.append("fName", props.file.name);
            fd.append("file", getChunk(1));
            fd.append("properties", JSON.stringify(props.properties));
            fd.append("makeAvailable", JSON.stringify(props.makeAvailable));
            uploadFile({ eskey: -1, formData: fd }); // eskey is just used by rtk toolkit to requery when needed, needed but not important here
        } else {
            fd.append("id", "none"); // upload id starts as invalid, but need a string
            fd.append("chunkNumber", currentChunk.toString());
            fd.append("chunk", getChunk(1));
            uploadChunk(fd);
        }
    };

    React.useEffect(() => {
        if (props.startUpload) {
            // in order for parent to start upload, we must be idle.
            // Retrying later is done only within the list item
            if (state === eUploadFileState.Idle) {
                startUpload(true);
            }
        }
        // eslint-disable-next-line
    }, [props.startUpload]);

    const getStateIcon = () => {
        switch (state) {
            case eUploadFileState.Idle:
                return <MoreHorizIcon color="primary" fontSize="small" />;
            case eUploadFileState.Uploading:
                return <CachedIcon color="primary" fontSize="small" />;
            case eUploadFileState.FinalizingUpload:
                return <EditIcon color="primary" fontSize="small" />;
            case eUploadFileState.UploadSuccess:
                return <CheckCircle color="success" fontSize="small" />;
            case eUploadFileState.UploadFailed:
                return <ErrorOutline color="error" fontSize="small" />;
            case eUploadFileState.Unknown:
                return <Error color="warning" fontSize="small" />;
        }
    };

    const getSecondaryAction = () => {
        switch (state) {
            case eUploadFileState.Idle:
            case eUploadFileState.UploadSuccess:
            case eUploadFileState.Unknown:
                return (
                    <ListItemIcon onClick={() => props.deleteFile(props.file)}>
                        <DeleteOutlineIcon color="error" fontSize="small" />
                    </ListItemIcon>
                );
            case eUploadFileState.Uploading:
                return (
                    <Typography variant="caption" component="div" color="text.secondary">
                        Progress: {uploadPercentage}%
                    </Typography>
                );
            case eUploadFileState.FinalizingUpload:
                return (
                    <Typography variant="caption" component="div" color="text.secondary">
                        Saving...
                    </Typography>
                );
            case eUploadFileState.UploadFailed:
                return (
                    <div>
                        <ListItemIcon onClick={() => startUpload(false)}>
                            <ReplayIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemIcon onClick={() => props.deleteFile(props.file)}>
                            <DeleteOutlineIcon color="error" fontSize="small" />
                        </ListItemIcon>
                    </div>
                );
        }
    };

    return (
        <ListItem key={props.file.name} sx={{ borderTop: "1px solid lightgray" }}>
            <ListItemButton>
                <ListItemIcon>{getStateIcon()}</ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ variant: "body2" }}
                    primary={`${props.file.name}`}
                />
                <ListItemSecondaryAction>{getSecondaryAction()}</ListItemSecondaryAction>
            </ListItemButton>
        </ListItem>
    );
};

export default UploadFileListItem;
