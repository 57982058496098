import { TimelineContent } from "@mui/lab";
import React from "react";
import { Box, Typography } from "@mui/material";
import { JournalEntry } from "../../../services/types/journalEntryTypes";

export interface JournalEntryTimeLineItemContentProps {
    entry: JournalEntry;
}

const JournalEntryTimeLineItemContent: React.FC<JournalEntryTimeLineItemContentProps> = (props) => {
    return (
        <TimelineContent>
            <Box
                sx={{
                    pl: 4,
                    pb: 4,
                    pt: 3,
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    borderRadius: 3,
                    backgroundColor: "white",
                }}
            >
                <Typography variant="h6" component="span" sx={{ mt: -2 }}>
                    {`${props.entry.categoryName} Journal Entry`}
                </Typography>
                <Typography variant="body2" color={"Gray"}>
                    <b>Author: </b>
                    {props.entry.createdByUser}
                </Typography>
                <Typography variant="body1">{props.entry.entry}</Typography>
            </Box>
        </TimelineContent>
    );
};

export default JournalEntryTimeLineItemContent;
