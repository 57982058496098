import * as React from "react";
import { Card } from "@mui/material";
import {
    DetailsView,
    FileManagerComponent,
    NavigationPane,
    Toolbar,
    Inject,
    SuccessEventArgs,
    FailureEventArgs,
} from "@syncfusion/ej2-react-filemanager";
import { Config } from "../../../../config";

interface TemplatesProps {}

const ManageTemplatesPanel: React.FC<TemplatesProps> = () => {
    const apiManagementRootUrl: string | undefined = Config.REACT_APP_CCL_API_MANAGEMENT_URL;
    let hostUrl: string = apiManagementRootUrl + "ccl-access2/";
    //let hostUrl : string = "https://localhost:7094/";

    function onSuccess(args: SuccessEventArgs) {
        console.log("Ajax request successful");
    }

    function onFailure(args: FailureEventArgs) {
        console.log("Ajax request has failed");
    }

    const OnBeforeSend = (args: { ajaxSettings: { beforeSend: (args: any) => void } }) => {
        args.ajaxSettings.beforeSend = function (args) {
            const token = localStorage.getItem("token") as string;
            args.httpRequest.setRequestHeader("authorization", `Bearer ${token}`);
        };
    };

    return (
        <Card sx={{ wdith: "fit-content", minHeight: 800 }}>
            <div className="control-section">
                <FileManagerComponent
                    id="file"
                    height={800}
                    contextMenuSettings={{
                        file: ["Open", "|", "Cut", "Copy", "|", "Delete", "Rename", "|", "Details"],
                        folder: [
                            "Open",
                            "|",
                            "Cut",
                            "Copy",
                            "Paste",
                            "|",
                            "Delete",
                            "|",
                            "Details",
                        ],
                        layout: [
                            "SortBy",
                            "View",
                            "Refresh",
                            "|",
                            "Paste",
                            "|",
                            "NewFolder",
                            "Upload",
                            "|",
                            "Details",
                            "|",
                            "SelectAll",
                        ],
                        visible: true,
                    }}
                    beforeSend={OnBeforeSend.bind(this)}
                    toolbarSettings={{
                        items: [
                            "NewFolder",
                            "Upload",
                            "Cut",
                            "Copy",
                            "Paste",
                            "Delete",
                            "Download",
                            "SortBy",
                            "Refresh",
                            "Selection",
                            "View",
                            "Details",
                        ],
                        visible: true,
                    }}
                    view="LargeIcons"
                    enablePersistence={false}
                    ajaxSettings={{
                        downloadUrl: hostUrl + "syncfusion/AzureDownload",
                        getImageUrl: hostUrl + "syncfusion/AzureGetImage",
                        uploadUrl: hostUrl + "syncfusion/AzureUpload",
                        url: hostUrl + "syncfusion/AzureFileOperations",
                    }}
                    success={onSuccess.bind(this)}
                    failure={onFailure.bind(this)}
                >
                    <Inject services={[NavigationPane, DetailsView, Toolbar]} />
                </FileManagerComponent>
            </div>
        </Card>
    );
};

export default ManageTemplatesPanel;
