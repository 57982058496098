import * as React from "react";
import { Card, Stack } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import FastAccess from "./homeDetails/fastAccess";
import Information from "./homeDetails/information";
import SystemAlert from "./systemAlert";
import HomeTitleBar from "./homeDetails/homeTitleBar";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
    const breadcrumbs = <SimpleBreadcrumbs></SimpleBreadcrumbs>;

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Card sx={{ height: "100%", width: "100%", padding: 2 }}>
                <Stack direction={"column"} spacing={1}>
                    <HomeTitleBar />
                    <SystemAlert />
                    <FastAccess />
                    <Information />
                </Stack>
            </Card>
        </StandardLayout>
    );
};

export default Home;
