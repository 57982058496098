import * as React from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSessionSearchParams } from "../../app/slices/sessionListConfigSlice";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import { getDateStringAdjusted, getUtcDateStringWithoutOffset } from "../../utilities/dateHelpers";
import ActiveSessionsTabPanel from "./activeSessionsTabPanel";
import { UserClaimsService, eGroupIds } from "../../services/currentUserService/currentUserService";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";

export const Sessions: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.sessionListConfig);

    // local state
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    //eslint-disable-next-line
    const [searchString, setSearchString] = useState<string>(
        config.IsDateSearchActive ? "" : config.Keyword
    );
    const [isDateSearchActive, setIsDateSearchActive] = useState<boolean>(
        config.IsDateSearchActive
    );
    const claimsService = new UserClaimsService();
    const isProgramManager = claimsService.IsUserInGroups([eGroupIds.eClientAdmin]);

    // the config date times are set to generic dates when searching by keyword
    //  here we make sure the dates are cleared if the keyword search is active
    //  this is really only for when we return to the page after leaving
    React.useEffect(() => {
        if (!isDateSearchActive) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [isDateSearchActive]);

    const getSessionsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            return;
        }
        setIsDateSearchActive(true);
        dispatch(
            updateSessionSearchParams({
                StartDate: getUtcDateStringWithoutOffset(startDate),
                EndDate: getUtcDateStringWithoutOffset(endDate),
                Keyword: "*",
                IsDateSearchActive: true,
            })
        );
    };

    const getSessionByKeyword = async (searchString: string) => {
        if (searchString == null || searchString === "" || searchString === "*") {
            return;
        }
        setIsDateSearchActive(false);
        dispatch(
            updateSessionSearchParams({
                StartDate: getDateStringAdjusted(-2), // api requires some date but it won't be used in search
                EndDate: getDateStringAdjusted(2), // api requires some date but it won't be used in search
                Keyword: searchString.trim(),
                IsDateSearchActive: false,
            })
        );
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                {isProgramManager ? "My Sessions" : "Sessions"}
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Stack height={1} width={1}>
                {isProgramManager ? (
                    <Card
                        sx={{
                            height: 1,
                            width: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ActiveSessionsTabPanel isProgramManager={true} />
                    </Card>
                ) : (
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <CclTextSearchBar
                                initialSearchTerm={searchString}
                                searchLabel="Enter PR Code, ID, or Title Keyword"
                                executeSearch={getSessionByKeyword}
                            />
                            <CclDateSearchBar
                                initialStartDate={startDate}
                                initialEndDate={endDate}
                                startDateLabel="Earliest Start Date"
                                endDateLabel="Latest Start Date"
                                executeSearch={getSessionsByDate}
                                allowNullDates={false}
                            />
                        </Box>
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: 1,
                                width: 1,
                                padding: 2,
                                paddingTop: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    width: 1,
                                    height: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    flexGrow: 1,
                                }}
                            >
                                <ActiveSessionsTabPanel isProgramManager={false} />
                            </Box>
                        </Grid>
                    </Card>
                )}
            </Stack>
        </StandardLayout>
    );
};

export default Sessions;
