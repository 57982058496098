import * as React from "react";
import { TextField, Autocomplete, Alert, Box } from "@mui/material";
import { useGetRaterTypesByInstrumentKeyQuery } from "../../../../services/cclTokenedEnterpriseRaterApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import { RaterDetails } from "../../../../services/types/assessmentAPITypes";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type RaterTypesDialogProps = {
    open: boolean;
    raterDetail: RaterDetails | null;
    onOk: (rMKey: number, raterTypeId: number) => void;
    onCancel: () => void;
};

const RaterTypesDialog: React.FC<RaterTypesDialogProps> = (props) => {
    const [selectedRaterType, setSelectedRaterType] = React.useState<string>("");
    const { data: raterTypeList, isLoading } = useGetRaterTypesByInstrumentKeyQuery(
        props.raterDetail?.instrumentKey ?? 0,
        { skip: props.raterDetail?.instrumentKey === null }
    );

    const handleOk = () => {
        if (props.raterDetail?.rM_Key != null) {
            props.onOk(
                props?.raterDetail?.rM_Key,
                raterTypeList
                    ? raterTypeList?.filter((r) => r.name === selectedRaterType)[0].raterTypeId
                    : 0
            );
        }
    };

    if (isLoading) {
        return <ComponentLoader msg="Loading" />;
    }

    return (
        <CclDrawer
            title={"Edit Rater"}
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.onCancel}
        >
            <Box>
                <Autocomplete
                    id="tags-standard"
                    options={
                        raterTypeList
                            ?.filter((r) => r.name !== props.raterDetail?.raterTypeName)
                            .map((option) => option.name) ?? []
                    }
                    value={selectedRaterType}
                    onChange={(e: any, newValue: string | null) => {
                        setSelectedRaterType(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Rater Types"
                            label="Rater Types"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
                {(selectedRaterType === "Boss" ||
                    selectedRaterType === "Other" ||
                    selectedRaterType === "Superior") && (
                    <Alert severity="warning">
                        You are switching this rater to a non-Confidential Type. Please notify the
                        rater if necessary.
                    </Alert>
                )}
            </Box>

            <CclDrawerActionBar>
                <CclButton id="cancel-btn" onClick={() => props.onCancel()} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclButton
                    id="ok-btn"
                    disabled={selectedRaterType === "" || selectedRaterType === null}
                    onClick={handleOk}
                    mode={"primary"}
                >
                    Save
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};
export default RaterTypesDialog;
