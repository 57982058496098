import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblUserGeneralInfoTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "imKey",
        fieldType: "string",
        editable: false,
        label: "User ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "fullname",
        fieldType: "string",
        editable: false,
        label: "Name",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "email",
        fieldType: "string",
        editable: false,
        label: "Email",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto: @@@",
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
        
    },
    {
        id: "accountId",
        fieldType: "string",
        editable: false,
        label: "Grand Central ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
          value: "",
          editFieldType: eInfoTableValueFieldEditType.Text,
          url: null,
          hoverText: null,
          checked: null,
          id: "",
          selectOptions: null, //TODO: Fix this for drop down option
        }
    }
];
