import * as React from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
    UpdateWorkPoolMember,
    WorkPool,
    WorkPoolMember,
} from "../../../../../services/types/resources/workPoolTypes";
import {
    useRemoveCadreMemberMutation,
    useUpdateCadreMemberAvailabilityMutation,
} from "../../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import { AccessEventWorkPoolDetails } from "../../../../../services/types/accessEventTypes";
import { useSelector } from "react-redux";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import CclPersistentDataGrid from "../../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { updateWorkPoolResourceGridState } from "../../../../../app/slices/workPoolsResourceListTableStateSlice";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclCommonLink from "../../../../../components/common/cclCommonLink";
import AddUserDialog from "../searchUsers/addUserDialog";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

interface WorkPoolGeneralProps {
    loading: boolean;
    data: WorkPool;
}

const WorkPoolGeneral: React.FC<WorkPoolGeneralProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.workPoolResourceListTableState);

    const claimservice = new UserClaimsService();

    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [addUserDialogOpen, setAddUserDialogOpen] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    const [removeMember, {isError, isLoading}] = useRemoveCadreMemberMutation();
    const [doUpdateAvailability] = useUpdateCadreMemberAvailabilityMutation();

    const [selectedRow, setSelectedRow] = React.useState<any>(null);

    const { logEvent } = useLogAccessEvent();

    const startRemoveMemberFlow = () => {
        let selectedUser: UpdateWorkPoolMember = {
            cKey: props.data.cKey,
            imKey: selectedRow[0].imKey,
        };
         handleRemoveMember(selectedUser);
    };

    const handleRemoveMember = (user: UpdateWorkPoolMember) => {
        removeMember(user)
            .unwrap()
            .then(() => {
                setShowSnackbar(true);
                setMessage(isError ? "Work Pool Member Deletion Failed" : "Work Pool Member Deleted");
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${props.data?.cKey}`,
                    workPoolName: props.data?.name ?? "",
                };
                logEvent("WorkPoolMemberDeleted", evtData);
            });
    };

    const updateAvailability = (imKey: string, cKey: string) => {
        doUpdateAvailability([imKey, cKey]).unwrap().then(() => {
            const evtData : AccessEventWorkPoolDetails = {
                workPoolId: props.data.cKey.toString(),
                workPoolName: props.data.name,
            }
            logEvent("WorkPoolMemberEdited", evtData);
        });
    };

    let COLUMNS = [
        {
            field: "available",
            headerName: "Available",
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {claimservice.IsUserInAppRole(1) ? (
                        <>
                            <Switch
                                defaultChecked={params.value}
                                onChange={() => {
                                    updateAvailability(params.row.imKey, params.row.cKey);
                                }}
                                color="primary"
                            ></Switch>
                        </>
                    ) : (
                        <>
                            <Typography>{params.value ? "Yes" : "No"}</Typography>
                        </>
                    )}
                </>
            ),
            flex: 0.5,
            type: "string",
        },
        {
            field: "imKey",
            headerName: "ID",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink text={params.value} keyVal={params.value} to={`/resource/${params.row.pKey}/0`} />
            ),
            type: "string",
            width: 100,
        },
        {
            field: "name",
            headerName: "Resource",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink text={params.value} keyVal={params.value} to={`/resource/${params.row.pKey}/0`} />
            ),
            flex: 0.8,
            valueGetter: getFullName,
        },
        {
            field: "emailAddress",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink text={params.value} keyVal={params.value} to={`mailto:${params.value}`}  type={"email"}/>
            ),
            flex: 1.25,
        },
        {
            field: "status",
            headerName: "D365 Status",
            flex: 0.65,
            type: "string",
        },
        {
            field: "resourceType",
            headerName: "Resource Type",
            flex: 0.65,
            type: "string",
        },
        {
            field: "region",
            headerName: "Region",
            flex: 0.65,
            type: "string",
        },
        {
            field: "reportingLocation",
            headerName: "Reporting Location",
            flex: 0.65,
            type: "string",
        },
    ];

    return (
        <>
            <Stack spacing={2} width={"100%"}>
                {props.data.deliverySupportCadreMember && (
                    <CclPersistentDataGrid
                        columns={COLUMNS}
                        data={props.data.deliverySupportCadreMember}
                        loading={props.loading}
                        stateSelector={stateSelector}
                        userPreferenceId={0}
                        getRowIdFn={(row: WorkPoolMember) => row.imKey}
                        actionCreator={updateWorkPoolResourceGridState}
                        initState={JSON.stringify({
                            sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                            columns: { columnVisibilityModel: { status: true } },
                            filter: {
                                filterModel: {
                                    items: [
                                        {
                                            id: 1,
                                            columnField: "status",
                                            operatorValue: "startsWith",
                                            value: "active",
                                        },
                                    ],
                                },
                            },
                        })}
                        miscButtons={
                            claimservice.IsUserInAppRole(1) ? (
                                [
                                    {
                                        id: "add-member",
                                        caption: "Add Member",
                                        onClick: () => {setAddUserDialogOpen(true)},
                                        restricted: true,
                                        returnSelected: true,
                                    },
                                    {
                                        id: "delete-member",
                                        caption: "Delete Member",
                                        onClick: () => {startRemoveMemberFlow()},
                                        restricted: false,
                                        returnSelected: true,
                                        loadingBtn: true,
                                        isLoading: isLoading,
                                        disable: !selectedRow || selectedRow?.length === 0 || selectedRow?.length > 1,
                                    }
                            ]) : []}
                        hideFooter={false}
                        hideSettingsButtons={true}
                        showCheckboxes={true}
                        onSelectedRowsChange={(rows: any[] | undefined) => setSelectedRow(rows)}
                    />
                )}
            </Stack>

            <AddUserDialog 
                data={props.data} 
                cKey={props.data.cKey} 
                open={addUserDialogOpen}
                status={(error:boolean)=>{setError(error)}}
                onClose={(trigger: boolean) => {
                    setAddUserDialogOpen(false);
                    if (trigger) {
                        setShowSnackbar(true);
                        if (error) {
                            setMessage("Work Pool Member Addition Failed");
                        } else {
                            setMessage("Work Pool Member Added");
                        }
                    }
                }}            
            />

            <CclAlertSnackbar 
                open={showSnackbar} 
                message={message} 
                severity={error || isError ? "error" : "success"} 
                onClose={() => setShowSnackbar(false) }            
            />
        </>
    );
};

export default WorkPoolGeneral;
