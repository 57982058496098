import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useEnv } from "./context/env.context";

export const Auth0ProviderWithHistory = ({
    children,
}: PropsWithChildren<any>): JSX.Element | null => {
    const navigate = useNavigate();
    const { domain, clientId, audience, applicationId, tenantId } = useEnv();

    const onRedirectCallback = (appState?: AppState | undefined, user?: User | undefined) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    //if (!(domain && clientId && audience)) {
    if (!(domain && clientId)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: window.location.origin,
                scope: `openid profile email loginApplicationId-${applicationId} tenantId-${tenantId} signup-false`,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};
