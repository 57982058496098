import * as React from "react";
import { Stack, Card, Grid, AlertColor, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateProductSaleSearchParams } from "../../app/slices/productSaleListConfigSlice";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import {
    useChangeProductSalesFacilitatorMutation,
    useGetProductSaleParticipantsByDateMutation,
    useLazyGetParticipantCompassUrlQuery,
    useLazyGetProductSaleParticipantsByFacilitatorOrParticipantEmailQuery,
    useReleaseProductSalesMutation,
} from "../../services/cclTokenedEnterpriseParticipantApi";
import {
    ChangeProductSalesFacilitatorPayload,
    GetCompassUrlResponse,
    ProductSaleParticipant,
} from "../../services/types/enterpriseParticipantApiTypes";
import { getDateStringAdjusted } from "../../utilities/dateHelpers";
import ProductSaleAssessmentList from "./productSaleAssessmentList";
import { useSendCompassInvitationsMutation } from "../../services/cclTokenedGrandCentralApi";
import {
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../services/types/rtkQueryTypes";
import { FileDownloadService } from "../../services/fileDownloadService/fileDownloadService";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import ComponentLoader from "../../components/common/componentLoader";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";
import CclEnterNewFacilitatorDrawer from "./cclEnterNewFacilitatorDrawer";
import { useSearchParams } from "react-router-dom";

export type ProductSalesCardProps = {
    searchparam: string;
};

const ProductSalesCard: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.productSaleListConfig);
    const [params] = useSearchParams();

    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [currentEvent, setCurrentEvent] = React.useState<string>("");

    const [searchEmail, setSearchEmail] = React.useState<string>("");
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);
    const [
        runSearch,
        {
            data: paxByDate,
            isLoading: paxByDateIsLoading,
            isError: paxByDateIsError,
            isSuccess: paxByDateIsSuccess,
        },
    ] = useGetProductSaleParticipantsByDateMutation();
    const [
        trigger,
        {
            data: paxByEmail,
            isLoading: paxByEmailIsLoading,
            isError: paxByEmailIsError,
            isSuccess: paxByEmailIsSuccess,
        },
    ] = useLazyGetProductSaleParticipantsByFacilitatorOrParticipantEmailQuery();
    const [reportsToReplace, setReportsToReplace] = React.useState<string[]>([]);
    const [openFacilitatorEmailDialog, setOpenFacilitatorEmailDialog] =
        React.useState<boolean>(false);
    const [sendChangeFacilitator, { isSuccess: changeFacilitatorSuccess }] =
        useChangeProductSalesFacilitatorMutation();
    const { logEvent } = useLogAccessEvent();
    const [errorOpen, setErrorOpen] = React.useState<boolean>(false);
    const [releaseReports] = useReleaseProductSalesMutation();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();
    const downloadService = new FileDownloadService();

    const [productSales, setProductSales] = React.useState<ProductSaleParticipant[]>([]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    // eslint-disable-next-line
    const clearDateSearch = () => {
        // this is a hack to overcome an error in the mui datepicker that won't allow us to set an invalid date to null.
        // We have to first set a valid date, then go to null
        if (startDateError) {
            setStartDate(new Date());
            setTimeout(() => setStartDate(null), 100);
        } else {
            setStartDate(null);
        }
        if (endDateError) {
            setEndDate(new Date());
            setTimeout(() => setEndDate(null), 100);
        } else {
            setEndDate(null);
        }
        setEndDateError(false);
        setStartDateError(false);
    };

    React.useEffect(() => {
        if (!params.get("searchparam")) {
            return;
        }
        setSearchEmail(params.get("searchparam") ?? "");
        trigger(params.get("searchparam") ?? "");
        //eslint-disable-next-line
    }, [params]);

    React.useEffect(() => {
        if (!changeFacilitatorSuccess) return;

        if (config.IsEmailSearchActive) {
            clearDateSearch();
            trigger(config.Email.trim());
        } else {
            setSearchEmail("");
            runSearch({
                start: config.StartDate,
                end: config.EndDate,
            });
        }

        setStartDateError(false);
        setEndDateError(false);
        // eslint-disable-next-line
    }, [changeFacilitatorSuccess]);

    React.useEffect(() => {
        if (paxByDateIsError) {
            setErrorOpen(true);
            return;
        }

        if (paxByDate) {
            setProductSales(paxByDate);
        } else {
            setProductSales([]);
        }
        // eslint-disable-next-line
    }, [paxByDateIsError, paxByDate]);

    React.useEffect(() => {
        if (paxByEmailIsError) {
            setErrorOpen(true);
            return;
        }

        if (paxByEmail) {
            setProductSales(paxByEmail);
        } else {
            setProductSales([]);
        }
        // eslint-disable-next-line
    }, [paxByEmail, paxByEmailIsError]);

    const getAssessmentsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            setStartDateError(startDate == null);
            setEndDateError(endDate == null);
            return;
        }
        setStartDateError(false);
        setEndDateError(false);
        setSearchEmail("");
        runSearch({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        });
        setStartDate(startDate);
        setEndDate(endDate);
        dispatch(
            updateProductSaleSearchParams({
                StartDate: startDate.toISOString(),
                EndDate: endDate.toISOString(),
                Email: searchEmail,
                IsEmailSearchActive: false,
            })
        );
    };

    const getAssessmentsByEmail = async (email?: string) => {
        if ((searchEmail == null || searchEmail.trim() === "") && !email) {
            return;
        }
        clearDateSearch();
        if (email) setSearchEmail(email.trim());
        trigger(email ? email.trim() : searchEmail.trim());
        dispatch(
            updateProductSaleSearchParams({
                StartDate: getDateStringAdjusted(-2), // api requires some date but it won't be used in search
                EndDate: getDateStringAdjusted(2), // api requires some date but it won't be used in search
                Email: email ? email.trim() : searchEmail.trim(),
                IsEmailSearchActive: true,
            })
        );
    };

    const handleChangeFacilitator = (email: string) => {
        if (reportsToReplace?.length > 0 && email !== "") {
            setCurrentEvent("change-facilitator");

            let payload: ChangeProductSalesFacilitatorPayload = {
                facilitatorEmail: email,
                reportIds: reportsToReplace,
            };
            sendChangeFacilitator(payload)
                .unwrap()
                .then(() => {
                    logEvent("FacilitatorChanged", null);
                    ShowSnackbarElement("Facilitator Updated", "success");
                    setOpenFacilitatorEmailDialog(false);
                    setCurrentEvent("");
                })
                .catch((error) => {
                    ShowSnackbarElement("Facilitator Update Failed", "error");
                    setOpenFacilitatorEmailDialog(false);
                    setCurrentEvent("");
                });
        } else {
            ShowSnackbarElement("Facilitator Update Failed", "error");
            setOpenFacilitatorEmailDialog(false);
            setCurrentEvent("");
        }
    };

    const changeFacilitator = (rows?: any[]) => {
        if (rows != null && rows?.length > 0) {
            var reportIds: string[] = rows.map((r) => r.reportId);
            setReportsToReplace([...reportIds]);
            setOpenFacilitatorEmailDialog(true);
        } else {
            setReportsToReplace([]);
            setOpenFacilitatorEmailDialog(false);
        }
    };

    const handleDownloadReports = (rows?: any[]) => {
        if (rows === undefined || rows.length <= 0) {
            return;
        }

        setCurrentEvent("download");
        const reportIds = rows.map((r) => r.reportId as string);
        let filename = "ProductSales.zip";
        if (rows.length === 1) {
            if (rows[0] == null) return; // shouldn't happen but just in case
            filename = `${rows[0].fullName} ${rows[0].assessmentType}.pdf`;
        }

        downloadService
            .DownloadProductSaleReports({
                fname: filename,
                reportids: reportIds,
            })
            .then(() => {
                logEvent("UserDownloadedProductSale", null);
                ShowSnackbarElement("Download Completed", "success");
                setCurrentEvent("");
            })
            .catch((error) => {
                ShowSnackbarElement("Download Failed", "error");
                setCurrentEvent("");
            });
    };

    const handleActivateCompass = (rows?: any[]) => {
        if (rows === undefined || rows.length <= 0) {
            return;
        }

        setCurrentEvent("activate");
        const reportIds = rows.map((r) => r.reportId as string);
        releaseReports(reportIds)
            .unwrap()
            .then((releaseResults) => {
                if (releaseResults === undefined || releaseResults.length <= 0) {
                    ShowSnackbarElement("Compass Activation Failed", "error");
                    setCurrentEvent("");
                    return;
                }

                const releasedReportIds: string[] =
                    releaseResults?.filter((r) => r.success).map((r) => r.reportId) ?? [];

                if (releasedReportIds != null && releasedReportIds.length > 0) {
                    const prodSaleParticipants: ProductSaleParticipant[] =
                        productSales?.filter((ps) =>
                            releasedReportIds.some((r) => r === ps.reportId)
                        ) ?? [];
                    const sendInvitesPayload: SendCompassInvitationRequest = {
                        recipients: prodSaleParticipants.map((p) => {
                            return {
                                email: p.emailAddress,
                                firstName: p.firstName,
                                lastName: p.lastName,
                            };
                        }),
                        disableEmail: false,
                        autoAccept: false,
                        ignorePendingInvites: false,
                    };
                    //refresh product sale search results
                    if (config.IsEmailSearchActive) {
                        getAssessmentsByEmail();
                    } else {
                        getAssessmentsByDate(startDate, endDate);
                    }
                    sendCompassInvitations(sendInvitesPayload)
                        .unwrap()
                        .then((response: SendInvitationResponse) => {
                            if (response.numberInivitationsSent > 0) {
                                logEvent("UserActivatedProductSales", null);
                                ShowSnackbarElement("Compass Activation Completed", "success");
                            } else if (response.numberFailed > 0) {
                                ShowSnackbarElement("Compass Activation Failed", "error");
                            }
                            setCurrentEvent("");
                        })
                        .catch((error) => {
                            ShowSnackbarElement("Compass Activation Failed", "error");
                            setCurrentEvent("");
                        });
                } else {
                    ShowSnackbarElement("Compass Activation Failed", "error");
                    setCurrentEvent("");
                }
            })
            .catch((error) => {
                ShowSnackbarElement("Compass Activation Failed", "error");
                setCurrentEvent("");
            });
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                ShowSnackbarElement("Error Opening Compass", "error");
            });
    };

    return (
        <Stack height={1} width={1}>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclEnterNewFacilitatorDrawer
                open={openFacilitatorEmailDialog}
                onOk={(email: string) => handleChangeFacilitator(email)}
                onCancel={() => setOpenFacilitatorEmailDialog(false)}
                saving={currentEvent === "change-facilitator"}
            />
            <CclErrorDialog
                open={errorOpen}
                title={"Error Retrieving Product Sale Assessments"}
                msg={"Make sure the date range for your search is not larger than one month."}
                onOk={() => setErrorOpen(false)}
            ></CclErrorDialog>
            <Card
                sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CclTextSearchBar
                        initialSearchTerm={searchEmail}
                        searchLabel="Enter Participant or Facilitator Email"
                        executeSearch={getAssessmentsByEmail}
                    />
                    <CclDateSearchBar
                        initialStartDate={null}
                        initialEndDate={null}
                        startDateLabel="Earliest Scored Date"
                        endDateLabel="Latest Scored Date"
                        executeSearch={getAssessmentsByDate}
                        allowNullDates={false}
                    />
                </Box>
                {paxByDateIsLoading || paxByEmailIsLoading ? (
                    // search, or loading in progress
                    <ComponentLoader msg={"Loading"} />
                ) : paxByDateIsSuccess || paxByEmailIsSuccess || productSales.length > 0 ? (
                    <Grid
                        sx={{
                            height: 1,
                            width: 1,
                            padding: 2,
                            paddingTop: 0,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <ProductSaleAssessmentList
                            loading={paxByEmailIsLoading || paxByDateIsLoading}
                            productSales={productSales ?? []}
                            changeFacilitator={(rows?: any[]) => changeFacilitator(rows)}
                            downloadReports={(rows?: any[]) => handleDownloadReports(rows)}
                            activateCompass={(rows?: any[]) => handleActivateCompass(rows)}
                            viewCompass={viewCompass}
                            currentEvent={currentEvent}
                        />
                    </Grid>
                ) : (
                    <CclSearchDefault
                        line1="Start searching for Product Sales"
                        line2="Enter Participant or Facilitator Email or enter a Date Range and click Search"
                    />
                )}
            </Card>
        </Stack>
    );
};

export default ProductSalesCard;
