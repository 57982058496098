import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const RedirectToEmail: React.FC = () => {
    const { notificationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/email/${notificationId}`);
    }, [notificationId, navigate]);

    return null;
};

export default RedirectToEmail;
