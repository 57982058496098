import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
    return isRtkError(error) && "status" in error;
};

export const isRtkError = (
    result: any
): result is { error: FetchBaseQueryError | SerializedError } => !!result.error;

export const getErrorMsg = (error: unknown, defaultMsg: string = ""): string => {
    if (isRtkError(error)) {
        if (isFetchBaseQueryError(error)) return JSON.stringify(error.data);
        else if ("message" in error) return (error as SerializedError)?.message ?? defaultMsg;
    }
    return defaultMsg;
};
