import * as React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Box, IconButton, styled, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import UserInfoPanel from '../userInfoPanel';

const drawerWidth = 216;

interface AppBarProps extends MuiAppBarProps {
    open: boolean;
    smallScreen: boolean;
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !=="smallScreen" && prop !== "open"})<AppBarProps>(({ open, smallScreen, ...props }) => ({
    width: smallScreen ? "100%" : `calc(100% - 71px)`,
    marginLeft: 0,
    zIndex: props.theme.zIndex.drawer + 1,
    transition: props.theme.transitions.create(["width", "margin"], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: smallScreen ? "100%" : `calc(100% - ${drawerWidth}px)`,
        transition: props.theme.transitions.create(["width", "margin"], {
            easing: props.theme.transitions.easing.sharp,
            duration: props.theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export interface TopbarProps {
    desktopOpen: boolean;
    smallScreen: boolean;
    breadcrumbs: React.ReactNode;
    handleDesktopDrawerToggle: () => void;
    handleMobileDrawerToggle: () => void;
}

export const Topbar: React.FC<TopbarProps> = ({desktopOpen, smallScreen, handleDesktopDrawerToggle, handleMobileDrawerToggle, breadcrumbs}) => {

    return (
        <AppBar 
            open={desktopOpen} 
            smallScreen={smallScreen} 
            position="fixed" 
            sx={{boxShadow: "none"}}
        >
            <Toolbar className="toolbar" sx={{minHeight:"0px !important"}} >
                {smallScreen ? (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleMobileDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDesktopDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        {!desktopOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                )}
                <Box sx={{ flexGrow: 1 }}>{breadcrumbs}</Box>
                <UserInfoPanel />
            </Toolbar>
        </AppBar>
    )
};

export default Topbar;