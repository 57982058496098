import * as React from "react";
import { AlertColor, Box, Card, Grid } from "@mui/material";
import { useGetCclEventsMutation } from "../../services/cclTokenedSessionApi";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import { useLazyGetAssetsBySessionKeyQuery } from "../../services/cclTokenedAssetsApi";
import { useLazyGetCclParticipantsByEventKeyQuery } from "../../services/cclTokenedEnterpriseParticipantApi";
import { EventDocument } from "../../services/types/search.service.types";
import ScoringReportDownloadsDataGrid from "./scoringReportDownloadsDataGrid";
import ComponentLoader from "../../components/common/componentLoader";
import { FileDownloadService } from "../../services/fileDownloadService/fileDownloadService";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import { AccessEventSessionDetails } from "../../services/types/accessEventTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";
import CclDownloadWarningAlert from "../../components/common/cclDownloadWarningAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateScoreReportDownloadSearchParams } from "../../app/slices/scoreReportDownloadListConfigState";
import { Asset, Participant } from "../../services/types/enterpriseParticipantApiTypes";

export interface ScoringReportDownloadsProps {}

export const ScoringReportDownloads: React.FC<ScoringReportDownloadsProps> = (props) => {
    const config = useSelector((state: any) => state.scoreReportDownloadListConfig);
    const [eventInProgess, setEventInProgress] = React.useState<string>("");
    const [searching, setSearching] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>(config.Keyword ?? "");
    const [foundProgramCode, setFoundProgramCode] = React.useState<string>("");
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [allAssets, setAllAssets] = React.useState<Asset[] | undefined>(undefined);
    const [allParticipants, setAllParticipants] = React.useState<Participant[] | undefined>(
        undefined
    );
    const [searchForEvent] = useGetCclEventsMutation();
    const [getAssetsBySessionKey] = useLazyGetAssetsBySessionKeyQuery();
    const [getParticipantsByEventKey] = useLazyGetCclParticipantsByEventKeyQuery();

    const { logEvent } = useLogAccessEvent();
    const dispatch = useDispatch();
    const downloadService = new FileDownloadService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const executeSearch = React.useCallback(
        (keyword: string, manual: boolean) => {
            setSearching(true);
            setSearchTerm(keyword);
            setAllAssets(undefined);
            setAllParticipants(undefined);
            searchForEvent({ keyword: keyword })
                .unwrap()
                .then((results: EventDocument[]) => {
                    let matches = results.filter(
                        (e) => e.eventnumber.toString() === keyword || e.projectCode === keyword
                    );
                    if (matches?.length > 0) {
                        setFoundProgramCode(matches[0].projectCode);
                        getParticipantsByEventKey(matches[0].eventnumber)
                            .unwrap()
                            .then((participants: Participant[]) => {
                                if (participants.length === 0) {
                                    ShowSnackbarElement("No assessments found", "warning");
                                } else {
                                    setAllParticipants(participants);
                                    getAssetsBySessionKey(matches[0].eventnumber)
                                        .unwrap()
                                        .then((assets: Asset[]) => {
                                            setSearching(false);
                                            if (assets.length > 0) {
                                                setAllAssets(assets);
                                            } else {
                                                if (manual) {
                                                    ShowSnackbarElement(
                                                        "No assessments found",
                                                        "warning"
                                                    );
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            setSearching(false);

                                            ShowSnackbarElement(
                                                "Error retrieving session or related assessments.",
                                                "error"
                                            );
                                        });
                                }
                            })
                            .catch((error) => {
                                setSearching(false);
                                ShowSnackbarElement(
                                    "Error retrieving session or related assessments.",
                                    "error"
                                );
                            });
                    } else {
                        setFoundProgramCode("");
                        if (manual) {
                            ShowSnackbarElement("Session not found.", "warning");
                        }
                        setSearching(false);
                    }
                })
                .catch((error) => {
                    setSearching(false);
                });
            dispatch(updateScoreReportDownloadSearchParams({ Keyword: keyword }));
        },
        [searchForEvent, getParticipantsByEventKey, getAssetsBySessionKey, dispatch]
    );

    React.useEffect(() => {
        executeSearch(searchTerm, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeSearch]);

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        setEventInProgress(eventName);
        switch (eventName) {
            case "download":
                let filename: string = "";
                let aikeys = selectedIds.map((id) => +id);
                if (aikeys.length > 30) {
                    ShowSnackbarElement(
                        "Maximum of 30 files may be downloaded at one time.",
                        "error"
                    );
                    return;
                } else if (aikeys.length > 1) {
                    filename =
                        foundProgramCode !== ""
                            ? `${foundProgramCode}_ScoredAssessments.zip`
                            : "ScoredAssessments.zip";
                } else if (aikeys.length === 1 && allAssets != null && allAssets.length >= 1) {
                    let ast = allAssets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        let fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    ShowSnackbarElement("Download Not Possible", "error");
                    setEventInProgress("");
                    return; // nothing selected
                }
                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: true })
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: foundProgramCode ?? "",
                        };
                        logEvent("AssessmentsDownloaded", evtData);
                        ShowSnackbarElement("Download Completed", "success");
                        setEventInProgress("");
                    })
                    .catch((error: { message: string }) => {
                        ShowSnackbarElement("Download Failed", "error");
                        setEventInProgress("");
                    });
                break;
        }
    };

    const getSessionByKeyword = (keyword: string) => {
        executeSearch(keyword, true);
    };

    return (
        <Box width={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
            <Card
                sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CclTextSearchBar
                        initialSearchTerm={searchTerm}
                        searchLabel="Enter PR Code or ID"
                        executeSearch={getSessionByKeyword}
                    />
                </Box>
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: 1,
                        width: 1,
                        padding: 2,
                        paddingTop: 0,
                    }}
                >
                    <Box
                        gap={2}
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            position: "relative",
                        }}
                    >
                        {showSnackbar ? (
                            <CclAlertSnackbar
                                open={true}
                                message={snackbarMessage}
                                severity={snackbarSeverity}
                                onClose={() => setShowSnackbar(false)}
                            />
                        ) : null}
                        {searching ? (
                            // search, or loading in progress
                            <ComponentLoader msg={"Loading"} />
                        ) : allAssets !== undefined &&
                          allParticipants !== undefined &&
                          allAssets?.length > 0 &&
                          allParticipants?.length > 0 ? (
                            <React.Fragment>
                                <CclDownloadWarningAlert />
                                <ScoringReportDownloadsDataGrid
                                    participants={
                                        allParticipants?.filter(
                                            (p) => p.registrationStatus === "Confirmed"
                                        ) ?? []
                                    }
                                    assets={
                                        allAssets?.filter((a) => a.fileType === "Assessment") ?? []
                                    }
                                    handleEvent={onGridToolbarButtonClick}
                                    eventInProgress={eventInProgess}
                                />
                            </React.Fragment>
                        ) : (
                            <CclSearchDefault
                                line1="Start searching for Sessions"
                                line2="Enter PR Code or ID and click Search"
                            />
                        )}
                    </Box>
                </Grid>
            </Card>
        </Box>
    );
};

export default ScoringReportDownloads;
