import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertColor, Box, Card, Grid, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import UserSearchDataGridPro from "./userSearchList";
import {
    updateUserSearchParams,
    UserSearchListConfigState,
} from "../../app/slices/userSearchListConfigSlice";
import { SearchEnterpriseUsersRequest } from "../../services/types/search.service.types";
import { useSearchEnterpriseUsersMutation } from "../../services/cclTokenedEnterpriseUserApi";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import { useSearchParams } from "react-router-dom";
import ComponentLoader from "../../components/common/componentLoader";
import CclDoubleTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclDoubleTextSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import CclButton from "../../components/common/cclButtons/cclButton";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";
import CreateNewUserDrawer from "./createNewUserDrawer";

interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
    // redux state
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const searchparam = searchParams.get("searchparam") ?? "";
    const config: UserSearchListConfigState = useSelector(
        (state: any) => state.userSearchListConfig
    );

    const [searchEmail, setSearchEmail] = React.useState<string>(
        searchparam ? searchparam : config.Email
    );
    const [searchFirstName, setSearchFirstName] = React.useState<string>(config.FirstName);
    const [searchLastName, setSearchLastName] = React.useState<string>(config.LastName);
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [showCreateNewUserDrawer, setShowCreateNewUserDrawer] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const [searchUsers, { data: users, isLoading, isSuccess }] = useSearchEnterpriseUsersMutation();

    const claimsService = new UserClaimsService();
    const isUserAdmin = claimsService.IsUserSuperAdmin();

    React.useEffect(() => {
        if (isSuccess && users && users.length >= 30000) setShowSearchToBroad(true);
        //eslint-disable-next-line
    }, [users, isSuccess]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    React.useEffect(() => {
        if (!searchParams || !searchparam) {
            return;
        }
        const payload = {
            keywords: [`${searchParams.get("searchparam") ?? ""}`],
        };
        runSearchUsers(payload);
        //eslint-disable-next-line
    }, [searchParams]);

    const getUsersByEmail = async (email: string) => {
        if (email == null || email === "") {
            return;
        }
        setSearchFirstName("");
        setSearchLastName("");
        runSearchUsers({
            keywords: email.split(" "),
            firstName: "",
            lastName: "",
            partialSearch: false,
        });
    };

    const getUsersByName = async (firstName: string, lastName: string) => {
        if ((firstName == null || firstName === "") && (lastName == null || lastName === "")) {
            return;
        }
        setSearchEmail("");
        runSearchUsers({
            keywords: [],
            firstName: firstName,
            lastName: lastName,
            partialSearch: false,
        });
    };

    const runSearchUsers = async (payload: SearchEnterpriseUsersRequest) => {
        searchUsers(payload);

        dispatch(
            updateUserSearchParams({
                FirstName: payload.firstName ?? "",
                LastName: payload.lastName ?? "",
                Email: payload.keywords.join(", "),
                IsEmailSearchActive: payload.keywords.length > 0,
            })
        );
    };

    const handleCreateNewUser = async () => {
        setShowCreateNewUserDrawer(true);
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Users
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <React.Fragment>
                <CclErrorDialog
                    open={showSearchToBroad}
                    title={"Too Many Results"}
                    msg={
                        "There are too many results for this search. Consider adjusting your search parameters and searching again."
                    }
                    onOk={() => setShowSearchToBroad(false)}
                />
                {showCreateNewUserDrawer ? (
                    <CreateNewUserDrawer
                        open={true}
                        onClose={() => setShowCreateNewUserDrawer(false)}
                        showSnackbar={ShowSnackbarElement}
                    />
                ) : null}
                <Stack height={1} width={1}>
                    {showSnackbar ? (
                        <CclAlertSnackbar
                            open={true}
                            onClose={() => setShowSnackbar(false)}
                            message={snackbarMessage}
                            severity={snackbarSeverity}
                        />
                    ) : null}
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <CclTextSearchBar
                                initialSearchTerm={searchEmail}
                                searchLabel="Enter User Email(s) or ID(s) or Search Term(s)"
                                executeSearch={getUsersByEmail}
                            />
                            <CclDoubleTextSearchBar
                                executeSearch={getUsersByName}
                                initialFieldOneSearchTerm={searchFirstName}
                                fieldOneSearchLabel={"Enter First Name"}
                                initialFieldTwoSearchTerm={searchLastName}
                                fieldTwoSearchLabel={"Enter Last Name"}
                                requireBothFields={false}
                                suppressErrors={true}
                            />
                        </Box>
                        {isLoading ? (
                            <ComponentLoader msg={""} />
                        ) : isSuccess && users ? (
                            <Grid
                                sx={{
                                    height: 1,
                                    width: 1,
                                    padding: 2,
                                    paddingTop: 0,
                                }}
                            >
                                <UserSearchDataGridPro
                                    createNewUser={handleCreateNewUser}
                                    loading={isLoading}
                                    users={users ?? []}
                                />
                            </Grid>
                        ) : (
                            <CclSearchDefault
                                line1="Start searching for Users"
                                line2="Enter User Email(s), ID(s) or enter First and Last Name and click Search"
                                hasButtons={isUserAdmin}
                            >
                                <CclButton mode={"primary"} onClick={handleCreateNewUser}>
                                    Create User
                                </CclButton>
                            </CclSearchDefault>
                        )}
                    </Card>
                </Stack>
            </React.Fragment>
        </StandardLayout>
    );
};

export default Users;
