import { Box } from '@mui/material';
import * as React from 'react';
import UserAuth0LoginPanel from '../../../users/userDetail/auth0Logins/userAuth0LoginPanel';

interface PartnerLoginsProps {
    email: string;
}

const PartnerLogins: React.FC<PartnerLoginsProps> = (props) => {

    return (
        <Box sx={{ width: 1, height: 1 }}>
            <UserAuth0LoginPanel UserEmail={props.email}/>
        </Box>
    );
}

export default PartnerLogins;