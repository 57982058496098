import * as React from "react";
import { Alert, AlertTitle, Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import { DetailsTabPanel } from "../../../../components/common/detailTabPanel/detailTabPanel";
import { useGetAssetsBySessionKeyQuery } from "../../../../services/cclTokenedAssetsApi";
import { useGetAssignedCoachesBySessionKeyQuery } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../services/cclTokenedEnterpriseParticipantApi";
import { Asset } from "../../../../services/types/enterpriseParticipantApiTypes";
import ParticipantFilesSubPanel from "./participantFilesSubPanel";
import SessionFilesSubPanel from "./sessionFilesSubPanel";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";

interface SessionFilesPanelProps {
    sessionId: string;
    readOnly?: boolean;
    isProductSale?: boolean;
}

const SessionFilesPanel: React.FC<SessionFilesPanelProps> = (props) => {
    const [alignment, setAlignment] = React.useState<number>(0);
    const [sessionAssets, setSessionAssets] = React.useState<Asset[]>([]);
    const [participantAssets, setParticipantAssets] = React.useState<Asset[]>([]);
    const {
        data: allAssets,
        isSuccess,
        isLoading,
        isError,
        error,
    } = useGetAssetsBySessionKeyQuery(props.sessionId, { skip: props.sessionId === "" });
    const { data: allParticipants, isSuccess: paxSuccess } = useGetCclParticipantsByEventKeyQuery(
        props.sessionId,
        { skip: props.sessionId === "" }
    );
    const { data: assignedCoaches } = useGetAssignedCoachesBySessionKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });

    React.useEffect(() => {
        if (isSuccess && paxSuccess && allAssets != null && allAssets.length > 0) {
            const confirmedParticipants = allParticipants.filter(
                (p) => p.registrationStatus === "Confirmed"
            );
            const sesAssets: Asset[] = allAssets.filter(
                (a) => confirmedParticipants?.findIndex((p) => p.imKey === a.imKey) === -1
            );
            const paxAssets: Asset[] = allAssets.filter(
                (a) => confirmedParticipants?.findIndex((p) => p.imKey === a.imKey) !== -1
            );

            setSessionAssets(sesAssets.length > 0 ? [...sesAssets] : []);
            setParticipantAssets(paxAssets.length > 0 ? [...paxAssets] : []);
        }

        if (allAssets == null || allAssets.length === 0) {
            setSessionAssets([]);
            setParticipantAssets([]);
        }
        // eslint-disable-next-line
    }, [allParticipants, allAssets]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setAlignment(newValue);
    };

    // Tablist
    const claimsService = new UserClaimsService();
    const tablist: [string, React.ReactNode][] =
        claimsService.IsUserInGroups([eGroupIds.eClientAdmin]) || props.isProductSale
            ? [
                  [
                      "Participant Files",
                      <ParticipantFilesSubPanel
                          sessionId={props.sessionId}
                          assets={participantAssets}
                          participants={
                              allParticipants?.filter(
                                  (p) => p.registrationStatus === "Confirmed"
                              ) ?? []
                          }
                          readOnly={props.readOnly}
                      />,
                  ],
              ]
            : [
                  [
                      "Participant Files",
                      <ParticipantFilesSubPanel
                          sessionId={props.sessionId}
                          assets={participantAssets}
                          participants={
                              allParticipants?.filter(
                                  (p) => p.registrationStatus === "Confirmed"
                              ) ?? []
                          }
                          readOnly={props.readOnly}
                      />,
                  ],
                  [
                      "Assigned Resource Files",
                      <SessionFilesSubPanel
                          sessionId={props.sessionId}
                          assets={sessionAssets}
                          coaches={assignedCoaches?.coaches?.filter((c) => !c.isProposed) ?? []}
                          readOnly={props.readOnly}
                      />,
                  ],
              ];

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Files" />;
    }

    if (isSuccess) {
        return (
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    borderWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ borderWidth: 0, flexGrow: 1 }}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleTabChange}
                        sx={{ mb: 3 }}
                    >
                        {tablist.map((tab, i) => (
                            <ToggleButton
                                key={i}
                                id={`session-detail-file-panel-tab-${i}`}
                                value={i}
                            >
                                {tab[0]}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
                {tablist.map((tab, i) => (
                    <DetailsTabPanel
                        detailType={"session-detail-files"}
                        key={tab[0]}
                        value={alignment}
                        index={i}
                    >
                        {tab[1]}
                    </DetailsTabPanel>
                ))}
            </Box>
        );
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving participant assignment data for session ${
                props.sessionId
            } (${getErrorMsg(error)})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving participant assignment data for session ${props.sessionId}`
    );
};

export default SessionFilesPanel;
