import { Alert, AlertTitle } from "@mui/material";
import * as React from "react";
import ComponentLoader from "../../../../components/common/componentLoader";
import { useGetAuth0LoginsQuery } from "../../../../services/cclTokenedGrandCentralApi";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import Auth0LoginTable from "./auth0LoginTable";

interface UserAuth0LoginPanelProps {
    UserEmail: string;
}

const UserAuth0LoginPanel: React.FC<UserAuth0LoginPanelProps> = (props) => {
    const {
        data: userAuth0LogsData,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetAuth0LoginsQuery(props.UserEmail.trim(), { skip: !props.UserEmail });

    if (isLoading) {
        return <ComponentLoader msg={"Loading Logins"} />;
    }

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isError || isError) {
        return showErrorAlert(
            `Error retrieving auth0 logins for user ${props.UserEmail} (${getErrorMsg(error)})`
        );
    }

    if (isSuccess) {
        return <Auth0LoginTable isLoading={isLoading} Auth0LoginList={userAuth0LogsData} />;
    }

    return <span>No User Auth0 Logs</span>;
};

export default UserAuth0LoginPanel;
