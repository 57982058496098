import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ApplicationRolesSearchListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: ApplicationRolesSearchListTableStateState = {
    TableState: JSON.stringify({ sorting: { sortModel: [{ field: "name", sort: "asc" }] } }),
};

export const applicationRolesSearchListTableStateSlice = createSlice({
    name: "applicationRolesSearchListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateApplicationRolesSearchGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateApplicationRolesSearchGridState } = applicationRolesSearchListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const ApplicationRolesSearchListTableState = (state: RootState) => state;
export default applicationRolesSearchListTableStateSlice.reducer;
