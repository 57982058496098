import * as React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SignContractStepper from "./signContractStepper/signContractStepper";
import { ProfileContract } from "../../../../../services/types/enterpriseCoachingApiTypes";

export interface SignContractDialogProps {
    open: boolean;
    onClose: () => void;
    contract: ProfileContract | null;
}

export const SignContractDialog: React.FC<SignContractDialogProps> = (props) => {
    const onFinish = () => {
        props.onClose();
    };

    const onClose = () => {
        props.onClose();
    };

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={props.open}
            // onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                Sign Your Contract
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <SignContractStepper contract={props.contract} onFinish={onFinish} />
            </DialogContent>
        </Dialog>
    );
};

export default SignContractDialog;
