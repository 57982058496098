import * as React from "react";
import { AlertColor, Grid } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import { SessionDetailType } from "../../../../services/types/sessionDetailTypes";
import SessionDetailsAccordian from "./details/sessionDetailsAccordion";
import SessionAssessmentSetupAccordion from "./assessmentSetup/sessionAssessmentsSetupAccordian";
import { SessionProgramManagersAccordion } from "./programManagers/sessionProgramManagersAccordian";
import SessionSetupAccordion from "./setup/sessionSetupAccordion";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface SessionGeneralPanelProps {
    sessionId: string;
    readOnly?: boolean;
}

const SessionGeneralPanel: React.FC<SessionGeneralPanelProps> = (props) => {
    const [sessionDetail, setSessionDetail] = React.useState<SessionDetailType | undefined>(
        undefined
    );
    const [isDraft, setIsDraft] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const {
        data: sessionData,
        isLoading,
        isSuccess,
    } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });

    React.useEffect(() => {
        if (sessionData === undefined) {
            setSessionDetail(undefined);
            return;
        }

        let sd: SessionDetailType = JSON.parse(JSON.stringify(sessionData));
        sd.start = new Date(sd.start.toString().slice(0, -1));
        sd.end = new Date(sd.end.toString().slice(0, -1));
        setSessionDetail(sd);
        setIsDraft(sd.status === "Draft");
    }, [sessionData]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    if (isLoading) {
        return <ComponentLoader msg={""} />;
    }

    if (isSuccess && sessionDetail !== undefined) {
        return (
            <Grid container spacing={2} sx={{ height: "fit-content" }}>
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                <Grid item xs={12} md={6}>
                    <SessionDetailsAccordian
                        sessionDetail={sessionDetail}
                        readOnly={props.readOnly}
                    />
                </Grid>
                {!isDraft ? (
                    <Grid item xs={12} md={6}>
                        <SessionSetupAccordion
                            esKey={sessionDetail.sessionKey}
                            readOnly={props.readOnly}
                            showSnackbar={ShowSnackbarElement}
                        />
                    </Grid>
                ) : null}
                {!isDraft ? (
                    <Grid item xs={12}>
                        <SessionAssessmentSetupAccordion
                            esKey={sessionDetail.sessionKey}
                            projectCode={sessionDetail.sessionCode}
                            hasAssessmentPlatform={sessionDetail.assessmentPlatform !== "N/A"}
                            readOnly={props.readOnly}
                            showSnackbar={ShowSnackbarElement}
                        />
                    </Grid>
                ) : null}
                {!isDraft &&
                (sessionDetail?.type.trim() === "Custom" ||
                    sessionDetail?.type.trim() === "Coaching") ? (
                    <Grid item xs={12}>
                        <SessionProgramManagersAccordion
                            esKey={sessionDetail.sessionKey}
                            programCode={sessionDetail.sessionCode}
                            hasAssessmentPlatform={sessionDetail.assessmentPlatform !== "N/A"}
                            readOnly={props.readOnly}
                            showSnackbar={ShowSnackbarElement}
                        />
                    </Grid>
                ) : null}
            </Grid>
        );
    }
    return <span>No Session Data Loaded</span>;
};

export default SessionGeneralPanel;
