import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface RegistrationListTableStateState {
  TableState: string
};

// Define the initial state using that type
const initialState: RegistrationListTableStateState = {
  TableState: JSON.stringify({sorting:{sortModel:[{ field:'startDate',sort:'asc'}]}})
};

export const registrationListTableStateSlice = createSlice({
  name: 'registrationListTableState',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateRegistrationGridState: (state, action: PayloadAction<string | null>) => {
        state.TableState = action?.payload ?? initialState.TableState;
    }
  }
});

export const { updateRegistrationGridState } = registrationListTableStateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const registrationListTableState = (state: RootState) => state;
export default registrationListTableStateSlice.reducer;