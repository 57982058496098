import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface SessionListTableStateState {
  TableState: string
};

// Define the initial state using that type
const initialState: SessionListTableStateState = {
  TableState: JSON.stringify({sorting:{sortModel:[{ field:'startdate',sort:'asc'}]}})
};

export const sessionListTableStateSlice = createSlice({
  name: 'sessionListTableState',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateSessionsGridState: (state, action: PayloadAction<string | null>) => {
        state.TableState = action?.payload ?? initialState.TableState;
    }
  }
});

export const { updateSessionsGridState } = sessionListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const sessionListTableState = (state: RootState) => state;
export default sessionListTableStateSlice.reducer;