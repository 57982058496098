import * as React from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    TextFieldProps,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { SessionEventOption } from "../../../../../services/types/sessionOptionsType";

interface CompassOptionsProps {
    sessionId: string;
    radioLabels: string[];
    compassOption: SessionEventOption | null;
    coachOption: SessionEventOption | null;
    needsInvitations: boolean;
    onRadioChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    onScheduledDateChange: (date: Date | null, keyboardInputValue: string | undefined) => void;
}

const CompassOptions: React.FC<CompassOptionsProps> = (props) => {
    const [radioValue, setRadioValue] = React.useState<string | null>(null);
    const [scheduledDate, setScheduledDate] = React.useState<Date | null | undefined>(null);
    const [disableOptions, setDisableOptions] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.coachOption != null && props.coachOption.active) {
            setRadioValue("resource");
            setScheduledDate(null);
        } else if (props.compassOption != null) {
            if (props.compassOption.active && props.needsInvitations) setDisableOptions(true);

            const rv = props.compassOption.active
                ? "activate"
                : props.compassOption.scheduled
                ? "schedule"
                : "deactivate";
            setRadioValue(rv);

            const sd =
                props.compassOption.scheduled && props.compassOption.scheduledActivation != null
                    ? new Date(props.compassOption.scheduledActivation + "Z")
                    : null;
            setScheduledDate(sd);
        } else {
            setRadioValue("deactivate");
        }
    }, [props.coachOption, props.compassOption, props.needsInvitations]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (disableOptions) return;
        props.onRadioChange(event, value);
        setRadioValue(value);
        if (value !== "schedule") setScheduledDate(null);
        else if (props.compassOption?.scheduled) {
            const dt =
                props.compassOption.scheduledActivation != null
                    ? new Date(props.compassOption.scheduledActivation + "Z")
                    : null;
            setScheduledDate(dt);
        }
    };

    const onDateChange = (newValue: Date | null, keyboardInputValue?: string | undefined) => {
        setScheduledDate(newValue);
        props.onScheduledDateChange(newValue, keyboardInputValue);
    };

    return (
        <Box sx={{ m: 2, p: 1 }}>
            <FormControl>
                <FormLabel color="info" id="compass-activation-radio-buttons-group-label">
                    Select one of the following
                </FormLabel>
                <RadioGroup
                    value={radioValue}
                    onChange={onChange}
                    aria-labelledby="compass-activation-radio-buttons-group-label"
                    defaultValue="deactivated"
                    name="compass-activation-radio-buttons-group"
                    sx={{ pl: 4 }}
                >
                    <FormControlLabel
                        value="activate"
                        control={<Radio disabled={disableOptions} size="small" color="default" />}
                        label={props.radioLabels[0]}
                    />
                    <FormControlLabel
                        value="schedule"
                        control={<Radio disabled={disableOptions} size="small" color="default" />}
                        label={props.radioLabels[1]}
                    />
                    <DateTimePicker
                        value={scheduledDate ?? null}
                        onChange={onDateChange}
                        disabled={radioValue !== "schedule"}
                        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                            params.fullWidth = true;
                            return (
                                <TextField
                                    {...params}
                                    onKeyPress={undefined}
                                    sx={{ ml: 7, mb: 2 }}
                                />
                            );
                        }}
                    ></DateTimePicker>
                    {props.radioLabels.length > 3 && (
                        <FormControlLabel
                            value="resource"
                            control={
                                <Radio disabled={disableOptions} size="small" color="default" />
                            }
                            label={props.radioLabels[2]}
                        />
                    )}
                    <FormControlLabel
                        value="deactivate"
                        control={<Radio disabled={disableOptions} size="small" color="default" />}
                        label={props.radioLabels[props.radioLabels.length - 1]}
                    />
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default CompassOptions;
