import * as React from "react";
import { Alert, AlertColor, AlertTitle, Stack } from "@mui/material";
import AssetsDataGridPro from "../../../../components/shared/assetsDataGridPro/assetsDataGridPro";
import { useLazyGetAssetsByImKeyQuery } from "../../../../services/cclTokenedAssetsApi";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import FileUploadDrawer from "../../../resources/resourceDetail/files/upload/fileUploadDrawer";
import { Asset } from "../../../../services/types/enterpriseParticipantApiTypes";
import CclDownloadWarningAlert from "../../../../components/common/cclDownloadWarningAlert";

interface UserFilesPanelProps {
    email: string;
    imKey: number;
    readonly?: boolean;
    fullName?: string;
}

const UserFilesPanel: React.FC<UserFilesPanelProps> = (props) => {
    const [currentEvent, setCurrentEvent] = React.useState<string>("");
    const [uploadDialogOpen, setUploadDialogsOpen] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [returnedAssets, setReturnedAssets] = React.useState<Asset[] | undefined>(undefined);
    const { logEvent } = useLogAccessEvent();

    const downloadService = new FileDownloadService();

    const [
        refetchAssets,
        {
            data: lazyAssets,
            isLoading: lazyIsLoading,
            isSuccess: lazyIsSuccess,
            isError: lazyIsError,
            error: lazyError,
        },
    ] = useLazyGetAssetsByImKeyQuery();

    React.useEffect(() => {
        refetchAssets(props.imKey);
    }, [props.imKey]);

    React.useEffect(() => {
        const newAssets = lazyAssets === undefined ? undefined : [...lazyAssets];
        setReturnedAssets(newAssets);
    }, [lazyAssets]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    const onDrawerClose = () => {
        refetchAssets(props.imKey);
        setUploadDialogsOpen(false);
    };

    const handleEvents = (evt: string, ids: number[]) => {
        switch (evt) {
            case "download":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement("Download Not Possible", "info");
                    return;
                }

                setCurrentEvent("download");

                let filename = props.fullName ? `${props.fullName}Files.zip` : "Files.zip";
                if (ids.length === 1) {
                    let asset = returnedAssets?.find((a) => a.id === ids[0]);
                    if (asset) {
                        filename = asset.name;
                    }
                }

                downloadService
                    .DownloadAssets({
                        fname: filename,
                        aikeys: ids,
                        flatzip: true,
                    })
                    .then(() => {
                        const evtData: AccessEventIndividualDetails = {
                            imKey: props.imKey.toString(),
                            email: props.email,
                        };
                        logEvent("IndividualFilesDownloaded", evtData);
                        setCurrentEvent("");
                        ShowSnackbarElement("Download Completed", "success");
                    })
                    .catch((error) => {
                        setCurrentEvent("");
                        ShowSnackbarElement("Download Failed", "error");
                    });
                break;

            case "upload":
                setUploadDialogsOpen(true);
                break;

            default:
                break;
        }
    };

    if (lazyIsLoading || lazyIsSuccess) {
        return (
            <Stack spacing={3} sx={{ width: 1 }}>
                <CclDownloadWarningAlert />
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                <FileUploadDrawer
                    email={props.email}
                    imkey={props.imKey}
                    open={uploadDialogOpen}
                    onClose={() => onDrawerClose()}
                />
                <AssetsDataGridPro
                    assets={returnedAssets ?? []}
                    isLoading={lazyIsLoading}
                    handleEvent={handleEvents}
                    readonly={props.readonly ?? false}
                    currentEvent={currentEvent}
                />
            </Stack>
        );
    }

    if (lazyIsError) {
        return showErrorAlert(
            `Error retrieving files for user key ${props.imKey} (${getErrorMsg(lazyError)})`
        );
    }

    return showErrorAlert(`Unknown error retrieving files for user key ${props.imKey}`);
};
export default UserFilesPanel;
