import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ApplicationUsersSearchListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: ApplicationUsersSearchListTableStateState = {
    TableState: JSON.stringify({ sorting: { sortModel: [{ field: "name", sort: "asc" }] } }),
};

export const applicationUsersSearchListTableStateSlice = createSlice({
    name: "applicationUsersSearchListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateApplicationUsersSearchGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateApplicationUsersSearchGridState } = applicationUsersSearchListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const ApplicationUsersSearchListTableState = (state: RootState) => state;
export default applicationUsersSearchListTableStateSlice.reducer;
