import { ReactElement } from "react";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";

interface ReadOnlyGateProps {
    reactElement: ReactElement;
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    readOnly?: boolean;
}

const ReadOnlyGate: React.FC<ReadOnlyGateProps> = (props) => {
    const claimsService = new UserClaimsService();

    if (claimsService.IsReadOnly() || props.readOnly) {
        return <></>;
    } else return props.reactElement;
};

export default ReadOnlyGate;
