import React from "react";
import { Tooltip, Typography, styled } from "@mui/material";
import { GridColumnGroupHeaderParams } from "@mui/x-data-grid";

export interface AssessmentStatusGroupHeaderProps extends GridColumnGroupHeaderParams {
    hoverTitle: string;
}

const AssessmentStatusGroupHeader: React.FC<AssessmentStatusGroupHeaderProps> = (props) => {
    const HeaderRoot = styled("div")(({ theme }) => ({
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: theme.spacing(0.5),
        },
    }));

    const colTitle = props.headerName ?? props.groupId ?? "";

    return (
        <HeaderRoot>
            <Tooltip
                title={<Typography sx={{ fontSize: "1.25em" }}>{props.hoverTitle}</Typography>}
            >
                <span>{colTitle}</span>
            </Tooltip>
        </HeaderRoot>
    );
};

export default AssessmentStatusGroupHeader;
