import * as React from "react";

type FlexGridProps = {
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
};
export const FlexGrid: React.FC<FlexGridProps> = ({ children }) => {
    return (
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>{children}</div>
        </div>
    );
};

export default FlexGrid;
