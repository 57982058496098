import * as React from "react";
import { Stack } from "@mui/material";
import ManageCompassPanel from "./compass/manageCompassPanel";
import ManageLookingGlassPanel from "./lookingGlass/manageLookingGlassPanel";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

interface SessionDigitalProductsPanelProps {
    sessionId: string;
    readOnly?: boolean;
}

const SessionDigitalProductsPanel: React.FC<SessionDigitalProductsPanelProps> = (props) => {
    return (
        <Stack height={1} width={1} spacing={5} sx={{ p: 3 }}>
            <CclGenericAccordian headingText={"Manage Compass"}>
                <ManageCompassPanel sessionId={props.sessionId} readOnly={props.readOnly} />
            </CclGenericAccordian>
            <CclGenericAccordian headingText={"Manage Looking Glass"}>
                <ManageLookingGlassPanel sessionId={props.sessionId} readOnly={props.readOnly} />
            </CclGenericAccordian>
        </Stack>
    );
};

export default SessionDigitalProductsPanel;
