import * as React from "react";
import { AlertColor, Box, Stack } from "@mui/material";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblRegistrationGeneralTemplate } from "./cclInfoTblRegistrationGeneralTemplate";
import {
    Participant,
    SetParticipantExpirationPayload,
    SetParticipantStatusPayload,
} from "../../../../services/types/enterpriseParticipantApiTypes";
import {
    useGetCclCnxParticipantIntakeSurveyUrlQuery,
    useSetParticipantExpirationDateMutation,
    useSetParticipantStatusMutation,
} from "../../../../services/cclTokenedEnterpriseParticipantApi";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";
import { AccessEventRegistrationDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface RegistrationGeneralPanelProps {
    registration: Participant | undefined;
}

const RegistrationGeneralPanel: React.FC<RegistrationGeneralPanelProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [generalTemplate, setGeneralTemplate] = React.useState<CclInfoTableRowTemplate[]>(
        CclInfoTblRegistrationGeneralTemplate
    );
    const [registration, setRegistration] = React.useState<Participant | undefined>(
        props.registration
    );

    const [getSurveyUrl] = React.useState<boolean>(false);
    const {
        data: surveyUrl,
        isSuccess: surveyUrlIsSuccess,
        isError: surveyUrlIsError,
    } = useGetCclCnxParticipantIntakeSurveyUrlQuery(
        (props.registration?.esiKey as number).toString(),
        { skip: !getSurveyUrl && props.registration?.esiKey == null }
    );
    const [saveExpiration, { isLoading: expirationIsLoading }] =
        useSetParticipantExpirationDateMutation();
    const [saveStatus, { isLoading: statusIsLoading }] = useSetParticipantStatusMutation();
    const { logEvent } = useLogAccessEvent();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const saveParticipantDetails = (e: any) => {
        for (let i = 0; i < e.length; i++) {
            if (e[i].name === "expirationDate") {
                if (props.registration) {
                    let expirationArgs: SetParticipantExpirationPayload = {
                        esiKey: props.registration?.esiKey,
                        payload: {
                            ExpirationDate: e[i].value.toISOString(),
                        },
                    };
                    saveExpiration(expirationArgs)
                        .unwrap()
                        .then(() => {
                            const evtData: AccessEventRegistrationDetails = {
                                esiKey: props.registration?.esiKey.toString() ?? "",
                                imKey: props.registration?.imKey.toString() ?? "",
                                email: props.registration?.emailAddress ?? "",
                            };

                            logEvent("RegistrationDetailsEdited", evtData);
                            ShowSnackbarElement("Registration Updated", "success");
                        })
                        .catch(() => {
                            ShowSnackbarElement("Registration Update Failed", "error");
                        });
                }
            } else if (e[i].name === "isComplete") {
                if (props.registration) {
                    let statusArgs: SetParticipantStatusPayload = {
                        esiKey: props.registration?.esiKey,
                        payload: {
                            Completed: e[i].value,
                        },
                    };
                    saveStatus(statusArgs)
                        .unwrap()
                        .then(() => {
                            const evtData: AccessEventRegistrationDetails = {
                                esiKey: props.registration?.esiKey.toString() ?? "",
                                imKey: props.registration?.imKey.toString() ?? "",
                                email: props.registration?.emailAddress ?? "",
                            };

                            logEvent("RegistrationDetailsEdited", evtData);
                            ShowSnackbarElement("Registration Updated", "success");
                        })
                        .catch(() => {
                            ShowSnackbarElement("Registration Update Failed", "error");
                        });
                }
            }
        }
    };

    React.useEffect(() => {
        // a bit of a hack here modifying the template. Need to fix in the future with better info table implementation
        if (props.registration != null) {
            let pax = props.registration;
            pax.sessionStatus = pax.session?.status;
            pax.sessionTitle = pax.session?.title;
            pax.sessionCode = pax.session?.sessionCode;
            pax.sessionKey = pax.session?.sessionKey;
            setRegistration(pax);
            let tmplt = [...generalTemplate];
            let row = tmplt.find((r) => r.id === "fullName");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/user?email=${encodeURI(
                    props.registration.emailAddress
                )}&imkey=${props.registration.imKey}`;
            }

            row = tmplt.find((r) => r.id === "sessionCode");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/session/${props.registration.session.sessionKey}`;
            }

            row = tmplt.find((r) => r.id === "imKey");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/user?email=${props.registration.emailAddress}&imkey=${props.registration.imKey}&firstName=${props.registration.firstName}&lastName=${props.registration.lastName}`;
            }
            setGeneralTemplate(tmplt);
        }
        // eslint-disable-next-line
    }, [props.registration]);

    React.useEffect(() => {
        let tmplt = [...CclInfoTblRegistrationGeneralTemplate];
        if (surveyUrl != null && surveyUrl.url !== "" && surveyUrlIsSuccess) {
            const row: CclInfoTableRowTemplate = {
                id: "cnxIntakeUrl",
                fieldType: "string",
                editable: false,
                label: "Intake Survey",
                required: false,
                value: "",
                actionField: null,
                valueField: {
                    value: "View Intake Survey",
                    editFieldType: eInfoTableValueFieldEditType.Link,
                    url: surveyUrl.url,
                    hoverText: null,
                    checked: null,
                    id: "",
                    selectOptions: null, //TODO: Fix this for drop down option
                },
            };
            tmplt.push(row);
        }
        console.log(props.registration?.registrationStatus);

        if (
            !props.registration?.isCoachingParticipant ||
            props.registration.registrationStatus !== "Confirmed"
        ) {
            tmplt = tmplt.filter((x) => x.id !== "isComplete" && x.id !== "expirationDate");
        }
        setGeneralTemplate(tmplt);
        // eslint-disable-next-line
    }, [surveyUrl, surveyUrlIsSuccess, surveyUrlIsError]);

    if (registration != null) {
        return (
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={3}>
                <CclGenericAccordian
                    headingText={"Participant"}
                    sx={{
                        width: {
                            sm: 1,
                            md: 0.8,
                            lg: 0.7,
                            xl: 0.5,
                        },
                    }}
                >
                    <Box
                        sx={{
                            m: 2,
                            border: 1,
                            borderColor: "#EEEEEE",
                            borderRadius: 3,
                        }}
                    >
                        {showSnackbar ? (
                            <CclAlertSnackbar
                                open={true}
                                onClose={() => setShowSnackbar(false)}
                                message={snackbarMessage}
                                severity={snackbarSeverity}
                            />
                        ) : null}
                        <CclInfoTable
                            onSave={saveParticipantDetails}
                            template={generalTemplate}
                            data={registration}
                            saving={expirationIsLoading || statusIsLoading}
                        />
                    </Box>
                </CclGenericAccordian>
            </Stack>
        );
    }

    return <span>No Registration Data Loaded</span>;
};

export default RegistrationGeneralPanel;
