import { Alert, AlertColor, AlertTitle } from "@mui/material";
import {
    useGetAppSettingQuery,
    useSaveAppSettingMutation,
} from "../../services/cclTokenedSettingsApi";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import React from "react";
import { SystemSettingRequest } from "../../services/types/accessSettingsTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import CclButton from "../../components/common/cclButtons/cclButton";
import ComponentLoader from "../../components/common/componentLoader";
import SetAlertDrawer from "./setAlertDrawer";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";

interface SystemAlertProps {}

export type SystemAlertDetails = {
    title: string | null;
    msg: string | null;
    severity: AlertColor | null;
};

const SystemAlert: React.FC<SystemAlertProps> = (props) => {
    const systemAlertTag = "SystemAlert";
    const claimService = new UserClaimsService();
    const [showUpdateDrawer, setShowUpdateDrawer] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [alert, setAlert] = React.useState<SystemAlertDetails>({
        title: null,
        msg: null,
        severity: null,
    });
    const { data: systemAlert, isLoading, isError } = useGetAppSettingQuery("SystemAlert");
    const [storeSetting] = useSaveAppSettingMutation();
    const { logEvent } = useLogAccessEvent();

    const updateAlert = React.useCallback((alrt: SystemAlertDetails | null) => {
        if (alrt == null) {
            setAlert({ title: null, msg: null, severity: null });
        } else {
            setAlert({ title: alrt.title, msg: alrt.msg, severity: alrt.severity });
        }
    }, []);

    React.useEffect(() => {
        updateAlert(systemAlert?.data ? JSON.parse(systemAlert.data) : null);
    }, [systemAlert, updateAlert]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleUpdate = (
        title: string | null,
        msg: string | null,
        severity: AlertColor | null
    ) => {
        const newAlertDetails: SystemAlertDetails = {
            title: title == null || title === "" ? null : title,
            msg: msg == null || msg === "" ? null : msg,
            severity: severity == null ? "info" : severity,
        };
        const request: SystemSettingRequest = {
            tag: systemAlertTag,
            setting: {
                tag: systemAlertTag,
                data: JSON.stringify(newAlertDetails),
                // the following fields are populated on server later, these values are not used
                createdUser: "",
                createdUtc: new Date(),
                modifiedUser: "",
                modifiedUtc: new Date(),
            },
        };

        storeSetting(request)
            .unwrap()
            .then((result) => {
                updateAlert(result?.data ? JSON.parse(result.data) : null);
                logEvent("SystemAlertSet", null);
                ShowSnackbarElement("System Alert Updated.", "success");
            })
            .catch((error) => {
                updateAlert(null);
                ShowSnackbarElement(`Failed To Update System Alert: ${error.message}`, "error");
            });
        setShowUpdateDrawer(false);
    };

    if (isError) {
        return null;
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading" />;
    }

    return (
        <>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <SetAlertDrawer
                open={showUpdateDrawer}
                title={alert?.title}
                msg={alert?.msg}
                severity={alert?.severity}
                onSave={handleUpdate}
                onCancel={() => setShowUpdateDrawer(false)}
            />
            <Alert
                severity={alert.severity ?? "info"}
                action={
                    claimService.IsUserSuperAdmin() ? (
                        <CclButton
                            size={"small"}
                            onClick={() => setShowUpdateDrawer(true)}
                            sx={{ float: "right", minWidth: "140px" }}
                            mode={"primary"}
                        >
                            Set System Alert
                        </CclButton>
                    ) : null
                }
                sx={{ border: "1px solid #E0E0E0", borderRadius: "3px" }}
            >
                {alert.title != null ? <AlertTitle>{alert.title}</AlertTitle> : null}
                {alert.msg ?? "System Alerts and Notifications: None at this time!"}
            </Alert>
        </>
    );
};

export default SystemAlert;
