import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardContent, CardHeader, Grid, Link, Typography } from "@mui/material";
import useLogAccessEvent from "../hooks/useLogAccessEvent";
import { UserClaimsService } from "../services/currentUserService/currentUserService";

export const Error: React.FC = () => {
    const claimsService = new UserClaimsService();
    const { logout } = useAuth0();
    const { logEvent } = useLogAccessEvent();

    const logoutWithRedirect = () => {
        logEvent("UserLoggedOut", null);
        claimsService.ClearToken();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
        <Grid container alignItems="center" justifyContent={"center"} pt={20}>
            <Grid item xs={4}>
                <Card>
                    <CardHeader title="CCL Access: System Issue" />
                    <CardContent>
                        <Typography variant="body2">
                            An issue has occurred and we cannot currently log you in to CCL Access.
                            This could be because you do not have access to the CCL Access
                            application or because the application is having difficulty connecting
                            to CCL systems at this time.
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            We recommend waiting a couple of minutes and then trying to access the
                            application again. In some cases clearing your browser history and then
                            trying to login again has been known to resolve the issue.                             
                            <br />
                            <br />
                            <Link href="https://support.ccl.org/kb/article/77-clearing-your-browser-cache/">
                                <Button
                                    variant="text"
                                    sx={{ p: 0, justifyContent: "left" }}
                                >
                                    Clearing Browser History
                                </Button>
                            </Link>
                            <br />
                            <br />
                            Additionally, 
                            logging out of all CCL Applications using the link below, and then logging 
                            in again, has been known to solve the problem.
                            <br />
                            <br />
                            <Button
                                variant="text"
                                onClick={logoutWithRedirect}
                                sx={{ p: 0, justifyContent: "left" }}
                            >
                                Logout
                            </Button>
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            If the problem persists please visit the CCL Support Center.
                            <br />
                            <br />
                            <Link href="https://support.ccl.org">
                            <Button
                                variant="text"
                                sx={{ p: 0, justifyContent: "left" }}
                            >
                                CCL Support Center
                            </Button>
                        </Link>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Error;
