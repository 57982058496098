import * as React from "react";
import { Alert, AlertTitle, Link, Typography, Stack } from "@mui/material";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import DetailPage from "../../../components/common/detailPage/detailPage";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import UserApplicationsPanel from "./applications/userApplicationsPanel";
import UserGeneralPanel from "./general/userGeneralPanel";
import UserNotificationsPanel from "./notifications/userNotificationsPanel";
import UserSessionsPanel from "./sessions/userSessionsPanel";
import StandardLayout from "../../../layouts/standard";
import PageLoader from "../../../components/common/pageLoader";
import UserAuth0LoginPanel from "./auth0Logins/userAuth0LoginPanel";
import useCombinationKeyPress from "../../../hooks/useCombinationKeyPress";
import EditNoteIcon from "@mui/icons-material/EditNote";
import IndividualHistoryPanel from "../../../components/shared/individualHistoryPanel/individualHistoryPanel";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../services/currentUserService/currentUserService";
import UserParticipantPanel from "./participants/userParticipantsPanel";
import {
    useGetDemographicsByProfileKeyQuery,
    useGetPkeyByImKeyQuery,
} from "../../../services/cclTokenedEnterpriseCoachingApi";
import { Profile } from "../../../services/types/enterpriseCoachingApiTypes";
import { RouteAccess } from "../../../app/slices/allowedRoutesSlice";
import UserJournalDrawer from "./userJournalDrawer";

const UserDetail: React.FC = () => {
    const claimsService = new UserClaimsService();
    const allowJournalEntries =
        claimsService.IsUserCclAdminOrSuperAdmin() ||
        claimsService.IsUserNwaAdmin() ||
        claimsService.IsUserInGroups([eGroupIds.eClientAdmin]);
    const isNonCclAdmin = claimsService.IsUserInGroups([
        eGroupIds.eNWAAdmin,
        eGroupIds.eClientAdmin,
    ]);

    const [searchParams] = useSearchParams();
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [imkey, setImkey] = React.useState<number>(-1);
    const [tabIndex, setTabIndex] = React.useState<number>(isNonCclAdmin ? 0 : 2);
    const [journalKey, setJournalKey] = React.useState<number>(Date.now());
    const detailEl = React.useRef<HTMLDivElement>(null);
    const onKeyDown = (event: KeyboardEvent) => {
        setDrawerOpen(true);
        event.preventDefault();
    };
    useCombinationKeyPress(["j"], onKeyDown, detailEl.current);
    const { data: pkey } = useGetPkeyByImKeyQuery(imkey.toString());
    const { data } = useGetDemographicsByProfileKeyQuery(`${pkey?.pkey}`, {
        skip: !pkey || !pkey.pkey,
    });
    const emptyProfile = {} as Profile;
    const profile = data == null ? emptyProfile : data;

    React.useEffect(() => {
        setEmail(searchParams.get("email") ?? "");
        setImkey(+(searchParams.get("imkey") ?? "-1"));
        setFirstName(searchParams.get("firstName") ?? "");
        setLastName(searchParams.get("lastName") ?? "");
        setTabIndex(+(searchParams.get("tabIndex") ?? 2));
    }, [searchParams]);

    let tablist: [string, number, React.ReactNode][] = [
        [
            "Details",
            2,
            <UserApplicationsPanel
                emailAddress={email}
                firstName={firstName}
                lastName={lastName}
                imKey={imkey.toString()}
                profile={profile}
            />,
        ],
        ["Accounts", 0, <UserGeneralPanel emailAddress={email} imkey={imkey} profile={profile} />],
        ["Logins", 3, <UserAuth0LoginPanel UserEmail={email} />],
        ["Sessions", 4, <UserSessionsPanel emailAddress={email} />],
        ["Files", 7, <UserParticipantPanel imKey={imkey} emailAddress={email} />],
        ["Emails", 1, <UserNotificationsPanel emailAddress={email} />],
        [
            "Logs",
            6,
            <IndividualHistoryPanel
                journalTabKey={journalKey}
                imKey={imkey.toString()}
                emailAddress={email}
                idPrefix="user-detail"
                onCreateJournalEntry={() => setDrawerOpen(true)}
            />,
        ],
    ];

    if (isNonCclAdmin) {
        tablist = tablist.filter((tab) => {
            return tab[0] !== "Accounts" && tab[0] !== "Sessions" && tab[0] !== "Files";
        });
    }

    const allowedRoutes: RouteAccess = claimsService.GetCurrentAllowedRoutes();

    const breadcrumbs = (
        <Stack direction={"row"} spacing={3}>
            <SimpleBreadcrumbs>
                {allowedRoutes.allowUsers ? (
                    <Typography variant="h6" color="text.primary">
                        <Link key="findUserLink" component={RouterLink} to="/users">
                            Users
                        </Link>
                    </Typography>
                ) : (
                    <Typography variant="h6" color="text.primary">
                        Users
                    </Typography>
                )}
                <Typography variant="h6" color="text.primary">
                    {email !== "" ? email : "Unknown"}
                </Typography>
            </SimpleBreadcrumbs>
            {allowJournalEntries && <EditNoteIcon onClick={() => setDrawerOpen(true)} />}
        </Stack>
    );

    function onSaveJournalEntry() {
        setJournalKey(Date.now());
        setDrawerOpen(false);
    }

    function showErrorAlert(msg: string | undefined) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <div>
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                        {msg}
                    </Alert>
                </div>
            </StandardLayout>
        );
    }

    if (email === "" && imkey === -1) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={""} />
            </StandardLayout>
        );
    }

    if (email !== "" || imkey !== -1) {
        return (
            <div ref={detailEl}>
                {allowJournalEntries && (
                    <UserJournalDrawer
                        userId={imkey.toString()}
                        email={email}
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        onSave={() => onSaveJournalEntry()}
                    />
                )}
                <DetailPage
                    breadcrumbs={breadcrumbs}
                    descriptor={"users"}
                    tablist={tablist}
                    activeTab={tabIndex}
                ></DetailPage>
            </div>
        );
    }

    return showErrorAlert(`No user data found for this email ${email}`);
};

export default UserDetail;
