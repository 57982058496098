import * as React from "react";
import { Stack } from "@mui/material";
import {
    useGetAssignedCoachesBySessionKeyQuery,
    useGetAvailableCoachesBySessionKeyQuery,
} from "../../../../../services/cclTokenedEnterpriseCoachingApi";
import CnxParticipantListPanel from "./cnxParticipantListPanel";
import CnxAssignedCoachPanel from "./cnxAssignedCoachPanel";
import CnxProposedCoachPanel from "./cnxProposedCoachPanel";
import { CnxParticipant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { useGetCnxParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { PersonInfo } from "../../../../../models/personInfo";

interface CnxSessionResourcesPanelProps {
    sessionId: string;
    setSaveNeeded: (needSave: boolean) => void;
}

const CnxSessionResourcesPanel: React.FC<CnxSessionResourcesPanelProps> = (props) => {
    const [selectedParticipant, setSelectedParticipant] = React.useState<CnxParticipant | null>(
        null
    );
    const [selectedParticipantProposedCoaches, setSelectedParticipantProposedCoaches] =
        React.useState<PersonInfo[]>([]);
    const [selectedParticipantAssignedCoach, setSelectedParticipantAssignedCoach] =
        React.useState<PersonInfo | null>(null);
    const { data: availableCoaches } = useGetAvailableCoachesBySessionKeyQuery(props.sessionId);
    const { data: assignedCoaches } = useGetAssignedCoachesBySessionKeyQuery(props.sessionId);
    const { data: allParticipants, isSuccess: participantsSuccess } =
        useGetCnxParticipantsByEventKeyQuery(props.sessionId);

    React.useEffect(() => {
        if (selectedParticipant != null) {
            const propCoaches =
                assignedCoaches?.coaches
                    ?.filter((c) => c.isProposed && c.paxImKey === selectedParticipant?.imKey)
                    .map((fc) => {
                        return { firstName: fc.firstName, lastName: fc.lastName, imKey: fc.imKey };
                    }) ?? [];
            const assignedCoach =
                assignedCoaches?.coaches
                    ?.filter((c) => !c.isProposed && c.paxImKey === selectedParticipant?.imKey)
                    .map((fc) => {
                        return { firstName: fc.firstName, lastName: fc.lastName, imKey: fc.imKey };
                    })[0] ?? null;
            setSelectedParticipantProposedCoaches(propCoaches);
            setSelectedParticipantAssignedCoach(assignedCoach);
        } else {
            setSelectedParticipantProposedCoaches([]);
            setSelectedParticipantAssignedCoach(null);
        }
    }, [selectedParticipant, assignedCoaches]);

    React.useEffect(() => {
        if (allParticipants != null && allParticipants.length > 0 && selectedParticipant != null) {
            const newSelectedParticipant = allParticipants.find(
                (p) => p.esiKey === selectedParticipant.esiKey
            );
            setSelectedParticipant(newSelectedParticipant ?? null);
        }
        // eslint-disable-next-line
    }, [allParticipants]);

    return (
        <Stack direction="row" spacing={4} width={1} height={1}>
            <CnxParticipantListPanel
                participants={participantsSuccess ? allParticipants : []}
                selectedParticipant={selectedParticipant}
                onSelectParticipant={(selected) => setSelectedParticipant(selected)}
                assignedCoaches={assignedCoaches?.coaches ?? []}
            />
            <Stack direction="column" spacing={4} width={0.5} height={1}>
                <CnxProposedCoachPanel
                    participant={selectedParticipant}
                    currentProposedCoaches={selectedParticipantProposedCoaches}
                    availableCoaches={
                        availableCoaches?.coaches?.map((c) => {
                            return { firstName: c.firstName, lastName: c.lastName, imKey: c.imKey };
                        }) ?? []
                    }
                />
                <CnxAssignedCoachPanel
                    participant={selectedParticipant}
                    currentAssignedCoach={selectedParticipantAssignedCoach}
                    availableCoaches={
                        availableCoaches?.coaches?.map((c) => {
                            return { firstName: c.firstName, lastName: c.lastName, imKey: c.imKey };
                        }) ?? []
                    }
                />
            </Stack>
        </Stack>
    );
};
export default CnxSessionResourcesPanel;
