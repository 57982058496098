import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { PersonInfo } from "../../../../../models/personInfo";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";

const COLUMNS = [
    {
        field: "fullname",
        headerName: "Select All",
        type: "string",
        valueGetter: (params: any) => {
            return `${params.row.firstName} ${params.row.lastName}`;
        },
        renderCell: (params: GridRenderCellParams) => <span>{params.value}</span>,
        flex: 1,
    },
];

export interface PersonUploadDataGridProps {
    people: PersonInfo[];
    onSelectChange: (selectedIds: number[]) => void;
    selectedImKeys: number[];
}

export const PersonUploadDataGrid: React.FC<PersonUploadDataGridProps> = (props) => {
    const [selectedImKeys, setSelectedImKeys] = React.useState<GridSelectionModel>(
        props.selectedImKeys
    );
    return (
        <DataGridPro
            getRowId={(row) => row.imKey}
            rows={props.people == null ? [] : props.people}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            disableSelectionOnClick
            checkboxSelection={true}
            onSelectionModelChange={(imkeys: GridSelectionModel) => {
                const selImKeys = imkeys.map((i) => +i);
                setSelectedImKeys(selImKeys);
                props.onSelectChange(selImKeys);
            }}
            autoHeight
            hideFooter={true}
            selectionModel={selectedImKeys}
        />
    );
};

export default PersonUploadDataGrid;
