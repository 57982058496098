import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { SessionProgramManager } from "../../../../../services/types/sessionDetailTypes";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridCustomButtonToolbar from "../../../../../components/common/cclDataGridCustomButtonToolbar/cclDataGridCustomButtonToolbar";
import CclCommonLink from "../../../../../components/common/cclCommonLink";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";

export interface SessionProgramManagersTableProps {
    ProgramManagersList: SessionProgramManager[] | undefined;
    isLoading: boolean;
    handleEvent: (evt: string, id: number) => void;
    readOnly?: boolean;
    removing: boolean;
}

const SessionProgramManagersTable: React.FC<SessionProgramManagersTableProps> = (props) => {
    const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
    const claimsService = new UserClaimsService();
    const isCclAdmin = claimsService.IsUserCclAdminOrSuperAdmin();

    const COLUMNS = [
        {
            field: "individualId",
            headerName: "ID",
            type: "number",
            renderCell: (params: GridRenderCellParams) =>
                isCclAdmin ? (
                    params.row.imKey !== "0" ? (
                        <CclCommonLink
                            keyVal={params.value}
                            text={params.value}
                            to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.value}`}
                        />
                    ) : (
                        ""
                    )
                ) : (
                    params.value
                ),
            width: 100,
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            flex: 1,
        },
        {
            field: "firstName",
            headerName: "First Name",
            type: "string",
            width: 150,
        },
        {
            field: "lastName",
            headerName: "Last Name",
            width: 150,
            type: "string",
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 150,
            type: "string",
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.individualId}
            rows={props.ProgramManagersList ?? []}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.isLoading}
            components={{ Toolbar: CclDataGridCustomButtonToolbar }}
            componentsProps={
                props.handleEvent !== undefined && props.readOnly !== true
                    ? {
                          toolbar: {
                              miscButtons: [
                                  {
                                      id: "add",
                                      caption: "Add Administrator",
                                      label: "Add Administrator",
                                      disable: false,
                                  },
                                  {
                                      id: "remove",
                                      caption: "Remove Administrator",
                                      label: "Remove Administrator(s)",
                                      disable: selectedIds.length !== 1,
                                      loadingBtn: true,
                                      isLoading: props.removing,
                                  },
                              ],
                              btnClick: (buttonName: string) => {
                                  props.handleEvent(buttonName, selectedIds[0]);
                              },
                          },
                      }
                    : undefined
            }
            checkboxSelection={!props.readOnly}
            onSelectionModelChange={(ids: GridSelectionModel) => {
                setSelectedIds([...ids.map((i) => i as number)]);
            }}
            isCellEditable={(params) => params.row.groupReportAvailable === true}
            autoHeight
        />
    );
};

export default SessionProgramManagersTable;
