import { ListItemButton, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { AssociatedTemplate } from "../../../../services/types/generateDocumentsApiTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";

interface SelectedTemplatesListItemProps {
    template: AssociatedTemplate;
    handleTemplateDelete: (associatedTemplate: AssociatedTemplate) => void;
    deleteDisabled: boolean;
}

const SelectedTemplatesListItem: React.FC<SelectedTemplatesListItemProps> = (props) => {
    const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
        props.handleTemplateDelete(props.template);
    };

    return (
        <ListItemButton key={"lib_" + props.template.id}>
            <ListItem
                key={"li_" + props.template.id}
                secondaryAction={
                    <CclButton
                        mode={"primary"}
                        disabled={props.deleteDisabled}
                        onClick={(e) => handleDeleteClick(e)}
                    >
                        Delete
                    </CclButton>
                }
            >
                <ListItemText
                    key={"lit_" + props.template.id}
                    primary={props.template.name}
                    sx={{ fontSize: 8 }}
                />
            </ListItem>
        </ListItemButton>
    );
};

export default SelectedTemplatesListItem;
