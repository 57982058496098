import * as React from "react";
import { TextField, Box } from "@mui/material";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";

export type AddIdentityDrawerProps = {
    gcAccountId: string;
    gcAccountEmail: string;
    open: boolean;
    handleAdd: (newIdentity: { providerCode: string; providerId: string } | null) => void;
    handleClose: () => void;
    inProgress?: boolean;
};

const AddIdentityDrawer: React.FC<AddIdentityDrawerProps> = (props) => {
    const [newProviderId, setNewProviderId] = React.useState<{
        providerCode: string;
        providerId: string;
    } | null>(null);

    React.useEffect(() => {
        if (!props.open) setNewProviderId(null);
    }, [props.open]);

    return (
        <CclDrawer
            title="Add Enterprise ID (ImKey)"
            open={props.open}
            aria-labelledby="add-provider-identity"
            onClose={() => props.handleClose()}
        >
            <Box>
                <TextField
                    value={newProviderId?.providerId ?? ""}
                    sx={{ mt: 3, width: 1, maxHeight: "57px" }}
                    label="ImKey"
                    variant="outlined"
                    onChange={(e) => {
                        e.preventDefault();
                        setNewProviderId({
                            providerCode: "enterprise",
                            providerId: e.target.value,
                        });
                    }}
                />
            </Box>
            <CclDrawerActionBar>
                <CclButton id="cancel-btn" onClick={() => props.handleClose()} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclLoadingButton
                    id="save-btn"
                    onClick={() => props.handleAdd(newProviderId)}
                    disabled={newProviderId === null}
                    mode={"primary"}
                    loading={props.inProgress}
                >
                    Save
                </CclLoadingButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AddIdentityDrawer;
