import * as React from "react";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FormControlLabel, Switch } from "@mui/material";
import { Box } from "@mui/system";
import { CclPersistantDataGridMiscButton } from "../cclPersistentDataGrid/cclPersistentDataGrid";
import CclButton from "../cclButtons/cclButton";
import CclLoadingButton from "../cclButtons/cclLoadingButton";

//TODO - Resolve disparity between btn.onClick and btnClick, currently there seems to be no need for the btn.onClick prop inside of miscButtons

export interface CclDataGridToolbarProps {
    showSaveState: boolean;
    savestate: () => any;
    clearstate: () => any;
    resultBar?: boolean;
    resultBarText?: string;
    miscButtons?: CclPersistantDataGridMiscButton[];
    btnClick?: (btnId: string) => any;
    hideSettingsButtons?: boolean;
    anySelected: boolean;
    saveInProgress: boolean;
    clearInProgress: boolean;
}

const CclToolbarBtnSx = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "4px",
    paddingBottom: "4px",
    margin: "5px",
    fontSize: "12px",
    lineHeight: "21px",
};

const CclToolbarBtnSxRight = {
    borderWidth: "1px",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "3px",
    paddingBottom: "3px",
    margin: "5px",
    fontSize: "12px",
    lineHeight: "21px",
    float: "right",
};

const CclDataGridToolbar: React.FC<CclDataGridToolbarProps> = (props) => {
    const handleMiscClick = (btnid: string) => {
        if (props.btnClick != null) props.btnClick(btnid);
    };

    const handleCheckedClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.checked
            ? handleMiscClick(event.target.id + "enable")
            : handleMiscClick(event.target.id + "disable");
    };

    return (
        <GridToolbarContainer>
            <Box sx={{ width: 1 }}>
                {!props.hideSettingsButtons ? (
                    <>
                        <CclLoadingButton
                            sx={CclToolbarBtnSxRight}
                            onClick={props.clearstate}
                            mode={"secondary"}
                            loading={props.clearInProgress}
                        >
                            Clear Settings
                        </CclLoadingButton>
                        <CclLoadingButton
                            sx={CclToolbarBtnSxRight}
                            onClick={props.savestate}
                            mode={"secondary"}
                            loading={props.saveInProgress}
                        >
                            Save Settings
                        </CclLoadingButton>
                    </>
                ) : null}
                <GridToolbarExport variant="outlined" sx={{ m: "5px", float: "right" }} />
                <GridToolbarDensitySelector
                    variant="outlined"
                    sx={{ m: "5px", float: "right" }}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                <GridToolbarFilterButton
                    className="mui-filter-button"
                    sx={{ float: "right" }}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                <GridToolbarColumnsButton
                    variant="outlined"
                    sx={{ margin: "5px", float: "right" }}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                {props.miscButtons &&
                    props.miscButtons.map((btn) =>
                        btn.type === "switch" ? (
                            <FormControlLabel
                                label={btn.caption}
                                control={
                                    <Switch
                                        key={btn.id}
                                        id={btn.id}
                                        size="small"
                                        color="primary"
                                        edge="start"
                                        onChange={handleCheckedClick}
                                        checked={btn.checkedState}
                                        inputProps={{
                                            "aria-labelledby": `coach-release-toggle`,
                                        }}
                                        sx={{ ...CclToolbarBtnSx, ml: 3 }}
                                    />
                                }
                            />
                        ) : btn.loadingBtn ? (
                            <CclLoadingButton
                                key={btn.id}
                                id={btn.id}
                                disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                                sx={CclToolbarBtnSx}
                                onClick={() => handleMiscClick(btn.id)}
                                mode={"primary"}
                                loading={btn.isLoading}
                            >
                                {btn.caption}
                            </CclLoadingButton>
                        ) : (
                            <CclButton
                                key={btn.id}
                                id={btn.id}
                                disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                                sx={CclToolbarBtnSx}
                                onClick={() => handleMiscClick(btn.id)}
                                mode={"primary"}
                            >
                                {btn.caption}
                            </CclButton>
                        )
                    )}
                {props.resultBar && <span>{props.resultBarText}</span>}
            </Box>
        </GridToolbarContainer>
    );
};

export default CclDataGridToolbar;
