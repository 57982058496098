import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface PartnerResourceListTableStateState {
  TableState: string
};

// Define the initial state using that type
const initialState: PartnerResourceListTableStateState = {
  TableState: JSON.stringify({sorting: {sortModel: [{ field: 'name', sort: 'asc' }]}})
};

export const partnerResourceListTableStateSlice = createSlice({
  name: 'partnerResourceListTableState',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updatePartnerResourceGridState: (state, action: PayloadAction<string | null>) => {
        state.TableState = action?.payload ?? initialState.TableState;
    }
  }
});

export const { updatePartnerResourceGridState } = partnerResourceListTableStateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const partnerResourceListTableState = (state: RootState) => state;
export default partnerResourceListTableStateSlice.reducer;