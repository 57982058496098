import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface SessionListConfigState {
    StartDate: string;
    EndDate: string;
    Keyword: string;
    IsDateSearchActive: boolean;
}

// Define the initial state using that type
const initialState: SessionListConfigState = {
    StartDate: "",
    EndDate: "",
    Keyword: "",
    IsDateSearchActive: false,
};

export const sessionListConfigSlice = createSlice({
    name: "sessionListConfig",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateSessionSearchParams: (state, action: PayloadAction<SessionListConfigState>) => {
            state.StartDate = action.payload.StartDate;
            state.EndDate = action.payload.EndDate;
            state.Keyword = action.payload.Keyword;
            state.IsDateSearchActive = action.payload.IsDateSearchActive;
        },
    },
});

export const { updateSessionSearchParams } = sessionListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSessionListConfig = (state: RootState) => state;
export default sessionListConfigSlice.reducer;
