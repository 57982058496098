import * as React from "react";
import { Stack, TextField, Box, Card, CardHeader } from "@mui/material";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import PersonUploadDataGrid from "./personUploadDataGrid";
import { PersonInfo } from "../../../../../models/personInfo";

export interface SelectPeopleStepPanelProps {
    sessionId: string;
    projectCode: string;
    isSessionFile: boolean;
    people: PersonInfo[];
    personType: string;
    selectedImKeys: number[];
    onNextPanel: (selectedImKeys: number[]) => void;
    onCancel: () => void;
}

export const SelectPeopleStepPanel: React.FC<SelectPeopleStepPanelProps> = (props) => {
    const [filter, setFilter] = React.useState<string>("");
    const [imkeys, setImkeys] = React.useState<number[]>([]);

    const getFilteredPeople = (): PersonInfo[] => {
        const newFilteredPeople =
            filter.length === 0
                ? [...props.people]
                : props.people.filter(
                      (p) =>
                          p.firstName.toLowerCase().includes(filter) ||
                          p.lastName.toLowerCase().includes(filter) ||
                          imkeys.find((i) => i === p.imKey) != null
                  );

        return newFilteredPeople.sort((a, b) => {
            return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName);
        });
    };

    const filteredPeople = getFilteredPeople();
    const selectCardTitle = `Select ${
        props.personType.charAt(0).toUpperCase() + props.personType.slice(1).toLowerCase() + "s"
    }`;

    const filterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newfilter = evt.target.value.toLowerCase();
        setFilter(newfilter);
    };

    return (
        <>
            <Stack direction="column" spacing={2} width={1}>
                <Card sx={{ width: 1 }}>
                    <CardHeader title={selectCardTitle} sx={{ mt: 1, pt: 2, mb: 0, pb: 2 }} />
                    <Stack
                        display="flex"
                        width={1}
                        flexGrow={1}
                        sx={{ height: "calc(100% - 60px)", p: 4 }}
                        spacing={4}
                    >
                        <TextField
                            size="small"
                            value={filter}
                            label={`${
                                props.personType === "participant"
                                    ? "Find participant"
                                    : "Find resource"
                            }`}
                            onChange={filterChange}
                            sx={{ width: 1, maxHeight: "57px" }}
                            id="find-person-search"
                            variant="outlined"
                        />
                        <PersonUploadDataGrid
                            people={filteredPeople ?? []}
                            onSelectChange={(imkeys: number[]) => setImkeys(imkeys)}
                            selectedImKeys={props.selectedImKeys}
                        />
                    </Stack>
                </Card>
            </Stack>

            <Box sx={{ display: "flex", flexDirection: "row", mt: 4 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton
                    color="primary"
                    id="cancel-btn"
                    onClick={props.onCancel}
                    mode={"secondary"}
                    sx={{ mr: 2 }}
                >
                    Cancel
                </CclButton>
                <CclButton
                    onClick={() => props.onNextPanel(imkeys)}
                    disabled={imkeys.length === 0}
                    mode={"primary"}
                >
                    Next
                </CclButton>
            </Box>
        </>
    );
};

export default SelectPeopleStepPanel;
