import { Link } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

export type CclCommonLinkProps = {
    text: string;
    keyVal: string;
    to: string;
    type?: string;
    target?: string;
};

const CclCommonLink: React.FC<CclCommonLinkProps> = (props) => {
    // We can use this to change link styling based on the type prop easily if it is needed. For now it just returns a link component.
    return (
        <Link key={props.keyVal} component={RouterLink} to={props.to} target={props.target}>
            {props.text}
        </Link>
    );
};

export default CclCommonLink;
