import * as React from "react";
import { Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";

import ProductSalesCard from "./productSalesCard";

interface ProductSalesProps {}

const ProductSales: React.FC<ProductSalesProps> = () => {
    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Product Sales
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <ProductSalesCard />
        </StandardLayout>
    );
};

export default ProductSales;
