import * as React from "react";
import { Card, CardHeader, Stack, styled, TextField } from "@mui/material";
import {
    useGetCclEventByEventKeyQuery,
    useGetSessionOptionsByEventKeyQuery,
} from "../../../../../services/cclTokenedSessionApi";
import ComponentLoader from "../../../../../components/common/componentLoader";

const CustomizedTextField = styled(TextField)`
    background-color: #f1f1f2;
    width: 100%;
`;

interface CompassStatusCardProps {
    sessionId: string;
}

const CompassStatusCard: React.FC<CompassStatusCardProps> = (props) => {
    const [statusString, setStatusString] = React.useState<string>("Loading...");
    const { data: sessionDetails, isSuccess: detailsSuccess } = useGetCclEventByEventKeyQuery(
        props.sessionId,
        { skip: props.sessionId === "" }
    );
    const { data: sessionOptions, isSuccess: sessionOptionsSuccess } =
        useGetSessionOptionsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });

    React.useEffect(() => {
        if (sessionOptionsSuccess && sessionOptions != null) {
            const compassOption = sessionOptions?.sessionEventOptions.find((o) => o.optionId === 1);
            const coachOption = sessionOptions?.sessionEventOptions.find((o) => o.optionId === 2);
            if (coachOption != null && coachOption.active) {
                setStatusString("Resource Activation Enabled");
            } else if (compassOption != null) {
                const schedDate =
                    compassOption.scheduledActivation != null
                        ? new Date(compassOption.scheduledActivation + "Z")
                        : new Date();
                const changeDate = new Date(compassOption.changedDate + "Z");
                const status = compassOption.active
                    ? `Active (by: ${compassOption.changedBy} on ${changeDate.toLocaleString()})`
                    : compassOption.scheduled
                    ? `Not Active, Scheduled for ${schedDate.toLocaleString()} (by: ${
                          compassOption.changedBy
                      })`
                    : "Not Active, Not Scheduled";
                setStatusString(status);
            } else {
                setStatusString("Not Active, Not Scheduled");
            }
        }
        // eslint-disable-next-line
    }, [sessionOptions]);

    if (detailsSuccess) {
        const stDateStr: string = new Date(sessionDetails.start).toLocaleDateString();
        const endDateStr: string = new Date(sessionDetails.end).toLocaleDateString();

        return (
            <Card sx={{ height: "100%", width: "100%", p: 2 }}>
                <CardHeader title={"Compass Status"} sx={{ p: 1 }} />
                <Stack height={1} width={1} spacing={4} sx={{ p: 3 }}>
                    <CustomizedTextField
                        disabled
                        label="Compass Status"
                        value={statusString}
                        variant="outlined"
                    />
                    <CustomizedTextField
                        disabled
                        label="Session Host Platform"
                        defaultValue={sessionDetails.hostingPlatform}
                        variant="outlined"
                    />
                    <CustomizedTextField
                        disabled
                        label="Session Type"
                        defaultValue={sessionDetails.sessionFormat}
                        variant="outlined"
                    />
                    <CustomizedTextField
                        disabled
                        label="Session Start Date"
                        defaultValue={stDateStr}
                        variant="outlined"
                    />
                    <CustomizedTextField
                        disabled
                        label="Session End Date"
                        defaultValue={endDateStr}
                        variant="outlined"
                    />
                </Stack>
            </Card>
        );
    }

    return <ComponentLoader msg={"Loading Compass Management Info"} />;
};

export default CompassStatusCard;
