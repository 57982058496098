import { Alert, Box, LinearProgress, List } from "@mui/material";
import { useAssociateTemplateMutation } from "../../../../services/cclTokenedGenerateDocumentsApi";
import {
    AssociatedTemplate,
    AssociateTemplateQueryArgs,
} from "../../../../services/types/generateDocumentsApiTypes";
import SelectedTemplatesListItem from "./selectedTemplatesListItem";

interface SelectedTemplatesProps {
    sessionId: string;
    isChanging: boolean;
    currentTemplates: AssociatedTemplate[];
}
export default function SelectedTemplatesList(props: SelectedTemplatesProps) {
    const [associateTemplate, { isLoading: associateTemplateIsLoading }] =
        useAssociateTemplateMutation();

    const deleteTemplateFileClicked = (associatedTemplate: AssociatedTemplate | undefined) => {
        if (associatedTemplate) {
            let associateTemplateArgs: AssociateTemplateQueryArgs = {
                esKey: Number(props.sessionId),
                body: {
                    programId: props.sessionId,
                    id: associatedTemplate.id,
                    boxchecked: false,
                },
            };
            associateTemplate(associateTemplateArgs);
        }
    };

    if (props.currentTemplates && props.currentTemplates.length === 0) {
        return <Alert severity="info">Select at least one template</Alert>;
    }

    return (
        <Box>
            {associateTemplateIsLoading || props.isChanging ? <LinearProgress /> : undefined}
            <List
                sx={{
                    mt: 0,
                    pb: 0,
                    borderBottom: "1px solid lightgray",
                    maxHeight: "100%",
                    overflow: "auto",
                }}
            >
                {props.currentTemplates &&
                    props.currentTemplates.length > 0 &&
                    props.currentTemplates.map((f, index) => (
                        <SelectedTemplatesListItem
                            key={"stli_" + f.id}
                            deleteDisabled={associateTemplateIsLoading}
                            template={f}
                            handleTemplateDelete={deleteTemplateFileClicked}
                        />
                    ))}
            </List>
        </Box>
    );
}
