import React from "react";
import { Box } from "@mui/material";
import {
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Asset } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export interface ResourceParticipantAssetListDataGridProps {
    esiKey: number;
    assets: Asset[] | undefined;
    assetsSelectionChange: (esiKey: number, aikeys: number[]) => void;
    selectedAssets: number[];
}

export const ResourceParticipantAssetListDataGrid: React.FC<
    ResourceParticipantAssetListDataGridProps
> = (props) => {
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

    React.useEffect(() => {
        if (props.selectedAssets && props.selectedAssets.length > 0) {
            setSelectionModel([...props.selectedAssets]);
        } else {
            setSelectionModel([]);
        }
    }, [props.selectedAssets]);

    const COLUMNS = [
        {
            field: "id",
            headerName: "ID",
            flex: 0.25,
        },
        {
            field: "name",
            headerName: "File",
            flex: 1,
        },
        {
            field: "createdDate",
            headerName: "Created",
            type: "date",
            valueGetter: (params: any) => new Date(params.row.createdDate),
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            flex: 0.25,
        },
        {
            field: "isReleased",
            headerName: "Status",
            flex: 0.35,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.value ? "Released" : "Not Released"}`,
            renderCell: (params: GridRenderCellParams) =>
                params.value === "Released" ? (
                    "Released"
                ) : (
                    <>
                        Not Released
                        <ErrorOutlineIcon color="error" fontSize="small" sx={{ pl: 1 }} />
                    </>
                ),
        },
        {
            field: "fileType",
            headerName: "Type",
            type: "string",
            flex: 0.4,
        },
    ];

    return (
        <Box sx={{ height: 1, width: 1, pl: 25, border: 0 }}>
            <DataGridPro
                getRowId={(row) => row.id}
                rows={props.assets == null ? [] : props.assets}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    props.assetsSelectionChange(
                        props.esiKey,
                        ids.map((aikey) => +aikey)
                    );
                    setSelectionModel([...ids]);
                }}
                selectionModel={selectionModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "createdDate", sort: "desc" }],
                    },
                }}
                hideFooter={true}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none",
                    },
                    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                }}
                keepNonExistentRowsSelected={false}
            />
        </Box>
    );
};

export default ResourceParticipantAssetListDataGrid;
