import * as React from "react";
import { Alert } from "@mui/material";

export interface CclDownloadWarningAlertProps {}

export const CclDownloadWarningAlert: React.FC<CclDownloadWarningAlertProps> = (props) => {
    return (
        <Alert severity={"info"}>
            You will lose access to downloaded PDF files after 60 days. You can download another
            copy from CCL Access if that happens which will be valid for another 60 days. This is to
            ensure CCL complies with data security and privacy legislation.
        </Alert>
    );
};

export default CclDownloadWarningAlert;
