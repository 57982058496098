import { DataGridPro, GridColDef, GridSelectionModel } from "@mui/x-data-grid-pro";
import { ProgramInstrument } from "../../../../../../../services/types/scoringStatusTypes";
import { Box } from "@mui/material";

export type ScoringRequestInstrumentListProps = {
    onChange: (selectedInstruments: number[]) => void;
    instruments: ProgramInstrument[];
};

const ScoringRequestInstrumentList: React.FC<ScoringRequestInstrumentListProps> = (props) => {
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Programs/Assessments",
            headerClassName: "instrument-list--header",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            flex: 1.0,
        },
    ];

    return (
        <Box
            sx={{
                width: "100%",
                "& .instrument-list--header": {
                    backgroundColor: "rgb(225, 225, 225)",
                    fontWeight: "bold",
                },
            }}
        >
            <DataGridPro
                getRowId={(row) => row.id}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    props.onChange([...ids.map((i) => i as number)]);
                }}
                rows={props.instruments}
                columns={columns}
                checkboxSelection
                autoHeight={true}
                hideFooter={true}
                disableColumnSelector={true}
                disableSelectionOnClick={true}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        backgroundColor: "rgb(225, 225, 225)",
                    },
                    "& .MuiDataGrid-root": {
                        borderLeft: "none",
                        borderRight: "none",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                        fontSize: "1.25em",
                    },
                }}
            />
        </Box>
    );
};

export default ScoringRequestInstrumentList;
