import * as React from "react";
import { Grid } from "@mui/material";
import { useGetAllBiosQuery } from "../../../services/cclTokenedEnterpriseCoachingApi";
import BioSearchDataGridPro from "./bioSearchList";
import { Biography } from "../../../services/types/bioApiTypes";
import { useLazyGetBioByEmailQuery } from "../../../services/cclTokenedBioApi";
import { useSelector } from "react-redux";

interface BioSearchTabPanelProps {}

const BioSearchTabPanel: React.FC<BioSearchTabPanelProps> = (props) => {
    const searchSelector = useSelector((state: any) => state.resourceListConfig);
    const { data: getAllBios, isLoading: loading } = useGetAllBiosQuery(null);
    const [searchBios, { data: bio, isLoading: bioLoading }] = useLazyGetBioByEmailQuery();

    const [resources, setResources] = React.useState<Biography[]>([]);

    React.useEffect(() => {
        if (searchSelector.Email == null) {
            return;
        }
        searchBios(searchSelector.Email);
        //eslint-disable-next-line
    }, [searchSelector.Email]);

    React.useEffect(() => {
        if (!getAllBios && !bio) {
            return;
        }
        if (getAllBios && !bio) {
            setResources(getAllBios.filter((bio: Biography) => bio.bioComplete));
        }
        if (bio) {
            if (!bio.bioComplete) return;

            setResources([bio]);
        }
        // eslint-disable-next-line
    }, [getAllBios, bio]);

    const dataLoading = (): boolean => {
        return loading ? loading : bioLoading;
    };

    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    {resources && (
                        <BioSearchDataGridPro loading={dataLoading()} resources={resources} />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default BioSearchTabPanel;
