import * as React from "react";
import {
    Alert,
    AlertColor,
    Box,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import {
    useAddIdentityMutation,
    useRemoveIdentityMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import { GrandCentralIdentityDetail } from "../../../../services/types/cclGrandCentralApiTypes";
import {
    AddEnterpriseIdentity,
    AddEnterpriseIdentityPayload,
    RemoveEnterpriseIdentity,
} from "../../../../services/types/rtkQueryTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import AddIdentityDrawer from "./addIdentityDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type GrandCentralIdentitiesCardProps = {
    gcAccountId: string;
    gcAccountEmail: string;
    gcIdentities: GrandCentralIdentityDetail[];
    isUserAdmin: boolean;
    tenantId: string;
    imKey: string;
};

const GrandCentralIdentitiesCard: React.FC<GrandCentralIdentitiesCardProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [addIdentityOpen, setAddIdentityOpen] = React.useState<boolean>(false);

    const { logEvent } = useLogAccessEvent();
    const [removeIdentityMutation, { isLoading: removeLoading }] = useRemoveIdentityMutation();
    const [addIdentityMutation, { isLoading: addLoading }] = useAddIdentityMutation();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const removeIdentity = (identity: string) => {
        const request: RemoveEnterpriseIdentity = {
            email: props.gcAccountEmail ?? "",
            identityId: identity,
        };
        removeIdentityMutation(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.imKey,
                    email: props.gcAccountEmail,
                };

                logEvent("UserAccountEdited", evtData);
                ShowSnackbarElement("Identity Removed", "success");
            })
            .catch(() => {
                ShowSnackbarElement("Identity Removal Failed", "error");
            });
    };

    const handleAddIdentity = (
        newIdentity: { providerCode: string; providerId: string } | null
    ) => {
        if (newIdentity == null) return;

        const payload: AddEnterpriseIdentityPayload = {
            providerIdValue: newIdentity.providerId,
            identityProviderCode: newIdentity.providerCode,
            tenantId: props.tenantId,
        };

        const request: AddEnterpriseIdentity = {
            email: props.gcAccountEmail,
            payload: payload,
        };
        addIdentityMutation(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.imKey,
                    email: props.gcAccountEmail,
                };

                logEvent("UserAccountEdited", evtData);
                ShowSnackbarElement("Identity Added", "success");
                setAddIdentityOpen(false);
            })
            .catch(() => {
                ShowSnackbarElement("Identity Addition Failed", "error");
                setAddIdentityOpen(false);
            });
    };

    return (
        <CclGenericAccordian headingText="Identities" sx={{ height: 1 }}>
            <AddIdentityDrawer
                gcAccountId={props.gcAccountId}
                gcAccountEmail={props.gcAccountEmail}
                open={addIdentityOpen}
                handleAdd={handleAddIdentity}
                handleClose={() => setAddIdentityOpen(false)}
                inProgress={addLoading}
            />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CardContent sx={{ pt: 0 }}>
                {props.gcIdentities.length > 0 ? (
                    <List sx={{ pt: 0, width: 1 }}>
                        {props.gcIdentities.map((i) => (
                            <React.Fragment key={i.identityId}>
                                <ListItem
                                    sx={{ p: 2 }}
                                    alignItems="flex-start"
                                    secondaryAction={
                                        props.isUserAdmin ? (
                                            <CclLoadingButton
                                                size={"small"}
                                                onClick={() => {
                                                    removeIdentity(i.identityId);
                                                }}
                                                mode={"primary"}
                                                loading={removeLoading}
                                            >
                                                Remove Identity
                                            </CclLoadingButton>
                                        ) : null
                                    }
                                >
                                    <ListItemText
                                        primary={i.identityProviderCode}
                                        secondary={
                                            <Typography
                                                sx={{ display: "inline" }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {i.providerIdValue}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Alert severity="info">
                        {`There are no provider identities associated with this account.`}
                    </Alert>
                )}
                {props.isUserAdmin ? (
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <CclButton
                            aria-label="add-enterprise-identity"
                            size={"small"}
                            onClick={() => setAddIdentityOpen(true)}
                            sx={{ mr: 4 }}
                            mode={"primary"}
                        >
                            Add Identity
                        </CclButton>
                    </Box>
                ) : null}
            </CardContent>
        </CclGenericAccordian>
    );
};

export default GrandCentralIdentitiesCard;
