import { Box } from "@mui/material";

const returnDefaultUserImage = (nameVal: string) => {
    let name = nameVal.split(" ");
    let firstIntial = name[0].charAt(0);
    let lastInitial = name[1].charAt(0);
    return (
        <>
            <Box
                sx={{
                    height: "120px",
                    width: "120px",
                    margin: "auto",
                    textAlign: "center",
                    verticalAlign: "middle",
                    lineHeight: "120px",
                    backgroundColor: '#8E44AD',
                    fontSize: "30px",
                    color: "white",
                    borderRadius: "50%"
                }}>
                <p style={{ margin: "0" }}>
                    {firstIntial + lastInitial}
                </p>
            </Box>
        </>
    )
}

export default returnDefaultUserImage