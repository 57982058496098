import * as React from "react";
import {
    Stack,
    TextField,
} from "@mui/material";


export interface addEmailTemplateTabOne {
    setValue: (key: string, value: string) => void;
    trackingId: string;
    templateName: string;
    subject: string;
    ccRecipients: string;
    bccRecipients: string;
    notificationType: number;
}

export const AddEmailTemplateTabOne: React.FC<addEmailTemplateTabOne> = (props) => {

    return (
        <>
            <Stack>
                <Stack direction={"row"} width="100%">
                    <TextField 
                        value={props.templateName}
                        required
                        onChange={(e) => props.setValue("templateName",e.target.value)}
                        label="Template Name"
                        sx={{margin: 2, width: "100%"}}
                    />
                    <TextField 
                        value={props.trackingId}
                        required
                        onChange={(e) => props.setValue("trackingId", e.target.value)}
                        label="Tracking ID"
                        sx={{margin: 2, width: "100%"}}
                    />
                </Stack>   
                <TextField 
                    value={props.subject}
                    required
                    onChange={(e) => props.setValue("subject",e.target.value)}
                    label="Subject"
                    sx={{margin: 2}}
                />
                <TextField 
                    value={props.ccRecipients}
                    onChange={(e) => props.setValue("ccRecipients",e.target.value)}
                    label="Cc Recipients"
                    sx={{margin: 2}}
                />
                <TextField 
                    value={props.bccRecipients}
                    onChange={(e) => props.setValue("bccRecipients",e.target.value)}
                    label="Bcc Recipients"
                    sx={{margin: 2}}
                />
            </Stack>
        </>
    );
};

export default AddEmailTemplateTabOne;
