import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import ContentPasteSearchTwoToneIcon from "@mui/icons-material/ContentPasteSearchTwoTone";

export interface CclSearchDefaultProps {
    line1: string;
    line2: string;
    hasButtons?: boolean;
    children?: React.ReactNode;
}

export const CclSearchDefault: React.FC<CclSearchDefaultProps> = (props) => {
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ mt: 15 }}>
            <Typography variant="h5">{props.line1}</Typography>
            <Typography variant="h6">{props.line2}</Typography>
            <ContentPasteSearchTwoToneIcon color="primary" sx={{ fontSize: "200px" }} />
            {props.hasButtons && props.children ? (
                <React.Fragment>
                    <Typography variant="h6" sx={{ marginTop: "4px", marginBottom: "4px" }}>
                        OR
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={3}
                        sx={{ width: 1 }}
                    >
                        {props.children}
                    </Box>
                </React.Fragment>
            ) : null}
        </Stack>
    );
};

export default CclSearchDefault;
