import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CclGenericConfirmationDialog from "../../../components/common/cclGenericConfirmationDialog";

export interface editEmailtemplate {
    open: boolean;
    onClose: () => void;
}

export const EditEmailtemplate: React.FC<editEmailtemplate> = (props) => {
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);

    const editEmailtemplate = () => {};

    const onCancel = () => {
        setOpenConfirmation(false);
    };

    const onOk = () => {
        setOpenConfirmation(false);
        editEmailtemplate();
    };

    return (
        <>
            <CclGenericConfirmationDialog open={openConfirmation} onCancel={onCancel} onOk={onOk} />

            <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle>Create new Email Template</DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    );
};

export default EditEmailtemplate;
