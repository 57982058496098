import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { EnvProvider } from "./context/env.context";
import { Auth0ProviderWithHistory } from "./auth0-provider-with-history";
import { Provider } from "react-redux";
import { store } from "./app/store";

ReactGA.initialize("G-32KMZ61TPS");

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <EnvProvider>
                    <Auth0ProviderWithHistory>
                            <App />
                    </Auth0ProviderWithHistory>
                </EnvProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
