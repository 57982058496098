import * as React from "react";
import BiographyBox from "../../../../components/shared/biography/biographyBox";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";

export type ResourceBioProps = {
    pKey: string;
    imKey: string;
    email: string;
};

export const ResourceBio: React.FC<ResourceBioProps> = (props) => {
    const claimservice = new UserClaimsService();
    //Since readonly is now not always true from here it cannot be used as a sole identifier if a user is from "Resources", check against imKey as well
    return (
        <BiographyBox
            pKey={props.pKey}
            readonly={!claimservice.IsUserCclAdminOrSuperAdmin()}
            imKey={props.imKey}
            email={props.email}
        />
    );
};
export default ResourceBio;
