import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface ContractCardHeaderProps {
    text: string;
    showRequired: boolean;
    showInfoIcon: boolean;
    infoText?: string;
}

export const ContractCardHeader: React.FC<ContractCardHeaderProps> = (props) => {
    return (
        <Box sx={{ p: 0, pb: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ pt: 1, textDecoration: "underline", display: "inline" }}>
                {props.text}
            </Typography>
            {props.showRequired ? (
                <Typography
                    variant="h5"
                    color={"error"}
                    sx={{ pl: 1, display: "inline", textDecoration: "bold" }}
                >
                    *
                </Typography>
            ) : null}
            {props.showInfoIcon ? (
                <Tooltip
                    placement="right-start"
                    title={<Typography sx={{ fontSize: "1.25em" }}>{props.infoText}</Typography>}
                >
                    <HelpOutlineIcon color={"primary"} sx={{ pl: 2, fontSize: "1.75rem" }} />
                </Tooltip>
            ) : null}
        </Box>
    );
};

export default ContractCardHeader;
