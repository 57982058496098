import React from "react";
import { Avatar, Typography, Stack } from "@mui/material";
import { UserClaimsService } from "../services/currentUserService/currentUserService";
import { useGetDemographicsByProfileKeyQuery } from "../services/cclTokenedEnterpriseCoachingApi";

export const UserInfoPanel: React.FC = () => {
    const claimsService = new UserClaimsService();
    const [name] = React.useState<string>(
        claimsService.GetCurrentUserName() ? claimsService.GetCurrentUserName() : "Unknown User"
    );
    const [pic, setPic] = React.useState<string>(
        claimsService.GetCurrentUserPicture() ? claimsService.GetCurrentUserPicture() : ""
    );
    const [pKey] = React.useState<string>(claimsService.GetCurrentResourceId());
    const [role] = React.useState<string>(claimsService.GetCurrentUserGroup());

    const { data: userData } = useGetDemographicsByProfileKeyQuery(pKey, { skip: !pKey });

    React.useEffect(() => {
        if (userData) {
            setPic(userData.profilePictureUrl);
        }
    }, [userData]);

    return (
        <div>
            <Stack direction="row" m={2}>
                <Stack marginRight={"7px"}>
                    <Typography variant="body2" marginLeft={"auto"}>
                        {name}
                    </Typography>
                    <Typography variant="body2" marginLeft={"auto"}>
                        {role}
                    </Typography>
                </Stack>
                <Avatar sx={{ display: { xs: "none", sm: "flex" } }} alt={name} src={pic}>
                    initials
                </Avatar>
            </Stack>
        </div>
    );
};

export default UserInfoPanel;
