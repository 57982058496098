import * as React from 'react';
import { useGetCclParticipantsByEmailQuery } from '../../../../services/cclTokenedEnterpriseParticipantApi';
import ComponentLoader from '../../../../components/common/componentLoader';
import RegistrationSessionsPanel from '../../../registrations/registrationDetail/sessions/registrationSessionsPanel'
interface UserSessionsPanelProps {
    emailAddress: string
}
   
const UserSessionsPanel: React.FC<UserSessionsPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclParticipantsByEmailQuery(props.emailAddress.trim(), { skip:!props.emailAddress });
    
    if (isLoading) {
        return  <ComponentLoader msg={'Loading Sessions'} /> ;
    }
    
   if (isSuccess) {
    return ( 
        <RegistrationSessionsPanel esikey={data.length > 0?data[0].esiKey.toString():"0"} />
    );
   }
    return ( 
      <span>Unknown Error Loading Session</span>)
}
 
export default UserSessionsPanel;