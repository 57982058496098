import * as React from "react";
import {
    Alert,
    AlertColor,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { GrandCentralAccountEmailDetail } from "../../../../services/types/cclGrandCentralApiTypes";
import { useRemoveEmailMutation } from "../../../../services/cclTokenedGrandCentralApi";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";

export type GrandCentralEmailsCardProps = {
    gcAccountId: string;
    gcAccountEmail: string;
    gcEmails: GrandCentralAccountEmailDetail[];
    isUserAdmin: boolean;
    imKey: string;
};

const GrandCentralEmailsCard: React.FC<GrandCentralEmailsCardProps> = (props) => {
    const [gcEmails, setGcEmails] = React.useState<GrandCentralAccountEmailDetail[]>(
        props.gcEmails
    );
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const { logEvent } = useLogAccessEvent();
    const [removeEmail, { isLoading: removeEmailLoading }] = useRemoveEmailMutation();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleRemoveEmail = (email: string) => {
        removeEmail(email)
            .then((response) => {
                if (response) {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: props.imKey,
                        email: props.gcAccountEmail,
                    };

                    logEvent("UserEmailRemoved", evtData);
                    ShowSnackbarElement("Email Removed", "success");
                    setGcEmails(gcEmails.filter((e) => e.email !== email));
                } else {
                    ShowSnackbarElement("Email Removal Failed", "error");
                }
            })
            .catch(() => {
                ShowSnackbarElement("Email Removal Failed", "error");
            });
    };

    return (
        <CclGenericAccordian headingText="Emails">
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CardContent sx={{ pt: 0 }}>
                {gcEmails.length > 1 ? (
                    <List sx={{ pt: 0, width: 1 }}>
                        {gcEmails.map((e) => (
                            <React.Fragment key={e.email}>
                                <ListItem
                                    sx={{ p: 2 }}
                                    alignItems="flex-start"
                                    secondaryAction={
                                        props.isUserAdmin && !e.isAuth0Email ? (
                                            <CclLoadingButton
                                                size={"small"}
                                                onClick={() => {
                                                    handleRemoveEmail(e.email);
                                                }}
                                                loading={removeEmailLoading}
                                                mode={"primary"}
                                            >
                                                Remove Email
                                            </CclLoadingButton>
                                        ) : null
                                    }
                                >
                                    <ListItemText primary={e.email} />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Alert severity="info">
                        {`There are no other emails associated with this account, other than ${props.gcAccountEmail}.`}
                    </Alert>
                )}
            </CardContent>
        </CclGenericAccordian>
    );
};

export default GrandCentralEmailsCard;
