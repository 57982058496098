import * as React from "react";
import { Box, Card, CardHeader, Stack, TextField } from "@mui/material";
import { PersonInfo } from "../../../../../models/personInfo";
import { CnxParticipant } from "../../../../../services/types/enterpriseParticipantApiTypes";

interface CnxAssignedCoachPanelProps {
    participant: CnxParticipant | null;
    availableCoaches: PersonInfo[];
    currentAssignedCoach: PersonInfo | null;
}

const CnxAssignedCoachPanel: React.FC<CnxAssignedCoachPanelProps> = (props) => {
    return (
        <Card sx={{ width: 1, height: "auto", bgcolor: "white" }}>
            <CardHeader
                title={
                    props.participant == null
                        ? "Assigned Resource"
                        : `Assigned Resource for ${props.participant.firstName} ${props.participant.lastName}`
                }
            />
            <Stack spacing={3} width={1} sx={{ p: 2, flexGrow: 1 }}>
                <Box sx={{ mx: 2, px: 1, my: 0, py: 0 }}>
                    <TextField
                        id="assigned-coach-name"
                        value={
                            props.currentAssignedCoach
                                ? `${props.currentAssignedCoach.firstName} ${props.currentAssignedCoach.lastName}`
                                : ""
                        }
                        label={""}
                        sx={{ mb: 4, mx: 2, width: "100%" }}
                        disabled={true}
                        variant={"standard"}
                    />
                </Box>
            </Stack>
        </Card>
    );
};

export default CnxAssignedCoachPanel;
