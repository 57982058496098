import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ResourcesPageState {
    selectedTab: string;
}

// Define the initial state using that type
const initialState: ResourcesPageState = {
    selectedTab: "-1",
};

export const resourcesPageSlice = createSlice({
    name: "resourcesPage",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateResourcesPageSelectedTab: (state, action: PayloadAction<string>) => {
            state.selectedTab = action.payload;
        },
    },
});

export const { updateResourcesPageSelectedTab } = resourcesPageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectResourcesPage = (state: RootState) => state;
export default resourcesPageSlice.reducer;
