import * as React from "react";
import { useGetCclParticipantSessionsQuery } from "../../../../services/cclTokenedEnterpriseParticipantApi";
import { useGetCclEventsMutation } from "../../../../services/cclTokenedSessionApi";
import { Alert, AlertTitle } from "@mui/material";
import RegistrationSessionsDataGridPro from "./registrationSessionsDataGridPro";
import ComponentLoader from "../../../../components/common/componentLoader";
import { add, startOfToday } from "date-fns";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";

interface RegistrationSessionsPanelProps {
    esikey: string;
}

const RegistrationSessionsPanel: React.FC<RegistrationSessionsPanelProps> = (props) => {
    let startDate: Date = new Date(add(startOfToday(), { years: -100 }));
    let endDate: Date = new Date(add(startOfToday(), { years: 100 }));
    const {
        data: eskeys,
        isLoading: regIsLoading,
        isSuccess: regIsSuccess,
        isError: regIsError,
        error: regError,
    } = useGetCclParticipantSessionsQuery(props.esikey, { skip: props.esikey === "" });
    const [runSearch, { data: sessions, isLoading, isError, error }] = useGetCclEventsMutation();

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (regIsSuccess && sessions === undefined && isLoading === false && isError === false) {
        runSearch({
            keyword: eskeys?.join(", "),
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        });

        return <ComponentLoader msg={"Loading"} />;
    }

    if (regIsLoading) {
        return <ComponentLoader msg={"Loading"} />;
    }

    if (regIsError || isError) {
        const msg = regIsError ? getErrorMsg(regError) : getErrorMsg(error);
        console.log(msg);

        return showErrorAlert(
            `Error retrieving sessions for registration key ${props.esikey} (${msg})`
        );
    }

    return <RegistrationSessionsDataGridPro sessions={sessions} loading={isLoading} />;
};

export default RegistrationSessionsPanel;
