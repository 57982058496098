import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { getDateStringAdjusted } from '../../utilities/dateHelpers';

// Define a type for the slice state
export interface ApplicationInvitationsSearchListConfigState {
    StartDate: string,
    EndDate: string,
    EmailSearch: string,
    IsDateSearchActive: boolean
};

// Define the initial state using that type
const initialState: ApplicationInvitationsSearchListConfigState = {
    StartDate: getDateStringAdjusted(-5),
    EndDate: getDateStringAdjusted(30),
    EmailSearch: '',
    IsDateSearchActive: true
};

export const applicationInvitationsSearchListConfigSlice = createSlice({
  name: 'applicationInvitationsSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateApplicationsInvitationsSearchParams: (state, action: PayloadAction<ApplicationInvitationsSearchListConfigState>) => {
        state.StartDate = action.payload.StartDate;
        state.EndDate = action.payload.EndDate;
        state.EmailSearch = action.payload.EmailSearch;
        state.IsDateSearchActive = action.payload.IsDateSearchActive;
    }
  }
});

export const { updateApplicationsInvitationsSearchParams } = applicationInvitationsSearchListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicationInvitationsSearchListConfig = (state: RootState) => state;
export default applicationInvitationsSearchListConfigSlice.reducer;
