export type RaterDetails = {
    formMasterName: string;
    instrumentKey: number;
    raterName: string;
    raterEmail: string;
    participant_ESI_Key: string;
    participantName: string;
    participantEmail: string;
    eSI_Reg_Status: number;
    raterTypeName: string;
    raterTypeKey: number;
    language: string;
    languageKey: number;
    bpiasT_Name: string;
    eFA_Key: number;
    formActualKey: number;
    rM_Key: number;
    rM_SubmittedDate: Date | null;
    surveyStatusId: number;
    statusTypeId: number;
    surveyId: string;
    formActualScoredInd: number;
};

export type RaterType = {
    raterTypeId: number;
    name: string;
};

export type DeleteRaterRequest = {
    rmkey: number;
    esiKey: number;
    esKey: number;
};

export type RaterSwitchRequest = {
    rmkey: number;
    raterTypeKey: number;
    formActualID: number;
    esiKey: number;
    esKey: number;
};

export enum EmailType {
    RegistrationConfirmation = 1,
    ParticipantInvitation = 2,
    ParticipantReminder1 = 3,
    ParticipantReminder2 = 4,
    RaterInvitation = 5,
    RaterReminder = 6,
    RegistrationConfirmationIasOE = 7,
    GlpParticipantInvitation = 10,
    GlpParticipantReminder = 11,
    CnxParticipantInvitation = 12,
    CnxParticipantReminder = 13,
    ProductParticipantInvitation = 10001,
    ProductParticipantReminder = 10002,
    ProductParticipantSelfOnlyInvitation = 10003,
    ProductParticipantSelfOnlyReminder = 10004,
    ProductRaterInvitation = 11001,
}

export type EmailRequest = {
    ParticipantId: string;
    RaterId: Number;
    EmailTypeId: EmailType;
    EmailDateToSend: Date;
};

export type AddRaterRequest = {
    participantId: number;
    instrumentId: number;
    firstName: string;
    lastName: string;
    email: string;
    raterTypeId: number;
    languageId: number;
};

export type AddRaterResult = {
    status: number;
    email: string;
};

export type LanguageList = {
    languageId: number;
    name: string;
};

export type LanguageRequest = {
    Instrument: number;
    PreferredLanguageId: number;
};

export type SearchRaters = {
    email: string;
};

export type Individual = {
    individualId: number;
    firstName: string;
    lastName: string;
    email: string;
    preferredLanguageId: string;
    preferredLanguageName: string;
};

export type AssessmentItem = {
    buildPlanId: number;
    assessmentId: number;
    participantId: number;
    instrumentId: number;
    instrumentName: string;
    instrumentTypeId: number;
    instrumentTypeName: string;
};
