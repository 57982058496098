import * as React from "react";
import { TextFieldProps, TextField, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";

export interface CclInfoTableEditDateTimePickerProps {
    value: string;
    onChange?: (newDate: Date | null, required: boolean) => void;
    required?: boolean;
    readonly: boolean;
}

const CclInfoTableEditDatePicker: React.FC<CclInfoTableEditDateTimePickerProps> = (props) => {
    const [datetime, setdatetime] = React.useState<Date | null>(
        props.value === "" ? null : new Date(props.value)
    );

    React.useEffect(() => {
        if (props.value != null && props.value !== "") {
            setdatetime(new Date(props.value));
        } else {
            setdatetime(null);
        }
    }, [props.value]);

    const onChange = (newdate: Date | null) => {
        setdatetime(newdate);
        if (props.onChange != null) props.onChange(newdate, props?.required ?? false);
    };

    return (
        <>
            <DatePicker
                disabled={props.readonly}
                label=""
                value={datetime}
                onChange={onChange}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    params.variant = "standard";
                    params.inputProps = { style: { fontSize: 14 } };
                    params.value = datetime?.toLocaleDateString() || "";
                    return <TextField {...params} />;
                }}
            ></DatePicker>
            {!props.readonly && (
                <IconButton
                    // style={{ padding: 0 }}
                    edge="end"
                    size="small"
                    disabled={!datetime}
                    onClick={() => onChange(null)}
                >
                    <ClearIcon />
                </IconButton>
            )}
        </>
    );
};

export default CclInfoTableEditDatePicker;
