import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblUserAuth0GeneralInfoTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "userId",
        fieldType: "string",
        editable: false,
        label: "Auth0 ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "createdAt",
        fieldType: "DateTime",
        editable: false,
        label: "Created",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "upn",
        fieldType: "string",
        editable: false,
        label: "UPN",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto: @@@",
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "lastLogin",
        fieldType: "DateTime",
        editable: false,
        label: "Last Physical Login",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "loginCount",
        fieldType: "string",
        editable: false,
        label: "Login Count",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "lastIp",
        fieldType: "string",
        editable: false,
        label: "Last IP Address",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
