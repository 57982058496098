import * as React from "react";
import { DataGridPro, GridRowId, GridSelectionModel } from "@mui/x-data-grid-pro";
import {
    AllAttributeOptions,
    AttributeData,
    UpdateProfile,
} from "../../../../services/types/myAccessTypes";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import { useDeleteProfileAttributeMutation } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import AddAttributeDrawer from "./addAttributeDrawer";
import { AlertColor } from "@mui/material";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import { eAttributeKey } from "../myAttributesTab";

interface MyAttributesTableProps {
    allAttributes: AllAttributeOptions;
    userProfileAttributes: AttributeData[];
    attributeKey: eAttributeKey;
    attributeName: string;
    fromResource: boolean;
    pkey: string;
    imkey: string;
    email: string;
    readonly: boolean;
}

function getItemName(params: any) {
    if (!params.row.profileItem) {
        return "";
    }
    return params.row.profileItem.name;
}

function getItemLevel(params: any) {
    if (!params.row.profileItemTypeLevel) {
        return "";
    }
    return params.row.profileItemTypeLevel.name;
}

function getLanguageName(params: any) {
    if (!params.row.language) {
        return "";
    }
    return params.row.language.name;
}

function getColumnFromPitKey(key: number) {
    switch (key) {
        case 1:
            return 0;
        case 2:
            return 1;
        case 3:
            return 2;
        case 5:
            return 3;
        case 6:
            return 4;
        case 7:
            return 5;
        case 8:
            return 6;
        default:
            return 0;
    }
}

const MyAttributesTable: React.FC<MyAttributesTableProps> = (props) => {
    const [userData, setUserData] = React.useState<AttributeData[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<any>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [currentEvent, setCurrentEvent] = React.useState<string>("");

    const { logEvent } = useLogAccessEvent();

    const [deleteProfileAttribute] = useDeleteProfileAttributeMutation();

    const handleSelection = (ids: GridSelectionModel) => {
        if (!ids) {
            return;
        }
        const selectedRowsData = ids.map((id) => userData.find((row) => row.key === id));
        setSelectedRow(selectedRowsData);
    };

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const getProfileDatafromKey = () => {
        const data: AttributeData[] = [];
        for (let i = 0; i < props.userProfileAttributes.length; i++) {
            if (props.userProfileAttributes[i].profileItemType.key === props.attributeKey) {
                data.push(props.userProfileAttributes[i]);
            }
        }
        return data;
    };

    React.useEffect(() => {
        if (props.userProfileAttributes && props.attributeKey) {
            let data = getProfileDatafromKey();
            if (!data) {
                return;
            }
            setUserData(data);
        }
        // eslint-disable-next-line
    }, [props.userProfileAttributes, props.allAttributes, props.attributeKey]);

    const COLUMNS = [
        //Education
        [
            {
                field: "name",
                headerName: "Area",
                type: "string",
                valueGetter: getItemName,
                flex: 0.7,
            },
            {
                field: "degree",
                headerName: "Degree",
                valueGetter: getItemLevel,
                type: "string",
                flex: 0.8,
            },
            {
                field: "specialization",
                headerName: "Concentration",
                type: "string",
                flex: 0.8,
            },
        ],
        //Language
        [
            {
                field: "languageName",
                headerName: "Language",
                valueGetter: getLanguageName,
                type: "string",
                flex: 0.7,
            },
        ],
        //Industry Experience
        [
            {
                field: "area",
                headerName: "Area",
                valueGetter: getItemName,
                type: "string",
                flex: 0.7,
            },
            {
                field: "levelName",
                headerName: "Level",
                valueGetter: getItemLevel,
                type: "string",
                flex: 0.8,
            },
        ],
        //Professional Certifications
        [
            {
                field: "area",
                headerName: "Area",
                valueGetter: getItemName,
                type: "string",
                flex: 0.7,
            },
            {
                field: "initialCertYear",
                headerName: "Initial Certification",
                type: "string",
                flex: 0.8,
            },
            {
                field: "certExpiresYear",
                headerName: "Certification Expires",
                type: "string",
                flex: 0.8,
            },
        ],
        //Assessments
        [
            {
                field: "area",
                headerName: "Area",
                valueGetter: getItemName,
                type: "string",
                flex: 0.7,
            },
            {
                field: "levelName",
                headerName: "Level",
                valueGetter: getItemLevel,
                type: "string",
                flex: 0.8,
            },
        ],
        //Simulations Experientials and Activities
        [
            {
                field: "area",
                headerName: "Area",
                valueGetter: getItemName,
                type: "string",
                flex: 0.7,
            },
            {
                field: "levelName",
                headerName: "Level",
                valueGetter: getItemLevel,
                type: "string",
                flex: 0.8,
            },
        ],
        //Tools Models and Content
        [
            {
                field: "area",
                headerName: "Area",
                valueGetter: getItemName,
                type: "string",
                flex: 0.7,
            },
            {
                field: "levelName",
                headerName: "Level",
                valueGetter: getItemLevel,
                type: "string",
                flex: 0.8,
            },
        ],
    ];

    const sendLogEvent = (tag: string) => {
        const evtData: AccessEventIndividualDetails = {
            imKey: props.imkey,
            email: props.email,
        };

        logEvent(tag, evtData);
    };

    const removeAttributeEvent = () => {
        const tag: string = props.fromResource
            ? "ResourceProfileAttributeChanged"
            : "UserDeletedProfileAttribute";
        sendLogEvent(tag);
    };

    const handleButtonClick = (buttonName: string) => {
        setCurrentEvent(buttonName);
        switch (buttonName) {
            case "delete":
                handleDelete();
                break;
            case "edit":
                setEdit(true);
                setOpen(true);
                break;
            case "add":
                setOpen(true);
                break;
            default:
                break;
        }
    };

    const handleDelete = () => {
        if (!selectedRow) {
            ShowSnackbarElement("Profile Attribute Update Not Possible", "warning");
            setCurrentEvent("");
            return;
        }
        let updateData: UpdateProfile = {
            paKey: selectedRow[0].key,
            payload: [],
        };
        deleteProfileAttribute(updateData)
            .unwrap()
            .then(() => {
                removeAttributeEvent();
                ShowSnackbarElement("Profile Attribute Updated", "success");
                setCurrentEvent("");
            })
            .catch((error) => {
                ShowSnackbarElement("Profile Attribute Update Failed", "error");
                setCurrentEvent("");
            });
    };

    const handleDialogClose = () => {
        setEdit(false);
        setOpen(false);
        handleSelection([]);
        setSelectionModel([]);
    };

    const handleAddEditResult = (result: boolean, isAdd: boolean) => {
        if (result) {
            ShowSnackbarElement("Profile Attribute Updated", "success");
        } else {
            ShowSnackbarElement("Profile Attribute Update Failed", "error");
        }
        setCurrentEvent("");
    };

    let columns = COLUMNS[getColumnFromPitKey(props.attributeKey)];

    const fieldName = () => {
        switch (props.attributeName) {
            case "Education":
                return "name";
            case "Languages":
                return "languageName";
            default:
                return "area";
        }
    };

    return (
        <CclGenericAccordian headingText={props.attributeName}>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}

            <AddAttributeDrawer
                open={open}
                onClose={handleDialogClose}
                resultHandler={handleAddEditResult}
                originalObject={userData}
                allAttributes={props.allAttributes}
                attributeKey={props.attributeKey}
                attributeName={props.attributeName}
                fromResource={props.fromResource}
                update={edit}
                currentAttribute={selectedRow}
                imkey={props.imkey}
                pkey={props.pkey}
                email={props.email}
            />

            <DataGridPro
                getRowId={(row: AttributeData) => row.key}
                rows={userData}
                columns={columns}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={
                    ((props.attributeName === "Assessments (maintained by CCL)" ||
                        props.attributeName ===
                            "Professional Certifications (maintained by CCL)") &&
                        !props.fromResource) ||
                    props.readonly
                        ? {
                              toolbar: {
                                  showSaveState: false,
                                  hideSettingsButtons: true,
                              },
                          }
                        : {
                              toolbar: {
                                  showSaveState: false,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                      {
                                          id: "add",
                                          caption: "Add",
                                          label: "Add",
                                          returnSelected: true,
                                          onClick: () => handleButtonClick("add"),
                                      },
                                      {
                                          id: "edit",
                                          caption: "Edit",
                                          label: "Edit",
                                          returnSelected: true,
                                          disable:
                                              selectionModel.length === 0 ||
                                              selectionModel.length > 1,
                                          onClick: () => handleButtonClick("edit"),
                                      },
                                      {
                                          id: "delete",
                                          caption: "Delete",
                                          label: "Delete",
                                          returnSelected: true,
                                          restricted: false,
                                          disable:
                                              selectionModel.length === 0 ||
                                              selectionModel.length > 1,
                                          onClick: () => handleButtonClick("delete"),
                                          loadingBtn: true,
                                          isLoading: currentEvent === "delete",
                                      },
                                  ],
                                  btnClick: (buttonName: string) => handleButtonClick(buttonName),
                              },
                          }
                }
                initialState={{
                    sorting: {
                        sortModel: [{ field: fieldName(), sort: "asc" }],
                    },
                }}
                autoHeight
                checkboxSelection={
                    ((props.attributeName !== "Assessments (maintained by CCL)" &&
                        props.attributeName !==
                            "Professional Certifications (maintained by CCL)") ||
                        props.fromResource) &&
                    !props.readonly
                }
                disableMultipleSelection={true}
                disableSelectionOnClick
                selectionModel={selectionModel}
                onSelectionModelChange={(id: GridSelectionModel) => {
                    if (id.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = id.filter((s) => !selectionSet.has(s));
                        handleSelection(result);
                        setSelectionModel(result);
                    } else {
                        handleSelection(id);
                        setSelectionModel(id);
                    }
                }}
            />
        </CclGenericAccordian>
    );
};

export default MyAttributesTable;
