import React, { ReactElement } from "react";
import { ChipPropsColorOverrides, ClickAwayListener, Paper, Popper } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
    ParticipantStatusInstrumentForm,
    ProgramParticipantScoringStatus,
} from "../../../../../../services/types/scoringStatusTypes";
import RaterDetailsTable from "./raterDetails/raterDetailsTable";
import { OverridableStringUnion } from "@mui/types";
import BaseStatusPill from "./baseStatusPill";

export interface RaterSummaryStatusPillProps {
    participant: ProgramParticipantScoringStatus;
    instrumentId: number;
}

const RaterSummaryStatusPill: React.FC<RaterSummaryStatusPillProps> = (props) => {
    const [showPopper, setShowPopper] = React.useState<boolean>(false);
    const [popperAnchorEl, setPopperAnchorEl] = React.useState<HTMLElement | null>(null);

    let invited: number = 0;
    let completed: number = 0;

    const paxInstrument = props.participant.instruments.find(
        (i) => i.instrumentId === props.instrumentId
    );

    if (paxInstrument != null) {
        const raterDetails = paxInstrument.forms.filter((f) => f.raterTypeName !== "Self");
        invited = raterDetails.reduce((a: number, b: ParticipantStatusInstrumentForm) => {
            return a + b.surveysSent;
        }, 0);
        completed = raterDetails.reduce((a: number, b: ParticipantStatusInstrumentForm) => {
            return a + b.receivedCount;
        }, 0);
    }

    let clr:
        | OverridableStringUnion<
              "error" | "success" | "warning" | "default" | "primary" | "secondary" | "info",
              ChipPropsColorOverrides
          >
        | undefined = "default";
    let icon: ReactElement = <></>;
    let label: string = "";

    if (invited === 0) {
        clr = "warning";
        icon = <RemoveCircleOutlineIcon color="warning" fontSize="small" />;
        label = "None Assigned";
    } else if (invited > completed) {
        clr = "primary";
        icon = <ScheduleIcon color="primary" fontSize="small" />;
        label = `${completed}/${invited} Completed`;
    } else {
        clr = "success";
        icon = <CheckCircleOutlineIcon color="success" fontSize="small" />;
        label = `${completed}/${invited} Completed`;
    }

    const onHover = (event: React.MouseEvent<HTMLElement>) => {
        setPopperAnchorEl(event.currentTarget);
        setShowPopper(true);
    };

    const onHoverOff = () => {
        setShowPopper(false);
    };

    return (
        <>
            <BaseStatusPill
                icon={icon}
                label={label}
                color={clr}
                onHover={onHover}
                onHoverOff={onHoverOff}
            />
            <Popper open={showPopper} anchorEl={popperAnchorEl} placement={"right"}>
                <ClickAwayListener onClickAway={onHoverOff}>
                    <Paper elevation={6}>
                        <RaterDetailsTable
                            participant={props.participant}
                            instrumentId={props.instrumentId}
                        />
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

export default RaterSummaryStatusPill;
