import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { IconButton, Tooltip } from "@mui/material";
import { ProductSaleParticipant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclAlertDialog from "../../../../../components/common/cclAlertDialog";
import CclDataGridToolbar from "../../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../../components/common/cclCommonLink";

export interface ResourceProductSalesListProps {
    productSales: ProductSaleParticipant[];
    isLoading: boolean;
    handleEvent: (evt: string, ids: string[]) => void;
    viewCompass: (imkey: number) => void;
    isUsersData: boolean;
    currentEvent: string;
}

export const ResourceProductSalesListDataGridPro: React.FC<ResourceProductSalesListProps> = (
    props
) => {
    const [showLargeUploadWarning, setShowLargeUploadWarning] = React.useState<boolean>(false);
    const [selectedCount, setSelectedCount] = React.useState<number>(0);
    const claimsService = new UserClaimsService();
    const showLinks = claimsService.ShouldSeeAccessInternalLinks();

    const [reportIds, setReportIds] = React.useState<string[]>([]);

    const handleEvent = (evt: string) => {
        if (evt === "download") {
            if (reportIds.length > 30) {
                setSelectedCount(reportIds.length);
                setShowLargeUploadWarning(true);
                return;
            }
            const ids: string[] = reportIds.length > 0 ? reportIds.map((r) => r.toString()) : [];
            props.handleEvent("download", ids);
        } else {
            const ids: string[] = reportIds.length > 0 ? reportIds.map((r) => r.toString()) : [];
            props.handleEvent(evt, ids);
        }
    };

    const COLUMNS = [
        {
            field: "fullName",
            headerName: "Participant",
            renderCell: (params: GridRenderCellParams) =>
                showLinks ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/user?email=${encodeURI(params.row.emailAddress)}&imkey=${
                            params.row.imKey
                        }`}
                    />
                ) : (
                    params.value
                ),
            flex: 0.4,
        },
        {
            field: "emailAddress",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 0.75,
        },
        {
            field: "assessmentType",
            headerName: "Assessment",
            type: "string",
            flex: 0.75,
        },
        {
            field: "scoreDate",
            headerName: "Scored",
            valueGetter: (params: any) => new Date(params.row.scoreDate),
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
        },
        {
            field: "isReleasedToCompass",
            headerName: "Released (Compass)",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    <Tooltip title="View In Compass">
                        <IconButton
                            size="small"
                            onClick={() => props.viewCompass(params.row.imKey)}
                            color="primary"
                            sx={{ pr: 2, pl: 2 }}
                        >
                            <ExploreOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    {params.value ? "Yes" : "No"}
                </span>
            ),
            width: 150,
        },
    ];

    const readonly = !props.isUsersData && claimsService.IsReadOnly();

    return (
        <React.Fragment>
            <CclAlertDialog
                title={`Too Many Files (${selectedCount}) Selected For Download`}
                msg="For data security and privacy purposes files can only be downloaded in batches of 30 or less. Please deselect some files and try again."
                open={showLargeUploadWarning}
                onOk={() => setShowLargeUploadWarning(false)}
                severity={"error"}
            />
            <DataGridPro
                density="compact"
                getRowId={(row) => `${row.imKey}:${row.reportId ?? "unknown"}`}
                rows={props.productSales}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={
                    readonly
                        ? undefined
                        : {
                              toolbar: {
                                  showSaveState: true,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                      {
                                          id: "download",
                                          label: "Download Assessment(s)",
                                          caption: "Download Assessment(s)",
                                          disable: reportIds?.length <= 0,
                                          color: "warning",
                                          onClick: () => {
                                              handleEvent("download");
                                          },
                                          loadingBtn: true,
                                          isLoading: props.currentEvent === "download",
                                      },
                                      {
                                          id: "activateCompass",
                                          label: "Activate Compass",
                                          caption: "Activate Compass",
                                          disable: reportIds?.length !== 1,
                                          color: "warning",
                                          onClick: () => {
                                              handleEvent("activateCompass");
                                          },
                                          loadingBtn: true,
                                          isLoading: props.currentEvent === "activateCompass",
                                      },
                                  ],
                                  btnClick: (buttonName: string) => handleEvent(buttonName),
                                  readonly: !props.isUsersData && claimsService.IsReadOnly(),
                              },
                          }
                }
                loading={props.isLoading}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    const selIds = new Set(ids);
                    const rids: string[] = props.productSales
                        .filter(
                            (row) =>
                                selIds.has(`${row.imKey}:${row.reportId ?? "unknown"}`) &&
                                row.reportId !== undefined
                        )
                        .map((row) => row.reportId as string);
                    setReportIds([...rids]);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "scoreDate", sort: "desc" }],
                    },
                }}
            />
        </React.Fragment>
    );
};

export default ResourceProductSalesListDataGridPro;
