import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ResourceListConfigState {
    Email: string | null;
    FirstName: string | null;
    LastName: string | null;
}

// Define the initial state using that type
const initialState: ResourceListConfigState = {
    Email: null,
    FirstName: null,
    LastName: null,
};

export const resourceListConfigSlice = createSlice({
    name: "resourceListConfig",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateResourceSearchParams: (
            state,
            action: PayloadAction<{
                email: string | null;
                firstName: string | null;
                lastName: string | null;
            }>
        ) => {
            state.Email = action.payload.email;
            state.FirstName = action.payload.firstName;
            state.LastName = action.payload.lastName;
        },
    },
});

export const { updateResourceSearchParams } = resourceListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectResourceListConfig = (state: RootState) => state;
export default resourceListConfigSlice.reducer;
