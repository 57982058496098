import * as React from "react";
import { Alert, AlertTitle, Card } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import FlexGrid from "../../../../layouts/flexGrid";
import { useGetResourceAppointmentsBySessionKeyQuery } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import SessionAssignmentListDataGridPro from "./sessionAssignmentsList";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";

export interface SessionAssignmentsPanelProps {
    sessionId: string;
}

const SessionAssignmentsPanel: React.FC<SessionAssignmentsPanelProps> = (props) => {
    const {
        data: assignments,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetResourceAppointmentsBySessionKeyQuery(props.sessionId);

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Assignments" />;
    }

    if (isSuccess) {
        if (assignments != null && assignments.length > 0) {
            return (
                <Card sx={{ height: 1, width: 1, m: 2, p: 2 }}>
                    <FlexGrid>
                        <SessionAssignmentListDataGridPro assignments={assignments} />
                    </FlexGrid>
                </Card>
            );
        }

        return (
            <Card sx={{ height: 1, width: 1, m: 2, p: 2 }}>
                <span>No assignments found</span>
            </Card>
        );
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving resource appointments for session ${props.sessionId} (${getErrorMsg(
                error
            )})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving resource appointments for session ${props.sessionId}`
    );
};

export default SessionAssignmentsPanel;
