import React, { ReactElement } from "react";
import { ChipPropsColorOverrides } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { ProgramParticipantScoringStatus } from "../../../../../../services/types/scoringStatusTypes";
import { OverridableStringUnion } from "@mui/types";
import BaseStatusPill from "./baseStatusPill";

export interface RaterStatusPillProps {
    participant: ProgramParticipantScoringStatus;
    instrumentId: number;
    raterId: number;
}

const RaterStatusPill: React.FC<RaterStatusPillProps> = (props) => {
    let invited: number = 0;
    let completed: number = 0;
    let minRequired: number = 0;

    const paxInstrument = props.participant.instruments.find(
        (i) => i.instrumentId === props.instrumentId
    );

    if (paxInstrument != null) {
        const raterDetails = paxInstrument.forms.find((f) => f.raterTypeId === props.raterId);
        if (raterDetails != null) {
            minRequired = raterDetails.minimumRequired;
            invited = raterDetails.surveysSent;
            completed = raterDetails.receivedCount;
        }
    }

    let clr:
        | OverridableStringUnion<
              "error" | "success" | "warning" | "default" | "primary" | "secondary" | "info",
              ChipPropsColorOverrides
          >
        | undefined = "default";
    let icon: ReactElement = <></>;
    let label: string = "";

    if (invited === 0 || invited < minRequired) {
        clr = "warning";
        icon =
            invited === 0 ? (
                <RemoveCircleOutlineIcon color="warning" fontSize="small" />
            ) : (
                <WarningAmberIcon color="warning" fontSize="small" />
            );
        label = invited === 0 ? "None Assigned" : `${completed}/${invited} Completed`;
    } else if (completed !== invited) {
        clr = "primary";
        icon = <ScheduleIcon color="primary" fontSize="small" />;
        label = `${completed}/${invited} Completed`;
    } else {
        clr = "success";
        icon = <CheckCircleOutlineIcon color="success" fontSize="small" />;
        label = `${completed}/${invited} Completed`;
    }

    return <BaseStatusPill icon={icon} label={label} color={clr} />;
};

export default RaterStatusPill;
