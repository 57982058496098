import React from "react";
import { ChipPropsColorOverrides } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContrastIcon from "@mui/icons-material/Contrast";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { OverridableStringUnion } from "@mui/types";
import BaseStatusPill from "./baseStatusPill";

export interface ScoringStatusPillProps {
    status: number;
}
// Not Scorable = 0,
// Scorable = 1,
// Scored = 2,
// Cancelled = 5
const ScoringStatusPill: React.FC<ScoringStatusPillProps> = (props) => {
    let icon = <></>;
    let lbl: string = "";
    let clr:
        | OverridableStringUnion<
              "default" | "success" | "primary" | "secondary" | "error" | "info" | "warning",
              ChipPropsColorOverrides
          >
        | undefined = "default";

    if (props.status === 0) {
        clr = "warning";
        icon = <RemoveCircleOutlineIcon color="warning" fontSize="small" />;
        lbl = "Not Scorable";
    } else if (props.status === 1) {
        clr = "secondary";
        icon = <ContrastIcon color="secondary" fontSize="small" />;
        lbl = "Scorable";
    } else if (props.status === 2) {
        clr = "success";
        icon = <CheckCircleIcon color="success" fontSize="small" />;
        lbl = "Scored";
    } else {
        clr = "default";
        icon = <NotInterestedIcon color="disabled" fontSize="small" />;
        lbl = "Cancelled";
    }

    return <BaseStatusPill icon={icon} label={lbl} color={clr} />;
};

export default ScoringStatusPill;
