import React from "react";
import { Box } from "@mui/material";

export interface EmailViewerProps {
    content: string;
    disableLinks: boolean;
}

export const EmailViewer: React.FC<EmailViewerProps> = (props) => {
    let _ = require("lodash");
    let emailContent =
        props.content !== undefined && props.content !== null ? props.content : "No Data";
    emailContent = _.unescape(emailContent);

    const linksActive = props.disableLinks ? "not-active-content" : "";

    return (
        <Box>
            <div className={linksActive}>
                {" "}
                <span dangerouslySetInnerHTML={{ __html: emailContent }} />
            </div>
        </Box>
    );
};

export default EmailViewer;
