import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import * as React from "react";
import { PersonInfo } from "../../../../../models/personInfo";
import { Asset } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import CclDataGridToolbar from "../../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

const COLUMNS = [
    {
        field: "name",
        headerName: "Resource",
        valueGetter: getFullName,
        type: "string",
        flex: 1,
    },
    {
        field: "fileName",
        headerName: "File Name",
        type: "string",
        flex: 1,
    },
    {
        field: "createdDate",
        headerName: "Uploaded",
        valueGetter: (params: any) => new Date(params.row.createdDate),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        flex: 0.65,
    },
];

type SessionAssetRow = {
    id: number;
    imkey: number;
    firstName: string;
    lastName: string;
    fileName: string;
    createdDate: Date;
};

export interface SessionAssetsDataGridProps {
    assets: Asset[];
    coaches: PersonInfo[];
    handleEvent: (eventName: string, selectedIds: (number | string)[]) => void;
    readOnly?: boolean;
    eventInProgress: string;
}

export const SessionAssetsDataGrid: React.FC<SessionAssetsDataGridProps> = (props) => {
    const [sessionAssets, setSessionAssets] = React.useState<SessionAssetRow[]>([]);
    const [ids, setIds] = React.useState<(number | string)[]>([]);
    const claimservice = new UserClaimsService();

    React.useEffect(() => {
        if (props.assets && props.assets.length > 0 && props.coaches && props.coaches.length > 0) {
            const tempAssets = props.assets.filter(
                (a) => props.coaches.find((c) => c.imKey === a.imKey) != null
            );
            const pa: SessionAssetRow[] = tempAssets.map((a) => {
                const coach = props.coaches.find((c) => c.imKey === a.imKey); // pax is populate because filter above
                const sar: SessionAssetRow = {
                    id: a.id,
                    imkey: a.imKey,
                    firstName: coach?.firstName ?? "Unknown",
                    lastName: coach?.lastName ?? "Unknown",
                    fileName: a.name,
                    createdDate: a.createdDate,
                };
                return sar;
            });
            setSessionAssets(
                pa.sort((a, b) => {
                    return (
                        a.lastName.localeCompare(b.lastName) ||
                        a.firstName.localeCompare(b.firstName) ||
                        a.fileName.localeCompare(b.fileName)
                    );
                })
            );
        } else {
            setSessionAssets([]);
        }
    }, [props.assets, props.coaches]);

    return (
        <DataGridPro
            getRowId={(row) => row.id}
            rows={sessionAssets == null ? [] : sessionAssets}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={
                claimservice.IsReadOnly() || props.readOnly
                    ? undefined
                    : {
                          toolbar: {
                              showSaveState: true,
                              hideSettingsButtons: true,
                              miscButtons: [
                                  {
                                      id: "upload",
                                      label: "Upload File(s)",
                                      caption: "Upload File(s)",
                                      disable: props.coaches.length <= 0,
                                      color: "warning",
                                      onClick: () => {
                                          props.handleEvent("upload", ids);
                                      },
                                  },
                                  {
                                      id: "download",
                                      label: "Download File(s)",
                                      caption: "Download File(s)",
                                      disable: ids?.length <= 0,
                                      color: "warning",
                                      onClick: () => {
                                          props.handleEvent("download", ids);
                                      },
                                      loadingBtn: true,
                                      isLoading: props.eventInProgress === "download",
                                  },
                                  {
                                      id: "delete",
                                      label: "Delete File(s)",
                                      caption: "Delete File(s)",
                                      disable: ids?.length <= 0,
                                      color: "warning",
                                      onClick: () => {
                                          props.handleEvent("delete", ids);
                                      },
                                      loadingBtn: true,
                                      isLoading: props.eventInProgress === "delete",
                                  },
                              ],
                              btnClick: (buttonName: string) => props.handleEvent(buttonName, ids),
                          },
                      }
            }
            disableSelectionOnClick
            checkboxSelection={true}
            onSelectionModelChange={(ids: GridSelectionModel) => {
                setIds(ids.map((i) => i));
            }}
            initialState={{
                sorting: { sortModel: [{ field: "name", sort: "asc" }] },
            }}
        />
    );
};

export default SessionAssetsDataGrid;
