import * as React from "react";
import { Grid } from "@mui/material";
import WorkPoolsDataGridPro from "./workPoolsSearchList";
import { WorkPool } from "../../../services/types/resources/workPoolTypes";
import { useGetAllCadresQuery } from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import AddWorkPool from "./addWorkPool";
import CclAlertSnackbar from "../../../components/common/cclAlertSnackbar";

interface WorkPoolsTabPanelProps {}

const WorkPoolsTabPanel: React.FC<WorkPoolsTabPanelProps> = () => {
    const { data: getAllWorkPools, isLoading: loading } = useGetAllCadresQuery();

    const [resources, setResources] = React.useState<WorkPool[]>([]);
    const [triggerNewPool, setTriggerNewPool] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!getAllWorkPools) {
            return;
        }

        setResources(getAllWorkPools);
        // eslint-disable-next-line
    }, [getAllWorkPools, loading]);

    const startNewPoolFlow = () => {
        setTriggerNewPool(true);
    };

    return (
        <React.Fragment>
            <CclAlertSnackbar
                open={showSnackbar}
                message={error ? "Work Pool Creation Failed" : "Work Pool Created"}
                severity={error ? "error" : "success"}
                onClose={() => {
                    setShowSnackbar(false);
                }}
            />
            {triggerNewPool && (
                <AddWorkPool
                    open={triggerNewPool}
                    status={(error: boolean) => {
                        setError(error);
                    }}
                    onClose={(trigger: boolean) => {
                        setTriggerNewPool(false);
                        if (trigger) {
                            setShowSnackbar(true);
                        }
                    }}
                />
            )}
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    {resources && (
                        <WorkPoolsDataGridPro
                            loading={loading}
                            resources={resources}
                            addWorkPool={startNewPoolFlow}
                        />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default WorkPoolsTabPanel;
