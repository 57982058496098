import * as React from "react";
import { DataGridPro, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { NotificationDocument } from "../../../../services/types/search.service.types";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../components/common/cclCommonLink";

export interface RegistrationNotificationsListProps {
    notifications: NotificationDocument[] | undefined;
    isLoading: boolean;
    fromMyAccess?: boolean;
    myAccessCallback?: any;
}

export const RegistrationNotificationsDataGridPro: React.FC<RegistrationNotificationsListProps> = (
    props
) => {
    const claimsService = new UserClaimsService();
    const showLinks = claimsService.ShouldSeeAccessInternalLinks();
    const notifications: NotificationDocument[] =
        props.notifications == null ? [] : props.notifications;

    const COLUMNS = [
        {
            field: "subject",
            headerName: "Subject",
            renderCell: (params: GridRenderCellParams) =>
                !showLinks ? (
                    props.fromMyAccess ? (
                        <span
                            style={{ cursor: "pointer", color: "#003da7" }}
                            onClick={() => props.myAccessCallback(params.row.id)}
                        >
                            {params.value}
                        </span>
                    ) : (
                        <span>{params.value}</span>
                    )
                ) : (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/email/${params.row.id}`}
                    />
                ),
            type: "string",
            flex: 1,
        },
        {
            field: "createdUtc",
            headerName: "Created",
            valueGetter: (params: any) => new Date(params.row.createdUtc),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleString()}</span>
            ),
            flex: 0.3,
        },
        {
            field: "sender",
            headerName: "Sender",
            type: "string",
            flex: 0.4,
        },
        {
            field: "recipient",
            headerName: "Recipient",
            flex: 0.4,
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            type: "string",
        },
        {
            field: "templateTrackingId",
            headerName: "Template",
            type: "string",
            flex: 0.3,
        },
    ];

    return (
        <React.Fragment>
            <DataGridPro
                getRowId={(row) => row.id}
                rows={notifications}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                loading={props.isLoading}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={{
                    toolbar: {
                        showSaveState: false,
                        hideSettingsButtons: true,
                    },
                }}
                initialState={{ sorting: { sortModel: [{ field: "createdUtc", sort: "desc" }] } }}
            />
        </React.Fragment>
    );
};

export default RegistrationNotificationsDataGridPro;
