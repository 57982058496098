import React from "react";
import JournalEntryDrawer from "../../../components/common/jouralEntryDrawer/journalEntryDrawer";
import CclAlertDialog from "../../../components/common/cclAlertDialog";
import { Link, Typography } from "@mui/material";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventRegistrationDetails } from "../../../services/types/accessEventTypes";

export type RegistrationJournalDrawerProps = {
    esiKey: string;
    imKey: string;
    email: string;
    sessionCode: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
};

const RegistrationJournalDrawer: React.FC<RegistrationJournalDrawerProps> = (props) => {
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    const catData = { esiKey: props.esiKey, imKey: props.imKey, sessionCode: props.sessionCode };

    function saveEntry() {
        const evtData : AccessEventRegistrationDetails = {
            esiKey: props.esiKey,
            imKey: props.imKey,
            email: props.email,
        };
        logEvent("RegistrationJournalEntryCreated", evtData);
        props.onSave();
    };

    return (
        <>
            <CclAlertDialog
                open={alertOpen}
                onOk={() => setAlertOpen(false)}
                severity={"error"}
                title="Error Saving Journal Entry"
                msg={alertMessage}
            />
            <JournalEntryDrawer
                journalCategory={3}
                journalCategoryData={JSON.stringify(catData)}
                alert={
                    <Typography>
                        Please note: Any journal entries entered here will not display in IAS. This
                        feature is for proof of concept and user feedback purpose only. Journaling
                        will remain in IAS until this proof of concept is finalized, including
                        templates for journal entries. If you wish to provide any feedback please
                        contact Andy Wilson (
                        <Link href="mailto:wilsona@ccl.org">wilsona@ccl.org</Link>).
                    </Typography>
                }
                alertColor="error"
                open={props.open}
                onClose={() => props.onClose()}
                onSave={() => saveEntry()}
                onSaveError={(msg: string) => setAlertMessage(msg)}
            />
        </>
    );
};

export default RegistrationJournalDrawer;
