import * as React from "react";
import {
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextFieldProps,
    Autocomplete,
    Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ApplicationAccount, TenantGroup } from "../../../../services/types/rtkQueryTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";

export type UserEditApplicationDrawerProps = {
    open: boolean;
    onOk: (userAccess: boolean, groups: string[] | undefined, expirationDate: Date | null) => void;
    onCancel: () => any;
    tenantGroups: TenantGroup[];
    userAccount: ApplicationAccount;
    emailAddress: string;
    updating: boolean;
};

const UserEditApplicationDrawer: React.FC<UserEditApplicationDrawerProps> = (props) => {
    const [enableApplication, setEnableApplication] = React.useState<boolean>(
        props.userAccount.enabled
    );
    const [groupAddRemove, setGroupAddRemove] = React.useState<string[] | undefined>(
        props.userAccount.groups
    );
    const [expirationDate, setExpirationDate] = React.useState<Date | null>(
        props.userAccount.expiration
    );
    const [isExpirationError, setIsExpirationError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setEnableApplication(props.userAccount.enabled);
        setExpirationDate(props.userAccount.expiration);
        setGroupAddRemove(props.userAccount.groups);
    }, [props.userAccount]);

    return (
        <CclDrawer
            title={`Edit ${props.userAccount.application} For ${props.emailAddress}`}
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.onCancel}
        >
            <Box>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={enableApplication}
                                onChange={(e: any) => setEnableApplication(e.target.checked)}
                                name="gilad"
                                sx={{ mt: 2, mb: 2 }}
                            />
                        }
                        label="Enabled"
                    />
                </FormGroup>
                <DatePicker
                    label="Expiration Date"
                    value={expirationDate}
                    onChange={(newValue: Date | null) => {
                        setExpirationDate(newValue);
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                        params.fullWidth = true;
                        return (
                            <TextField
                                {...params}
                                error={isExpirationError}
                                helperText={isExpirationError ? "Enter a valid date" : ""}
                            />
                        );
                    }}
                    onError={(reason, value) => {
                        if (reason) {
                            setIsExpirationError(true);
                        } else {
                            setIsExpirationError(false);
                        }
                    }}
                />
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={props.tenantGroups.map((option) => option.name)}
                    value={groupAddRemove}
                    onChange={(e: any, newValue: string[]) => {
                        setGroupAddRemove(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={props.userAccount.application}
                            placeholder="Groups"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
            </Box>
            <CclDrawerActionBar>
                <CclButton id="cancel-btn" onClick={() => props.onCancel()} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclLoadingButton
                    id="ok-btn"
                    onClick={() => props.onOk(enableApplication, groupAddRemove, expirationDate)}
                    mode={"primary"}
                    loading={props.updating}
                >
                    Save
                </CclLoadingButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default UserEditApplicationDrawer;
