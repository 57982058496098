import * as React from "react";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import EmailTemplatesDataGridPro from "./emailTemplateDataGrid";
import {
    useGetAllEmailTemplatesQuery,
    useLazyGetEmailTemplateByIdQuery,
    useLazyGetEmailTemplatesByKeywordQuery,
} from "../../services/cclTokenedEmailTemplateApi";
import { Card, Link, Typography } from "@mui/material";
import { EmailMessageTemplate } from "../../services/types/emailTemplateTypes";
import { Link as RouterLink } from "react-router-dom";
import ComponentLoader from "../../components/common/componentLoader";

interface TemplatePageProps {}

const TemplatePage: React.FC<TemplatePageProps> = () => {
    const [data, setData] = React.useState<EmailMessageTemplate[]>([]);
    const [searchparam] = React.useState<string>("");
    const [searchMode, setSearchMode] = React.useState<boolean>(false);
    const [searchId, setSearchId] = React.useState<boolean>(false);

    const { data: emailTemplates, isLoading } = useGetAllEmailTemplatesQuery();
    const [search, { data: searchResults }] = useLazyGetEmailTemplateByIdQuery();
    const [searchTerm, { data: searchTermResults }] = useLazyGetEmailTemplatesByKeywordQuery();

    React.useEffect(() => {
        window.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                handleSearch();
            }
        });
    });

    React.useEffect(() => {
        if (searchMode) {
            if (searchId && searchResults) {
                let value = [searchResults];
                setData(value);
            } else {
                if (searchTermResults) {
                    setData(searchTermResults);
                }
            }
        } else {
            if (emailTemplates) {
                setData(emailTemplates);
            }
        }
        //eslint-disable-next-line
    }, [searchResults, searchTermResults, emailTemplates]);

    const handleSearch = () => {
        let test = checkValidId(searchparam);
        if (test) {
            setSearchId(true);
            setSearchMode(true);
            search(searchparam);
        } else {
            if (searchparam.length > 0) {
                setSearchId(false);
                setSearchMode(true);
                searchTerm(searchparam);
            }
        }
    };

    const checkValidId = (id: string) => {
        if (id.length > 0) {
            //eslint-disable-next-line
            if (
                !/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
                    id
                )
            ) {
                return false;
            }
        }
        return true;
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Link component={RouterLink} variant="h6" color="text.primary" to="/emails">
                Emails
            </Link>
            <Typography variant="h6" color="text.primary">
                Email Templates
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Card sx={{ height: 1, width: 1, display: "flex", flexDirection: "column" }}>
                {isLoading ? (
                    <ComponentLoader msg={""} />
                ) : data ? (
                    <EmailTemplatesDataGridPro emailTemplates={data} isLoading={isLoading} />
                ) : null}
            </Card>
        </StandardLayout>
    );
};

export default TemplatePage;
