import React from "react";
import { Config } from "../config";
import { Env } from "../models/env";

const domain: string | undefined = Config.REACT_APP_AUTH0_DOMAIN;
const clientId: string | undefined = Config.REACT_APP_AUTH0_CLIENT_ID;
const applicationId: string | undefined = Config.REACT_APP_APPLICATION_ID;
const tenantId: string | undefined = Config.REACT_APP_TENANT_ID;
const apiServerUrl: string | undefined = Config.REACT_APP_CCL_API_MANAGEMENT_URL;
const buildId: string | undefined = Config.REACT_APP_APPLICATION_BUILD_ID;
const commitId: string | undefined = Config.REACT_APP_APPLICATION_COMMIT_ID;
const commitBranch: string | undefined = Config.REACT_APP_APPLICATION_COMMIT_BRANCH;

const isEnvValid = domain && clientId && applicationId && tenantId && apiServerUrl;

if (!isEnvValid) {
    throw new Error("Missing environment variables.");
}

const dotenv: Env = {
    domain: domain,
    clientId: clientId,
    audience: "", //audience
    apiServerUrl: apiServerUrl,
    applicationId: applicationId as string,
    tenantId: tenantId as string,
    buildId: buildId as string,
    commitId: commitId as string,
    commitBranch: commitBranch as string,
};

export const EnvContext = React.createContext<Env>(dotenv);

export const useEnv = () => {
    const context = React.useContext(EnvContext);
    if (!context) {
        throw new Error(`useEnv must be used within a EnvProvider`);
    }
    return context;
};

type EnvProviderProps = {
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
};

export const EnvProvider: React.FC<EnvProviderProps> = (props) => {
    return <EnvContext.Provider value={dotenv} {...props} />;
};
