import * as React from "react";
import { Box, Stack, TextField } from "@mui/material";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";

export interface CclInfoTableEditNotesProps {
    label: string;
    value: string;
    onChange: (newText: string, required: boolean) => void;
    required: boolean;
    readonly: boolean;
}
const CclInfoTableEditNotes: React.FC<CclInfoTableEditNotesProps> = (props) => {
    const [text, setText] = React.useState<string>(props.value);
    const label = props.required ? `${props.label} (Required)` : props.label;
    const claimsService = new UserClaimsService();

    React.useEffect(() => {
        setText(props.value);
    }, [props.value]);

    const handleChange = (newText: string) => {
        setText(newText);
        props.onChange(newText, props.required);
    };

    return (
        <Box sx={{ m: 3, mt: 4, width: 1 }}>
            <Stack width={1} spacing={2}>
                <TextField
                    id="info-text-notes-input"
                    value={text}
                    error={props.required && !text ? true : false}
                    label={label}
                    onChange={(e) => handleChange(e.target.value)}
                    disabled={claimsService.IsReadOnly()}
                    fullWidth={true}
                    variant="outlined"
                    sx={{ mr: 3 }}
                    multiline
                    rows={3}
                />
            </Stack>
        </Box>
    );
};

export default CclInfoTableEditNotes;
