import * as React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import {
    FormControl,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Switch,
} from "@mui/material";
import { Box } from "@mui/system";
import { InstrumentVisibility } from "./assessmentStatusDataGrid";
import { right } from "@popperjs/core";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { AccessEventSessionDetails } from "../../../../../../services/types/accessEventTypes";
import CclButton from "../../../../../../components/common/cclButtons/cclButton";
import useLogAccessEvent from "../../../../../../hooks/useLogAccessEvent";
import { useGetAppUrlsQuery } from "../../../../../../services/cclTokenedSettingsApi";

export interface AssessmentStatusDataGridToolbarProps {
    instrumentVisibility: InstrumentVisibility[];
    anySelected: boolean;
    handleButtonClick: (button: string) => void;
    onAssessmentFilterChange: (visible: boolean, instrumentId: number) => void;
    defaultDetailLevel: number;
    handle360DetailChange?: (detailLevelSetting: number) => void;
    showDetailLevelSelect: boolean;
    readOnly?: boolean;
    sessionCode: string;
    sessionKey: number;
}

const AssessmentStatusDataGridToolbar: React.FC<AssessmentStatusDataGridToolbarProps> = (props) => {
    const [select360DetailSetting, setSelect360DetailSetting] = React.useState<number>(
        props.defaultDetailLevel
    );
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { logEvent } = useLogAccessEvent();
    const { data: urls } = useGetAppUrlsQuery();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const toggleVisibility = (visible: boolean, instrumentId: number) => {
        props.onAssessmentFilterChange(visible, instrumentId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handle360DetailChange = (event: SelectChangeEvent<number>) => {
        const newDetailSetting = event.target.value as number;
        setSelect360DetailSetting(newDetailSetting);
        if (props.handle360DetailChange) props.handle360DetailChange(newDetailSetting);
    };

    const openCclScoring = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        const evtData: AccessEventSessionDetails = {
            projectCode: props.sessionCode ?? "unknown",
        };
        logEvent("AssessmentsScored", evtData);
        window.open(`${urls["CCLScoring"]}/program-status/${props.sessionKey}`, "_blank");
    };

    const assessmentChecklistOpen = Boolean(anchorEl);
    const id = assessmentChecklistOpen ? "assessment-visiblity-checkbox-popover" : undefined;

    return (
        <GridToolbarContainer>
            <Box sx={{ width: 1, justifyContent: "space-between" }} padding={1}>
                <CclButton
                    mode={"primary"}
                    onClick={openCclScoring}
                    sx={{
                        pt: 2,
                        pb: 2,
                        mt: 3,
                        mb: 2,
                        mr: 2,
                        ml: 2,
                    }}
                >
                    Score Assessments
                </CclButton>
                {props.instrumentVisibility.length > 0 ? (
                    <React.Fragment>
                        <CclButton
                            size="small"
                            onClick={handleClick}
                            endIcon={
                                assessmentChecklistOpen ? (
                                    <ArrowDropDown sx={{ mt: "-3px" }} />
                                ) : (
                                    <ArrowRight sx={{ mt: "-3px" }} />
                                )
                            }
                            sx={{
                                pt: 2,
                                pb: 2,
                                mt: 3,
                                mb: 2,
                                mr: 2,
                                ml: 2,
                                float: right,
                            }}
                            mode={"primary"}
                        >
                            Filter Assessments
                        </CclButton>
                        <Popover
                            id={id}
                            open={assessmentChecklistOpen}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <List dense>
                                {props.instrumentVisibility
                                    .sort((a: InstrumentVisibility, b: InstrumentVisibility) =>
                                        a.name > b.name ? 1 : -1
                                    )
                                    .map((iv) => (
                                        <ListItem key={iv.id} value={iv.id}>
                                            <Switch
                                                checked={iv.visible}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                    checked: boolean
                                                ) => toggleVisibility(checked, iv.id)}
                                                size="small"
                                                sx={{ p: "5px", mr: "4px" }}
                                            />
                                            <ListItemText primary={iv.name} />
                                        </ListItem>
                                    ))}
                            </List>
                        </Popover>
                    </React.Fragment>
                ) : null}
                {props.showDetailLevelSelect ? (
                    <FormControl
                        sx={{ p: 1, m: 2, float: right, fontSize: "8px" }}
                        size="small"
                        variant={"outlined"}
                    >
                        {/* <InputLabel
                            id="360-detail-level-label"
                            sx={{ paddingTop: "5px", paddingLeft: "5px" }}
                        >
                            360 Detail Level
                        </InputLabel> */}
                        <Select
                            size="small"
                            // labelId="360-detail-level-label"
                            id="select-360-detail-level"
                            value={select360DetailSetting}
                            // label="360 Detail Level"
                            onChange={handle360DetailChange}
                        >
                            <MenuItem value={0}>Only Show Scorability</MenuItem>
                            <MenuItem value={1}>Show Self And Rater Summary</MenuItem>
                            <MenuItem value={2}>Show All Rater Categories</MenuItem>
                        </Select>
                    </FormControl>
                ) : null}
            </Box>
        </GridToolbarContainer>
    );
};

export default AssessmentStatusDataGridToolbar;
