import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface AccessEventListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: AccessEventListTableStateState = {
    TableState: JSON.stringify({ sorting: { sortModel: [{ field: "createdUtc", sort: "desc" }] } }),
};

export const accessEventListTableStateSlice = createSlice({
    name: "accessEventListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateAccessEventListGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateAccessEventListGridState } = accessEventListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const AccessEventListTableState = (state: RootState) => state;
export default accessEventListTableStateSlice.reducer;
