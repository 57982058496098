import * as React from "react";
import { Link } from "@mui/material";
import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { EventDocument } from "../../../../services/types/search.service.types";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

const COLUMNS = [
    {
        field: "eventnumber",
        headerName: "ID",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <Link href={`/session/${params.row.eventnumber}`}>{params.value}</Link>
        ),
        width: 100,
    },
    {
        field: "projectCode",
        headerName: "Project ID",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <Link href={`/session/${params.row.eventnumber}`}>{params.value}</Link>
        ),
        width: 125,
    },
    {
        field: "title",
        headerName: "Title",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <Link href={`/session/${params.row.eventnumber}`}>{params.value}</Link>
        ),
        flex: 1,
    },
    {
        field: "startdate",
        headerName: "Start",
        valueGetter: (params: any) => new Date(params.row.startdate),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 150,
    },
    {
        field: "endDate",
        headerName: "End",
        valueGetter: (params: any) => new Date(params.row.endDate),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 150,
    },
    {
        field: "typeName",
        headerName: "Type",
        type: "string",
        width: 150,
    },
    {
        field: "programCode",
        headerName: "Code",
        type: "string",
        width: 50,
    },
    {
        field: "campusName",
        headerName: "Campus",
        width: 175,
        type: "string",
    },
    {
        field: "location",
        headerName: "Delivery Location",
        type: "string",
        width: 175,
    },
    {
        field: "hostPlatformName",
        headerName: "Hosting Platform",
        type: "string",
        width: 200,
    },
    {
        field: "surveyPlatformName",
        headerName: "Assessment Platform",
        type: "string",
        width: 200,
    },
    {
        field: "hasCompassSku",
        headerName: "Compass",
        type: "string",
        valueGetter: (params: GridValueGetterParams) => `${params.value ? "Yes" : "No"}`,
        width: 85,
    },
];

export interface RegistrationSessionsDataGridProProps {
    sessions: EventDocument[] | undefined;
    loading: boolean;
}

export const RegistrationSessionsDataGridPro: React.FC<RegistrationSessionsDataGridProProps> = (
    props
) => {
    const [sessions, setSessions] = React.useState<EventDocument[]>([]);

    React.useLayoutEffect(() => {
        let newsessions = props.sessions == null ? [] : props.sessions;
        setSessions(newsessions);
    }, [props.sessions]);

    return (
        <DataGridPro
            getRowId={(row) => row.eventnumber}
            rows={sessions}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.loading}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: false,
                    hideSettingsButtons: true,
                },
            }}
            initialState={{ sorting: { sortModel: [{ field: "startdate", sort: "desc" }] } }}
        />
    );
};

export default RegistrationSessionsDataGridPro;
