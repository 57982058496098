import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface UserSearchListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: UserSearchListTableStateState = {
    TableState: JSON.stringify({ sorting: { sortModel: [{ field: "email", sort: "asc" }] } }),
};

export const userSearchListTableStateSlice = createSlice({
    name: "userSearchListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateUserSearchGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateUserSearchGridState } = userSearchListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const UserSearchListTableState = (state: RootState) => state;
export default userSearchListTableStateSlice.reducer;
