import { Fragment, useState, useEffect } from "react";
import { useLazyGetScoringSnapshotQuery } from "../../services/cclScoringStatusApi";
import { Typography } from "@mui/material";
import ScoringSnapshotDataGridPro from "./scoringSnapshotDataGridPro";
import {
    ScoringSnapshotResponse,
    ScoringSnapshotItem,
} from "../../services/types/scoringStatusTypes";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";

export interface ScoringSnapshotProps {}

export const ScoringSnapshot: React.FC<ScoringSnapshotProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snapshotItems, setSnapshotItems] = useState<ScoringSnapshotItem[]>([]);
    const [getSnapshot, { data: lazySnapshot, isLoading, isSuccess, isFetching }] =
        useLazyGetScoringSnapshotQuery();

    useEffect(() => {
        refreshSnapshot(false);
        //eslint-disable-next-line
    }, []);

    const refreshSnapshot = (manual: boolean) => {
        getSnapshot()
            .unwrap()
            .then((snapshot: ScoringSnapshotResponse) => {
                setSnapshotItems([...snapshot.items]);
                if (manual) {
                    setShowSnackbar(true);
                }
            })
            .catch(() => {});
    };

    if (isLoading || (isSuccess && lazySnapshot != null)) {
        return (
            <Fragment>
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={"Data Refreshed"}
                        severity={"success"}
                    />
                ) : null}
                <ScoringSnapshotDataGridPro
                    loading={isLoading || isFetching}
                    snapshotItems={snapshotItems}
                    refresh={refreshSnapshot}
                />
            </Fragment>
        );
    }

    return <Typography>Error loading page</Typography>;
};

export default ScoringSnapshot;
