import * as React from "react";
import { Alert, Box, Grid } from "@mui/material";
import { useGetSessionOptionsByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import ComponentLoader from "../../../../../components/common/componentLoader";
import ActivateCompassCard from "./activateCompassCard";
import CompassStatusCard from "./compassStatusCard";
import ReadOnlyGate from "../../../../../components/userAccess/ReadOnlyGate";

interface ManageCompassPanelProps {
    sessionId: string;
    readOnly?: boolean;
}

const ManageCompassPanel: React.FC<ManageCompassPanelProps> = (props) => {
    const [noDigitalTool, setNoDigitalTool] = React.useState<boolean>(true);
    const {
        data: sessionOptions,
        isSuccess,
        isLoading,
        isError,
    } = useGetSessionOptionsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });

    React.useEffect(() => {
        if (isSuccess && sessionOptions != null) {
            const compassOption = sessionOptions.digitalTools.find((dt) => dt.id === 1);
            setNoDigitalTool(compassOption == null);
        }
        // eslint-disable-next-line
    }, [sessionOptions]);

    return (
        <Box sx={{ mb: 2, mt: 2, p: 2, display: "flex", flexDirection: "column" }}>
            {isLoading && <ComponentLoader msg={"Loading Compass Management Info"} />}
            {isError && (
                <Alert severity="error">
                    {`Error retrieving Compass information for session key ${props.sessionId}`}
                </Alert>
            )}
            {isSuccess && noDigitalTool && (
                <Alert severity="info">
                    {
                        "Compass must be added as an item requirement in F&O for this project in order to manage activation for participants"
                    }
                </Alert>
            )}
            {isSuccess && !noDigitalTool && (
                <Grid container width={1} spacing={3}>
                    <ReadOnlyGate
                        readOnly={props.readOnly}
                        reactElement={
                            <Grid item xs={12} md={6}>
                                <ActivateCompassCard sessionId={props.sessionId} />
                            </Grid>
                        }
                    />
                    <Grid item xs={12} md={6}>
                        <CompassStatusCard sessionId={props.sessionId} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default ManageCompassPanel;
