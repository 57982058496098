import React from "react";
import JournalEntryDrawer from "../../../components/common/jouralEntryDrawer/journalEntryDrawer";
import CclAlertDialog from "../../../components/common/cclAlertDialog";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../services/types/accessEventTypes";

export type UserJournalDrawerProps = {
    userId: string;
    email: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
};

const UserJournalDrawer: React.FC<UserJournalDrawerProps> = (props) => {
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    const catData = { userId: props.userId, email: props.email };

    function saveEntry() {
        const evtData:AccessEventIndividualDetails = {
            imKey: props.userId,
            email: props.email,
        };
        logEvent("UserJournalEntryCreated", evtData);
        props.onSave();
    };

    return (
        <>
            <CclAlertDialog
                open={alertOpen}
                onOk={() => setAlertOpen(false)}
                severity={"error"}
                title="Error Saving Journal Entry"
                msg={alertMessage}
            />
            <JournalEntryDrawer
                journalCategory={5}
                journalCategoryData={JSON.stringify(catData)}
                open={props.open}
                onClose={() => props.onClose()}
                onSave={() => saveEntry()}
                onSaveError={(msg: string) => setAlertMessage(msg)}
            />
        </>
    );
};

export default UserJournalDrawer;
