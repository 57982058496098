import React from "react";
import {
    useGetSessionSetUpProgramManagersByEventKeyQuery,
    usePutProgramManagerSessionMutation,
} from "../../../../../services/cclTokenedSessionApi";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import SessionProgramManagersTable from "./sessionProgramManagersTable";
import AddEditProgramManagerDrawer from "./addEditProgramManagerDrawer";
import { SessionProgramManager } from "../../../../../services/types/sessionDetailTypes";
import { Alert, AlertColor } from "@mui/material";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";

export interface SessionProgramManagersAccordionProps {
    esKey: number;
    programCode: string;
    hasAssessmentPlatform: boolean;
    readOnly?: boolean;
    showSnackbar: (message: string, severity: AlertColor) => void;
}

export const SessionProgramManagersAccordion: React.FC<SessionProgramManagersAccordionProps> = (
    props
) => {
    const [selectedPM, setSelectedPM] = React.useState<SessionProgramManager | null>(null);
    const [openAddEditDrawer, setOpenAddEditDrawer] = React.useState<boolean>(false);
    const {
        data: programManagers,
        isSuccess,
        isLoading,
        isError,
    } = useGetSessionSetUpProgramManagersByEventKeyQuery(props.esKey.toString(), {
        skip: !props.esKey,
    });
    const [saveProgramManager, { isLoading: removing }] = usePutProgramManagerSessionMutation();
    const { logEvent } = useLogAccessEvent();

    const removeProgramManager = (id: number) => {
        var removeManager = programManagers?.find((pm) => pm.individualId === id);
        if (!removeManager) {
            props.showSnackbar("Client Administrator Removal Failed", "error");
            return;
        }
        var payload: SessionProgramManager = { ...removeManager };
        payload.programId = props.esKey;
        payload.status = "Remove";
        saveProgramManager(payload)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: props.programCode,
                };
                logEvent("SessionSetupSaved", evtData);
                props.showSnackbar("Client Administrator Removed", "success");
            })
            .catch(() => {
                props.showSnackbar("Client Administrator Removal Failed", "error");
            });
    };

    const handleEvent = (evt: string, id: number) => {
        switch (evt) {
            case "add":
                setSelectedPM(null);
                setOpenAddEditDrawer(true);
                break;

            case "remove":
                removeProgramManager(id);
                break;
        }
    };

    return (
        <CclGenericAccordian headingText="Client Administrators" sx={{ h: 1 }}>
            {openAddEditDrawer ? (
                <AddEditProgramManagerDrawer
                    open
                    esKey={props.esKey}
                    programCode={props.programCode}
                    onCancel={() => setOpenAddEditDrawer(false)}
                    managerToEdit={selectedPM}
                    showSnackbar={props.showSnackbar}
                />
            ) : null}
            {!props.hasAssessmentPlatform ? (
                <Alert severity="warning" sx={{ width: "100%" }}>
                    An Assessment Platform has not been specified in D365 and therefore this feature
                    is unavailable at this time. Please specify an Assessment Platform in D365 to
                    gain access to this feature.
                </Alert>
            ) : isSuccess || isLoading ? (
                <SessionProgramManagersTable
                    ProgramManagersList={programManagers}
                    isLoading={isLoading}
                    handleEvent={handleEvent}
                    readOnly={props.readOnly}
                    removing={removing}
                />
            ) : isError ? (
                <Alert severity="error" sx={{ width: "100%" }}>
                    There was an error retrieving Client Administrators for this session.
                </Alert>
            ) : null}
        </CclGenericAccordian>
    );
};
export default SessionProgramManagersAccordion;
