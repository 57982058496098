import * as React from "react";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

type SelectOption = {
    val: string;
    option: string;
};

export interface CclInfoTableEditSelectProps {
    value: string;
    onChange: (newSelection: any, required: boolean) => void;
    options: { val: string; option: string }[] | null;
    required: boolean;
    readonly: boolean;
}

const CclInfoTableEditSelect: React.FC<CclInfoTableEditSelectProps> = (props) => {
    const [selected, setSelected] = React.useState<SelectOption | null>(
        props.options?.find((o) => o.option === props.value) ??
            ({ val: "", option: "Nothing Selected" } as SelectOption)
    );

    React.useEffect(() => {
        if (props.options != null && props.value != null) {
            var prop = props.options.find((o) => o.val === props.value.toString());
            if (prop != null) setSelected(prop as SelectOption);
        }
    }, [props.options, props.value]);

    const getOptionFromVal = (val: string): string => {
        if (val == null) return "Nothing Selected";

        let opt = props.options?.find((o) => o.val === val);
        if (opt == null) return "Nothing Selected";

        return opt.option;
    };

    const handleChange = (event: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: event.target.value,
            option: getOptionFromVal(event.target.value),
        };
        setSelected(newopt);
        if (newopt.val !== "") props.onChange(newopt, props.required);
    };

    if (props.readonly) {
        return (
            <Typography sx={{ fontSize: 14, fontWeight: "fontWeightBold", w: 1 }}>
                {selected?.option ?? ""}
            </Typography>
        );
    }

    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected?.val ?? ""}
            renderValue={(value) => {
                return getOptionFromVal(value);
            }}
            fullWidth={true}
            onChange={handleChange}
            variant={"standard"}
            sx={{ fontSize: 14 }}
        >
            {props.options &&
                props.options.length > 0 &&
                props.options.map((opt, i) => {
                    return (
                        <MenuItem
                            key={opt.val}
                            value={opt.val}
                            selected={opt.val === selected?.val}
                        >
                            {opt.option}
                        </MenuItem>
                    );
                })}
        </Select>
    );
};

export default CclInfoTableEditSelect;
