import * as React from "react";
import { Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import CclInfoTable, {
    CclInfoTableFormValue,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { Tenant } from "../../../../services/types/cclGrandCentralApiTypes";
import { CclInfoTblTenantTemplate } from "./cclInfoTblTenantTemplate";
import { useUpdateCclTenantMutation } from "../../../../services/cclTokenedGrandCentralApi";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

export type SystemTenantDetailProps = {
    systemId: string;
    systemName: string;
    tenant: Tenant;
    readonly: boolean;
    expanded: boolean;
};

export const SystemTenantDetail: React.FC<SystemTenantDetailProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [updateTenant] = useUpdateCclTenantMutation();
    const { logEvent } = useLogAccessEvent();
    var tenantInfoTemplate = JSON.parse(JSON.stringify(CclInfoTblTenantTemplate));

    const handleSave = (changedFields: CclInfoTableFormValue[]) => {
        var updateBody = JSON.parse(JSON.stringify(props.tenant));
        changedFields.forEach((field) => {
            updateBody[field.name] = field.value;
        });
        updateTenant({
            tenantId: props.tenant.tenantId,
            systemId: props.tenant.systemId,
            name: updateBody["tenantName"],
            isPrivate: updateBody["isPrivate"],
        })
            .unwrap()
            .then((response) => {
                const evtData : AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
            })
            .catch((error) => {
                setErrorMessage(`Error updating tenant: ${error.data}`);
                setShowErrorDialog(true);
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title="Error Updating Tenant Information"
            />
            <Accordion key={props.tenant.tenantId} defaultExpanded={props.expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${props.tenant.tenantId}-tenant-panel-content`}
                    id={`${props.tenant.tenantId}-tenant-panel-header`}
                >
                    <Typography>{props.tenant.tenantName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CclInfoTable
                        template={tenantInfoTemplate}
                        data={props.tenant}
                        onSave={handleSave}
                        readonly={props.readonly}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SystemTenantDetail;
