import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

const useCombinationKeyPress = (keys: any[], callback: any, node: HTMLDivElement | null = null) => {
    // implement the callback ref pattern
    const callbackRef = useRef(callback);
    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    const internalHandler = (event: any) => {
        // check if one of the key is part of the ones we want
        if (event.ctrlKey && event.altKey && keys.some((key: any) => event.key === key)) {
            callbackRef.current(event);
        }
    };
    // handle what happens on key press
    const handleKeyPress = useCallback(internalHandler, [keys]);

    useEffect(() => {
        // target is either the provided node or the document
        const targetNode = node ?? document;
        // attach the event listener
        targetNode && targetNode.addEventListener("keydown", handleKeyPress);

        // remove the event listener
        return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress, node]);
};

export default useCombinationKeyPress;
