import * as React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import SelectPeopleStepPanel from "./selectPeopleStepPanel";
import UploadFilesStepPanel from "./uploadFilesStepPanel";
import { PersonInfo } from "../../../../../models/personInfo";

export interface SessionFileUploadStepperProps {
    sessionId: string;
    projectCode: string;
    isSessionFile: boolean;
    people: PersonInfo[];
    personType: string;
    sessionCode: string;
    onFinish: (request: any) => void;
    onCancel: () => void;
}

export const SessionFileUploadStepper: React.FC<SessionFileUploadStepperProps> = (props) => {
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [selectedImKeys, setSelectedImKeys] = React.useState<number[]>([]);

    const onParticipantListChange = (imKeys: number[]) => {
        setSelectedImKeys([...imKeys]);
        setActiveStep(1);
    };

    const firstStepLabel = props.personType === "participant" ? "Participants" : "Resources";
    const steps: [string, React.ReactNode][] = [
        [
            firstStepLabel,
            <SelectPeopleStepPanel
                onNextPanel={onParticipantListChange}
                onCancel={props.onCancel}
                sessionId={props.sessionCode}
                projectCode={props.projectCode}
                isSessionFile={props.isSessionFile}
                people={props.people}
                personType={props.personType}
                selectedImKeys={selectedImKeys}
            />,
        ],
        [
            "Files",
            <UploadFilesStepPanel
                onFinalize={() => props.onFinish(null)}
                onPreviousPanel={() => setActiveStep(0)}
                onCancel={props.onCancel}
                sessionId={props.sessionId}
                projectCode={props.projectCode}
                isSessionFile={props.isSessionFile}
                people={props.people}
                personType={props.personType}
                sessionCode={props.sessionCode}
                imKeys={selectedImKeys}
            />,
        ],
    ];

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    const stepProps: { completed?: boolean; key: number } = { key: index };

                    return (
                        <Step {...stepProps}>
                            <StepLabel>{step[0]}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {steps[activeStep][1]}
        </Box>
    );
};

export default SessionFileUploadStepper;
