import * as React from "react";
import { Alert, AlertColor, Box, Card, CardHeader, Stack, TextField } from "@mui/material";
import { PersonInfo } from "../../../../../models/personInfo";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { CnxParticipant } from "../../../../../services/types/enterpriseParticipantApiTypes";

interface CnxProposedCoachPanelProps {
    participant: CnxParticipant | null;
    availableCoaches: PersonInfo[];
    currentProposedCoaches: PersonInfo[];
}

const CnxProposedCoachPanel: React.FC<CnxProposedCoachPanelProps> = (props) => {
    const [alertNeeded, setAlertNeeded] = React.useState<boolean>(false);
    const [alertSeverity, setAlertSeverity] = React.useState<AlertColor | undefined>("info");
    const [alertMessage, setAlertMessage] = React.useState<string>("Loading...");

    // eslint-disable-next-line
    const claimsService = new UserClaimsService();

    React.useEffect(() => {
        function getAlertTitleUtilityClass() {
            if (props.participant == null) {
                setAlertSeverity("info");
                setAlertMessage("Select Participant");
                return;
            }
            if (props.participant?.cnxRegistration == null) {
                setAlertSeverity("error");
                setAlertMessage("No CNX registration found");
                return;
            }
            if (props.participant.cnxRegistration.coachingTypeId === 2) {
                setAlertSeverity("info");
                setAlertMessage("Proposed coaches not used for integrated coaching");
                return;
            }
            if (props.participant.cnxRegistration.coachingTypeId === 1) {
                if (props.participant.coachingStage === 1) {
                    if (props.participant.stageProgress !== 2) {
                        setAlertSeverity("warning");
                        setAlertMessage("Intake survey not complete.");
                        return;
                    } else {
                        setAlertSeverity("info");
                        setAlertMessage("No proposed coaches");
                        return;
                    }
                } else if (props.participant.coachingStage === 2) {
                    if (props.participant.stageProgress === 2) {
                        setAlertSeverity("success");
                        setAlertMessage("Coachee has started prioritization");
                        return;
                    }
                }
            }
        }

        if (
            props.participant == null ||
            props.participant?.cnxRegistration == null ||
            props.participant?.cnxRegistration.coachingTypeId === 2
        ) {
            getAlertTitleUtilityClass();
            setAlertNeeded(true);
            return;
        }
        if (claimsService.IsReadOnly()) {
            setAlertNeeded(true);
            return;
        } else if (props.participant?.cnxRegistration?.coachingTypeId === 1) {
            getAlertTitleUtilityClass();
            if (props.participant.coachingStage === 1) {
                if (props.participant.stageProgress !== 2) {
                    setAlertNeeded(true);
                    return;
                } else if (props.participant.stageProgress === 2) {
                    setAlertNeeded(true);
                    return;
                }
            } else if (props.participant.coachingStage === 2) {
                setAlertNeeded(props.participant.stageProgress === 2);
                return;
            } else if (props.participant.coachingStage >= 3) {
                setAlertNeeded(false);
                return;
            }
        }
        setAlertNeeded(false);
    }, [claimsService, props.participant]);

    return (
        <Card sx={{ width: 1, height: "auto", bgcolor: "white" }}>
            <CardHeader
                title={
                    props.participant == null
                        ? "Proposed Resources"
                        : `Proposed Resources for ${props.participant.firstName} ${props.participant.lastName}`
                }
            />
            <Stack spacing={3} width={1} sx={{ p: 2, flexGrow: 1 }}>
                {alertNeeded && (
                    <Box sx={{ px: 3, my: 0, py: 0 }}>
                        <Alert severity={alertSeverity}>{alertMessage}</Alert>
                    </Box>
                )}
                <Box sx={{ mx: 2, px: 1, my: 0, py: 0 }}>
                    {props.currentProposedCoaches.map((c, index) => {
                        return (
                            <TextField
                                key={index}
                                value={`${c.firstName} ${c.lastName}`}
                                label={""}
                                sx={{ mb: 4, width: "100%", mx: 2 }}
                                disabled={true}
                                variant={"standard"}
                            />
                        );
                    })}
                </Box>
            </Stack>
        </Card>
    );
};

export default CnxProposedCoachPanel;
