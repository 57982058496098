import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/lab";
import { Box, FormControlLabel, LinearProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTemplateFilesQuery } from "../../../../services/cclTokenedGenerateDocumentsApi";
import {
    AssociatedTemplate,
    TemplateFiles,
    TemplateFilesQueryArgs,
} from "../../../../services/types/generateDocumentsApiTypes";

interface TemplateTreeItemProps {
    sessionId: string;
    parentNodeId: string;
    width?: string;
    selectedTemplates: AssociatedTemplate[];
    handleCheckboxClick: (templateFile: TemplateFiles | undefined) => void;
}

const TemplateTreeItem: React.FC<TemplateTreeItemProps> = (props) => {
    const templateFilesQueryArgs: TemplateFilesQueryArgs = {
        esKey: Number(props.sessionId),
        parent: props.parentNodeId,
    };

    const { data, isLoading } = useTemplateFilesQuery(templateFilesQueryArgs);
    const [toggled, setToggled] = React.useState<string[]>([]);

    const handleToggle = (event: any, nodeIds: any) => {
        setToggled(nodeIds);
    };

    const isToggled = (nodeId: string) => {
        return toggled.includes(nodeId);
    };

    const myheight = props.parentNodeId === "%23" ? "400px" : "100%";

    const proxyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleCheckboxClick(data?.find((x) => x.id === event.target.value));
    };

    if (isLoading) {
        return (
            <Box sx={{ width: "100%" }}>
                <LinearProgress />
            </Box>
        );
    }

    return (
        <TreeView
            key={"treeView_" + Math.random}
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
                width: "{props.width}",
                overflowY: "auto",
                height: myheight,
                minHeight: myheight,
            }}
            onNodeToggle={handleToggle}
        >
            {data?.map((templateFile, index) =>
                isToggled(templateFile.id) ? (
                    <TreeItem
                        key={"treeItem_" + templateFile.id}
                        nodeId={templateFile.id}
                        label={templateFile.text}
                        children={
                            <TemplateTreeItem
                                selectedTemplates={props.selectedTemplates}
                                handleCheckboxClick={props.handleCheckboxClick}
                                sessionId={props.sessionId}
                                parentNodeId={templateFile.id}
                            />
                        }
                    />
                ) : !templateFile.text.toLowerCase().endsWith(".docx") &&
                  !templateFile.text.toLowerCase().endsWith(".doc") &&
                  !templateFile.text.toLowerCase().endsWith(".txt") &&
                  !templateFile.text.toLowerCase().endsWith(".csv") &&
                  !templateFile.text.toLowerCase().endsWith(".pdf") ? (
                    <TreeItem
                        key={"treeItem_" + templateFile.id}
                        nodeId={templateFile.id}
                        label={templateFile.text}
                        children={<div id={index + "_" + templateFile.id}></div>}
                    />
                ) : (
                    <FormControlLabel
                        key={"fcl_" + templateFile.id}
                        id={templateFile.id + "_fcl"}
                        sx={{
                            paddingLeft: 4,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: 0,
                            marginBottom: 0,
                            width: "95%",
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    paddingLeft: 0,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginTop: 0,
                                    marginBottom: 0,
                                }}
                                onChange={proxyChange}
                                value={templateFile.id}
                                checked={props.selectedTemplates
                                    .map((x) => x.id.replace("Q1RLXFJlcG9zaXRvcnlc", ""))
                                    .includes(templateFile.id)}
                            />
                        }
                        label={templateFile.text}
                    />
                )
            )}
        </TreeView>
    );
};

export default TemplateTreeItem;
