import * as React from "react";
import { Alert } from "@mui/material";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import SignContractDialog from "./signContract/signContractDialog";
import {
    useDeclineContractMutation,
    useGetOpenContractByProfileKeyQuery,
} from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { DeclineContractRequest } from "../../../../services/types/enterpriseCoachingApiTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export interface SignContractBannerProps {
    isUsersRecord: boolean;
    pKey: string;
}

export const SignContractBanner: React.FC<SignContractBannerProps> = (props) => {
    const claimsService = new UserClaimsService();
    const [showSignContract, setShowSignContract] = React.useState<boolean>(false);
    const [confirmDeclineOpen, setConfirmDeclineOpen] = React.useState<boolean>(false);
    const [secondConfirmDeclineOpen, setSecondConfirmDeclineOpen] = React.useState<boolean>(false);
    const [declineProfileContract] = useDeclineContractMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: profileContract, isSuccess } = useGetOpenContractByProfileKeyQuery(props.pKey, {
        skip: !props.isUsersRecord || +props.pKey <= 0,
    });

    const firstConfirmDeclineContract = () => {
        setConfirmDeclineOpen(false);
        setSecondConfirmDeclineOpen(true);
    };

    const confirmDeclineContract = () => {
        if (
            profileContract == null ||
            profileContract.profileContractId == null ||
            profileContract.contract?.contractId == null
        )
            return;

        const request: DeclineContractRequest = {
            profileKey: props.pKey,
            payload: {
                profileContractId: profileContract.profileContractId,
                contractId: profileContract.contract.contractId,
            },
        };
        declineProfileContract(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventIndividualDetails = {
                    imKey: claimsService.GetCurrentUserId(),
                    email: claimsService.GetCurrentUserEmail(),
                };

                logEvent("UserDeclinedContract", evtData);
            });
        setConfirmDeclineOpen(false);
    };

    if ((isSuccess && profileContract != null) || showSignContract) {
        return (
            <Alert severity="info">
                Your current contract expires June 30, 2023. Please click the 'Sign Contract' button
                below to access and sign your 2023-2024 contract in order to be scheduled for work
                with CCL in 2023-24. The contract year will be displayed as 2024 because it expires
                in 2024, but it will cover July 1, 2023 through June 30, 2024. Your deadline for
                signing the 2024 contract is June 30, 2023.
                <br />
                <CclButton
                    size="small"
                    onClick={() => setShowSignContract(true)}
                    sx={{ mt: 4 }}
                    mode={"primary"}
                >
                    Sign Contract
                </CclButton>
                <CclButton
                    size="small"
                    onClick={() => setConfirmDeclineOpen(true)}
                    sx={{ mt: 4, ml: 4 }}
                    mode={"primary"}
                >
                    Decline Contract
                </CclButton>
                <SignContractDialog
                    open={showSignContract}
                    onClose={() => setShowSignContract(false)}
                    contract={profileContract ?? null}
                />
                <CclGenericConfirmationDialog
                    open={confirmDeclineOpen}
                    title="Decline 2023/2024 Contract?"
                    msg="Are you sure you want to decline your contract for 2023/2024?"
                    onCancel={() => setConfirmDeclineOpen(false)}
                    onOk={() => firstConfirmDeclineContract()}
                />
                <CclGenericConfirmationDialog
                    open={secondConfirmDeclineOpen}
                    title="Decline 2023/2024 Contract?"
                    msg="Please confirm once more you want to decline your contract for 2023/2024. You won't be able to reverse this action."
                    onCancel={() => setSecondConfirmDeclineOpen(false)}
                    onOk={() => confirmDeclineContract()}
                />
            </Alert>
        );
    }

    return <React.Fragment></React.Fragment>;
};

export default SignContractBanner;
