import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridRowId,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import { WorkPool } from "../../../services/types/resources/workPoolTypes";
import { addCustomDataGridFilters } from "../../../components/common/customDataGridFilters/customDataGridFilters";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import { Link } from "@mui/material";
import { useDeleteCadreByCkeyMutation } from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventWorkPoolDetails } from "../../../services/types/accessEventTypes";
import { useNavigate } from "react-router-dom";
import EditWorkPoolDialog from "./workPoolDetails/editWorkPool/editWorkPoolDialog";
import CclDataGridToolbar from "../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclAlertSnackbar from "../../../components/common/cclAlertSnackbar";

export interface WorkPoolsSearchListProps {
    resources: WorkPool[];
    loading: boolean;
    addWorkPool: () => void;
    fromResources?: boolean;
}

export const WorkPoolsSearchDataGridPro: React.FC<WorkPoolsSearchListProps> = (props) => {
    const claimservice = new UserClaimsService();
    const isUserWorkPoolManager: boolean = claimservice.IsUserInAppRole(1);
    const { logEvent } = useLogAccessEvent();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false);

    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);

    const updateSelectedRows = (rows: any[] | undefined) => {
        if (!rows) return;
        let rowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
        let rowArray: any[] = [];
        for (let row of Array.from(rowsMap.values())) {
            rowArray.push(row);
        }
        setSelectedRows(rowArray);
    };

    const [deletePool, {isLoading, isError}] = useDeleteCadreByCkeyMutation();

    const handleMessage = () => {
        if (edit) {
            if (error) {
                return "Work Pool Update Failed";
            } else {
                return "Work Pool Updated";
            }
        } else {
            if (isError) {
                return "Work Pool Deletion Failed";
            } else {
                return "Work Pool Deleted";
            }
        }

    }

    const deleteWorkPool = () => {
        if (!selectedRows[0]) {
            return;
        }
        deletePool(selectedRows[0].cKey)
            .unwrap()
            .then(() => {
                setShowSnackbar(true);
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${selectedRows[0].cKey}`,
                    workPoolName: selectedRows[0].name ?? "",
                };
                logEvent("WorkPoolDeleted", evtData);
                navigate("/resources/6");
            });
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "Name",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <Link component={RouterLink} to={`/work-pool/${params.row.cKey}`}>
                    {params.value}
                </Link>
            ),
            flex: 0.5,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            flex: 1,
        },
        {
            field: "typeName",
            headerName: "Category",
            type: "string",
            flex: 0.5,
        },
    ];

    const miscButtonClick = (id: string) => {
        switch (id) {
            case "delete-work-pool-button":
                setEdit(false);
                deleteWorkPool();
                break;
            case "edit-work-pool-button":
                setConfirmEdit(true);
                break;
            case "add-work-pool-button":
                props.addWorkPool();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CclAlertSnackbar
                open={showSnackbar}
                onClose={() => {setShowSnackbar(false)}}
                severity={isError || error ? "error" : "success"}
                message={handleMessage()}
            />

            {confirmEdit && (
                <EditWorkPoolDialog
                    workPool={selectedRows[0]}
                    open={confirmEdit}
                    error={(error: boolean) => {setError(error)}}
                    onClose={(trigger: boolean) => {
                        setConfirmEdit(false);
                        if (trigger) {
                            setEdit(true);
                            setShowSnackbar(true);
                        }
                    }}
                />
            )}

            <DataGridPro
                getRowId={(row: WorkPool) => row.cKey}
                rows={props.resources}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                loading={props.loading}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={{
                    toolbar: {
                        csvOptions: {
                            allColumns: true,
                            fileName: "ccl-file.csv",
                        },
                        showSaveState: false,
                        hideSettingsButtons: true,
                        savestate: null,
                        clearstate: null,
                        miscButtons:
                            isUserWorkPoolManager && !props.fromResources
                                ? [
                                    {
                                        id: "add-work-pool-button",
                                        caption: "Create Work Pool",
                                        onClick: props.addWorkPool,
                                        returnSelected: true,
                                        restricted: true,
                                    },
                                    {
                                        id: "edit-work-pool-button",
                                        caption: "Edit Work Pool",
                                        onClick: () => {
                                            setConfirmEdit(true);
                                        },
                                        returnSelected: true,
                                        restricted: true,
                                        disable:
                                            selectedRows === undefined ||
                                            selectedRows.length > 1 ||
                                            selectedRows.length === 0,
                                    },
                                    {
                                        id: "delete-work-pool-button",
                                        caption: "Delete Work Pool",
                                        onClick: () => {
                                            deleteWorkPool();
                                        },
                                        returnSelected: true,
                                        restricted: false,
                                        loadingBtn: true,
                                        isLoading: isLoading,
                                        disable:
                                            selectedRows === undefined ||
                                            selectedRows.length > 1 ||
                                            selectedRows.length === 0,
                                    },
                                  ]
                                : [],
                        btnClick: miscButtonClick,
                        anySelected: null,
                    },
                }}
                initialState={{
                    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                }}
                apiRef={apiRef}
                hideFooter={false}
                checkboxSelection={isUserWorkPoolManager && !props.fromResources}
                disableSelectionOnClick={false}
                onSelectionModelChange={(rows: any[] | undefined) => updateSelectedRows(rows)}
            />
        </>
    );
};

export default WorkPoolsSearchDataGridPro;
