import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface AssessmentStatusViewPreferencesState {
    ShowAllRaters: boolean;
}

// Define the initial state using that type
const initialState: AssessmentStatusViewPreferencesState = {
    ShowAllRaters: false,
};

export const assessmentStatusViewPreferencesSlice = createSlice({
    name: "assessmentStatusViewPreferences",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateAssessmentViewPreferencesShowAllRaters: (
            state,
            action: PayloadAction<boolean | null>
        ) => {
            state.ShowAllRaters = action?.payload ?? initialState.ShowAllRaters;
        },
    },
});

export const { updateAssessmentViewPreferencesShowAllRaters } =
    assessmentStatusViewPreferencesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAssessmentStatusViewPreferences = (state: RootState) => state;
export default assessmentStatusViewPreferencesSlice.reducer;
