import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface UserClaimsState {
    claims: Record<string, string>[];
};

// Define the initial state using that type
const initialState: UserClaimsState = {
    claims: []
};

export const userClaimsSlice = createSlice({
  name: 'userClaims',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addUserClaims: (state, action: PayloadAction<Record<string,string>[]>) => {
        state.claims = action.payload;
    }
  }
})

export const { addUserClaims } = userClaimsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUserClaims = (state: RootState) => state;
export default userClaimsSlice.reducer;