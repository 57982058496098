import * as React from "react";
import { Box } from "@mui/material";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import { PersonInfo } from "../../../../../models/personInfo";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import SessionFileUploadStepper from "./sessionFileUploadStepper";

interface SessionFileUploadDrawerProps {
    sessionId: string;
    projectCode: string;
    isSessionFile: boolean;
    people: PersonInfo[];
    personType: string;
    open: boolean;
    onClose: () => void;
}

const SessionFileUploadDrawer: React.FC<SessionFileUploadDrawerProps> = (props) => {
    const { data: sessionDetails } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });

    return (
        <CclDrawer
            title={`Upload ${
                props.personType === "participant" ? "Participant" : "Assigned Resource"
            } Files for ${sessionDetails?.sessionCode}`}
            open={props.open}
            onClose={props.onClose}
        >
            <Box width={1} sx={{ p: 2 }}>
                <SessionFileUploadStepper
                    onFinish={props.onClose}
                    onCancel={props.onClose}
                    sessionId={props.sessionId}
                    projectCode={props.projectCode}
                    sessionCode={sessionDetails?.sessionCode ?? "Unknown Session Code"}
                    isSessionFile={props.isSessionFile}
                    people={props.people}
                    personType={props.personType}
                />
            </Box>
        </CclDrawer>
    );
};

export default SessionFileUploadDrawer;
