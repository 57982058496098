import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";

type SimpleBreadcrumbsProps = {
    children?: React.ReactElement | React.ReactElement[] | undefined;
};

export const SimpleBreadcrumbs: React.FC<SimpleBreadcrumbsProps> = ({ children }) => {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Typography variant="h6" color="text.secondary">
                CCL Access
            </Typography>
            {children}
        </Breadcrumbs>
    );
};
