import * as React from "react";
import { useGetCadreByPkeyQuery } from "../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import WorkPoolsSearchDataGridPro from "../../workPools/workPoolsSearchList";

interface ResourceWorkPoolsPanelProps {
    pkey: string;
}

const ResourceWorkPoolsPanel: React.FC<ResourceWorkPoolsPanelProps> = (props) => {
    const { data, isLoading } = useGetCadreByPkeyQuery(props.pkey);

    return (
        <>
            {data && (
                <WorkPoolsSearchDataGridPro
                    resources={data}
                    loading={isLoading}
                    addWorkPool={function (): void {}}
                    fromResources={true}
                />
            )}
        </>
    );
};

export default ResourceWorkPoolsPanel;
