import * as React from "react";
import { useParams } from "react-router-dom";
import { useGetCclSystemQuery } from "../../../services/cclTokenedGrandCentralApi";
import { Alert, Card, CardHeader, Typography } from "@mui/material";
import PageLoader from "../../../components/common/pageLoader";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../../layouts/standard";
import SystemDetailsPanel from "./systemDetailsPanel";
import SystemApplications from "./systemDetailApplications/systemApplications";
import SystemTenants from "./systemDetailTenants/systemTenants";
import DetailPage from "../../../components/common/detailPage/detailPage";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import CclCommonLink from "../../../components/common/cclCommonLink";

export type SystemDetailProps = {
    systemId: string;
};

export const SystemDetail: React.FC = (props) => {
    const params = useParams<SystemDetailProps>();
    const systemId = params.systemId !== undefined ? params.systemId : "";
    const { data: system, isError, isLoading } = useGetCclSystemQuery(systemId);
    var claimsService = new UserClaimsService();
    const readonly = !claimsService.IsUserInAppRole(7);

    const tablist: [string, number, React.ReactNode][] =
        system !== undefined
            ? [
                  ["Details", 0, <SystemDetailsPanel system={system} readonly={readonly} />],
                  ["Applications", 1, <SystemApplications system={system} readonly={readonly} />],
                  ["Tenants", 2, <SystemTenants system={system} readonly={readonly} />],
              ]
            : [];

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                <CclCommonLink
                    keyVal="findApplicationLink"
                    text="Applications"
                    to="/applications"
                />
            </Typography>
            <Typography variant="h6" color="text.primary">
                {system ? system.systemName : ""}
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (isLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={""} />
            </StandardLayout>
        );
    }

    if (isError || system == null) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <Card sx={{ width: 1 }}>
                    <CardHeader
                        title={
                            "Failed to retrieve system information for system id " + params.systemId
                        }
                    />
                </Card>
            </StandardLayout>
        );
    }

    return claimsService.IsUserCclAdminOrSuperAdmin() ? (
        <DetailPage
            descriptor={"gc-system"}
            breadcrumbs={breadcrumbs}
            tablist={tablist}
            activeTab={0}
        />
    ) : (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Alert severity="error">You do not have permission to view this page. </Alert>
        </StandardLayout>
    );
};

export default SystemDetail;
