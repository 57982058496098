import * as React from "react";
import { Box } from "@mui/material";
import { SendInvitationsStepper } from "./sendInvitationsStepper/sendInvitationsStepper";
import { Application } from "../../../services/types/cclGrandCentralApiTypes";
import { SendInvitationsRequest } from "../../../services/types/rtkQueryTypes";
import CclDrawer from "../../../components/common/cclDrawer/cclDrawer";

export interface SendBatchInvitationsDialogProps {
    open: boolean;
    onClose: (request: SendInvitationsRequest | null) => void;
    applications: Application[];
}

export const SendBatchInvitationsDialog: React.FC<SendBatchInvitationsDialogProps> = (props) => {
    return (
        <CclDrawer
            open={props.open}
            onClose={() => props.onClose(null)}
            title="Send Invitations"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box>
                <SendInvitationsStepper
                    applications={props.applications}
                    onFinish={props.onClose}
                    onCancel={() => props.onClose(null)}
                />
            </Box>
        </CclDrawer>
    );
};

export default SendBatchInvitationsDialog;
