import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

/*
        "tenantId": "7e8dbdc5-49cd-42b3-b856-1e9ff1fe41f1",
        "systemId": "78d623de-44eb-4bed-92c6-b24fc68f0083",
        "name": "CCL Access 2.0",
        "isPrivate": true,    
*/
export const CclInfoTblTenantTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "tenantId",
        fieldType: "string",
        editable: false,
        label: "Tenant ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "tenantName",
        fieldType: "string",
        editable: true,
        label: "Name",
        required: true,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "isPrivate",
        fieldType: "string",
        editable: true,
        label: "Private",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Checkbox,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
