import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ResourceListTableStateState {
  TableState: string
};

// Define the initial state using that type
const initialState: ResourceListTableStateState = {
  TableState: JSON.stringify(
    {
      sorting: 
        {
          sortModel: 
            [{ field: 'name', sort: 'asc' }]
        },
      filter:{ 
        filterModel: { 
          items: [
            {
              id: 1,
              columnField: "status", 
              operatorValue: "startsWith", 
              value: "active"
            }]
          }
        }
      })
};

export const resourceListTableStateSlice = createSlice({
  name: 'resourceListTableState',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateResourceGridState: (state, action: PayloadAction<string | null>) => {
        state.TableState = action?.payload ?? initialState.TableState;
    }
  }
});

export const { updateResourceGridState } = resourceListTableStateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const resourceListTableState = (state: RootState) => state;
export default resourceListTableStateSlice.reducer;