import * as React from "react";
import { Alert, AlertTitle } from "@mui/material";
import FlexGrid from "../../../../layouts/flexGrid";
import { useGetCclNotificationsByRecipientMutation } from "../../../../services/cclTokenedNotificationApi";
import RegistrationNotificationsDataGridPro from "../../../registrations/registrationDetail/notifications/registrationNotificationsDataGridPro";
import ComponentLoader from "../../../../components/common/componentLoader";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import CommonPopup from "../../../../components/common/commonPopup";
import NotificationPreviewPanel from "../../../notifications/notificationDetail/preview/NotificationPreviewPanel";

interface UserNotificationsPanelProps {
    emailAddress: string;
    fromMyAccess?: boolean;
}

const UserNotificationsPanel: React.FC<UserNotificationsPanelProps> = (props) => {
    const [runSearch, { data: notifications, isLoading, isError, error }] =
        useGetCclNotificationsByRecipientMutation();
        
    const [notificationId, setNotificationId] = React.useState<string>("");
    const [open, setOpen] = React.useState<boolean>(false);
     const [previewLoaded, setPreviewLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.emailAddress !== undefined && props.emailAddress.length > 0)
            runSearch(props.emailAddress);
    }, [props.emailAddress, runSearch]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Notifications" />;
    }

    if (isError) {
        return showErrorAlert(getErrorMsg(error, ""));
    }

    const notificationsCallback = (id: string) => {
        setNotificationId(id);
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    return (
        <>
            <FlexGrid>
                <RegistrationNotificationsDataGridPro
                    fromMyAccess={props.fromMyAccess ? props.fromMyAccess : false}
                    notifications={notifications}
                    isLoading={isLoading}
                    myAccessCallback={notificationsCallback}
                />
            </FlexGrid>
            {open && notificationId &&
                <CommonPopup
                    open={open}
                    onClose={onClose}
                    previewLoaded={previewLoaded }
                >
                    <NotificationPreviewPanel
                        notificationId={notificationId}
                        forceChecked={true}
                        onPreviewLoaded={() => setPreviewLoaded(true)}
                />
            </CommonPopup>}
        </>
    );
};

export default UserNotificationsPanel;
