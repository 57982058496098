import * as React from "react";
import { Box } from "@mui/material";

export interface CclDrawerActionBarProps {
    children?: React.ReactElement | React.ReactElement[] | undefined;
}

export const CclDrawerActionBar: React.FC<CclDrawerActionBarProps> = (props) => {
    return (
        <Box display={"flex"} gap={3} sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
            {props.children}
        </Box>
    );
};

export default CclDrawerActionBar;
