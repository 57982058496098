import * as React from "react";
import { AlertColor, Stack } from "@mui/material";
import FlexGrid from "../../../../layouts/flexGrid";
import ApplicationRolesDataGrid from "./applicationRolesDataGrid";
import {
    useGetAllRolesQuery,
    useGetAllUsersWithRolesQuery,
} from "../../../../services/cclTokenedApplicationRolesApi ";
import AddRoleToUserDrawer from "./addRoleToUserDrawer";
import { GrandCentralGetUsersbyGroupPayload } from "../../../../services/types/cclGrandCentralApiTypes";
import { eGroupIds } from "../../../../services/currentUserService/currentUserService";
import { Config } from "../../../../config";
import { useGetUsersbyGroupQuery } from "../../../../services/cclTokenedGrandCentralApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface ApplicationRolesPanelProps {}

const ApplicationRolesPanel: React.FC<ApplicationRolesPanelProps> = (props) => {
    const applicationId: string = Config.REACT_APP_APPLICATION_ID;
    const usersByRole: GrandCentralGetUsersbyGroupPayload = {
        applicationId: applicationId,
        groupIds: `${eGroupIds.eCCLAdmin},${eGroupIds.eCCLSuperAdmin}`,
    };

    const [showAddRole, setShowAddRole] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const { data, isLoading } = useGetAllUsersWithRolesQuery();
    const { data: roles, isLoading: rolesLoading } = useGetAllRolesQuery();
    const { data: usersByGroup } = useGetUsersbyGroupQuery(usersByRole);
    const { data: rolesList } = useGetAllRolesQuery();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const startAddRoleFlow = () => {
        setShowAddRole(true);
    };

    const sortUsersArray = (array: any[]) => {
        let sortedArr = [...array];
        return sortedArr.sort((a, b) => a.firstName.localeCompare(b.firstName));
    };

    const sortRolesArray = (array: any[]) => {
        let sortedArr = [...array];
        return sortedArr.sort((a, b) => a.roleName.localeCompare(b.roleName));
    };

    return (
        <Stack width={1} height={1} spacing={2}>
            {showAddRole && rolesList && usersByGroup && (
                <AddRoleToUserDrawer
                    open={showAddRole}
                    onClose={() => setShowAddRole(false)}
                    roles={sortRolesArray(rolesList ?? [])}
                    users={sortUsersArray(usersByGroup ?? [])}
                    showSnackBar={(message, severity) => ShowSnackbarElement(message, severity)}
                />
            )}
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            {isLoading || rolesLoading || !data || !roles ? (
                <ComponentLoader msg={"Loading"} />
            ) : (
                <FlexGrid>
                    <ApplicationRolesDataGrid
                        users={data.filter((n) => n.imKey !== 0)}
                        roles={roles}
                        isLoading={isLoading}
                        showResultBar={!isLoading}
                        startAddRoleFlow={startAddRoleFlow}
                        showSnackBar={(message, severity) => ShowSnackbarElement(message, severity)}
                    />
                </FlexGrid>
            )}
        </Stack>
    );
};

export default ApplicationRolesPanel;
