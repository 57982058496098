import { TimelineContent } from "@mui/lab";
import React from "react";
import { Box, ClickAwayListener, Paper, Popper, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccessEventDetailsDataTable from "./accessEventDetailsDataTable";
import { AccessEvent } from "../../../services/types/accessEventTypes";

export interface AccessEventTimeLineItemContentProps {
    event: AccessEvent;
}

const AccessEventTimeLineItemContent: React.FC<AccessEventTimeLineItemContentProps> = (props) => {
    const [showPopper, setShowPopper] = React.useState<boolean>(false);
    const [popperAnchorEl, setPopperAnchorEl] = React.useState<SVGSVGElement | null>(null);

    const onIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setPopperAnchorEl(event.currentTarget);
        setShowPopper(true);
    };

    return (
        <TimelineContent>
            <Box
                sx={{
                    pl: 4,
                    pb: 4,
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    borderRadius: 3,
                    backgroundColor: "rgb(240, 240, 240)",
                }}
            >
                <Typography variant="h6" component="span" sx={{ mt: -2 }}>
                    {props.event.accessEventTypeName}
                </Typography>
                <InfoOutlinedIcon
                    fontSize={"medium"}
                    color="primary"
                    onClick={(evt) => onIconClick(evt)}
                    sx={{ mt: 2, ml: 2 }}
                />
                <Popper open={showPopper} anchorEl={popperAnchorEl} placement={"right"}>
                    <ClickAwayListener onClickAway={() => setShowPopper(false)}>
                        <Paper elevation={6}>
                            <AccessEventDetailsDataTable event={props.event} />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
                <Typography variant="body2" color={"Gray"}>
                    {props.event.userEmail}
                </Typography>
            </Box>
        </TimelineContent>
    );
};

export default AccessEventTimeLineItemContent;
