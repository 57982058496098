import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { getDateStringAdjusted } from '../../utilities/dateHelpers';

// Define a type for the slice state
export interface AccessEventListConfigState {
  StartDate: string,
  EndDate: string,
  Email: string,
  IsEmailSearchActive: boolean
};

// Define the initial state using that type
const initialState: AccessEventListConfigState = {
  StartDate: getDateStringAdjusted(-3),
  EndDate: getDateStringAdjusted(1),
  Email: '',
  IsEmailSearchActive: false
};

export const accessEventListConfigSlice = createSlice({
  name: 'accessEventListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateAccessEventSearchParams: (state, action: PayloadAction<AccessEventListConfigState>) => {
      state.StartDate = action.payload.StartDate;
      state.EndDate = action.payload.EndDate;
      state.Email = action.payload.Email;
      state.IsEmailSearchActive = action.payload.IsEmailSearchActive;
    }
  }
});

export const { updateAccessEventSearchParams } = accessEventListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccessEventListConfig = (state: RootState) => state;
export default accessEventListConfigSlice.reducer;
