import { DataGridPro, GridColDef, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GenerateDocumentsResult } from "../../../../services/types/generateDocumentsApiTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";

const COLUMNS: GridColDef[] = [
    {
        field: "name",
        headerName: "File Name",
        flex: 1,
    },
    {
        field: "status",
        headerName: "Status",
        width: 200,
        type: "string",
        valueGetter: (params: GridRenderCellParams) => {
            let s = params.row.status;
            if (s === "1") return "Document Generated";
            if (s === "2") return "Document Not Supported";
            if (s === "3") return "Error Generating Document";
            if (s === "4") return "Unmatched Fields";
            if (s === "5") return "Nothing To Do";
            if (s === "6") return "Document Already Exists";
            else return "";
        },
    },
];

interface GenerateDocumentsGridProProps {
    isLoading: boolean;
    data: GenerateDocumentsResult[];
}

export default function GenerateDocumentsGridPro(props: GenerateDocumentsGridProProps) {
    return (
        <DataGridPro
            getRowId={(row) => row.name}
            rows={props.data}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.isLoading}
            disableSelectionOnClick
        />
    );
}
