import * as React from "react";
import { Card, CardHeader, Stack, styled, TextField } from "@mui/material";
import { SessionOptions } from "../../../../../services/types/sessionOptionsType";

const CustomizedTextField = styled(TextField)`
    background-color: #f1f1f2;
    width: 100%;
`;

interface LookingGlassStatusCardProps {
    sessionId: string;
    sessionOptions: SessionOptions;
}

const LookingGlassStatusCard: React.FC<LookingGlassStatusCardProps> = (props) => {
    // const [statusString, setStatusString] = React.useState<string>("Loading...");

    let statusString = "Loading...";
    if (props.sessionOptions != null) {
        const lookingGlassOption = props.sessionOptions?.sessionEventOptions.find(
            (o) => o.optionId === 4
        );
        if (lookingGlassOption != null) {
            const changeDate = new Date(lookingGlassOption.changedDate + "Z");
            statusString = lookingGlassOption.active
                ? `Active (by: ${lookingGlassOption.changedBy} on ${changeDate.toLocaleString()})`
                : "Not Active";
        } else {
            statusString = "Not Active";
        }
    }

    return (
        <Card sx={{ height: "100%", width: "100%", p: 2 }}>
            <CardHeader title={"Looking Glass Status"} sx={{ p: 1 }} />
            <Stack height={1} width={1} spacing={4} sx={{ p: 3 }}>
                <CustomizedTextField
                    disabled
                    label="Looking Glass Status"
                    value={statusString}
                    variant="outlined"
                />
            </Stack>
        </Card>
    );
};

export default LookingGlassStatusCard;
