import { CclInfoTableRowTemplate } from "../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblCalendarSessionDetailTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "title",
        fieldType: "Text",
        editable: false,
        label: "Title",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "programCode",
        fieldType: "Text",
        editable: false,
        label: "Project ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "scoreDate",
        fieldType: "Date",
        editable: false,
        label: "Score Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "startDate",
        fieldType: "Date",
        editable: false,
        label: "Start Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "endDate",
        fieldType: "Date",
        editable: false,
        label: "End Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "campusAbbreviation",
        fieldType: "Text",
        editable: false,
        label: "Campus",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
