import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblResourceInfoTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "ceContactId",
        fieldType: "string",
        editable: false,
        label: "Contact ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
          value: "",
          editFieldType: eInfoTableValueFieldEditType.Text,
          url: null,
          hoverText: null,
          checked: null,
          id: "",
          selectOptions: null, //TODO: Fix this for drop down option
        }
    },
    {
        id: "bookableresourceid",
        fieldType: "string",
        editable: false,
        label: "Resource ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "status",
        fieldType: "string",
        editable: false,
        label: "Status",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "jobTitle",
        fieldType: "string",
        editable: false,
        label: "Title",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "cclRelationship",
        fieldType: "string",
        editable: false,
        label: "Resource Type",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "companyName",
        fieldType: "string",
        editable: false,
        label: "Company",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "cclstartDate",
        fieldType: "Date",
        editable: false,
        label: "CCL Start Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
