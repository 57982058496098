import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { cclTokenedSessionApi } from "../services/cclTokenedSessionApi";
import { cclTokenedResourceApi } from "../services/cclTokenedResourceApi";
import { cclTokenedNotificationApi } from "../services/cclTokenedNotificationApi";
import { cclTokenedEnterpriseParticipantApi } from "../services/cclTokenedEnterpriseParticipantApi";
import { cclTokenedGrandCentralApi } from "../services/cclTokenedGrandCentralApi";
import { cclTokenedEnterpriseCoachingApi } from "../services/cclTokenedEnterpriseCoachingApi";
import { cclTokenedUserPreferenceApi } from "../services/cclTokenedUserPreferenceApi";
import { cclTokenedApplicationFeaturesApi } from "../services/cclTokenedApplicationFeaturesApi";
import accessEventListConfigReducer from "./slices/accessEventListConfigSlice";
import accessEventListTableStateReducer from "./slices/accessEventListTableStateSlice";
import productSaleListConfigReducer from "./slices/productSaleListConfigSlice";
import productSaleListTableStateReducer from "./slices/productSaleListTableStateSlice";
import assignmentListConfigReducer from "./slices/assignmentListConfigSlice";
import assignmentListTableStateReducer from "./slices/assignmentListTableStateSlice";
import notificationListConfigReducer from "./slices/notificationListConfigSlice";
import notificationListTableStateReducer from "./slices/notificationListTableStateSlice";
import partnerResourceListConfigReducer from "./slices/partnerResourceListConfigSlice";
import partnerResourceListTableStateReducer from "./slices/partnerResourceListTableStateSlice";
import registrationListConfigReducer from "./slices/registrationListConfigSlice";
import registrationListTableStateReducer from "./slices/registrationListTableStateSlice";
import resourceListConfigReducer from "./slices/resourceListConfigSlice";
import resourceListTableStateReducer from "./slices/resourceListTableStateSlice";
import resourcesPageReducer from "./slices/resourcesPageSlice";
import sessionListConfigReducer from "./slices/sessionListConfigSlice";
import sessionListTableStateReducer from "./slices/sessionListTableStateSlice";
import userSearchListConfigReducer from "./slices/userSearchListConfigSlice";
import applicationUsersSearchListConfigReducer from "./slices/applicationUsersSearchListConfigSlice";
import userSearchListTableStateReducer from "./slices/userSearchListTableStateSlice";
import applicationUsersSearchListTableStateReducer from "./slices/applicationUsersSearchListTableStateSlice";
import applicationRolesSearchListTableStateReducer from "./slices/applicationRolesSearchListTableStateSlice";
import workPoolsResourceListTableStateReducer from "./slices/workPoolsResourceListTableStateSlice";
import applicationInvitationsSearchListReducer from "./slices/applicationInvitationsSearchListConfigSlice";
import biosListTableStateSliceReducer from "./slices/resourceBioSearchGridSlice";
import resourceAttributeListTableStateReducer from "./slices/resourceAttributesSearchListSlice";
import contractsListTableStateReducer from "./slices/resourceContractsSearchListSlice";
import applicationsSearchListTableStateReducer from "./slices/applicationSearchListTableStateSlice";
import sessionParticipantTableStateReducer from "./slices/sessionParticipantTableStateSlice";
import emailTemplateTableStateSliceReducer from "./slices/emailTemplateTableStateSlice";
import scoreReportDownloadListConfigReducer from "./slices/scoreReportDownloadListConfigState";
import scoreReportDownloadListTableStateReducer from "./slices/scoreReportDownloadListTableStateSlice";
import userClaimsReducer from "./slices/userClaimsSlice";
import allowedRoutesReducer from "./slices/allowedRoutesSlice";
import { cclTokenedAssetsApi } from "../services/cclTokenedAssetsApi";
import { cclTokenedGenerateDocumentsApi } from "../services/cclTokenedGenerateDocumentsApi";
import { cclTokenedSettingsApi } from "../services/cclTokenedSettingsApi";
import { cclTokenedEnterpriseUserApi } from "../services/cclTokenedEnterpriseUserApi";
import { cclTokenedAccessEventsApi } from "../services/cclTokenedAccessEventsApi";
import { cclTokenedEnterpriseNetworkPartnerApi } from "../services/cclTokenedEnterpriseNetworkPartnerApi";
import { cclTokenedEnterpriseCoachingCalendarApi } from "../services/cclTokenedEnterpriseCoachingCalendarApi";
import { cclTokenedEnterpriseCoachingBiographyApi } from "../services/cclTokenedEnterpriseCoachingBiographyApi";
import { cclTokenedEnterpriseCoachingCadreApi } from "../services/cclTokenedEnterpirseCoachingCadreApi";
import { cclTokenedEnterpriseCoachingResourceNotesApi } from "../services/cclTokenedEnterpriseCoachingResourceNotesApi";
import { cclTokenedCcpApi } from "../services/cclTokenedOptifyApi";
import { cclScoringStatusApi } from "../services/cclScoringStatusApi";
import { cclTokenedJournalEntryApi } from "../services/cclTokenedJournalEntryApi";
import { cclTokenedCustomerHierarchyApi } from "../services/cclTokenedCustomerHierarchyApi";
import { cclTokenedBiosApi } from "../services/cclTokenedBioApi";
import assessmentStatusViewPreferencesReducer from "./slices/assessmentStatusViewPreferencesSlice";
import { cclTokenedEnterpriseRaterApi } from "../services/cclTokenedEnterpriseRaterApi";
import { cclTokenedApplicationRolesApi } from "../services/cclTokenedApplicationRolesApi ";
import { cclTokenedEmailTemplateApi } from "../services/cclTokenedEmailTemplateApi";

export const store = configureStore({
    reducer: {
        [cclScoringStatusApi.reducerPath]: cclScoringStatusApi.reducer,
        [cclTokenedSessionApi.reducerPath]: cclTokenedSessionApi.reducer,
        [cclTokenedEnterpriseNetworkPartnerApi.reducerPath]:
            cclTokenedEnterpriseNetworkPartnerApi.reducer,
        [cclTokenedResourceApi.reducerPath]: cclTokenedResourceApi.reducer,
        [cclTokenedNotificationApi.reducerPath]: cclTokenedNotificationApi.reducer,
        [cclTokenedEnterpriseParticipantApi.reducerPath]:
            cclTokenedEnterpriseParticipantApi.reducer,
        [cclTokenedGrandCentralApi.reducerPath]: cclTokenedGrandCentralApi.reducer,
        [cclTokenedEnterpriseCoachingApi.reducerPath]: cclTokenedEnterpriseCoachingApi.reducer,
        [cclTokenedUserPreferenceApi.reducerPath]: cclTokenedUserPreferenceApi.reducer,
        [cclTokenedAssetsApi.reducerPath]: cclTokenedAssetsApi.reducer,
        [cclTokenedGenerateDocumentsApi.reducerPath]: cclTokenedGenerateDocumentsApi.reducer,
        [cclTokenedSettingsApi.reducerPath]: cclTokenedSettingsApi.reducer,
        [cclTokenedEnterpriseUserApi.reducerPath]: cclTokenedEnterpriseUserApi.reducer,
        [cclTokenedAccessEventsApi.reducerPath]: cclTokenedAccessEventsApi.reducer,
        [cclTokenedEnterpriseCoachingCalendarApi.reducerPath]:
            cclTokenedEnterpriseCoachingCalendarApi.reducer,
        [cclTokenedEnterpriseCoachingBiographyApi.reducerPath]:
            cclTokenedEnterpriseCoachingBiographyApi.reducer,
        [cclTokenedEnterpriseCoachingResourceNotesApi.reducerPath]:
            cclTokenedEnterpriseCoachingResourceNotesApi.reducer,
        [cclTokenedCcpApi.reducerPath]: cclTokenedCcpApi.reducer,
        [cclTokenedJournalEntryApi.reducerPath]: cclTokenedJournalEntryApi.reducer,
        [cclTokenedCustomerHierarchyApi.reducerPath]: cclTokenedCustomerHierarchyApi.reducer,
        [cclTokenedEnterpriseCoachingCadreApi.reducerPath]:
            cclTokenedEnterpriseCoachingCadreApi.reducer,
        [cclTokenedBiosApi.reducerPath]: cclTokenedBiosApi.reducer,
        [cclTokenedEnterpriseRaterApi.reducerPath]: cclTokenedEnterpriseRaterApi.reducer,
        [cclTokenedApplicationRolesApi.reducerPath]: cclTokenedApplicationRolesApi.reducer,
        [cclTokenedApplicationFeaturesApi.reducerPath]: cclTokenedApplicationFeaturesApi.reducer,
        [cclTokenedEmailTemplateApi.reducerPath]: cclTokenedEmailTemplateApi.reducer,

        allowedRoutes: allowedRoutesReducer,
        assessmentStatusViewPreferences: assessmentStatusViewPreferencesReducer,
        userClaims: userClaimsReducer,
        resourcesPage: resourcesPageReducer,
        accessEventListConfig: accessEventListConfigReducer,
        accessEventListTableState: accessEventListTableStateReducer,
        productSaleListConfig: productSaleListConfigReducer,
        productSaleListTableState: productSaleListTableStateReducer,
        assignmentListConfig: assignmentListConfigReducer,
        assignmentListTableState: assignmentListTableStateReducer,
        notificationListConfig: notificationListConfigReducer,
        notificationListTableState: notificationListTableStateReducer,
        partnerResourceListConfig: partnerResourceListConfigReducer,
        partnerResourceListTableState: partnerResourceListTableStateReducer,
        registrationListConfig: registrationListConfigReducer,
        registrationListTableState: registrationListTableStateReducer,
        resourceListConfig: resourceListConfigReducer,
        resourceListTableState: resourceListTableStateReducer,
        scoreReportDownloadListConfig: scoreReportDownloadListConfigReducer,
        scoreReportDownloadListTableState: scoreReportDownloadListTableStateReducer,
        sessionListConfig: sessionListConfigReducer,
        sessionListTableState: sessionListTableStateReducer,
        userSearchListConfig: userSearchListConfigReducer,
        userSearchListTableState: userSearchListTableStateReducer,
        applicationUsersSearchListTableState: applicationUsersSearchListTableStateReducer,
        applicationUsersListConfig: applicationUsersSearchListConfigReducer,
        workPoolResourceListTableState: workPoolsResourceListTableStateReducer,
        applicationInvitationsTableState: applicationInvitationsSearchListReducer,
        applicationRolesSearchListTableState: applicationRolesSearchListTableStateReducer,
        biosSearchTableState: biosListTableStateSliceReducer,
        contractsSearchTableState: contractsListTableStateReducer,
        attributeSearchTableState: resourceAttributeListTableStateReducer,
        applicationSearchTableState: applicationsSearchListTableStateReducer,
        sessionParticipantTableState: sessionParticipantTableStateReducer,
        emailTemplateTableState: emailTemplateTableStateSliceReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(cclScoringStatusApi.middleware)
            .concat(cclTokenedSessionApi.middleware)
            .concat(cclTokenedEnterpriseNetworkPartnerApi.middleware)
            .concat(cclTokenedResourceApi.middleware)
            .concat(cclTokenedNotificationApi.middleware)
            .concat(cclTokenedEnterpriseParticipantApi.middleware)
            .concat(cclTokenedGrandCentralApi.middleware)
            .concat(cclTokenedEnterpriseCoachingApi.middleware)
            .concat(cclTokenedUserPreferenceApi.middleware)
            .concat(cclTokenedAssetsApi.middleware)
            .concat(cclTokenedGenerateDocumentsApi.middleware)
            .concat(cclTokenedSettingsApi.middleware)
            .concat(cclTokenedEnterpriseUserApi.middleware)
            .concat(cclTokenedAccessEventsApi.middleware)
            .concat(cclTokenedEnterpriseCoachingCalendarApi.middleware)
            .concat(cclTokenedEnterpriseCoachingBiographyApi.middleware)
            .concat(cclTokenedEnterpriseCoachingCadreApi.middleware)
            .concat(cclTokenedEnterpriseCoachingResourceNotesApi.middleware)
            .concat(cclTokenedCcpApi.middleware)
            .concat(cclTokenedJournalEntryApi.middleware)
            .concat(cclTokenedCustomerHierarchyApi.middleware)
            .concat(cclTokenedBiosApi.middleware)
            .concat(cclTokenedJournalEntryApi.middleware)
            .concat(cclTokenedEnterpriseRaterApi.middleware)
            .concat(cclTokenedApplicationRolesApi.middleware)
            .concat(cclTokenedEnterpriseRaterApi.middleware)
            .concat(cclTokenedApplicationFeaturesApi.middleware)
            .concat(cclTokenedEmailTemplateApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
