import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ContractsListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: ContractsListTableStateState = {
    TableState: JSON.stringify({
        sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
        },
    }),
};

export const ContractsListTableStateSlice = createSlice({
    name: "ContractsListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateContractsGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateContractsGridState } = ContractsListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const ContractsListTableState = (state: RootState) => state;
export default ContractsListTableStateSlice.reducer;
