import React, { ReactElement } from "react";
import { Chip, ChipPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

export interface BaseStatusPillProps {
    icon: ReactElement;
    label: string;
    color:
        | OverridableStringUnion<
              "error" | "success" | "warning" | "default" | "primary" | "secondary" | "info",
              ChipPropsColorOverrides
          >
        | undefined;
    onHover?: any;
    onHoverOff?: any;
}

const BaseStatusPill: React.FC<BaseStatusPillProps> = (props) => {
    return (
        <>
            <Chip
                icon={props.icon}
                label={props.label}
                color={props.color}
                variant="outlined"
                size="small"
                onMouseEnter={props.onHover}
                onMouseLeave={props.onHoverOff}
                sx={
                    props.color === undefined
                        ? {
                              width: "130px",
                              justifyContent: "left",
                              borderRadius: 7,
                              color: "gray",
                              backgroundColor: "white",
                              "& .MuiChip-label": {
                                  color: "black",
                                  paddingTop: "3px",
                              },
                          }
                        : {
                              width: "130px",
                              justifyContent: "left",
                              borderRadius: 7,
                              backgroundColor: "white",
                              "& .MuiChip-label": {
                                  color: "black",
                                  paddingTop: "3px",
                              },
                          }
                }
            />
        </>
    );
};

export default BaseStatusPill;
