import * as React from "react";
import { DataGridPro, GridColumns, GridSelectionModel } from "@mui/x-data-grid-pro";
import CclDataGridCustomButtonToolbar from "../../../../../components/common/cclDataGridCustomButtonToolbar/cclDataGridCustomButtonToolbar";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import { AssessmentListItem } from "../../../../../services/types/sessionDetailTypes";

export interface SessionAssessmentsSetupTableProps {
    AssessmentList: AssessmentListItem[] | undefined;
    isLoading: boolean;
    handleEvent: (evt: string, ids: number[]) => void;
    readOnly?: boolean;
}

const SessionAssessmentsSetupTable: React.FC<SessionAssessmentsSetupTableProps> = (props) => {
    const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
    const [selectedIdIsDocument, setSelectedIdIsDocument] = React.useState<boolean>(false);
    const showToolbar = props.handleEvent !== undefined && !props.readOnly;

    React.useEffect(() => {
        setSelectedIds([]);
    }, [props.AssessmentList]);

    const COLUMNS = [
        {
            field: "cclMasterKey",
            headerName: "CCL Master Key",
            type: "string",
            width: 150,
        },
        {
            field: "assessmentType",
            headerName: "Type",
            type: "string",
            width: 150,
        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            type: "string",
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.cclMasterKey}
            rows={props.AssessmentList ?? []}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.isLoading}
            components={{ Toolbar: CclDataGridCustomButtonToolbar }}
            componentsProps={
                showToolbar
                    ? {
                          toolbar: {
                              miscButtons: [
                                  {
                                      id: "addAssessment",
                                      caption: "Add Assessment",
                                      label: "Add Assessment",
                                      disable: props.isLoading,
                                  },
                                  {
                                      id: "addDocument",
                                      caption: "Add Document",
                                      label: "Add Document",
                                      disable: props.isLoading,
                                  },
                                  {
                                      id: "view",
                                      caption: "View Document",
                                      label: "View Document",
                                      disable: selectedIds.length !== 1 || !selectedIdIsDocument,
                                  },
                                  {
                                      id: "remove",
                                      caption: "Remove Item(s)",
                                      label: "Remove Item(s)",
                                      disable: selectedIds.length === 0,
                                  },
                              ],
                              btnClick: (buttonName: string) =>
                                  props.handleEvent(buttonName, selectedIds),
                          },
                      }
                    : undefined
            }
            checkboxSelection={showToolbar}
            onSelectionModelChange={(ids: GridSelectionModel) => {
                const selectedIds = [...ids.map((i) => i as number)];
                setSelectedIds(selectedIds);
                setSelectedIdIsDocument(
                    selectedIds.length === 1 &&
                        props.AssessmentList?.find((a) => a.cclMasterKey === selectedIds[0])
                            ?.instrumentTypeKey === 0
                );
            }}
            isCellEditable={(params) => params.row.groupReportAvailable === true}
            autoHeight
        />
    );
};

export default SessionAssessmentsSetupTable;
