import * as React from "react";
import { Alert, AlertTitle, Link, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../../layouts/standard";
import { useGetCclNotificationQuery } from "../../../services/cclTokenedNotificationApi";
import PageLoader from "../../../components/common/pageLoader";
import { useParams, Link as RouterLink } from "react-router-dom";
import NotificationGeneralPanel from "./general/NotificationGeneralPanel";
import DetailPage from "../../../components/common/detailPage/detailPage";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import { RouteAccess } from "../../../app/slices/allowedRoutesSlice";

export type NotificationDetailParams = {
    notificationId: string;
};

export const NotificationDetail: React.FC = (prop) => {
    const claimsService = new UserClaimsService();
    const allowedRoutes: RouteAccess = claimsService.GetCurrentAllowedRoutes();
    const params = useParams<NotificationDetailParams>();
    const notificationId = params.notificationId !== undefined ? params.notificationId : "";
    const { data, isLoading, isSuccess, isError, error } = useGetCclNotificationQuery(
        notificationId,
        { skip: notificationId === "" }
    );

    const tablist: [string, number, React.ReactNode][] = [
        ["Details", 0, <NotificationGeneralPanel notificationId={notificationId} />],
    ];

    function showErrorAlert(msg: string | undefined) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <div>
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                        {msg}
                    </Alert>
                </div>
            </StandardLayout>
        );
    }

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                {allowedRoutes.allowNotifications ? (
                    <Link key="findNotificationLink" component={RouterLink} to="/emails">
                        Emails
                    </Link>
                ) : (
                    <Typography variant="h6" color="text.primary">
                        Emails
                    </Typography>
                )}
            </Typography>
            <Typography variant="h6" color="text.primary">
                {data?.subject}
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (isLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={""} />
            </StandardLayout>
        );
    }

    if (isError) {
        return showErrorAlert(`Error retrieving ${notificationId} (${getErrorMsg(error)})`);
    }

    if (isSuccess) {
        if (data != null) {
            return (
                <DetailPage
                    descriptor={"notifications"}
                    breadcrumbs={breadcrumbs}
                    tablist={tablist}
                />
            );
        } else {
            return showErrorAlert(
                `No notification data found for notification Id ${notificationId}`
            );
        }
    }

    return showErrorAlert(`Unknown error retrieving notification ${notificationId}`);
};

export default NotificationDetail;
