import React from "react";
import { Alert, AlertColor, Box, Drawer, Stack, TextField, Typography } from "@mui/material";
import { useSaveJournalEntryMutation } from "../../../services/cclTokenedJournalEntryApi";
import { JournalEntry } from "../../../services/types/journalEntryTypes";
import CclErrorDialog from "../cclErrorDialog";
import CclButton from "../cclButtons/cclButton";

export type JournalEntryDrawerProps = {
    journalCategory: number;
    journalCategoryData: string;
    alert?: JSX.Element;
    alertColor?: AlertColor;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    onSaveError: (msg: string) => void;
};

const JournalEntryDrawer: React.FC<JournalEntryDrawerProps> = (props) => {
    const [entryText, setEntryText] = React.useState<string>("");
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [saveJournalEntry] = useSaveJournalEntryMutation();

    const handleClose = () => {
        setEntryText("");
        props.onClose();
    };

    const handleSave = () => {
        if (entryText.trim() === "") {
            setShowErrorDialog(true);
        } else {
            const entry: JournalEntry = {
                journalEntryId: undefined, // populated when created on server
                entry: entryText,
                categoryId: props.journalCategory,
                categoryData: props.journalCategoryData,
                categoryName: "", // not needed for saving entry
                createdByUser: "", // not needed for saving entry
                createdUtc: new Date(), // not really needed, just satisfy compiler
            };
            saveJournalEntry(entry)
                .unwrap()
                .then(() => {
                    setEntryText("");
                    props.onSave();
                })
                .catch(() => props.onSaveError("Failed to save journal entry."));
        }
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg="Journal entry cannot be blank or empty."
            />
            <Drawer
                variant="temporary"
                anchor="right"
                open={props.open}
                onClose={handleClose}
                sx={{
                    display: "block",
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "35%",
                        paddingTop: "75px",
                    },
                }}
            >
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        {props.alert ? (
                            <Alert severity={props.alertColor ?? "info"}>{props.alert}</Alert>
                        ) : null}
                        <Typography variant="body1" sx={{ p: 2, width: 1 }}>
                            Create Journal Entry
                        </Typography>
                        <TextField
                            id="journal-input"
                            onChange={(evt) => setEntryText(evt.target.value)}
                            multiline
                            rows={8}
                            defaultValue=""
                            sx={{ m: 2, p: 2, width: 1 }}
                        >
                            {entryText}
                        </TextField>
                        <Box sx={{ w: 1, paddingRight: "8px", paddingLeft: "8px" }}>
                            <CclButton mode={"secondary"} onClick={handleClose} sx={{ p: 2 }}>
                                Cancel
                            </CclButton>
                            <CclButton
                                mode={"primary"}
                                onClick={handleSave}
                                sx={{ p: 2, float: "right" }}
                                disabled={entryText === ""}
                            >
                                Save
                            </CclButton>
                        </Box>
                    </Stack>
                </Box>
            </Drawer>
        </>
    );
};

export default JournalEntryDrawer;
