const envSettings = window as any;
export class Config {
    static REACT_APP_AUTH0_DOMAIN = envSettings.REACT_APP_AUTH0_DOMAIN;
    static REACT_APP_AUTH0_CLIENT_ID = envSettings.REACT_APP_AUTH0_CLIENT_ID;
    static REACT_APP_APPLICATION_ID = envSettings.REACT_APP_APPLICATION_ID;
    static REACT_APP_TENANT_ID = envSettings.REACT_APP_TENANT_ID;
    static REACT_APP_CCL_API_MANAGEMENT_URL = envSettings.REACT_APP_CCL_API_MANAGEMENT_URL;
    static REACT_APP_APPLICATION_BUILD_ID = envSettings.REACT_APP_APPLICATION_BUILD_ID;
    static REACT_APP_APPLICATION_COMMIT_ID = envSettings.REACT_APP_APPLICATION_COMMIT_ID;
    static REACT_APP_APPLICATION_COMMIT_BRANCH = envSettings.REACT_APP_APPLICATION_COMMIT_BRANCH;
}
