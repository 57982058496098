import * as React from "react";
import { Alert, AlertTitle, Card } from "@mui/material";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import ComponentLoader from "../../../common/componentLoader";
import { useGetAccessEventsByEmailAndKeywordMutation } from "../../../../services/cclTokenedAccessEventsApi";
import { AccessEvent } from "../../../../services/types/accessEventTypes";
import AccessLogsDataGridPro from "../../../../pages/accesslogs/accessLogsDataGridPro";

interface IndividualApplicationLogsCardProps {
    emailAddress: string;
    imKey: string;
    requireEsiKey?: boolean;
}

const IndividualApplicationLogsCard: React.FC<IndividualApplicationLogsCardProps> = (props) => {
    const [allLogEntries, setAllLogEntries] = React.useState<AccessEvent[]>([]);
    const [runSearchLogEntries, { isLoading, isError, error }] =
        useGetAccessEventsByEmailAndKeywordMutation();

    React.useEffect(() => {
        runSearchLogEntries({
            email: props.emailAddress,
            payload: { keyword: props.emailAddress, start: null, end: null },
        })
            .unwrap()
            .then((logentries: AccessEvent[]) => {
                const newLogEntries = props.requireEsiKey
                    ? logentries.filter((entry) => entry.data.includes("esiKey"))
                    : logentries;
                setAllLogEntries([...newLogEntries]);
            })
            .catch(() => {
                setAllLogEntries([]);
            });
    }, [props, runSearchLogEntries]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isError) {
        return showErrorAlert(getErrorMsg(error));
    }

    return (
        <Card sx={{ height: 1, width: 1, m: 2, p: 2 }}>
            {isLoading ? (
                <ComponentLoader msg="Loading" />
            ) : (
                <AccessLogsDataGridPro
                    accessEvents={allLogEntries}
                    loading={isLoading}
                    showSaveState={false}
                />
            )}
        </Card>
    );
};

export default IndividualApplicationLogsCard;
