import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface PartnerResourceListConfigState {
  Email: string | null
};

// Define the initial state using that type
const initialState: PartnerResourceListConfigState = {
  Email: null
};

export const partnerResourceListConfigSlice = createSlice({
  name: 'partnerResourceListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updatePartnerResourceEmailSearchParam: (state, action: PayloadAction<string | null>) => {
      state.Email = action.payload;
    }
  }
});

export const { updatePartnerResourceEmailSearchParam } = partnerResourceListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPartnerResourceListConfig = (state: RootState) => state;
export default partnerResourceListConfigSlice.reducer;