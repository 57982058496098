import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import * as React from "react";
import AdvancedSearchForm from "./advancedSearchForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface AdvancedSearchControlProps {
    onSearchResults: (pkeys: number[] | undefined) => void;
}

const AdvancedSearchControl: React.FC<AdvancedSearchControlProps> = (props) => {
    return (
        <Accordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ marginLeft: "15px", fontWeight: "bolder", whiteSpace: "nowrap" }}>
                    Advanced Search
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <AdvancedSearchForm onSearchResults={props.onSearchResults} />
            </AccordionDetails>
        </Accordion>
    );
};

export default AdvancedSearchControl;
