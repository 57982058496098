import * as React from "react";
import { Alert, AlertTitle, Card } from "@mui/material";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../services/cclTokenedEnterpriseParticipantApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import SessionParticipantListDataGridPro from "./sessionParticipantList";
import FlexGrid from "../../../../layouts/flexGrid";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";

interface SessionParticipantsPanelProps {
    sessionId: string;
    readOnly?: boolean;
}

const SessionParticipantsPanel: React.FC<SessionParticipantsPanelProps> = (props) => {
    const [confirmedParticipants, setConfirmedParticipants] = React.useState<Participant[]>([]);
    const [showAttendance, setShowAttendance] = React.useState<boolean>(true);
    const {
        data: sessionDetails,
        isLoading: detailsLoading,
        isSuccess: detailsSuccess,
        isError: detailsIsError,
        error: detailError,
    } = useGetCclEventByEventKeyQuery(props.sessionId, { skip: !props.sessionId });
    const {
        data: participants,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCclParticipantsByEventKeyQuery(props.sessionId, { skip: !props.sessionId });
    const claimsService = new UserClaimsService();

    React.useEffect(() => {
        if (sessionDetails != null) {
            const show =
                (sessionDetails?.type.trim() === "Custom" ||
                    sessionDetails?.type.trim() === "Open Enrollment") &&
                sessionDetails?.managingLocation.trim() !== "Licensees";
            setShowAttendance(show);
        }
    }, [sessionDetails]);

    React.useEffect(() => {
        if (participants != null && participants.length > 0) {
            const newConfirmedList = participants.filter(
                (p) => p.registrationStatus === "Confirmed"
            );
            setConfirmedParticipants(newConfirmedList);
        } else {
            setConfirmedParticipants([]);
        }
    }, [participants]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isLoading || detailsLoading) {
        return <ComponentLoader msg={"Loading Registrations"} />;
    }

    if (isSuccess && detailsSuccess && sessionDetails != null) {
        return (
            <Card sx={{ width: 1, m: 2, p: 2, flexGrow: 1 }}>
                <FlexGrid>
                    <SessionParticipantListDataGridPro
                        participants={confirmedParticipants}
                        showAttendance={showAttendance && !claimsService.IsReadOnly()}
                        sessionId={props.sessionId}
                        readOnly={props.readOnly}
                    />
                </FlexGrid>
            </Card>
        );
    }

    if (isError) {
        return showErrorAlert(getErrorMsg(error));
    }

    if (detailsIsError) {
        return showErrorAlert(getErrorMsg(detailError));
    }

    return showErrorAlert("Unknown error loading session registrations.");
};

export default SessionParticipantsPanel;
