import { Alert, AlertTitle, Box } from "@mui/material";
import * as React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!\
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventInput } from "@fullcalendar/core";
import { useGetCalendarEventsQuery } from "../../services/cclTokenedEnterpriseCoachingCalendarApi";
import ComponentLoader from "../common/componentLoader";
import { CalendarParams } from "../../services/types/enterpriseCoachingCalendarApiTypes";

import "./eventCalendar.css";

interface CalendarProps {
    imKey: string;
    pKey: string;
}

const EventCalendar: React.FC<CalendarProps> = (props) => {
    const params: CalendarParams = { pKey: props.pKey, imKey: props.imKey };
    const { data, isLoading, isError } = useGetCalendarEventsQuery(params);

    const calendarEvents = data
        ?.filter((x) => x.appointmentStatus !== "Canceled")
        .map((x) => {
            const e: EventInput = {};
            e.title = x.eventType;
            e.start = x.startDateTime;
            e.end = x.endDateTime;
            e.description = x.eventType;

            if (x.bookingType === "Hard Booking") {
                e.backgroundColor = "#000000";
                e.title = "(" + x.appointmentStatus + ") - " + x.eventTitle;
            } else if (x.bookingType === "Assignment") {
                e.backgroundColor = "#808080";
                e.title = "(Assignment) - " + x.eventTitle;
            } else if (x.bookingType === "Coaching Session") e.backgroundColor = "#00008B";
            else {
                e.backgroundColor = "#ADD8E6";
                e.textColor = "#000000";
            }
            return e;
        });

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isLoading || isLoading) {
        return <ComponentLoader msg={"Loading"} />;
    }

    if (isError) {
        return showErrorAlert(`Error retrieving calendar`);
    }

    return (
        <Box sx={{ width: 1, height: 1 }}>
            <FullCalendar
                height={1000}
                aspectRatio={1}
                plugins={[dayGridPlugin, timeGridPlugin]}
                headerToolbar={{
                    start: "today prev next",
                    center: "title",
                    end: "dayGridMonth dayGridWeek dayGridDay",
                }}
                initialView="dayGridMonth"
                events={calendarEvents}
                eventDisplay="block"
                showNonCurrentDates={false}
                buttonText={{
                    today: "Today",
                    month: "Month",
                    week: "Week",
                    day: "Day",
                    list: "List",
                }}
            />
        </Box>
    );
};

export default EventCalendar;
