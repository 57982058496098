import * as React from "react";
import { useEffect } from "react";
import { AlertColor, Grid } from "@mui/material";
import PartnerResourcesDataGridPro from "./partnerResourceList";
import {
    useGetAllPartnerResourcesQuery,
    useGetSinglePartnerResourcesQuery,
} from "../../../services/cclTokenedEnterpriseNetworkPartnerApi";
import { PartnerGroupPerson } from "../../../services/types/enterpriseNetworkPartnerTypes";
import { EditPartnerResourceDrawer } from "./editPartnerResourceDrawer";
import CclAlertSnackbar from "../../../components/common/cclAlertSnackbar";
import ComponentLoader from "../../../components/common/componentLoader";

interface PartnerResourcesTabPanelProps {
    isNwaAdmin: boolean | null;
    partnerId: string | null;
}

const PartnerResourcesTabPanel: React.FC<PartnerResourcesTabPanelProps> = (props) => {
    const [showAddResource, setShowAddResource] = React.useState<boolean>(false);
    const [resources, setResources] = React.useState<PartnerGroupPerson[]>([]);
    const [resourceToEdit, setResourceToEdit] = React.useState<PartnerGroupPerson | null>(null);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const {
        data: allResources,
        isSuccess: allIsSuccess,
        isLoading: allIsLoading,
    } = useGetAllPartnerResourcesQuery(undefined, { skip: props.isNwaAdmin === true });
    const {
        data: singlePartnerResources,
        isSuccess: singleIsSuccess,
        isLoading: singleIsLoading,
    } = useGetSinglePartnerResourcesQuery(props.partnerId ?? "-1", {
        skip: props.partnerId === null || !props.isNwaAdmin,
    });

    useEffect(() => {
        if (allIsSuccess) {
            const newResources = allResources && allResources.length > 0 ? [...allResources] : [];
            setResources(newResources);
        }
    }, [allIsSuccess, allResources]);

    useEffect(() => {
        if (singleIsSuccess) {
            const newResources =
                singlePartnerResources && singlePartnerResources.length > 0
                    ? [...singlePartnerResources]
                    : [];
            setResources(newResources);
        }
    }, [singleIsSuccess, singlePartnerResources]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };
    const dataLoading = (): boolean => {
        return allIsLoading || singleIsLoading;
    };

    const addPartnerResource = () => {
        setResourceToEdit(null);
        setShowAddResource(true);
    };

    const editPartnerResource = (imkey: number) => {
        const r = resources.find((re) => re.imKey === imkey);
        if (r == null) return;

        setResourceToEdit(r);
        setShowAddResource(true);
    };

    return (
        <React.Fragment>
            <EditPartnerResourceDrawer
                open={showAddResource}
                handleClose={() => setShowAddResource(false)}
                resource={resourceToEdit}
                userPartnerKey={
                    props.isNwaAdmin === true && props.partnerId !== null ? +props.partnerId : null
                }
                showSnackbar={ShowSnackbarElement}
            />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    {resources && !dataLoading() ? (
                        <PartnerResourcesDataGridPro
                            loading={dataLoading()}
                            resources={resources}
                            hidePartner={props.isNwaAdmin ?? false}
                            addPartnerResource={addPartnerResource}
                            editPartnerResource={editPartnerResource}
                        />
                    ) : (
                        dataLoading() && <ComponentLoader msg="" />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default PartnerResourcesTabPanel;
