import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface WorkPoolResourceListTableStateState {
  TableState: string
};

// Define the initial state using that type
const initialState: WorkPoolResourceListTableStateState = {
  TableState: JSON.stringify({ 
    sorting: { sortModel: [{ field: "name", sort: "asc" }]}, 
    columns: { columnVisibilityModel: { status: true }},
    filter:{ 
      filterModel: { 
        items: [
          {
              id: 1,
              columnField: "status", 
              operatorValue: "startsWith", 
              value: "active"
          }]
        }
      } 
  })
};

export const workPoolResourceListTableStateSlice = createSlice({
  name: 'workPoolResourceListTableState',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateWorkPoolResourceGridState: (state, action: PayloadAction<string | null>) => {
        state.TableState = action?.payload ?? initialState.TableState;
    }
  }
});

export const { updateWorkPoolResourceGridState } = workPoolResourceListTableStateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const workPoolResourceListTableState = (state: RootState) => state;
export default workPoolResourceListTableStateSlice.reducer;