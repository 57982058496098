import * as React from "react";
import { AssetVersionStatus } from "../../../../../services/types/enterpriseParticipantApiTypes";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";

export interface AssessmentRescoredCellProps {
    latestVersionId: string;
    versionStatuses: AssetVersionStatus[];
    isAssessment: boolean;
}

const AssessmentRescoredCell: React.FC<AssessmentRescoredCellProps> = (props) => {
    const [sortedVersions, setSortedVersions] = React.useState<AssetVersionStatus[]>([]);

    React.useEffect(() => {
        if (props.versionStatuses == null || props.versionStatuses?.length === 0) {
            setSortedVersions([]);
            return;
        }
        let unsorted = [...props.versionStatuses];
        let sorted = unsorted.sort((a, b) => {
            // note: sorting in descending order
            if (a?.createdDateTime && b?.createdDateTime) {
                const adate = new Date(a?.createdDateTime);
                const bdate = new Date(b?.createdDateTime);
                return adate.getTime() - bdate.getTime();
            } else if (b?.createdDateTime == null) {
                return 1;
            } else {
                return -1;
            }
        });
        setSortedVersions([...sorted]);
    }, [props.versionStatuses]);

    const dateToString = (dt: Date | null): string => {
        let datestr: string = "";
        if (dt != null) {
            const newdt = new Date(dt);
            datestr = newdt.toLocaleString();
        }
        return datestr;
    };

    const toolTip = 
        <>
            <Paper elevation={6}>
                <Table size="small">
                    <TableHead sx={{ bgcolor: "#EBF0F8" }}>
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "fontWeightBold" }}
                            >
                                Version
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "fontWeightBold" }}
                            >
                                {props.isAssessment ? "Scored Date" : "Created Date"}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "fontWeightBold" }}
                            >
                                Release Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedVersions.map((row, index) => {
                            const sd: string = dateToString(row.scoredDate);
                            const rd: string = dateToString(row.releaseDate);
                            const cd: string = dateToString(row.createdDateTime);
                            const idfield =
                                row.versionID === props.latestVersionId
                                    ? `${index + 1} (latest)`
                                    : `${index + 1}`    
                            return (
                                <TableRow key={row.avKey}>
                                    <TableCell align="center">{idfield}</TableCell>
                                    <TableCell align="center">
                                        {props.isAssessment ? sd : cd}
                                    </TableCell>
                                    <TableCell align="center">{rd}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </>

    return (
        <span>
            {props.versionStatuses.length > 1 && (
                <Tooltip 
                    title={toolTip}
                    arrow
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, -8],
                                    },
                                },
                            ],
                        }
                    }}
                >
                    <IconButton>
                        <HistoryIcon color="action" />
                    </IconButton>
                </Tooltip>
            )}
        </span>
    );
};

export default AssessmentRescoredCell;
