import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ApplicationUsersSearchListConfigState {
  FirstName: string,
  LastName: string,
  Email: string,
  IsEmailSearchActive: boolean
};

// Define the initial state using that type
const initialState: ApplicationUsersSearchListConfigState = {
  FirstName: '',
  LastName: '',
  Email: '',
  IsEmailSearchActive: true
};

export const applicationUsersSearchListConfigSlice = createSlice({
  name: 'applicationUsersSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateApplicationsUsersSearchParams: (state, action: PayloadAction<ApplicationUsersSearchListConfigState>) => {
      state.FirstName = action.payload.FirstName;
      state.LastName = action.payload.LastName;
      state.Email = action.payload.Email;
      state.IsEmailSearchActive = action.payload.IsEmailSearchActive;
    }
  }
});

export const { updateApplicationsUsersSearchParams } = applicationUsersSearchListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicationUsersSearchListConfig = (state: RootState) => state;
export default applicationUsersSearchListConfigSlice.reducer;
