import React from "react";
import { Table, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AccessEvent } from "../../../services/types/accessEventTypes";

export interface AccessEventDetailsDataTableProps {
    event: AccessEvent;
}

const AccessEventDetailsDataTable: React.FC<AccessEventDetailsDataTableProps> = (props) => {
    if (props.event.accessEventTypeId <= 2 || props.event.data == null) return null;

    let dataProps: { label: string; val: string }[] = [];
    try {
        const dtObj = JSON.parse(props.event.data);
        for (var key in dtObj) {
            const val = Array.isArray(dtObj[key]) ? dtObj[key].join(",") : dtObj[key];
            dataProps.push({ label: key, val: val });
        }
    } catch {
        dataProps.push({ label: "error", val: "Invalid data logged" });
    }

    return (
        <Box
            sx={{
                p: 1,
                borderWidth: "1px",
                borderColor: "gray",
                borderStyle: "solid",
            }}
        >
            <Table size="small" aria-label="Access event meta data" sx={{ border: 0 }}>
                <TableBody>
                    {dataProps.map((prop, idx) => {
                        return (
                            <TableRow
                                key={`${props.event.accessEventId}-${idx}`}
                                sx={{ p: 0, m: 0, border: 0 }}
                            >
                                <TableCell
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        border: 0,
                                        width: "1px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Typography variant="button" sx={{ fontWeight: "bold" }}>
                                        {prop.label}:
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                    <Typography variant="subtitle1" sx={{ pl: 3 }}>
                                        {prop.val}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
};

export default AccessEventDetailsDataTable;
