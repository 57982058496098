import * as React from "react";
import { Box, Stack } from "@mui/material";
import {
    useGetAllAttributeOptionsQuery,
    useGetProfilesAttributesQuery,
} from "../../../services/cclTokenedEnterpriseCoachingApi";
import MyAttributesTable from "./myAttributesDetail/myAttributeTable";

interface MyAttributesProps {
    readonly: boolean;
    pkey: string;
    fromResource: boolean;
    imKey: string;
    email: string;
}

export enum eAttributeKey {
    Education = 1,
    Language = 2,
    Industry = 3,
    Certification = 5,
    Assessment = 6,
    Simulation = 7,
    Models = 8,
}

const MyAttributesTabPanel: React.FC<MyAttributesProps> = (props) => {
    const { data: AllAttributesData } = useGetAllAttributeOptionsQuery();
    const { data: userProfileData } = useGetProfilesAttributesQuery(props.pkey, {
        skip: props.pkey === "",
    });

    return (
        <Box sx={{ width: 1, height: 1 }}>
            {userProfileData && AllAttributesData && (
                <Stack spacing={2}>
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Education}
                        attributeName="Education"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Language}
                        attributeName="Languages"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Industry}
                        attributeName="Industry Experience"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Assessment}
                        attributeName="Assessments (maintained by CCL)"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Certification}
                        attributeName="Professional Certifications (maintained by CCL)"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Models}
                        attributeName="Tools, Models, & Content"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={eAttributeKey.Simulation}
                        attributeName="Simulations, Experientials, & Activities"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                </Stack>
            )}
        </Box>
    );
};

export default MyAttributesTabPanel;
