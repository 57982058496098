import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { getDateStringAdjusted } from '../../utilities/dateHelpers';

// Define a type for the slice state
export interface AssignmentListConfigState {
  StartDate: string,
  EndDate: string,
  Email: string,
  IsEmailSearchActive: boolean
};

// Define the initial state using that type
const initialState: AssignmentListConfigState = {
  StartDate: getDateStringAdjusted(0),
  EndDate: getDateStringAdjusted(7),
  Email: '',
  IsEmailSearchActive: false
};

export const assignmentListConfigSlice = createSlice({
  name: 'assignmentListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateAssignmentSearchParams: (state, action: PayloadAction<AssignmentListConfigState>) => {
      state.StartDate = action.payload.StartDate;
      state.EndDate = action.payload.EndDate;
      state.Email = action.payload.Email;
      state.IsEmailSearchActive = action.payload.IsEmailSearchActive;
    }
  }
});

export const { updateAssignmentSearchParams } = assignmentListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAssignmentListConfig = (state: RootState) => state;
export default assignmentListConfigSlice.reducer;
