import { Typography } from '@mui/material';
import * as React from 'react';
import UnderConstruction from '../../components/common/underConstruction';
import { SimpleBreadcrumbs } from '../../components/navigation/simpleBreadcrumbs';
import StandardLayout from '../../layouts/standard';

interface MyProfileProps {    
}
 
const MyProfile: React.FC<MyProfileProps> = () => {
    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">{`My Profile`}</Typography>                        
        </SimpleBreadcrumbs>
    );

    return ( 
        <StandardLayout breadcrumbs={breadcrumbs}>
            <UnderConstruction msg={`My Profile`} />
        </StandardLayout>
    );
}
 
export default MyProfile;