import * as React from "react";
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Invitation } from "../../../services/types/rtkQueryTypes";
import CclDataGridToolbar from "../../common/cclDataGridToolbar/cclDataGridToolbar";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";

let COLUMNS = [
    {
        field: "invitationID",
        headerName: "ID",
        type: "string",
        flex: 1,
    },
    {
        field: "application",
        headerName: "Application",
        type: "string",
        flex: 1,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.6,
    },
    {
        field: "sent",
        headerName: "Date Sent",
        valueGetter: (params: any) => new Date(params.row.sent),
        type: "Date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        flex: 0.35,
    },
    {
        field: "expiration",
        headerName: "Expiration Date",
        valueGetter: (params: any) => new Date(params.row.expiration),
        type: "Date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        flex: 0.35,
    },
];

export interface InvitationsDataGridProProps {
    invitations: Invitation[] | undefined;
    showInvitationId: boolean;
    sendInvitation?: () => void;
}

export const InvitationsDataGridPro: React.FC<InvitationsDataGridProProps> = (props) => {
    const claimservice = new UserClaimsService();
    const isSuperAdmin = claimservice.IsUserSuperAdmin();

    if (props.showInvitationId === false) {
        COLUMNS = COLUMNS.filter((x) => x.field !== "invitationID");
    }

    const handleButtonClick = (buttonName: string) => {
        if (buttonName === "send-invitation" && props.sendInvitation) {
            props.sendInvitation();
        }
    };

    return (
        <DataGridPro
            getRowId={(row) => row.invitationID}
            rows={props.invitations == null ? [] : props.invitations}
            columns={COLUMNS}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={
                isSuperAdmin
                    ? {
                          toolbar: {
                              showSaveState: true,
                              hideSettingsButtons: true,
                              miscButtons:
                                  isSuperAdmin && props.sendInvitation != null
                                      ? [
                                            {
                                                id: "send-invitation",
                                                caption: "Send Invitation",
                                                label: "Send Invitation",
                                                disable: !isSuperAdmin,
                                                returnSelected: true,
                                                restricted: true,
                                                onClick: () => handleButtonClick("disable-all"),
                                            },
                                        ]
                                      : [],
                              btnClick: (buttonName: string) => handleButtonClick(buttonName),
                          },
                      }
                    : {}
            }
            initialState={{
                sorting: {
                    sortModel: [{ field: "sent", sort: "desc" }],
                },
            }}
            autoHeight
        />
    );
};

export default InvitationsDataGridPro;
