import * as React from "react";
import {
    Stack,
    TextField,
    FormControlLabel,
    Box,
    Typography,
    Card,
    Radio,
    RadioGroup,
    Checkbox,
} from "@mui/material";
import { ContractCardContent, EligibilityContractData } from "./signContractStepper";
import ContractCardHeader from "./sharedComponents/cardHeader";
import CclButton from "../../../../../../components/common/cclButtons/cclButton";

export interface EligibilityStepPanelProps {
    pkey: number;
    eligibilityData: EligibilityContractData;
    onNextPanel: (nextStep: number, newdata: EligibilityContractData) => void;
    onPreviousPanel: () => void;
    activeStep: number;
    nextStep: number;
}

export const EligibilityStepPanel: React.FC<EligibilityStepPanelProps> = (props) => {
    const [isEmployed, setIsEmployed] = React.useState<boolean | undefined>(
        props.eligibilityData?.isEmployed
    );
    const [employmentInfo, setEmploymentInfo] = React.useState<string | undefined>(
        props.eligibilityData?.employmentInfo
    );
    const [noAdvertisingPresence, setNoAdvertisingPresence] = React.useState<boolean | undefined>(
        props.eligibilityData?.noAdvertisingPresence
    );
    const [haveLinkedIn, setHaveLinkedIn] = React.useState<boolean | undefined>(
        props.eligibilityData?.haveLinkedIn
    );
    const [website, setWebsite] = React.useState<string | undefined>(
        props.eligibilityData?.website
    );
    const [cclExclusively, setCclExclusively] = React.useState<boolean | undefined>(
        props.eligibilityData?.cclExclusively
    );
    const [stateRegistrationNumber, setStateRegistrationNumber] = React.useState<
        string | undefined
    >(props.eligibilityData?.stateRegistrationNumber);
    const [stateRegistrationState, setStateRegistrationState] = React.useState<string | undefined>(
        props.eligibilityData?.stateRegistrationState
    );
    const [newLegalEntity, setNewLegalEntity] = React.useState<boolean | undefined>(
        props.eligibilityData?.newLegalEntity
    );

    React.useEffect(() => {
        if (props.nextStep !== -1) handleNext(props.nextStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nextStep]);

    const handleNext = (nxtStep?: number) => {
        let ns = nxtStep !== undefined ? nxtStep : props.activeStep + 1;
        var eligibilityData: EligibilityContractData = {
            isEmployed: isEmployed,
            employmentInfo: employmentInfo,
            noAdvertisingPresence: noAdvertisingPresence,
            haveLinkedIn: haveLinkedIn,
            website: website,
            cclExclusively: cclExclusively,
            stateRegistrationNumber: stateRegistrationNumber,
            stateRegistrationState: stateRegistrationState,
            newLegalEntity: newLegalEntity,
        };

        props.onNextPanel(ns, eligibilityData);
    };

    return (
        <Stack direction={"column"}>
            <Box
                component="form"
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <Typography variant="h5">
                        The following information is required to substantiate your eligibility to
                        perform independent contractor work for the Center of Creative Leadership.
                    </Typography>
                    <Typography variant="body2" sx={{ pb: 3, color: "text.secondary" }}>
                        Closing the screen will stop the Sign Contract process but you will be able
                        to restart it again.
                    </Typography>
                    <Stack direction={"row"} width={1} spacing={3}>
                        <Card sx={{ width: 0.5 }}>
                            <ContractCardContent>
                                <ContractCardHeader
                                    text={"Employment"}
                                    showRequired={true}
                                    showInfoIcon={false}
                                />
                                <Typography variant="body2">
                                    Are you currently employed full time (not self-employed)?
                                </Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="contract-employment-info"
                                    name="employment-radio-group"
                                    sx={{ pb: 3 }}
                                    value={
                                        isEmployed == null ? undefined : isEmployed ? "yes" : "no"
                                    }
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        setIsEmployed(
                                            (evt.target as HTMLInputElement).value === "yes"
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">No</Typography>}
                                    />
                                </RadioGroup>
                                <Typography variant="body2" sx={{ pb: 2 }}>
                                    If yes, please specify
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="employment-details"
                                    label="Employment Details"
                                    value={employmentInfo}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmploymentInfo(event.target.value);
                                    }}
                                    size="small"
                                    sx={{ fontSize: "0.8125rem" }}
                                />
                            </ContractCardContent>
                        </Card>
                        <Card sx={{ width: 0.5 }}>
                            <ContractCardContent>
                                <ContractCardHeader
                                    text={"Advertising Your Entity"}
                                    showRequired={false}
                                    showInfoIcon={true}
                                    infoText="Please provide your professional website address. This should be your website and not Facebook. If you only have a professional LinkedIn presence, please state “LinkedIn” in Website."
                                />
                                <Typography variant="body2">LinkedIn?</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="contract-linkedin-info"
                                    name="linkedin-radio-group"
                                    sx={{ pb: 3 }}
                                    value={
                                        haveLinkedIn == null
                                            ? undefined
                                            : haveLinkedIn
                                            ? "yes"
                                            : "no"
                                    }
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        setHaveLinkedIn(
                                            (evt.target as HTMLInputElement).value === "yes"
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">No</Typography>}
                                    />
                                </RadioGroup>
                                <TextField
                                    fullWidth
                                    id="advertising-website"
                                    label="Website"
                                    size="small"
                                    sx={{ pb: 3, fontSize: "0.8125rem" }}
                                    value={website}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWebsite(event.target.value);
                                    }}
                                />
                                <FormControlLabel
                                    label="I have no advertising presence"
                                    control={
                                        <Checkbox
                                            checked={noAdvertisingPresence}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setNoAdvertisingPresence(event.target.checked);
                                            }}
                                            name="no-advertising-presence"
                                        />
                                    }
                                />
                            </ContractCardContent>
                        </Card>
                    </Stack>
                    <Stack direction={"row"} width={1} spacing={3}>
                        <Card sx={{ width: 0.5 }}>
                            <ContractCardContent>
                                <ContractCardHeader
                                    text={"Non-Exclusivity"}
                                    showRequired={true}
                                    showInfoIcon={true}
                                    infoText="You have represented that you are customarily engaged in an independent trade, occupation, profession, or business related to the services to be performed for CCL. You have represented that your services are generally available to the public and that as an independent contractor, you provide services to clients in addition to CCL. Please confirm below that you regularly conduct business with clients and client organizations in addition to CCL."
                                />
                                <Typography variant="body2">I have non-CCL clients:</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="exclusivity-info"
                                    name="exclusivity-radio-group"
                                    value={
                                        cclExclusively == null
                                            ? undefined
                                            : cclExclusively
                                            ? "yes"
                                            : "no"
                                    }
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        setCclExclusively(
                                            (evt.target as HTMLInputElement).value === "yes"
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">No</Typography>}
                                    />
                                </RadioGroup>
                            </ContractCardContent>
                        </Card>
                        <Card sx={{ width: 0.5 }}>
                            <ContractCardContent>
                                <ContractCardHeader
                                    text={"State Registration"}
                                    showRequired={false}
                                    showInfoIcon={true}
                                    infoText="Please provide your company’s state registration number and state of registration."
                                />
                                <TextField
                                    fullWidth
                                    id="state-reg-number"
                                    label="State Reg. Number"
                                    size="small"
                                    sx={{ mt: 2, pb: 6, fontSize: "0.8125rem" }}
                                    value={stateRegistrationNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setStateRegistrationNumber(event.target.value);
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="state-reg-abbreviation"
                                    label="State Abbreviation"
                                    size="small"
                                    sx={{ fontSize: "0.8125rem" }}
                                    value={stateRegistrationState}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setStateRegistrationState(event.target.value);
                                    }}
                                />
                            </ContractCardContent>
                        </Card>
                    </Stack>
                    <Card sx={{ width: 1 }}>
                        <ContractCardContent>
                            <ContractCardHeader
                                text={"Legal Entity"}
                                showRequired={true}
                                showInfoIcon={false}
                            />
                            <Typography variant="body2">
                                Are you contracting with CCL under a different legal entity/business
                                naem than last year? If so, please check the 'Yes' box to inform CCL
                                of the need to collect a new W-9 form. Otherwise check 'No' box.
                            </Typography>
                            <RadioGroup
                                row
                                aria-labelledby="legal-entity-info"
                                name="exclusivity-radio-group"
                                value={
                                    newLegalEntity == null
                                        ? undefined
                                        : newLegalEntity
                                        ? "yes"
                                        : "no"
                                }
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    setNewLegalEntity(
                                        (evt.target as HTMLInputElement).value === "yes"
                                    )
                                }
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio size="small" />}
                                    label={<Typography variant="body2">Yes</Typography>}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio size="small" />}
                                    label={<Typography variant="body2">No</Typography>}
                                />
                            </RadioGroup>
                        </ContractCardContent>
                    </Card>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclButton
                    onClick={() => props.onPreviousPanel()}
                    sx={{ mr: 1 }}
                    mode={"secondary"}
                >
                    Back
                </CclButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton onClick={() => handleNext()} mode={"primary"}>
                    Next
                </CclButton>
            </Box>
        </Stack>
    );
};

export default EligibilityStepPanel;
