import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface AssignmentListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: AssignmentListTableStateState = {
    TableState: JSON.stringify({
        sorting: { sortModel: [{ field: "startDateTimeUtc", sort: "asc" }] },
    }),
};

export const assignmentListTableStateSlice = createSlice({
    name: "assignmentListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateAssignmentGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateAssignmentGridState } = assignmentListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const AssignmentListTableState = (state: RootState) => state;
export default assignmentListTableStateSlice.reducer;
