import * as React from "react";
import { Box, Paper, Stack, Tab } from "@mui/material";
import StandardLayout from "../../../layouts/standard";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export type DetailPageProps = {
    breadcrumbs: JSX.Element;
    descriptor: string;
    tablist: [string, number, React.ReactNode][];
    activeTab?: number;
    identifier?: string;
    handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
};

const DetailPage: React.FC<DetailPageProps> = (props) => {
    const [value, setValue] = React.useState(`${props.activeTab}` ?? `0`);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (props.handleTabChange) {
            props.handleTabChange(event, +newValue);
            return;
        }
        setValue(newValue);
    };

    React.useEffect(() => {
        if (props.activeTab !== null && props.activeTab !== undefined) {
            setValue(`${props.activeTab}`);
        } else {
            setValue(`${props.tablist[0][1]}`);
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    function a11yProps(index: number) {
        return {
            id: `${props.descriptor}-detail-tab-${index}`,
            "aria-controls": `${props.descriptor}-detail-tabpanel-${index}`,
        };
    }

    return (
        <StandardLayout breadcrumbs={props.breadcrumbs}>
            <Stack height={1} width={1}>
                <Paper
                    id={"details-layout-" + props.identifier}
                    sx={{
                        width: 1,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "5px",
                    }}
                >
                    <TabContext value={`${value}`}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                variant="scrollable"
                                onChange={handleTabChange}
                                aria-label={`${props.descriptor}-detail-tabs`}
                            >
                                {props.tablist.map((tab, i) => (
                                    <Tab
                                        sx={{ textTransform: "none" }}
                                        key={tab[0]}
                                        label={tab[0]}
                                        value={`${tab[1]}`}
                                        {...a11yProps(i)}
                                    />
                                ))}
                            </TabList>
                        </Box>
                        {props.tablist.map((tab, i) => (
                            <TabPanel key={tab[0]} sx={{ height: "100%" }} value={`${tab[1]}`}>
                                <Box sx={{ p: 1, height: "100%", display: "flex" }}>{tab[2]}</Box>
                            </TabPanel>
                        ))}
                    </TabContext>
                </Paper>
            </Stack>
        </StandardLayout>
    );
};

export default DetailPage;

//<DetailsTabPanel detailType={props.descriptor} key={tab[0]} value={value} index={i}>{tab[2]}</DetailsTabPanel>
