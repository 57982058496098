import * as React from "react";
import { Box } from "@mui/material";

export interface DetailsTabPanelProps {
    children?:
        | React.ReactNode
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | undefined;
    index: number;
    value: number;
    detailType: string;
}

export const DetailsTabPanel: React.FC<DetailsTabPanelProps> = (props) => {
    const { children, value, index, detailType, ...other } = props;

    return (
        <div
            style={{ height: "100%", padding: "3px" }}
            role="tabpanel"
            hidden={value !== index}
            id={`${detailType}-detail-tabpanel-${index}`}
            aria-labelledby={`${detailType}-detail-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, height: "100%", display: "flex" }}>{props.children}</Box>
            )}
        </div>
    );
};
