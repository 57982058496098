import { Box } from '@mui/material';
import * as React from 'react';
import UserNotificationsPanel from '../../../users/userDetail/notifications/userNotificationsPanel';

interface PartnerNotificationsProps {
    email: string;
}

const PartnerNotifications: React.FC<PartnerNotificationsProps> = (props) => {

    return (
        <Box sx={{ width: 1, height: 1 }}>
            <UserNotificationsPanel emailAddress={props.email} fromMyAccess={true}/>
        </Box>
    );
}

export default PartnerNotifications;