import * as React from "react";
import { MouseEvent } from "react";
import { Alert, Box, Grid, Stack } from "@mui/material";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import PrimaryCoachAssignmentList from "./primaryCoachAssignmentList";
import CoachAssignmentParticipantList from "./coachAssignmentParticipantList";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { PersonInfo } from "../../../../../models/personInfo";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import CclLoadingButton from "../../../../../components/common/cclButtons/cclLoadingButton";

interface PrimaryCoachAssignmentSubPanelProps {
    sessionId: string;
    assignedCoaches: SessionDetailAssignedCoach[];
    updateCoachAssignment: (
        paxImKey: number,
        coachImKey: number,
        isPrimary: boolean,
        selected: boolean
    ) => void | undefined;
    removeCoachesAssignments: (coach: PersonInfo, isPrimary: boolean) => void;
    enableSave: boolean;
    handleSave: () => void;
    saveInProgress: boolean;
    readOnly: boolean;
}

const PrimaryCoachAssignmentSubPanel: React.FC<PrimaryCoachAssignmentSubPanelProps> = (props) => {
    const [selectedCoach, setSelectedCoach] = React.useState<PersonInfo | null>(null);
    const {
        data: allParticipants,
        isLoading: paxLoading,
        isSuccess: paxSuccess,
    } = useGetCclParticipantsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });

    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        props.handleSave();
    };

    function getSaveButtonElement(): JSX.Element {
        return (
            <Box width={1} sx={{ marginRight: 4 }}>
                {props.readOnly ? null : (
                    <CclLoadingButton
                        mode={"primary"}
                        loading={props.saveInProgress}
                        onClick={handleSave}
                        disabled={!props.enableSave}
                        sx={{ float: "right" }}
                    >
                        Save
                    </CclLoadingButton>
                )}
            </Box>
        );
    }

    return (
        <Stack height={1} width={1} sx={{ justify: "space-between" }}>
            <Grid
                container
                spacing={2}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}
                >
                    {paxSuccess && (
                        <CclGenericAccordian
                            headingText={"Primary Coaches"}
                            summaryRightSideElement={getSaveButtonElement()}
                        >
                            <Alert
                                severity="info"
                                sx={{
                                    mb: 3,
                                    pl: 3,
                                    pr: 3,
                                    pt: 1,
                                    pb: 1,
                                    width: 1,
                                    alignSelf: "center",
                                }}
                            >
                                Primary Coach assignment data is integrated to D365
                            </Alert>
                            <PrimaryCoachAssignmentList
                                sessionId={props.sessionId}
                                allParticipants={allParticipants?.filter(
                                    (p) => p.registrationStatus === "Confirmed"
                                )}
                                assignedCoachList={props.assignedCoaches}
                                onCoachSelect={(coach: PersonInfo | null) =>
                                    setSelectedCoach(coach)
                                }
                                unassignCoachesParticipants={(coach: PersonInfo) =>
                                    props.removeCoachesAssignments(coach, true)
                                }
                            />
                        </CclGenericAccordian>
                    )}
                    {paxLoading && <ComponentLoader msg={"Loading Coaches"} />}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}
                >
                    {paxSuccess && (
                        <CclGenericAccordian headingText={"Participants"}>
                            <CoachAssignmentParticipantList
                                allParticipants={allParticipants?.filter(
                                    (p) => p.registrationStatus === "Confirmed"
                                )}
                                assignedCoaches={props.assignedCoaches}
                                selectedCoach={selectedCoach}
                                onPaxChange={props.updateCoachAssignment}
                                forPrimaryCoaches={true}
                                assignAllToCoach={undefined}
                            />
                        </CclGenericAccordian>
                    )}
                    {paxLoading && <ComponentLoader msg={"Loading Participants"} />}
                </Grid>
            </Grid>
        </Stack>
    );
};

export default PrimaryCoachAssignmentSubPanel;
