import React, { ReactElement } from "react";
import { ChipPropsColorOverrides } from "@mui/material";
import ContrastIcon from "@mui/icons-material/Contrast";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { OverridableStringUnion } from "@mui/types";
import BaseStatusPill from "./baseStatusPill";

export interface SelfStatusPillProps {
    status: number;
    scored: boolean;
    hasRaters: boolean;
}
// Not Accessed = 1,
// In Process = 2,
// Complete = 4,
// Cancelled = 5
const SelfStatusPill: React.FC<SelfStatusPillProps> = (props) => {
    let clr:
        | OverridableStringUnion<
              "warning" | "primary" | "success" | "secondary" | "default" | "error" | "info",
              ChipPropsColorOverrides
          >
        | undefined = undefined;
    let icon: ReactElement = <></>;
    let label: string = "";

    if (props.status === 1) {
        clr = "warning";
        label = "Not Accessed";
        icon = <RemoveCircleOutlineIcon color="warning" fontSize="small" />;
    } else if (props.status === 2) {
        clr = "primary";
        label = "In Progress";
        icon = <ScheduleIcon color="primary" fontSize="small" />;
    } else if (props.status === 4) {
        if (props.scored) {
            clr = "success";
            label = "Scored";
            icon = <CheckCircleIcon color="success" fontSize="small" />;
        } else {
            label = "Complete";
            if (props.hasRaters) {
                clr = "success";
                icon = <CheckCircleIcon color="success" fontSize="small" />;
            } else {
                clr = "secondary";
                icon = <ContrastIcon color="secondary" fontSize="small" />;
            }
        }
    } else if (props.status === 5) {
        clr = undefined;
        label = "Cancelled";
        icon = <NotInterestedIcon color="disabled" fontSize="small" />;
    }

    return <BaseStatusPill icon={icon} label={label} color={clr} />;
};

export default SelfStatusPill;
