import { FormControl, Autocomplete, TextField } from "@mui/material";
import React from "react";

interface KeyValue {
    id: string;
    label: string;
}

interface AdvancedSearchMultiSelectProps {
    dataList: KeyValue[];
    label: string;
    id: string;
    handleSelectionChanged: (ids: number[]) => void;
}

const AdvancedSearchMultiSelect: React.FC<AdvancedSearchMultiSelectProps> = (props) => {
    const onTagsChange = (event: React.SyntheticEvent, value: Array<KeyValue>) => {
        var keys = value.map((x) => Number(x.id));
        props.handleSelectionChanged(keys);
        console.log(keys);
    };

    const selectId = "select-" + props.id;

    return (
        <FormControl fullWidth>
            <Autocomplete
                size="small"
                multiple
                id={selectId}
                options={props.dataList}
                getOptionLabel={(option) => option.label}
                onChange={onTagsChange}
                renderInput={(params) => (
                    <TextField {...params} label={props.label} placeholder={props.label} />
                )}
            />
        </FormControl>
    );
};

export default AdvancedSearchMultiSelect;
