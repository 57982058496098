import React from "react";
import { Chip, IconButton } from "@mui/material";
import {
    useGridApiContext,
    useGridSelector,
    gridDetailPanelExpandedRowsContentCacheSelector,
} from "@mui/x-data-grid-pro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface AssetDetailToggleProps {
    id: any;
    value: any;
    selectedCount: number;
}

// export const AssetDetailToggle: React.FC<Pick<GridRenderCellParams, "id" | "value"> & number> = (props) => {
export const AssetDetailToggle: React.FC<AssetDetailToggleProps> = (props) => {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return hasDetail ? (
        <IconButton size="small" tabIndex={-1} aria-label={isExpanded ? "Close" : "Open"}>
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${!isExpanded ? 270 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create("transform", {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
            {props.selectedCount > 0 && !isExpanded ? (
                <Chip color="primary" size={"small"} label={props.selectedCount} />
            ) : null}
        </IconButton>
    ) : (
        <></>
    );
};

export default AssetDetailToggle;
