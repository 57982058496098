import * as React from "react";
import {
    Select,
    MenuItem,
    Stack,
    FormControl,
    InputLabel,
    TextField,
    Autocomplete,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Typography,
    Box,
} from "@mui/material";
import {
    useGetCclAllSystemsQuery,
    useLazyGetGroupsByTenantIdQuery,
    useSendInvitationsMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import { SendInvitationsRequest } from "../../../../services/types/rtkQueryTypes";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventApplicationDetails } from "../../../../services/types/accessEventTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";

export type SendInvitationDrawerProps = {
    email: string;
    firstName: string;
    lastName: string;
    imKey: string;
    open: boolean;
    onClose: (result: boolean | null) => void;
};

type SelectOption = {
    val: string;
    option: string;
};

const SendInvitationDrawer: React.FC<SendInvitationDrawerProps> = (props) => {
    const { data: gcSystems } = useGetCclAllSystemsQuery();
    const [getTenantGroups, { data: tenantGroups }] = useLazyGetGroupsByTenantIdQuery();
    const [sendInvitations, { isLoading: sendIsLoading }] = useSendInvitationsMutation();
    const { logEvent } = useLogAccessEvent();

    const [systems, setSystems] = React.useState<SelectOption[]>([]);
    const [applications, setApplications] = React.useState<SelectOption[]>([]);
    const [tenants, setTenants] = React.useState<SelectOption[]>([]);
    const [groups, setGroups] = React.useState<SelectOption[]>([]);

    const [system, setSystem] = React.useState<SelectOption | null>(null);
    const [applicationId, setApplicationId] = React.useState<string>("");
    const [tenantId, setTenantId] = React.useState<string>("");
    const [daysValid, setDaysValid] = React.useState<number>(28);
    const [disableEmail, setDisableEmail] = React.useState<boolean>(false);
    const [autoAccept, setAutoAccept] = React.useState<boolean>(false);
    const [ignorePending, setIgnorePending] = React.useState<boolean>(false);
    const [invitationState, setInvitationState] = React.useState<string>("");
    const [selectedGroups, setSelectedGroups] = React.useState<SelectOption[]>([]);

    const ignorePendingTooltip: string =
        "Checking this box will invalidate any open invitations for the user for the selected application.";

    React.useEffect(() => {
        // on close clear out all the stored input states
        if (!props.open) {
            setSystem(null);
            setApplicationId("");
            setTenantId("");
            setDaysValid(28);
            setDisableEmail(false);
            setAutoAccept(false);
            setInvitationState("");
            setSelectedGroups([]);
        }
    }, [props.open]);

    React.useEffect(() => {
        if (gcSystems == null || gcSystems.length <= 0) {
            setSystems([]);
            return;
        }
        const sysopts = gcSystems?.map((s) => {
            return { val: s.systemId, option: s.systemName };
        });

        setSystems([...sysopts.sort(sortOptions)]);
    }, [gcSystems]);

    React.useEffect(() => {
        if (system?.val == null || system.val === "") {
            setApplications([]);
            setTenants([]);
            return;
        }

        const sys = gcSystems?.find((s) => s.systemId === system.val);
        if (sys == null) {
            setApplications([]);
            setTenants([]);
            return;
        }

        const appopts = sys.applications.map((a) => {
            return { val: a.applicationId, option: a.name };
        });
        const tenantopts = sys.tenants.map((t) => {
            return { val: t.tenantId, option: t.tenantName };
        });
        setApplications([...appopts.sort(sortOptions)]);
        setTenants([...tenantopts.sort(sortOptions)]);
        if (appopts.length === 1) setApplicationId(appopts[0].val);
        if (tenantopts.length === 1) setTenantId(tenantopts[0].val);
    }, [system, gcSystems]);

    React.useEffect(() => {
        setSelectedGroups([]);
        if (tenantId == null || tenantId === "") {
            setGroups([]);
            return;
        }
        getTenantGroups(tenantId);
        // eslint-disable-next-line
    }, [tenantId]);

    React.useEffect(() => {
        if (tenantGroups == null || tenantGroups.length <= 0) {
            setGroups([]);
            return;
        }
        const grpopts = tenantGroups.map((g) => {
            return { val: g.groupId, option: g.name };
        });
        setGroups([...grpopts.sort(sortOptions)]);
    }, [tenantGroups]);

    const sortOptions = (a: SelectOption, b: SelectOption) => {
        return a.option.localeCompare(b.option);
    };

    const handleSend = () => {
        var first = props.firstName === null ? "" : props.firstName;
        var last = props.lastName === null ? "" : props.lastName;

        const payload: SendInvitationsRequest = {
            recipients: [{ email: props.email, firstName: first, lastName: last }],
            applicationId: applicationId,
            tenantId: tenantId,
            daysValid: daysValid,
            state: invitationState,
            disableEmail: disableEmail,
            autoAccept: autoAccept,
            ignorePendingInvites: ignorePending,
            groups: selectedGroups.map((g) => {
                return g.val;
            }),
        };
        sendInvitations(payload)
            .unwrap()
            .then((response) => {
                if (response?.numberFailed === 0) {
                    const app = applications.find((a) => a.val === applicationId);
                    const evtData: AccessEventApplicationDetails = {
                        applicationName: app?.option ?? "",
                        applicationId: applicationId,
                        imKey: +props.imKey,
                        email: props.email,
                    };

                    logEvent("UserApplicationInvitationSent", evtData);
                    props.onClose(true);
                } else {
                    props.onClose(false);
                }
            })
            .catch((error) => {
                props.onClose(false);
            });
    };

    const getOptionFromVal = (val: string, options: SelectOption[]) => {
        const opt = options.find((o) => o.val === val);
        if (opt == null) return null;
        return opt.option;
    };

    return (
        <CclDrawer
            title={`Send Invitation To ${props.email}`}
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={function (): void {
                throw new Error("Function not implemented.");
            }}
        >
            <Box>
                <Stack direction={"column"} width={1} spacing={3}>
                    <Autocomplete
                        id="systems-outlined"
                        value={system}
                        options={systems}
                        getOptionLabel={(option) => option.option}
                        defaultValue={null}
                        onChange={(evt, val) => setSystem(val)}
                        renderInput={(params) => <TextField {...params} label={"Select System"} />}
                        disabled={systems.length <= 0}
                        sx={{ mt: 2 }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="select-application-label">Select Application</InputLabel>
                        <Select
                            labelId="select-application-label"
                            id="select-application"
                            value={applicationId ?? "Select Application"}
                            renderValue={(v) => {
                                return getOptionFromVal(v, applications);
                            }}
                            label="Select Application"
                            onChange={(evt) => setApplicationId(evt.target.value)}
                            disabled={systems.length <= 0}
                        >
                            {applications.map((opt) => (
                                <MenuItem
                                    key={opt.val}
                                    selected={opt.val === system?.val}
                                    value={opt.val}
                                >
                                    {opt.option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="select-tenant-label">Select Tenant</InputLabel>
                        <Select
                            labelId="select-tenant-label"
                            id="select-tenant"
                            value={tenantId ?? "Select Tenant"}
                            renderValue={(v) => {
                                return getOptionFromVal(v, tenants);
                            }}
                            label="Select Tenant"
                            onChange={(evt) => setTenantId(evt.target.value)}
                            disabled={systems.length <= 0}
                        >
                            {tenants.map((opt) => (
                                <MenuItem
                                    key={opt.val}
                                    selected={opt.val === system?.val}
                                    value={opt.val}
                                >
                                    {opt.option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        value={daysValid}
                        sx={{ width: 1, maxHeight: "57px", mt: 2 }}
                        id="outlined-adornment-days-valid"
                        label="Days To Expiration"
                        variant="outlined"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        onChange={(evt) => setDaysValid(+evt.target.value)}
                    />
                    <TextField
                        value={invitationState}
                        sx={{ width: 1, maxHeight: "57px", mt: 2 }}
                        id="outlined-adornment-invitation-state"
                        label="State information that may be needed by application upon invitation acceptance"
                        variant="outlined"
                        onChange={(evt) => setInvitationState(evt.target.value)}
                    />
                    <Autocomplete
                        multiple
                        id="groups-outlined"
                        value={selectedGroups}
                        options={groups}
                        getOptionLabel={(option) => option.option}
                        defaultValue={[]}
                        filterSelectedOptions
                        onChange={(evt, val) => setSelectedGroups([...val])}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={groups.length <= 0 ? "No Groups Available" : "Select Groups"}
                            />
                        )}
                        disabled={groups.length <= 0}
                    />
                    <FormGroup>
                        <FormControlLabel
                            label="Disable Email"
                            control={
                                <Checkbox
                                    checked={disableEmail}
                                    onChange={(evt) => setDisableEmail(evt.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Auto Accept"
                            control={
                                <Checkbox
                                    checked={autoAccept}
                                    onChange={(evt) => setAutoAccept(evt.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={
                                <React.Fragment>
                                    Ignore Pending Invitations
                                    {
                                        <Tooltip
                                            title={
                                                <Typography sx={{ fontSize: "1.25em" }}>
                                                    {ignorePendingTooltip}
                                                </Typography>
                                            }
                                        >
                                            <InfoOutlinedIcon fontSize="small" color={"info"} />
                                        </Tooltip>
                                    }
                                </React.Fragment>
                            }
                            control={
                                <Checkbox
                                    checked={ignorePending}
                                    onChange={(evt) => setIgnorePending(evt.target.checked)}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Box>
            <CclDrawerActionBar>
                <CclButton
                    id="cancel-btn"
                    onClick={() => props.onClose(null)}
                    disabled={sendIsLoading}
                    mode={"secondary"}
                >
                    Cancel
                </CclButton>
                <CclLoadingButton
                    id="ok-btn"
                    onClick={handleSend}
                    disabled={
                        system == null ||
                        tenantId == null ||
                        tenantId === "" ||
                        applicationId == null ||
                        applicationId === ""
                    }
                    loading={sendIsLoading}
                    mode={"primary"}
                >
                    Send
                </CclLoadingButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default SendInvitationDrawer;
