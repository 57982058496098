import * as React from "react";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { ProfileRole } from "../../../../services/types/enterpriseCoachingApiTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";

const COLUMNS = [
    {
        field: "description",
        headerName: "Name",
        flex: 1,
    },
];

export interface ResourceRolesDataGridProps {
    data: ProfileRole[] | undefined;
    isLoading: boolean;
}

export const ResourceRolesDataGrid: React.FC<ResourceRolesDataGridProps> = (props) => {
    const roles = props.data === undefined ? [] : props.data;

    return (
        <DataGridPro
            getRowId={(row) => row.prtKey}
            rows={roles}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.isLoading}
            initialState={{
                sorting: {
                    sortModel: [{ field: "description", sort: "asc" }],
                },
            }}
            disableSelectionOnClick={true}
            autoHeight
        />
    );
};

export default ResourceRolesDataGrid;
