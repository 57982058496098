import * as React from "react";
import { Alert, AlertTitle } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import CnxSessionResourcePanel from "./cnxSession/cnxSessionResourcesPanel";
import CclSessionResourcePanel from "./cclSession/cclSessionResourcesPanel";
import NwaSessionResourcePanel from "./nwaSession/nwaSessionResourcesPanel";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";

interface SessionResourcesPanelProps {
    sessionId: string;
    setSaveNeeded: (needSave: boolean) => void;
    readOnly?: boolean;
}

const SessionResourcesPanel: React.FC<SessionResourcesPanelProps> = (props) => {
    const [showCnx, setShowCnx] = React.useState<boolean>(false);
    const [showNwa, setShowNwa] = React.useState<boolean>(false);
    const {
        data: sessionDetails,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCclEventByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });

    React.useEffect(() => {
        if (sessionDetails != null && isSuccess) {
            if (sessionDetails.hostingPlatformKey === 7) {
                setShowCnx(true);
                setShowNwa(false);
            } else if (sessionDetails.managingLocation.trim() === "Licensees") {
                setShowCnx(false);
                setShowNwa(true);
            } else {
                setShowCnx(false);
                setShowNwa(false);
            }
        }
    }, [sessionDetails, isSuccess]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Assignments" />;
    }

    if (isSuccess) {
        if (showCnx)
            return (
                <CnxSessionResourcePanel
                    sessionId={props.sessionId}
                    setSaveNeeded={props.setSaveNeeded}
                />
            );
        else if (showNwa)
            return (
                <NwaSessionResourcePanel
                    sessionId={props.sessionId}
                    setSaveNeeded={props.setSaveNeeded}
                />
            );
        else
            return (
                <CclSessionResourcePanel
                    sessionId={props.sessionId}
                    setSaveNeeded={props.setSaveNeeded}
                    readOnly={props.readOnly}
                />
            );
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving participant assignment data for session ${
                props.sessionId
            } (${getErrorMsg(error)})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving participant assignment data for session ${props.sessionId}`
    );
};

export default SessionResourcesPanel;
