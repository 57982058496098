import * as React from "react";
import { Alert, AlertTitle, Link, Stack, Typography } from "@mui/material";
import { useParams, Link as RouterLink } from "react-router-dom";
import DetailPage from "../../../components/common/detailPage/detailPage";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import ResourceInfoPanel from "./info/resourceInfoPanel";
import StandardLayout from "../../../layouts/standard";
import AttributesPanel from "./attributes/AttributesPanel";
import ResourceBio from "./bios/resourceBio";
import Assignments from "./assignments/assignments";
import PageLoader from "../../../components/common/pageLoader";
import { Profile } from "../../../services/types/enterpriseCoachingApiTypes";
import { useGetDemographicsByProfileKeyQuery } from "../../../services/cclTokenedEnterpriseCoachingApi";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import ResourceWorkPoolsPanel from "./workPools/resourceWorkPools";
import EditNoteIcon from "@mui/icons-material/EditNote";
import useCombinationKeyPress from "../../../hooks/useCombinationKeyPress";
import ResourceJournalDrawer from "./resourceJournalDrawer";
import EventCalendar from "../../../components/shared/eventCalendar";

export type ResourceDetailProps = {
    pKey: string;
    target: string;
};

const ResourceDetail: React.FC = (props) => {
    const params = useParams<ResourceDetailProps>();
    const pKey = params.pKey !== undefined ? params.pKey : "";
    const target = params.target !== undefined ? params.target : "";
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const { data, isError, error, isLoading } = useGetDemographicsByProfileKeyQuery(pKey);
    const detailEl = React.useRef<HTMLDivElement>(null);

    const onKeyDown = (event: KeyboardEvent) => {
        setDrawerOpen(true);
        event.preventDefault();
    };
    const claimsService = new UserClaimsService();

    useCombinationKeyPress(["j"], onKeyDown, detailEl.current);
    const allowJournalEntries = false;
    //        claimsService.IsUserCclAdminOrSuperAdmin() || claimsService.IsUserNwaAdmin();
    const emptyProfile = {} as Profile;
    const profile = data == null ? emptyProfile : data;
    const email = profile.email ? profile.email : "";

    const fullTablist: [string, number, React.ReactNode][] = [
        ["Details", 0, <ResourceInfoPanel profile={profile} pKey={pKey} />],
        [
            "Attributes",
            1,
            <AttributesPanel imKey={data ? data.imKey.toString() : ""} pKey={pKey} email={email} />,
        ],
        [
            "Bio",
            2,
            <ResourceBio pKey={pKey} imKey={data ? data.imKey.toString() : ""} email={email} />,
        ],
        ["Work Pools", 3, <ResourceWorkPoolsPanel pkey={pKey} />],
        ["Assignments", 4, <Assignments profile={profile} pkey={pKey} />],
        ["Calendar", 5, <EventCalendar imKey={data ? data.imKey.toString() : ""} pKey={pKey} />],
    ];

    const nonAdminTabList = fullTablist.filter(
        (x) =>
            x[0] === "Profile" ||
            x[0] === "Attributes" ||
            x[0] === "Bio" ||
            x[0] === "Roles & Clients" ||
            x[0] === "Assignments"
    );

    const tablist = claimsService.IsUserCclAdminOrSuperAdmin() ? fullTablist : nonAdminTabList;

    function showErrorAlert(msg: string | undefined) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <div>
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                        {msg}
                    </Alert>
                </div>
            </StandardLayout>
        );
    }

    const breadcrumbs = (
        <Stack direction={"row"} spacing={3}>
            <SimpleBreadcrumbs>
                <Typography variant="h6" color="text.primary">
                    <Link key="findResourceLink" component={RouterLink} to="/resources">
                        Resources
                    </Link>
                </Typography>
                <Typography variant="h6" color="text.primary">
                    {profile != null ? profile.firstName + " " + profile.lastName : ""}
                </Typography>
            </SimpleBreadcrumbs>
            {allowJournalEntries && <EditNoteIcon onClick={() => setDrawerOpen(true)} />}
        </Stack>
    );

    if (isError) {
        return showErrorAlert(`Error retrieving ${pKey} (${getErrorMsg(error, "")})`);
    }

    if (isLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={""} />
            </StandardLayout>
        );
    }

    return (
        <div ref={detailEl}>
            {allowJournalEntries && (
                <ResourceJournalDrawer
                    pKey={+pKey}
                    imKey={data?.imKey ?? 0}
                    name={`${data?.firstName ?? "Unknown Resource"} ${data?.lastName ?? ""}`}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    onSave={() => setDrawerOpen(false)}
                />
            )}
            <DetailPage
                breadcrumbs={breadcrumbs}
                descriptor={"resources"}
                tablist={tablist}
                activeTab={target ? +target : 0}
                identifier="resources-details"
            ></DetailPage>
        </div>
    );
};

export default ResourceDetail;
