const components = {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
    },

    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 30,
                color: "white",
            },
        },
    },
    MuiLink: {
        defaultProps: {
            underline: "hover",
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: "#105da0",
                    fontWeight: "900",
                },
            },
        },
    },
    MuiCardHeader: {
        defaultProps: {
            titleTypographyProps: {
                variant: "subtitle1",
                fontWeight: "bolder",
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                padding: "4px",
                borderRadius: "6px",
                boxShadow:
                    "0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
                backgroundImage: "none",
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                backgroundImage: "none",
            },
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            day: {
                fontWeight: "300",
            },
        },
    },
    MuiPickersYear: {
        styleOverrides: {
            root: {
                height: "64px",
            },
        },
    },
    MuiPickersCalendar: {
        styleOverrides: {
            transitionContainer: {
                marginTop: "6px",
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            iconButton: {
                backgroundColor: "transparent",
                "& > *": {
                    backgroundColor: "transparent",
                },
            },
            switchHeader: {
                marginTop: "2px",
                marginBottom: "4px",
            },
        },
    },
    MuiPickersClock: {
        styleOverrides: {
            container: {
                margin: `32px 0 4px`,
            },
        },
    },
    MuiPickersClockNumber: {
        styleOverrides: {
            clockNumber: {
                left: `calc(50% - 16px)`,
                width: "32px",
                height: "32px",
            },
        },
    },
    MuiPickerDTHeader: {
        styleOverrides: {
            dateHeader: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
            timeHeader: {
                "& h3": {
                    fontSize: "3rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiPickersTimePicker: {
        styleOverrides: {
            hourMinuteLabel: {
                "& h2": {
                    fontSize: "3.75rem",
                    fontWeight: 300,
                },
            },
        },
    },
    MuiPickersToolbar: {
        styleOverrides: {
            toolbar: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: "6px",
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontSize: "14px",
                fontWeight: 500,
            },
        },
    },
    MuiDataGrid: {
        defaultProps: {
            density: "compact",
        },
    },
    MuiFormHelperText: {
        root: {
            // Use existing space / prevents shifting content below field
            //      marginTop: 0,
            height: 0,
        },
    },
    MuiAccordionSummary: {
        root: {
            fontWeight: "bold",
        },
        content: {
            fontWeight: "bold",
        },
    },
};

export default components;
