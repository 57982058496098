import * as React from "react";
import { Alert, Card, CardHeader, Table, TableBody, TableCell, TableRow } from "@mui/material";

export interface Auth0ConnectionCardProps {
    applicationId: string;
    auth0Connections: string[];
}

export const Auth0ConnectionCard: React.FC<Auth0ConnectionCardProps> = (props) => {
    return (
        <Card variant="outlined" sx={{ height: 1 }}>
            <CardHeader title="Auth0 Connections" />
            {props.auth0Connections && props.auth0Connections.length > 0 ? (
                <Table>
                    <TableBody>
                        {props.auth0Connections.map((conn) => (
                            <TableRow key={conn}>
                                <TableCell>{conn}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Alert severity="info">No Auth0 Connections Found</Alert>
            )}
        </Card>
    );
};

export default Auth0ConnectionCard;
