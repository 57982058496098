import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblMyInfoAdminEditTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "imKey",
        fieldType: "number",
        editable: false,
        label: "ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "fullName",
        fieldType: "string",
        editable: false,
        label: "Name",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "email",
        fieldType: "string",
        editable: false,
        label: "Email",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto: @@@",
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: 'officePhone',
        fieldType: 'string',
        editable: false,
        label: 'Office Phone',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'mobilePhone',
        fieldType: 'string',
        editable: false,
        label: 'Mobile Phone',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'homePhone',
        fieldType: 'string',
        editable: false,
        label: 'Home Phone',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'nationality',
        fieldType: 'string',
        editable: false,
        label: 'Country of Origin',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },{
        id: 'timeZoneId',
        fieldType: 'string',
        editable: false,
        label: 'Timezone',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'address.addressOne',
        fieldType: 'string',
        editable: false,
        label: 'Street Address',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'address.addressTwo',
        fieldType: 'string',
        editable: false,
        label: 'Apartment #',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },{
        id: 'address.city',
        fieldType: 'string',
        editable: false,
        label: 'City',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'address.country',
        fieldType: 'string',
        editable: false,
        label: 'Country',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'address.stateOrProvinceName',
        fieldType: 'string',
        editable: false,
        label: 'State/Province',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'address.postalCode',
        fieldType: 'string',
        editable: false,
        label: 'Postal Code',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    }, {
        id: 'prrKey',
        fieldType: 'string',
        editable: true,
        label: 'Region',
        required: true,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Select,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'prlKey',
        fieldType: 'string',
        editable: true,
        label: 'Reporting Location',
        required: true,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Select,
            url: null,
            hoverText: null,
            checked: null,
            id: 'profile-reporting-location',
            selectOptions: null //TODO: Fix this for drop down option
        }
    }

];