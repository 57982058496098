import React from "react";
import { Box, Drawer, Stack, TextField, Typography } from "@mui/material";
import { ProgramInstrument } from "../../../../../../../services/types/scoringStatusTypes";
import ScoringRequestInstrumentList from "./scoringRequestInstrumentList";
import CclButton from "../../../../../../../components/common/cclButtons/cclButton";

export type ScoringRequestDrawerProps = {
    open: boolean;
    onClose: () => void;
    onScore: (selectedInstrumentIds: number[]) => void;
    selectedParticipantCount: number;
    instruments: ProgramInstrument[];
};

const ScoringRequestDrawer: React.FC<ScoringRequestDrawerProps> = (props) => {
    const [selectedInstruments, setSelectedInstruments] = React.useState<number[]>([]);

    return (
        <>
            <Drawer
                variant="temporary"
                anchor="right"
                open={props.open}
                onClose={props.onClose}
                sx={{
                    display: "block",
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "35%",
                        paddingTop: "75px",
                    },
                }}
            >
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h4" sx={{ p: 2, width: 1 }}>
                            Scoring
                        </Typography>
                        <Stack spacing={10} sx={{ p: 2 }}>
                            <TextField
                                id="participant-count-text-field-readonly"
                                label="Participants"
                                defaultValue={`${props.selectedParticipantCount} participants selected`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                onMouseDown={(event) => {
                                    event.preventDefault();
                                }}
                                sx={{ width: 1 }}
                            />
                            <ScoringRequestInstrumentList
                                onChange={function (selectedInstruments: number[]): void {
                                    setSelectedInstruments(selectedInstruments);
                                }}
                                instruments={props.instruments}
                            />
                            <Stack direction="row" spacing={5}>
                                <CclButton
                                    onClick={props.onClose}
                                    sx={{ p: 2, width: 1 }}
                                    mode={"secondary"}
                                >
                                    Cancel
                                </CclButton>
                                <CclButton
                                    onClick={() => props.onScore(selectedInstruments)}
                                    disabled={selectedInstruments.length === 0}
                                    sx={{ p: 2, width: 1 }}
                                    mode={"primary"}
                                >
                                    Score
                                </CclButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Drawer>
        </>
    );
};

export default ScoringRequestDrawer;
