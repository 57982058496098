import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface sessionParticipantTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: sessionParticipantTableStateState = {
    TableState: JSON.stringify({
        sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
        },
        columns: {
            columnVisibilityModel: {
                projectCode: false,
                imKey: false,
                esiKey: true,
                name: true,
                firstName: false,
                lastName: false,
                emailAddress: true,
                attendance: true,
                organization: true,
                title: true,
                telephone: true,
                address1: false,
                address2: false,
                city: false,
                state: false,
                postalCode: false,
                country: false,
                ceRegistrationId: false,
            },
        },
    }),
};

export const sessionParticipantTableStateSlice = createSlice({
    name: "sessionParticipantTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateSessionsParticipantsGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateSessionsParticipantsGridState } = sessionParticipantTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const sessionParticipantTableState = (state: RootState) => state;
export default sessionParticipantTableStateSlice.reducer;
