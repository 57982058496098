import * as React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Alert, AlertTitle, Link, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../../layouts/standard";
import DetailPage from "../../../components/common/detailPage/detailPage";
import ApplicationUsersPanel from "./users/applicationUsersPanel";
import { useGetCclApplicationQuery } from "../../../services/cclTokenedGrandCentralApi";
import PageLoader from "../../../components/common/pageLoader";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import ApplicationInvitationPanel from "./invitations/applicationInvitationPanel";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import ApplicationRolesPanel from "./roles/applicationRoles";
import ApplicationLoginsPanel from "./logins/applicationLoginsPanel";
import Administration from "./features/administration";

export type ApplicationDetailProps = {
    applicationId: string;
};

const ApplicationDetail: React.FC = (props) => {
    const params = useParams<ApplicationDetailProps>();
    const [activeTab, setActiveTab] = React.useState<number>(0);

    const applicationId = params.applicationId !== undefined ? params.applicationId : "";
    const {
        data: application,
        isError,
        error,
        isLoading,
    } = useGetCclApplicationQuery(applicationId);
    const tablist: [string, number, React.ReactNode][] = [
        ["Users", 0, <ApplicationUsersPanel application={application} />],
        ["Invitations", 1, <ApplicationInvitationPanel application={application} />],
        ["Logins", 2, <ApplicationLoginsPanel application={application} />],
    ];

    const tablistwithroles: [string, number, React.ReactNode][] = [
        ["Users", 0, <ApplicationUsersPanel application={application} />],
        ["Invitations", 1, <ApplicationInvitationPanel application={application} />],
        ["Logins", 2, <ApplicationLoginsPanel application={application} />],
        ["Roles", 3, <ApplicationRolesPanel />],
        ["Features", 4, <Administration />],
    ];

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
        setActiveTab(newValue);
    };

    function showErrorAlert(msg: string | undefined) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <div>
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                        {msg}
                    </Alert>
                </div>
            </StandardLayout>
        );
    }

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                <Link key="findApplicationLink" component={RouterLink} to="/applications">
                    Applications
                </Link>
            </Typography>
            <Typography variant="h6" color="text.primary">
                {application ? application.name : ""}
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (isError) {
        return showErrorAlert(`Error retrieving ${applicationId} (${getErrorMsg(error)})`);
    }

    if (isLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={""} />
            </StandardLayout>
        );
    }
    const claimservice = new UserClaimsService();
    let tabs: [string, number, React.ReactNode][];

    if (
        applicationId === "991a0c0d-eafc-4307-8776-048f8e3e8c2d" &&
        claimservice.IsUserSuperAdmin()
    ) {
        tabs = tablistwithroles;
    } else {
        tabs = tablist;
    }

    return (
        <DetailPage
            descriptor={"application"}
            breadcrumbs={breadcrumbs}
            tablist={tabs}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
        />
    );
};

export default ApplicationDetail;
