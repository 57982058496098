import React from "react";
import { Stack, CircularProgress } from "@mui/material";

export interface ComponentLoaderProps {
    msg: string;
}

export const ComponentLoader: React.FC<ComponentLoaderProps> = (props) => {
    return (
        <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", width: "100%" }}
        >
            <CircularProgress color="inherit" />
            {/* <Typography align="center" variant="h6" component="div">
            {props.msg}
        </Typography>            */}
        </Stack>
    );
};

export default ComponentLoader;
