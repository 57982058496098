import { Box, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";

interface StyledDropzoneProps {
    onAddFile: (acceptedFiles: File[]) => void;
    multiple?: boolean;
}

const StyledDropzone: React.FC<StyledDropzoneProps> = (props) => {
    const maxSize = 1048576 * 10;

    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            props.onAddFile([...acceptedFiles]);
        },
        [props]
    );

    const { isDragAccept, isFocused, getRootProps, getInputProps, isDragReject } = useDropzone({
        onDrop,
        accept: { "text/csv": [".csv"] },
        minSize: 0,
        maxSize,
        multiple: props.multiple ?? true,
        maxFiles: 1,
        disabled: false,
    });

    const getBoarderColor = (): string => {
        if (isFocused) return "darkblue";
        else if (isDragAccept) return "darkgreen";
        else if (isDragReject) return "darkred";
        return "gray";
    };

    const getBackgroundColor = (): string => {
        if (isFocused) return "aliceblue";
        else if (isDragAccept) return "honeydew";
        else if (isDragReject) return "mistyrose";
        return "lightgray";
    };

    return (
        <Box
            className="container"
            sx={{
                width: 1,
                borderColor: getBoarderColor(),
                backgroundColor: getBackgroundColor(),
                p: 8,
                borderStyle: "dashed",
                borderWidth: 2,
                borderRadius: 3,
                "&:hover": {
                    border: "2px dashed darkblue",
                    color: "black",
                    backgroundColor: "aliceblue",
                },
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Typography align="center" variant="h6">
                        Drop recipient list CSV file here or click to ADD.
                    </Typography>
                    <Typography align="center" variant="body2">
                        Only recognized CSV files are allowed.
                    </Typography>
                    <Typography align="center" variant="body2">
                        Click link below to download a template, then edit in Excel.
                    </Typography>
                </Box>
            </div>
        </Box>
    );
};

export default StyledDropzone;
