import { Box } from "@mui/material";
import * as React from "react";
import UserApplicationsPanel from "../../../users/userDetail/applications/userApplicationsPanel";
import { useGetDemographicsByProfileKeyQuery } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { Profile } from "../../../../services/types/enterpriseCoachingApiTypes";

interface PartnerApplicationsProps {
    enterpriseData: any;
    email: string;
}

const PartnerApplications: React.FC<PartnerApplicationsProps> = (props) => {
    const claimsService = new UserClaimsService();
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const { data } = useGetDemographicsByProfileKeyQuery(claimsService.GetCurrentResourceId());
    const emptyProfile = {} as Profile;
    const profile = data == null ? emptyProfile : data;

    const enterpriseDetail = props.enterpriseData?.find(
        //@ts-ignore
        (e) => e.email.toLowerCase() === props.email.toLowerCase()
    );

    React.useEffect(() => {
        if (!enterpriseDetail) {
            return;
        }
        setFirstName(enterpriseDetail.firstName);
        setLastName(enterpriseDetail.lastName);
    }, [enterpriseDetail]);
    return (
        <Box sx={{ width: 1, height: 1 }}>
            <UserApplicationsPanel
                emailAddress={props.email}
                firstName={firstName}
                lastName={lastName}
                imKey={enterpriseDetail.imKey.toString()}
                profile={profile}
            />
        </Box>
    );
};

export default PartnerApplications;
