import * as React from "react";
import {
    useGetProductSaleParticipantsByFacilitatorEmailQuery,
    useLazyGetParticipantCompassUrlQuery,
    useReleaseProductSalesMutation,
} from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import FlexGrid from "../../../../../layouts/flexGrid";
import { Alert, AlertColor, AlertTitle, Stack } from "@mui/material";
import ResourceProductSalesListDataGridPro from "./resourceProductSalesList";
import { getErrorMsg } from "../../../../../utilities/rtkQueryErrorHelpers";
import { useSendCompassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import {
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../../../../services/types/rtkQueryTypes";
import {
    GetCompassUrlResponse,
    ProductSaleParticipant,
} from "../../../../../services/types/enterpriseParticipantApiTypes";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";
import CclDownloadWarningAlert from "../../../../../components/common/cclDownloadWarningAlert";

interface ResourceProductSalesPanelProps {
    email: string;
}

const ResourceProductSalesPanel: React.FC<ResourceProductSalesPanelProps> = (props) => {
    const [currentEvent, setCurrentEvent] = React.useState<string>("");
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [releaseReports /*, { data: releaseResults, isSuccess: releaseSuccess }*/] =
        useReleaseProductSalesMutation();
    const {
        data: productSales,
        isLoading,
        isError,
        error,
    } = useGetProductSaleParticipantsByFacilitatorEmailQuery(props.email, {
        skip: !props.email,
    });
    const { logEvent } = useLogAccessEvent();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();
    const downloadService = new FileDownloadService();
    const claimsService = new UserClaimsService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleEvents = (evt: string, ids: string[]) => {
        switch (evt) {
            case "download":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement("Download Not Possible", "info");
                    return;
                }

                setCurrentEvent(evt);
                let filename = "ProductSales.zip";
                if (ids.length === 1) {
                    const ps = productSales?.find((p) => p.reportId === ids[0]);
                    if (ps == null) {
                        // shouldn't happen but just in case
                        ShowSnackbarElement("Download Not Possible", "info");
                        return;
                    }
                    filename = `${ps.fullName} ${ps.assessmentType}.pdf`;
                }

                downloadService
                    .DownloadProductSaleReports({ fname: filename, reportids: ids })
                    .then(() => {
                        logEvent("UserDownloadedProductSale", null);
                        setCurrentEvent("");
                        ShowSnackbarElement("Download Completed", "success");
                    })
                    .catch((error) => {
                        setCurrentEvent("");
                        ShowSnackbarElement("Download Failed", "error");
                    });
                break;
            case "activateCompass":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement("Compass Activation Not Possible", "info");
                    return;
                }

                setCurrentEvent(evt);
                releaseReports(ids)
                    .unwrap()
                    .then((releaseResults) => {
                        if (releaseResults === undefined || releaseResults.length <= 0) {
                            setCurrentEvent("");
                            ShowSnackbarElement("Compass Activation Not Possible", "info");
                            return;
                        }

                        const releasedReportIds: string[] =
                            releaseResults?.filter((r) => r.success).map((r) => r.reportId) ?? [];

                        if (releasedReportIds != null && releasedReportIds.length > 0) {
                            const prodSaleParticipants: ProductSaleParticipant[] =
                                productSales?.filter((ps) =>
                                    releasedReportIds.some((r) => r === ps.reportId)
                                ) ?? [];
                            const sendInvitesPayload: SendCompassInvitationRequest = {
                                recipients: prodSaleParticipants.map((p) => {
                                    return {
                                        email: p.emailAddress,
                                        firstName: p.firstName,
                                        lastName: p.lastName,
                                    };
                                }),
                                disableEmail: false,
                                autoAccept: false,
                                ignorePendingInvites: false,
                            };
                            sendCompassInvitations(sendInvitesPayload)
                                .unwrap()
                                .then((response: SendInvitationResponse) => {
                                    setCurrentEvent("");
                                    if (
                                        response.numberFailed > 0 ||
                                        response.numberInivitationsSent === 0
                                    ) {
                                        ShowSnackbarElement("Compass Activation Failed", "error");
                                    } else {
                                        ShowSnackbarElement(
                                            "Compass Activation Completed",
                                            "success"
                                        );
                                    }
                                    if (response.numberInivitationsSent > 0) {
                                        logEvent("UserActivatedProductSales", null);
                                    }
                                })
                                .catch((error) => {
                                    setCurrentEvent("");
                                    ShowSnackbarElement(
                                        "Compass Released, Invitations Failed",
                                        "error"
                                    );
                                });
                        } else {
                            setCurrentEvent("");
                            ShowSnackbarElement("Compass Activation Failed", "error");
                        }
                    })
                    .catch((error) => {
                        setCurrentEvent("");
                        ShowSnackbarElement("Compass Activation Failed", "error");
                    });
                break;
            default:
                break;
        }
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch(() => {
                ShowSnackbarElement("View Compass Failed", "error");
            });
    };

    if (isError) {
        let msg = getErrorMsg(error);
        console.log(msg);
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    return (
        <Stack spacing={3} sx={{ width: 1 }}>
            <CclDownloadWarningAlert />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <FlexGrid>
                <ResourceProductSalesListDataGridPro
                    isLoading={isLoading}
                    productSales={productSales ?? []}
                    handleEvent={handleEvents}
                    viewCompass={viewCompass}
                    isUsersData={
                        props.email.toLowerCase() ===
                        claimsService.GetCurrentUserEmail().toLowerCase()
                    }
                    currentEvent={currentEvent}
                />
            </FlexGrid>
        </Stack>
    );
};

export default ResourceProductSalesPanel;
