import { add, startOfToday } from "date-fns";

export function getDateAdjusted(offsetDays: number): Date {
    return add(startOfToday(), {
        days: offsetDays,
    });
}

export function getDateStringAdjusted(offsetDays: number): string {
    var dt = add(startOfToday(), {
        days: offsetDays,
    });

    return dt.toISOString();
}

export function getUtcDateStringWithoutOffset(dt: Date): string {
    var month = (dt.getMonth() + 1).toString().padStart(2, "0");
    var day = dt.getDate().toString().padStart(2, "0");
    var dtUtc = `${dt.getFullYear()}-${month}-${day}T00:00:00.000Z`;
    return dtUtc;
}
