import * as React from "react";
import {
    Stack,
    FormControl,
    Box,
    Typography,
    Card,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import ContractCardHeader from "./sharedComponents/cardHeader";
import { ContractCardContent, InterestsContractData } from "./signContractStepper";
import {
    SelectOption,
    getOptionFromVal,
} from "../../../../../../components/common/selectOptions/selectOption";
import CclButton from "../../../../../../components/common/cclButtons/cclButton";

const integratedOptions: SelectOption[] = [
    { val: "1", option: "Open Enrollment Programs" },
    { val: "2", option: "Custom Programs" },
    { val: "3", option: "Both" },
    { val: "4", option: "Neither" },
];

const executiveOptions: SelectOption[] = [
    { val: "1", option: "Interested in developing the skill set" },
    { val: "2", option: "Yes, I'm already in the pool" },
    { val: "3", option: "No" },
];

export interface InterestsStepPanelProps {
    pkey: number;
    interestData: InterestsContractData;
    onNextPanel: (nextStep: number, newdata: InterestsContractData) => void;
    onPreviousPanel: () => void;
    activeStep: number;
    nextStep: number;
}

export const InterestsStepPanel: React.FC<InterestsStepPanelProps> = (props) => {
    const [selectedIntegrated, setSelectedIntegrated] = React.useState<SelectOption | null>({
        val: "-1",
        option: "Nothing Selected",
    } as SelectOption);
    const [selectedExecutive, setSelectedExecutive] = React.useState<SelectOption | null>({
        val: "-1",
        option: "Nothing Selected",
    } as SelectOption);

    React.useEffect(() => {
        if (props.nextStep !== -1) handleNext(props.nextStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nextStep]);

    React.useEffect(() => {
        if (props.interestData === undefined) return;
        if (props.interestData?.executiveCoachingId) {
            const selopt = executiveOptions.find(
                (eo) => +eo.val === props.interestData?.executiveCoachingId
            );
            if (selopt) setSelectedExecutive(selopt);
        }
        if (props.interestData?.integratedCoachingId) {
            const selopt = integratedOptions.find(
                (io) => +io.val === props.interestData?.integratedCoachingId
            );
            if (selopt) setSelectedIntegrated(selopt);
        }
    }, [props.interestData]);

    const handleIntegratedChange = (event: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: event.target.value,
            option: getOptionFromVal(event.target.value, integratedOptions) ?? "Unknown Option",
        };
        setSelectedIntegrated(newopt);
    };

    const handleExecutiveChange = (event: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: event.target.value,
            option: getOptionFromVal(event.target.value, executiveOptions) ?? "Unknown Option",
        };
        setSelectedExecutive(newopt);
    };

    const handleNext = (nxtStep?: number) => {
        let ns = nxtStep !== undefined ? nxtStep : props.activeStep + 1;
        let newdata: InterestsContractData = {
            integratedCoachingId:
                selectedIntegrated?.val !== undefined ? +selectedIntegrated.val : undefined,
            executiveCoachingId:
                selectedExecutive?.val !== undefined ? +selectedExecutive.val : undefined,
        };
        props.onNextPanel(ns, newdata);
    };

    return (
        <Stack direction={"column"}>
            <Box
                component="form"
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <Typography variant="h5">
                        Please provide your work interests but note this does not guarantee work in
                        any specific line of business (only applicable for coaches).
                    </Typography>
                    <Typography variant="body2" sx={{ pb: 3, color: "text.secondary" }}>
                        Closing the screen will stop the Sign Contract process but you will be able
                        to restart it again.
                    </Typography>
                    <Stack direction={"row"} width={1} spacing={3}>
                        <Card sx={{ width: 0.5 }}>
                            <ContractCardContent>
                                <ContractCardHeader
                                    text={"Integrated Coaching"}
                                    showRequired={false}
                                    showInfoIcon={false}
                                />
                                <Typography variant="body2" sx={{ pb: 4 }}>
                                    Are you interested in Integrated Coaching?
                                </Typography>
                                <FormControl sx={{ width: 1 }}>
                                    <InputLabel id="select-integrated-coaching-label">
                                        Select Option
                                    </InputLabel>
                                    <Select
                                        labelId="select-integrated-coaching-label"
                                        label="Select Option"
                                        id="select-integrated-coaching"
                                        value={
                                            selectedIntegrated?.val == null ||
                                            selectedIntegrated.val === "-1"
                                                ? ""
                                                : selectedIntegrated.val
                                        }
                                        onChange={handleIntegratedChange}
                                        size="small"
                                    >
                                        {integratedOptions &&
                                            integratedOptions.length > 0 &&
                                            integratedOptions.map((opt, i) => {
                                                return (
                                                    <MenuItem
                                                        key={opt.val}
                                                        value={opt.val}
                                                        selected={
                                                            opt.val === selectedIntegrated?.val
                                                        }
                                                    >
                                                        {opt.option}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </ContractCardContent>
                        </Card>
                        <Card sx={{ width: 0.5 }}>
                            <ContractCardContent>
                                <ContractCardHeader
                                    text={"Executive Coaching"}
                                    showRequired={false}
                                    showInfoIcon={false}
                                />
                                <Typography variant="body2" sx={{ pb: 4 }}>
                                    Are you interested in Executive Coaching?
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="select-executive-coaching-label">
                                        Select Option
                                    </InputLabel>
                                    <Select
                                        labelId="select-executive-coaching-label"
                                        id="select-executive-coaching"
                                        value={
                                            selectedExecutive?.val == null ||
                                            selectedExecutive.val === "-1"
                                                ? ""
                                                : selectedExecutive.val
                                        }
                                        label="Select Option"
                                        onChange={handleExecutiveChange}
                                        size="small"
                                    >
                                        {executiveOptions &&
                                            executiveOptions.length > 0 &&
                                            executiveOptions.map((opt, i) => {
                                                return (
                                                    <MenuItem
                                                        key={opt.val}
                                                        value={opt.val}
                                                        selected={
                                                            opt.val === selectedExecutive?.val
                                                        }
                                                    >
                                                        {opt.option}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </ContractCardContent>
                        </Card>
                    </Stack>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclButton
                    onClick={() => props.onPreviousPanel()}
                    mode={"secondary"}
                    sx={{ mr: 1 }}
                >
                    Back
                </CclButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton onClick={() => handleNext()} mode={"primary"}>
                    Next
                </CclButton>
            </Box>
        </Stack>
    );
};

export default InterestsStepPanel;
