import * as React from "react";
import { Alert, AlertColor, Box, CardActions } from "@mui/material";
import CclInfoTable from "../../../../components/common/cclInfoTable/cclInfoTable";
import { UserAuth0Detail } from "../../../../services/types/userPreferenceTypes";
import { CclInfoTblUserAuth0GeneralInfoTemplate } from "./CclInfoTblUserAuth0GeneralInfoTemplate";
import { useDeleteAuth0AccountMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { DeleteAuth0AccountRequest } from "../../../../services/types/rtkQueryTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

export type Auth0DetailsCardProps = {
    gcAccountEmail: string;
    auth0Detail: UserAuth0Detail | null;
    isUserAdmin: boolean;
    imKey: string;
};

const Auth0DetailsCard: React.FC<Auth0DetailsCardProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const { logEvent } = useLogAccessEvent();
    const [DeleteAuth0AccountMutation, { isLoading }] = useDeleteAuth0AccountMutation();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const DeleteAuth0 = () => {
        const Request: DeleteAuth0AccountRequest = {
            email: props.gcAccountEmail,
            auth0Id: props.auth0Detail?.accounts.userId ?? "0",
        };
        DeleteAuth0AccountMutation(Request)
            .then(() => {
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.imKey,
                    email: props.gcAccountEmail,
                };

                logEvent("UserAuth0AccountDeleted", evtData);
                ShowSnackbarElement("Auth0 Account Deleted", "success");
            })
            .catch(() => {
                ShowSnackbarElement("Auth0 Account Deletion Failed", "error");
            });
    };

    return (
        <CclGenericAccordian headingText={"Auth0"} sx={{ height: 1 }}>
            <Box gap={2} sx={{ m: 2, border: 1, borderColor: "#EEEEEE", borderRadius: 3 }}>
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                {props.auth0Detail?.accounts != null ? (
                    <CclInfoTable
                        template={CclInfoTblUserAuth0GeneralInfoTemplate}
                        data={props.auth0Detail.accounts}
                    />
                ) : (
                    <Alert severity="warning">
                        No Auth0 account data found for {props.gcAccountEmail}. This may be ok if
                        the user has yet to login to any applications.
                    </Alert>
                )}
                {props.isUserAdmin && props.auth0Detail?.accounts != null ? (
                    <CardActions disableSpacing sx={{ pt: 2, justifyContent: "flex-end" }}>
                        <CclLoadingButton
                            size={"small"}
                            onClick={DeleteAuth0}
                            mode={"primary"}
                            loading={isLoading}
                        >
                            Delete Auth0 Account
                        </CclLoadingButton>
                    </CardActions>
                ) : null}
            </Box>
        </CclGenericAccordian>
    );
};

export default Auth0DetailsCard;
