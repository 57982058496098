import * as React from "react";
import { Box, Card, CardHeader, List, Stack } from "@mui/material";
import { PersonInfo } from "../../../../../models/personInfo";
import { CnxParticipant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import CnxParticipantListItem from "./cnxParticipantListItem";

interface CnxParticipantListPanelProps {
    participants: CnxParticipant[];
    selectedParticipant: CnxParticipant | null;
    onSelectParticipant: (participant: CnxParticipant) => void;
    assignedCoaches: SessionDetailAssignedCoach[];
}

const CnxParticipantListPanel: React.FC<CnxParticipantListPanelProps> = (props) => {
    const paxFilter = "";
    const [filteredParticipants, setFilteredParticipants] = React.useState<CnxParticipant[]>([]);

    React.useEffect(() => {
        if (props.participants == null || props.participants.length <= 0)
            setFilteredParticipants([]);
        else {
            let filteredlist =
                paxFilter.length < 3
                    ? [...props.participants]
                    : props.participants.filter(
                          (p) =>
                              p.firstName.toLowerCase().includes(paxFilter) ||
                              p.lastName.toLowerCase().includes(paxFilter)
                      );
            filteredlist.sort((a, b) => {
                return (
                    a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
                );
            });
            setFilteredParticipants([...filteredlist]);
        }
    }, [paxFilter, props.participants, props.assignedCoaches]);

    const onSelect = (selected: CnxParticipant) => {
        props.onSelectParticipant(selected);
    };

    const getAssignedCoach = (imKey: number) => {
        let coach: PersonInfo | null = props.assignedCoaches
            ?.filter((c) => !c.isProposed && c.paxImKey === imKey)
            .map((fc) => {
                return { firstName: fc.firstName, lastName: fc.lastName, imKey: fc.imKey };
            })[0];
        return coach;
    };

    return (
        <Card sx={{ width: 0.5, height: 1 }}>
            <CardHeader title={"Participant List"} />
            <Stack spacing={2} width={1} sx={{ p: 2 }}>
                <Box>
                    <List dense={true} sx={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
                        {filteredParticipants.length > 0 &&
                            filteredParticipants.map((pax) => (
                                <CnxParticipantListItem
                                    key={pax.esiKey}
                                    participant={pax}
                                    selected={props.selectedParticipant?.esiKey === pax.esiKey}
                                    onSelect={onSelect}
                                    currentAssignedCoach={getAssignedCoach(pax.imKey)}
                                />
                            ))}
                    </List>
                </Box>
            </Stack>
        </Card>
    );
};

export default CnxParticipantListPanel;
