import * as React from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    TextField,
} from "@mui/material";
import { useAddCclTenantMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../../../utilities/generalUtilities";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export interface AddTenantDialogProps {
    open: boolean;
    systemId: string;
    onClose: () => void;
}

export const AddTenantDialog: React.FC<AddTenantDialogProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [newTenantName, setNewTenantName] = React.useState<string>("");
    const [newTenantIsPrivate, setNewTenantIsPrivate] = React.useState<boolean>(false);
    const [addTenant] = useAddCclTenantMutation();

    const showError = (msg: string) => {
        setErrorMessage("Failed to add tenant");
        setShowErrorDialog(true);
    };

    const onAddTenant = () => {
        addTenant({
            name: newTenantName,
            tenantId: generateGUID(),
            isPrivate: newTenantIsPrivate,
            systemId: props.systemId,
        })
            .unwrap()
            .then((res: boolean) => {
                console.log("Add Tenant result: ", res ? "Success" : "Failed");
                if (!res) {
                    showError("Failed to add tenant");
                }
                props.onClose();
            })
            .catch((err: { data: any }) => {
                showError(`Failed to add tenant: ${err.data}`);
                props.onClose();
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title="Error Adding Tenant"
            />
            <Dialog
                open={props.open}
                onClose={() => props.onClose()}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>Add Tenant</DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <Stack spacing={3}>
                        <TextField
                            required
                            id="new-tenant-name"
                            label="New Tenant Name"
                            variant="standard"
                            value={newTenantName}
                            onChange={(e) => setNewTenantName(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={newTenantIsPrivate}
                                    onChange={() => setNewTenantIsPrivate(!newTenantIsPrivate)}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            }
                            label="Is Private"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <CclButton onClick={props.onClose} mode={"secondary"}>
                        Cancel
                    </CclButton>
                    <CclButton
                        onClick={() => onAddTenant()}
                        disabled={newTenantName === ""}
                        mode={"primary"}
                    >
                        Add Tenant
                    </CclButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddTenantDialog;
