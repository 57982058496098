import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Alert, AlertColor, AlertTitle } from "@mui/material";
import CclButton from "./cclButtons/cclButton";

export type CclAlertDialogProps = {
    open: boolean;
    title?: string | undefined;
    msg?: string | undefined;
    onOk: () => void;
    severity: AlertColor;
};

const CclAlertDialog: React.FC<CclAlertDialogProps> = (props) => {
    const defaultTitle: string = "Alert";
    const defaultMsg: string = "Alert!";
    const [title, setTitle] = React.useState<string>(defaultTitle);
    const [message, setMessage] = React.useState<string>(defaultMsg);

    React.useEffect(() => {
        if (props.title == null || props.title === "") {
            switch (props.severity) {
                case "warning":
                    setTitle("Warning");
                    break;
                case "error":
                    setTitle("Error");
                    break;
                case "info":
                    setTitle("Information");
                    break;
                case "success":
                    setTitle("Success");
                    break;
            }
        } else {
            setTitle(props.title);
        }
    }, [props.title, props.severity]);

    React.useEffect(() => {
        if (props.msg == null || props.msg === "") {
            setMessage(defaultMsg);
        } else {
            setMessage(props.msg);
        }
    }, [props.msg]);

    const handleClose = (evt: any) => {
        if (props.onOk) {
            props.onOk();
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Alert severity={props.severity}>
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
            </DialogContent>
            <DialogActions>
                <CclButton id="ok-btn" onClick={handleClose} mode={"primary"}>
                    Okay
                </CclButton>
            </DialogActions>
        </Dialog>
    );
};

export default CclAlertDialog;
