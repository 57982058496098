import * as React from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { CnxParticipant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { PersonInfo } from "../../../../../models/personInfo";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";

interface CnxParticipantListItemProps {
    participant: CnxParticipant;
    selected: boolean;
    onSelect: (participant: CnxParticipant) => void;
    currentAssignedCoach: PersonInfo | null;
}

const CnxParticipantListItem: React.FC<CnxParticipantListItemProps> = (props) => {
    const claimsService = new UserClaimsService();

    const getSecondaryText = (): string => {
        if (props.participant.cnxRegistration == null) {
            return "Error: CNX registration not found.";
        }
        if (props.participant.cnxRegistration.coachingTypeId !== 1) return "";

        if (props.participant.coachingStage === 1 && props.participant.stageProgress !== 2)
            return "Note: Intake survey not complete.";

        let coaches = [...props.participant?.cnxRegistration?.coacheeSelectedProposedCoaches];
        if (coaches == null || coaches.length < 1) return "";

        coaches.sort((a, b) => {
            return a.preferenceOrder < b.preferenceOrder
                ? -1
                : a.preferenceOrder > b.preferenceOrder
                ? 1
                : 0;
        });
        if (props.participant.coachingStage === 2) {
            if (props.participant.stageProgress === 0)
                return (
                    "Not Prioritized: " +
                    coaches
                        .filter((c) => c != null)
                        .map((c) => `${c.firstName} ${c.lastName}`)
                        .join(", ")
                );
            else
                return (
                    "Prioritized: " +
                    coaches
                        .filter((c) => c != null)
                        .map((c) => `${c.preferenceOrder}. ${c.firstName} ${c.lastName}`)
                        .join(", ")
                );
        }

        if (props.participant.coachingStage > 2 && props.currentAssignedCoach != null)
            return `Assigned Coach: ${props.currentAssignedCoach.firstName} ${props.currentAssignedCoach.lastName}`;

        return "";
    };

    return (
        <ListItem key={props.participant.esiKey} sx={{ p: 0 }}>
            <ListItemButton
                disabled={claimsService.IsReadOnly()}
                selected={props.selected}
                sx={{ p: 2 }}
                onClick={() => props.onSelect(props.participant)}
            >
                <ListItemText
                    primary={`${props.participant.firstName} ${props.participant.lastName}`}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondary={getSecondaryText()}
                    secondaryTypographyProps={{ variant: "caption" }}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default CnxParticipantListItem;
