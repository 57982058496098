import { FormControl, Autocomplete, TextField } from "@mui/material";
import React from "react";

interface KeyValue {
    id: string;
    label: string;
}

interface AdvancedSearchSingleSelectProps {
    dataList: KeyValue[];
    label: string;
    id: string;
    handleSelectionChanged: (ids: number[]) => void;
}

const AdvancedSearchSingleSelect: React.FC<AdvancedSearchSingleSelectProps> = (props) => {
    const onTagsChange = (event: React.SyntheticEvent, value: KeyValue | null) => {
        if (value) {
            var a = [Number(value.id)];
            props.handleSelectionChanged(a);
        } else {
            props.handleSelectionChanged([]);
        }
    };

    const selectId = "select-" + props.id;

    return (
        <FormControl fullWidth>
            <Autocomplete
                size="small"
                id={selectId}
                options={props.dataList}
                getOptionLabel={(option) => option.label}
                onChange={onTagsChange}
                renderInput={(params) => (
                    <TextField {...params} label={props.label} placeholder={props.label} />
                )}
            />
        </FormControl>
    );
};

export default AdvancedSearchSingleSelect;
