import { Checkbox } from "@mui/material";
import * as React from "react";

export interface CclInfoTableEditCheckboxProps {
    value: boolean | null;
    onChange: (newValue: any, required: boolean) => void;
    required: boolean;
    readonly: boolean;
}

const CclInfoTableEditCheckbox: React.FC<CclInfoTableEditCheckboxProps> = (props) => {
    const [checked, setChecked] = React.useState<boolean>(props?.value ?? false);

    React.useEffect(() => {
        setChecked(props?.value ?? false);
    }, [props.value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setChecked(checked);
        props.onChange(checked, props.required);
    };

    return <Checkbox disabled={props.readonly} checked={checked} onChange={handleChange} />;
};

export default CclInfoTableEditCheckbox;
