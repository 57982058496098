import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { updateNotificationsGridState } from "../../app/slices/notificationListTableStateSlice";
import { CclPersistentDataGrid } from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { NotificationDocument } from "../../services/types/search.service.types";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../components/common/cclCommonLink";
import { useNavigate } from "react-router-dom";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";

const COLUMNS = [
    {
        field: "recipient",
        headerName: "Recipient",
        flex: 0.4,
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`mailto:${params.value}`}
                type={"email"}
            />
        ),
        type: "string",
    },
    {
        field: "subject",
        headerName: "Subject",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/email/${params.row.id}`}
            />
        ),
        type: "string",
        flex: 1,
    },
    {
        field: "createdUtc",
        headerName: "Created",
        valueGetter: (params: any) => new Date(params.row.createdUtc),
        type: "date",
        renderCell: (params: GridRenderCellParams) => <span>{params.value.toLocaleString()}</span>,
        flex: 0.3,
    },
    {
        field: "sender",
        headerName: "Sender",
        type: "string",
        flex: 0.4,
    },
    {
        field: "templateTrackingId",
        headerName: "Template",
        type: "string",
        flex: 0.3,
    },
];

export interface NotificationsListProps {
    notifications: NotificationDocument[] | undefined;
    isLoading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
}

export const NotificationsDataGridPro: React.FC<NotificationsListProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.notificationListTableState);
    const [notifications, setNotifications] = React.useState<NotificationDocument[]>(
        props.notifications == null ? [] : props.notifications
    );

    let navigate = useNavigate();
    let claimservice = new UserClaimsService();

    React.useLayoutEffect(() => {
        let newnotifications = props.notifications == null ? [] : props.notifications;
        setNotifications(newnotifications);
    }, [props.notifications]);

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={notifications}
            loading={props.isLoading}
            stateSelector={stateSelector}
            userPreferenceId={3}
            getRowIdFn={(row: NotificationDocument) => row.id}
            actionCreator={updateNotificationsGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "createdUtc", sort: "desc" }] },
            })}
            miscButtons={
                claimservice.IsUserInAppRole(8) || claimservice.IsUserSuperAdmin()
                    ? [
                          {
                              id: "manage-templates",
                              caption: "View Templates",
                              onClick: () => {
                                  navigate("/emails/templates");
                              },
                              returnSelected: true,
                              restricted: false,
                              disable: false,
                          },
                      ]
                    : []
            }
            resultBar={props.showResultBar ?? false}
            resultBarText={props.resultBarText ?? ""}
            hideFooter={false}
        />
    );
};

export default NotificationsDataGridPro;
