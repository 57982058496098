import CloseIcon from "@mui/icons-material/Close";
import {
    Alert,
    AlertColor,
    AppBar,
    Box,
    Dialog,
    Grid,
    IconButton,
    Select,
    SelectChangeEvent,
    Slide,
    Stack,
    Toolbar,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
    DataGridPro,
    GridColDef,
    GridColumns,
    GridRenderCellParams,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import * as React from "react";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import PageLoader from "../../../../components/common/pageLoader";
import FlexGrid from "../../../../layouts/flexGrid";
import {
    useAssociateTemplateMutation,
    useCurrentParticipantsQuery,
    useCurrentTemplatesQuery,
    useCustomPropertiesQuery,
    useDownloadZipArchiveMutation,
    useDynamicParticipantsQuery,
    useGenerateDocumentsMutation,
    useParticipantsPayloadQuery,
    useSaveParticipantEditsMutation,
    useTemplatesChangedMutation,
} from "../../../../services/cclTokenedGenerateDocumentsApi";
import {
    AssociateTemplateQueryArgs,
    CurrentParticipantsQueryArgs,
    CurrentTemplatesQueryArgs,
    DownloadZipArchiveQueryArgs,
    GenerateDocumentsQueryArgs,
    ParticipantDataModel,
    ParticipantsQueryArgs,
    SaveParticipantEditsQueryArgs,
    TemplateFiles,
} from "../../../../services/types/generateDocumentsApiTypes";
import TemplateTreeItem from "./templateTreeItem";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import CustomPropertiesPanel from "./customPropertiesPanel";
import GenerateDocumentsGridPro from "./generateDocumentsGridPro";
import ParticipantDataGridFooter from "./participantDataGridFooter";
import SelectedTemplatesList from "./selectedTemplatesList";
import TemplateParticipantAssignmentPanel from "./templateParticipantAssignmentPanel";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import ManageTemplatesPanel from "./manageTemplatesPanel";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

interface SessionDocumentsPanelProps {
    sessionId: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SessionDocumentsPanel: React.FC<SessionDocumentsPanelProps> = (props) => {
    const apiRef = useGridApiRef();
    const claimservice = new UserClaimsService();
    const [participantGridData, setParticipantGridData] = React.useState<object[]>([]);
    const [sessionCode, setSessionCode] = React.useState<string>("");

    const { data: sessionDetail } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });

    React.useEffect(() => {
        if (sessionDetail == null) return;
        setSessionCode(sessionDetail.sessionCode);
    }, [sessionDetail]);

    function SelectEditInputCell(props: GridRenderCellParams) {
        const { id, value, field } = props;
        //const apiRef = useGridApiContext();

        const handleChange = async (event: SelectChangeEvent) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        let customProperty = customPropertiesData?.filter(
            (x: { propertyName: string; propertyValueType: string }) =>
                x.propertyName === field && x.propertyValueType === "Choice"
        );
        let options: string[] = [""];

        if (customProperty && customProperty.length > 0) {
            options = options.concat(customProperty[0].validValues.split("\n"));
        }

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{ height: 1 }}
                native
                autoFocus
            >
                {options.map((option) => {
                    return <option>{option}</option>;
                })}
            </Select>
        );
    }

    const renderSelectEditInputCell: GridColDef["renderCell"] = (params) => {
        return <SelectEditInputCell {...params} />;
    };

    const participantsQueryArgs: ParticipantsQueryArgs = {
        esKey: Number(props.sessionId),
        emailAddress: "cclaccess@ccl.org",
    };

    const currentParticipantsQueryArgs: CurrentParticipantsQueryArgs = {
        id: Number(props.sessionId),
    };

    const currentTemplatesQueryArgs: CurrentTemplatesQueryArgs = {
        esKey: Number(props.sessionId),
    };

    const { data, isLoading, isError } = useParticipantsPayloadQuery(participantsQueryArgs);
    const { data: dynamicParticipantData, error: dynamicParticipantsError } =
        useDynamicParticipantsQuery(participantsQueryArgs);
    const {
        data: allParticipantsData,
        isLoading: allParticipantsLoading,
        error: allParticipantsError,
    } = useCurrentParticipantsQuery(currentParticipantsQueryArgs);
    const { data: currentTemplatesData, error: currentTemplatesError } =
        useCurrentTemplatesQuery(currentTemplatesQueryArgs);
    const [
        generateDocuments,
        {
            data: generateDocumentsData,
            isLoading: generateDocumentsIsLoading,
            isError: generateDocumentsIsError,
            isSuccess: generateDocumentsIsSuccess,
        },
    ] = useGenerateDocumentsMutation();
    const [associateTemplate, { isLoading: associateTemplateIsLoading }] =
        useAssociateTemplateMutation();
    const [manageTemplates] = useTemplatesChangedMutation();

    const [
        downloadDocuments,
        {
            isLoading: downloadDocumentsIsLoading,
            isError: downloadDocumentsError,
            isSuccess: downloadDocumentsSuccess,
        },
    ] = useDownloadZipArchiveMutation();
    const [saveParticipantEdits] = useSaveParticipantEditsMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: customPropertiesData } = useCustomPropertiesQuery(Number(props.sessionId));

    const [participantEditOpen, setParticipantEditOpen] = React.useState(false);
    const [customPropertiesEditOpen, setCustomPropertiesEditOpen] = React.useState(false);
    const [manageTemplateOpen, setManageTemplateOpen] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>("success");
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [selectedParticipants, setSelectedParticipants] = React.useState<number[]>([]);
    const [dirtyParticipantCells, setDirtyParticpantCells] = React.useState<ParticipantDataModel[]>(
        []
    );

    const currentParticipants = allParticipantsData ? allParticipantsData.templates : [];

    const handleReviewParticipantClickOpen = () => {
        setParticipantEditOpen(true);
    };

    const handleEditParticpantsDialogClose = () => {
        setConfirmOpen(false);
        setParticipantEditOpen(false);
    };

    const handleReviewCustomPropertiesClickOpen = () => {
        setCustomPropertiesEditOpen(true);
    };

    const handleEditCustomPropertiesDialogClose = () => {
        //setConfirmOpen(false);
        setCustomPropertiesEditOpen(false);
    };

    const handleManageTemplatesClickOpen = () => {
        setManageTemplateOpen(true);
    };

    const handleManageTemplateDialogClose = () => {
        manageTemplates(props.sessionId);
        setManageTemplateOpen(false);
    };

    const makeColumnsFromJSON = () => {
        if (dynamicParticipantData && dynamicParticipantData.Columns != null) {
            let columns = dynamicParticipantData.Columns;
            let datagridColumns = columns.map(
                (column: { index: any; name: any; editable: any }) => {
                    if (
                        customPropertiesData &&
                        customPropertiesData.filter(
                            (x) =>
                                x.propertyName === column.name && x.propertyValueType === "Choice"
                        ).length > 0
                    ) {
                        return {
                            field: column.index,
                            headerName: column.name,
                            width: 200,
                            editable: column.editable,
                            renderEditCell: renderSelectEditInputCell,
                        };
                    } else
                        return {
                            field: column.index,
                            headerName: column.name,
                            width: 200,
                            editable: column.editable,
                        };
                }
            );

            return datagridColumns;
        } else {
            return null;
        }
    };

    const makeDataFromJSON = () => {
        if (dynamicParticipantData) {
            let columns = makeColumnsFromJSON();
            if (columns === null) return [];
            let cellData = [];
            for (
                var i = 0;
                i < dynamicParticipantData.ProgramData.ClassroomData.ParticipantData.length;
                i++
            ) {
                let jsonData = Object.assign(
                    {},
                    dynamicParticipantData.ProgramData.ClassroomData.ParticipantData[i]
                );
                let jsonString = JSON.stringify(jsonData);
                for (var j = 0; j < columns.length; j++) {
                    let oldLabel = '"' + j.toString() + '":';
                    let newLabel = '"' + columns[j].index + '":';
                    jsonString = jsonString.replace(oldLabel, newLabel);
                }
                cellData[i] = JSON.parse(jsonString);
            }
            return cellData;
        }
        return [];
    };

    React.useEffect(() => {
        setParticipantGridData(makeDataFromJSON());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicParticipantData]);

    const templateFileClicked = (templateFile: TemplateFiles | undefined) => {
        if (templateFile) {
            const templateFound = currentTemplatesData?.templates.find((obj: { id: string }) => {
                return obj.id.replace("Q1RLXFJlcG9zaXRvcnlc", "") === templateFile.id;
            });
            if (!templateFound) {
                let associateTemplateArgs: AssociateTemplateQueryArgs = {
                    esKey: Number(props.sessionId),
                    body: {
                        programId: props.sessionId,
                        id: templateFile.id,
                        boxchecked: true,
                    },
                };
                associateTemplate(associateTemplateArgs);
            } else {
                let associateTemplateArgs: AssociateTemplateQueryArgs = {
                    esKey: Number(props.sessionId),
                    body: {
                        programId: props.sessionId,
                        id: templateFile.id,
                        boxchecked: false,
                    },
                };
                associateTemplate(associateTemplateArgs);
            }
        }
    };

    const handleParticipantSelection = (newSelectedParticipants: number[]) => {
        setSelectedParticipants(newSelectedParticipants);
    };

    const handleDownloadDocumentsClick = () => {
        const params: DownloadZipArchiveQueryArgs = {
            path: data ? data.documentPath : "",
            programCode: data ? data.programCode : "",
        };
        downloadDocuments(params)
            .unwrap()
            .then(() => {
                setOpenSnackbar(true);
                const evtData: AccessEventSessionDetails = {
                    projectCode: data?.programCode ?? "",
                };

                logEvent("DocumentsDownloaded", evtData);
            });
    };

    const handleSaveEditedParticipantDataClick = () => {
        const payload: SaveParticipantEditsQueryArgs = {
            esKey: Number(props.sessionId),
            body: dirtyParticipantCells,
        };
        saveParticipantEdits(payload)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: data?.programCode ?? "",
                };

                logEvent("ParticipantDataSaved", evtData);
            });
        setDirtyParticpantCells([]);
    };

    const handleGenerateDocuments = () => {
        let generateDocumentsQueryArgs: GenerateDocumentsQueryArgs = {
            esKey: Number(props.sessionId),
            body: {
                ProgramId: Number(props.sessionId),
                SelectedParticipants: selectedParticipants,
                SelectedTemplates: currentTemplatesData
                    ? currentTemplatesData.templates.map((x: { id: any }) => x.id)
                    : [],
                OverwriteExisting: true,
                EmailAddress: "cclaccess@ccl.org",
            },
        };
        generateDocuments(generateDocumentsQueryArgs)
            .unwrap()
            .then(() => {
                setOpenSnackbar(true);
                const evtData: AccessEventSessionDetails = {
                    projectCode: data?.programCode ?? "",
                };

                logEvent("DocumentsGenerated", evtData);
            });
    };

    const disableGenerateDocuments = () => {
        if (
            currentTemplatesData === undefined ||
            currentTemplatesData.templates == null ||
            currentTemplatesData.templates.length === 0 ||
            selectedParticipants == null ||
            selectedParticipants.length === 0
        )
            return true;
        else return false;
    };

    const handleParticipantCellEdit = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        var sourceData = makeDataFromJSON();
        for (var i = 0; i < sourceData.length; i++) {
            if (sourceData[i].RegNumber === newRow.RegNumber) {
                var j = 0;
                for (var p in sourceData[i]) {
                    var sourceValue = sourceData[i][p];
                    var editValue = newRow[p];
                    if (sourceValue !== editValue) {
                        var editModel: ParticipantDataModel = {
                            esiKey: Number(newRow.RegNumber),
                            row: i,
                            col: j,
                            value: editValue,
                        };
                        var otherDirtyParticipantCells = dirtyParticipantCells.filter(
                            // eslint-disable-next-line no-loop-func
                            (x) =>
                                !(
                                    x.esiKey === editModel.esiKey &&
                                    x.row === editModel.row &&
                                    x.col === editModel.col
                                )
                        );
                        otherDirtyParticipantCells.push(editModel);
                        setDirtyParticpantCells(otherDirtyParticipantCells);
                    }
                    j++;
                }
                break;
            }
        }
        return newRow;
    };

    React.useEffect(() => {
        if (generateDocumentsIsError) {
            setSnackbarSeverity("error");
        }
        if (generateDocumentsIsSuccess) {
            setSnackbarSeverity("success");
        }
        if (generateDocumentsIsError) {
            setSnackbarMessage("Document Generation Failed");
        }
        if (generateDocumentsIsSuccess) {
            setSnackbarMessage("Documents Generated");
        }
    }, [generateDocumentsIsError, generateDocumentsIsSuccess]);

    React.useEffect(() => {
        if (downloadDocumentsError) {
            setSnackbarSeverity("error");
        }
        if (downloadDocumentsSuccess) {
            setSnackbarSeverity("success");
        }

        if (downloadDocumentsError) {
            setSnackbarMessage("Document Download Failed");
        }

        if (downloadDocumentsSuccess) {
            setSnackbarMessage("Documents Downloaded");
        }
    }, [downloadDocumentsError, downloadDocumentsSuccess]);

    const handleParticipantCellEditError = (error: Error) => {};

    if (isLoading) {
        return <PageLoader msg={""} />;
    }

    if (
        isError ||
        allParticipantsError ||
        dynamicParticipantsError ||
        currentTemplatesError ||
        data?.message === null
    ) {
        return (
            <Stack width={1}>
                <Alert severity={data?.message ? "error" : "info"}>
                    {data?.message
                        ? data.message
                        : "The data warehouse is not yet populated with Participant data and document generation is not yet possible. Please check back later."}
                </Alert>
            </Stack>
        );
    }

    return (
        <Stack width={1}>
            <Stack direction="row" justifyContent="left" spacing={2} margin={3}>
                {claimservice.IsUserCclAdminOrSuperAdmin() || claimservice.IsUserNwaAdmin() ? (
                    <CclButton onClick={handleManageTemplatesClickOpen} mode={"primary"}>
                        View Templates
                    </CclButton>
                ) : (
                    <></>
                )}

                <CclButton onClick={handleReviewCustomPropertiesClickOpen} mode={"primary"}>
                    Custom Properties
                </CclButton>
                <CclButton onClick={handleReviewParticipantClickOpen} mode={"primary"}>
                    Review Participant Data
                </CclButton>
                <CclLoadingButton
                    loading={generateDocumentsIsLoading}
                    onClick={handleGenerateDocuments}
                    disabled={disableGenerateDocuments()}
                    mode={"primary"}
                >
                    Generate Documents
                </CclLoadingButton>
                <CclLoadingButton
                    fullWidth={false}
                    onClick={handleDownloadDocumentsClick}
                    disabled={
                        generateDocumentsData === undefined ||
                        generateDocumentsData.results.length < 1
                    }
                    loading={downloadDocumentsIsLoading}
                    mode={"primary"}
                >
                    Download Documents
                </CclLoadingButton>
            </Stack>
            <Grid container spacing={3} width={1}>
                <Grid item xs={12} md={12} lg={4}>
                    <CclGenericAccordian sx={{ maxHeight: 550 }} headingText={"Assign Templates"}>
                        <Box height={450}>
                            <TemplateTreeItem
                                selectedTemplates={
                                    currentTemplatesData ? currentTemplatesData.templates : []
                                }
                                width={"100%"}
                                sessionId={props.sessionId}
                                parentNodeId="%23"
                                handleCheckboxClick={templateFileClicked}
                            />
                        </Box>
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <CclGenericAccordian sx={{ maxHeight: 550 }} headingText={"Review Templates"}>
                        <Box height={450}>
                            <SelectedTemplatesList
                                currentTemplates={
                                    currentTemplatesData ? currentTemplatesData.templates : []
                                }
                                isChanging={associateTemplateIsLoading}
                                sessionId={props.sessionId}
                            />
                        </Box>
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <CclGenericAccordian
                        sx={{ maxHeight: 550 }}
                        headingText={"Select Participants"}
                    >
                        <Box height={450}>
                            <TemplateParticipantAssignmentPanel
                                sessionId={props.sessionId}
                                handleSelectionClicked={handleParticipantSelection}
                                allParticipants={currentParticipants}
                                isLoading={allParticipantsLoading}
                            />
                        </Box>
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <CclGenericAccordian
                        sx={{ maxHeight: 550 }}
                        headingText={"Generated Documents"}
                    >
                        <Box height={450}>
                            <Stack spacing={2} padding={2} height={1}>
                                <GenerateDocumentsGridPro
                                    isLoading={generateDocumentsIsLoading}
                                    data={
                                        generateDocumentsData ? generateDocumentsData.results : []
                                    }
                                />
                            </Stack>
                        </Box>
                    </CclGenericAccordian>
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={customPropertiesEditOpen}
                onClose={handleEditCustomPropertiesDialogClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", backgroundColor: "#282c34" }}>
                    <Toolbar>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            width={1}
                            alignItems="flex-start"
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                //onClick={() => dirtyParticipantCells.length > 0 ? setConfirmOpen(true): handleEditParticpantsDialogClose()}
                                onClick={() => handleEditCustomPropertiesDialogClose()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <CustomPropertiesPanel
                    esKey={Number(props.sessionId)}
                    programCode={sessionDetail?.sessionCode ?? "unknown"}
                />
            </Dialog>
            <Dialog
                fullScreen
                open={manageTemplateOpen}
                onClose={handleManageTemplateDialogClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", backgroundColor: "#282c34" }}>
                    <Toolbar>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            width={1}
                            alignItems="flex-start"
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                //onClick={() => dirtyParticipantCells.length > 0 ? setConfirmOpen(true): handleEditParticpantsDialogClose()}
                                onClick={() => handleManageTemplateDialogClose()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <ManageTemplatesPanel />
            </Dialog>
            <CclGenericConfirmationDialog
                msg="You have pending changes, are you sure you want to close without saving?"
                open={confirmOpen}
                onCancel={() => {
                    setConfirmOpen(false);
                }}
                onOk={() => handleEditParticpantsDialogClose()}
            />
            <Dialog
                fullScreen
                open={participantEditOpen}
                onClose={handleEditParticpantsDialogClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", backgroundColor: "#282c34" }}>
                    <Toolbar>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            width={1}
                            alignItems="flex-start"
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() =>
                                    dirtyParticipantCells.length > 0
                                        ? setConfirmOpen(true)
                                        : handleEditParticpantsDialogClose()
                                }
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <CclButton
                                disabled={dirtyParticipantCells.length === 0}
                                onClick={() => handleSaveEditedParticipantDataClick()}
                                mode={"secondary"}
                            >
                                Save Changes
                            </CclButton>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <FlexGrid>
                    <DataGridPro
                        apiRef={apiRef}
                        processRowUpdate={handleParticipantCellEdit}
                        onProcessRowUpdateError={handleParticipantCellEditError}
                        experimentalFeatures={{ newEditingApi: true }}
                        columns={addCustomDataGridFilters(
                            makeColumnsFromJSON() as GridColumns<any>
                        )}
                        rows={participantGridData}
                        getRowId={(row) => row.RegNumber}
                        components={{ Footer: ParticipantDataGridFooter }}
                        componentsProps={{
                            footer: { apiRef: apiRef, projectCode: sessionCode },
                        }} // NOTE: THIS WORKS BECAUSE WE DON"T ALLOW FILTERING ON THIS TABLE
                    />
                </FlexGrid>
            </Dialog>
            <CclAlertSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                severity={snackbarSeverity}
                message={snackbarMessage}
            />
        </Stack>
    );
};

export default SessionDocumentsPanel;
