import {
    GridColDef,
    GridColumns,
    GridFilterInputValue,
    GridFilterItem,
    getGridStringOperators,
} from "@mui/x-data-grid-pro";

export const addCustomDataGridFilters = (columns: GridColumns<any>): GridColumns<any> => {
    if (columns == null || columns.length < 1) return columns;

    let newColumns = [...columns];
    let indexes = newColumns
        .map((c, i) => {
            return c.type === "string" ? i : -1;
        })
        .filter((i) => i !== -1);

    indexes.forEach((i) => {
        newColumns[i] = {
            ...newColumns[i],
            filterOperators: [
                ...getGridStringOperators(),
                {
                    value: "noContain",
                    label: "does not contain",
                    getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
                        if (
                            !filterItem.columnField ||
                            !filterItem.value ||
                            !filterItem.operatorValue
                        ) {
                            return null;
                        }

                        const filterRegex = new RegExp(filterItem.value, "i");
                        return (params): boolean => {
                            const rowValue = column.valueGetter
                                ? column.valueGetter(params.value)
                                : params.value;
                            return !filterRegex.test(rowValue?.toString() || "");
                        };
                    },
                    InputComponent: GridFilterInputValue,
                },
            ],
        };
    });

    return newColumns;
};
