// Themes
export const THEMES = {
    DEFAULT: "DEFAULT",
    DARK: "DARK",
    LIGHT: "LIGHT",
    BLUE: "BLUE",
    GREEN: "GREEN",
    INDIGO: "INDIGO",
    CCLLIGHT: "CCLLIGHT",
    CCLCORE: "CCLCORE",
};
