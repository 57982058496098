import { Typography, Link } from "@mui/material";
import * as React from "react";
import { useParams, Link as RouterLink, useSearchParams } from "react-router-dom";
import DetailPage from "../../../components/common/detailPage/detailPage";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import PartnerGeneral from "./general/partnerGeneral";
import PartnerApplications from "./applications/partnerApplications";
import PartnerNotifications from "./notifications/partnerNotifications";
import PartnerParticipants from "./participants/partnerParticipants";
import PartnerLogins from "./logins/partnerLogins";
import { useGetCclUserAccountDetailQuery } from "../../../services/cclTokenedGrandCentralApi";

export type PartnerDetailProps = {
    imKey: string;
};

const PartnerDetail: React.FC = (props) => {
    const params = useParams<PartnerDetailProps>();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = React.useState<string>("");
    const [imKey, setImKey] = React.useState<string>("");

    const {
        data: enterpriseData,
        //isLoading: enterpriseLoading,
        //isSuccess: enterpriseSuccess,
    } = useGetCclUserAccountDetailQuery(email, {
        skip: email == null || email === "",
    });

    React.useEffect(() => {
        setEmail(searchParams.get("email") ?? "");
        setImKey(params.imKey ? params.imKey : "");
    }, [searchParams, params]);
    const tablist: [string, number, React.ReactNode][] = [
        ["General", 0, <PartnerGeneral email={email} />],
        ["Participants", 1, <PartnerParticipants email={email} imKey={imKey} />],
        ["Emails", 2, <PartnerNotifications email={email} />],
        [
            "Applications",
            3,
            <PartnerApplications enterpriseData={enterpriseData ?? null} email={email} />,
        ],
        ["Logins", 4, <PartnerLogins email={email} />],
    ];

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                <Link key="findResourceLink" component={RouterLink} to="/resources">
                    Partner Resources
                </Link>
            </Typography>
            <Typography variant="h6" color="text.primary">
                <Link key="findResourceLink" component={RouterLink} to="/resources">
                    {enterpriseData && (
                        <>{enterpriseData[0].firstName + " " + enterpriseData[0].lastName}</>
                    )}
                </Link>
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <DetailPage
            breadcrumbs={breadcrumbs}
            descriptor={"resources"}
            tablist={tablist}
        ></DetailPage>
    );
};

export default PartnerDetail;
