import * as React from "react";
import { Alert } from "@mui/material";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblUserGeneralInfoTemplate } from "./CclInfoTblUserGeneralInfoTemplate";
import { GrandCentralAccountDetail } from "../../../../services/types/cclGrandCentralApiTypes";
import { EnterpriseUserDetail } from "../../../../services/types/userPreferenceTypes";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type UserDetailsCardProps = {
    gcAccountEmail: string;
    imKey: number;
    gcDetail: GrandCentralAccountDetail | null;
    enterpriseDetails: EnterpriseUserDetail[] | null;
};

type GeneralTableRecord = {
    imKey: number;
    firstName: string;
    lastName: string;
    email: string;
    ceContactId: string;
    accountId: string;
};

const UserDetailsCard: React.FC<UserDetailsCardProps> = (props) => {
    const [userTemplate, setUserTemplate] = React.useState<CclInfoTableRowTemplate[]>(
        CclInfoTblUserGeneralInfoTemplate
    );
    const relatedImKeys =
        props.enterpriseDetails?.filter((ed) => ed.imKey !== props.imKey).map((ed) => ed.imKey) ??
        [];
    const enterpriseDetail = props.enterpriseDetails?.find(
        (e) => e.email.toLowerCase() === props.gcAccountEmail.toLowerCase()
    );
    const genData: GeneralTableRecord = {
        imKey: enterpriseDetail?.imKey ?? 0,
        firstName: enterpriseDetail?.firstName ?? "",
        lastName: enterpriseDetail?.lastName ?? "",
        email: enterpriseDetail?.email ?? "",
        ceContactId: enterpriseDetail?.ceContactId ?? "",
        accountId: props.gcDetail?.accountId ?? "No Account",
    };

    React.useEffect(() => {
        if (!enterpriseDetail) {
            return;
        }
        let tmplt = [...userTemplate];
        let row = tmplt.find((r) => r.id === "fullname");
        if (row != null) {
            row.valueField.value = enterpriseDetail.firstName + " " + enterpriseDetail.lastName;
            row.value = enterpriseDetail.firstName + " " + enterpriseDetail.lastName;
        }
        setUserTemplate(tmplt);
        // eslint-disable-next-line
    }, [enterpriseDetail]);

    return (
        <CclGenericAccordian headingText={"User"} sx={{ height: 1 }}>
            {relatedImKeys != null && relatedImKeys.length > 0 && (
                <Alert severity="error">
                    There are other IDs associated with {props.gcAccountEmail}:{" "}
                    {relatedImKeys.join(", ")}
                </Alert>
            )}
            <CclInfoTable template={userTemplate} data={genData} />
        </CclGenericAccordian>
    );
};

export default UserDetailsCard;
