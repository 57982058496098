import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface applicationsSearchListTableStateState {
    TableState: string;
}

// Define the initial state using that type
const initialState: applicationsSearchListTableStateState = {
    TableState: JSON.stringify({ sorting: { sortModel: [{ field: "name", sort: "asc" }] } }),
};

export const applicationsSearchListTableStateSlice = createSlice({
    name: "applicationsSearchListTableState",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateApplicationsSearchGridState: (state, action: PayloadAction<string | null>) => {
            state.TableState = action?.payload ?? initialState.TableState;
        },
    },
});

export const { updateApplicationsSearchGridState } = applicationsSearchListTableStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const applicationsSearchListTableState = (state: RootState) => state;
export default applicationsSearchListTableStateSlice.reducer;
