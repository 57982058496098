import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { getDateAdjusted } from "../../utilities/dateHelpers";

// Define a type for the slice state
export interface NotificationListConfigState {
    StartDate: string;
    EndDate: string;
    Keyword: string;
}

// Define the initial state using that type
const initialState: NotificationListConfigState = {
    StartDate: new Date(getDateAdjusted(-1).setHours(0o0, 0o0, 0o0, 0o0)).toISOString(),
    EndDate: new Date(getDateAdjusted(0).setHours(23, 59, 59, 999)).toISOString(),
    Keyword: "",
};

export const notificationListConfigSlice = createSlice({
    name: "notificationListConfig",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateNotificationSearchParams: (
            state,
            action: PayloadAction<NotificationListConfigState>
        ) => {
            state.StartDate = action.payload.StartDate;
            state.EndDate = action.payload.EndDate;
            state.Keyword = action.payload.Keyword;
        },
    },
});

export const { updateNotificationSearchParams } = notificationListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNotificationListConfig = (state: RootState) => state;
export default notificationListConfigSlice.reducer;
