import { Box, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";

interface EditImageDrawerProps {
    open: boolean;
    onCancel: () => void;
    saveImage: (file: File) => void;
}

const EditImageDrawer: React.FC<EditImageDrawerProps> = (props) => {
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const maxSize = 1048576 * 10;

    const onDrop = (acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
    };

    const { isDragAccept, isFocused, getRootProps, getInputProps, isDragReject, fileRejections } =
        useDropzone({
            onDrop,
            accept: {
                "image/jpg": [".jpg"],
                "image/jpeg": [".jpeg"],
                "image/jpe": [".jpe"],
            },
            minSize: 0,
            maxSize,
            multiple: false,
            disabled: false,
        });

    //const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
    const fileErrors = fileRejections.length > 0 ? true : false;

    const compileErrors = (): string[] => {
        let errors = fileRejections[0].errors;
        let errorMessages: string[] = [];
        for (let i = 0; i < errors.length; i++) {
            switch (errors[i].code) {
                case "file-invalid-type":
                    errorMessages.push("Only .jpg, .jpeg, or .jpe are allowed for upload.");
                    break;
                case "file-too-large":
                    errorMessages.push("File must be less than 10 MB");
                    break;
                default:
                    break;
            }
        }

        return errorMessages;
    };

    const uploadFile = () => {
        if (selectedFile != null) {
            props.saveImage(selectedFile);
        }
    };

    const getBoarderColor = (): string => {
        if (isFocused) return "darkblue";
        else if (isDragAccept) return "darkgreen";
        else if (isDragReject) return "darkred";
        return "gray";
    };

    const getBackgroundColor = (): string => {
        if (isFocused) return "aliceblue";
        else if (isDragAccept) return "honeydew";
        else if (isDragReject) return "mistyrose";
        return "lightgray";
    };

    return (
        <CclDrawer title={"Change Profile Picture"} open={props.open} onClose={props.onCancel}>
            <Box
                className="container"
                sx={{
                    borderColor: getBoarderColor(),
                    backgroundColor: getBackgroundColor(),
                    // m: 2,
                    p: 8,
                    borderStyle: "dashed",
                    borderWidth: 2,
                    borderRadius: 3,
                    "&:hover": {
                        border: "2px dashed darkblue",
                        color: "black",
                        backgroundColor: "aliceblue",
                    },
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Typography align="center" variant="h6">
                            Drop files here or click to ADD.
                        </Typography>
                        {selectedFile != null && (
                            <Typography align="center" variant="body1">
                                {selectedFile?.name}
                            </Typography>
                        )}
                        <Typography align="center" variant="body2">
                            Only .jpg, .jpeg, or .jpe are allowed for upload.
                        </Typography>
                        <Typography align="center" variant="body2">
                            max individual file size: <strong>10 MB</strong>
                        </Typography>
                        {isDragReject ? (
                            <Typography align="center" variant="body1" color="error">
                                Invalid file type or too many files selected
                            </Typography>
                        ) : null}
                        {selectedFile != null && fileErrors
                            ? compileErrors().map((error) => (
                                  <Typography align="center" variant="body1" color="error">
                                      {error}
                                  </Typography>
                              ))
                            : null}
                    </Box>
                </div>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 4 }}>
                <CclButton mode="secondary" onClick={props.onCancel} sx={{ float: "right" }}>
                    Cancel
                </CclButton>
                <CclButton mode="primary" onClick={uploadFile} sx={{ float: "right", ml: 3 }}>
                    Upload Picture
                </CclButton>
            </Box>
        </CclDrawer>
    );
};

export default EditImageDrawer;
