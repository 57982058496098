import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridRowId,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { PartnerGroupPerson } from "../../../services/types/enterpriseNetworkPartnerTypes";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../../components/common/cclCommonLink";
import CclDataGridToolbar from "../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

export interface PartnerResourcesListProps {
    resources: PartnerGroupPerson[];
    loading: boolean;
    hidePartner: boolean;
    addPartnerResource: () => void;
    editPartnerResource: (imkey: number) => void;
}

export const PartnerResourcesDataGridPro: React.FC<PartnerResourcesListProps> = (props) => {
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const claimsService = new UserClaimsService();
    const apiRef = useGridApiRef();

    const COLUMNS = [
        {
            field: "imKey",
            headerName: "ID",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
                />
            ),
        },
        {
            field: "name",
            headerName: "Resource",
            type: "string",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) =>
                !props.hidePartner || claimsService.IsUserNwaAdmin() ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
                    />
                ) : (
                    params.value
                ),
            flex: 0.8,
        },
        {
            field: "pgDescription",
            headerName: "Partner",
            type: "string",
            flex: 1.3,
            hide: props.hidePartner,
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1.3,
        },
        {
            field: "active",
            headerName: "Active Coach",
            type: "string",
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.active && params.row.isCoach ? "Yes" : "No"}`,
            flex: 0.35,
        },
        {
            field: "isAdmin",
            headerName: "Admin",
            valueGetter: (params: GridValueGetterParams) => `${params.value ? "Yes" : "No"}`,
            flex: 0.35,
        },
    ];

    const onSelectedRowsChange = () => {
        var selectedRowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
        var selectedRows: any[] = [];
        for (let row of Array.from(selectedRowsMap.values())) {
            selectedRows.push(row);
        }
        setSelectedRows(selectedRows);
    };

    const handleBtnClick = (btnId: string) => {
        if (btnId === "add-partner-resource-button") {
            props.addPartnerResource();
        } else if (btnId === "edit-partner-resource-button") {
            props.editPartnerResource(selectedRows[0]?.imKey);
        }
    };

    return (
        <DataGridPro
            getRowId={(row: PartnerGroupPerson) => `${row.imKey}-${row.pgKey}`}
            rows={props.resources}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.loading}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    csvOptions: {
                        allColumns: true,
                        fileName: "partner-resources.csv",
                    },
                    showSaveState: false,
                    miscButtons: [
                        {
                            id: "add-partner-resource-button",
                            caption: "Add Resource",
                            onClick: null,
                            returnSelected: false,
                            restricted: false,
                        },
                        {
                            id: "edit-partner-resource-button",
                            caption: "Edit Resource",
                            onClick: null,
                            returnSelected: true,
                            restricted: false,
                            disable: selectedRows.length !== 1,
                        },
                    ],
                    btnClick: handleBtnClick,
                    hideSettingsButtons: true,
                    anySelected: selectedRows.length > 0,
                },
            }}
            initialState={{ sorting: { sortModel: [{ field: "name", sort: "asc" }] } }}
            apiRef={apiRef}
            hideFooter={false}
            checkboxSelection={true}
            onSelectionModelChange={() => onSelectedRowsChange()}
        />
    );
};

export default PartnerResourcesDataGridPro;
