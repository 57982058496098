import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblPartnerDetailTemplate: CclInfoTableRowTemplate[] = [
    {
        id: 'name',
        fieldType: 'string',
        editable: false,
        label: 'Name',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'pgDescription',
        fieldType: 'string',
        editable: false,
        label: 'Partner',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'email',
        fieldType: 'string',
        editable: false,
        label: 'Email',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'activeText',
        fieldType: 'string',
        editable: false,
        label: 'Active Coach',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    },
    {
        id: 'adminText',
        fieldType: 'string',
        editable: false,
        label: 'Admin',
        required: false,
        value: '',
        actionField: null,
        valueField: {
            value: '',
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: '',
            selectOptions: null //TODO: Fix this for drop down option
        }
    }

];