import * as React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import { useGetAssignedCoachesBySessionKeyQuery } from "../../../../../services/cclTokenedEnterpriseCoachingApi";
import { DataGridPro, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../../services/currentUserService/currentUserService";
import CclDataGridToolbar from "../../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

type PaxAssignmentStatus = {
    firstName: string;
    lastName: string;
    imkey: number;
    primary: PrimaryCoaches;
    other: OtherCoaches;
};

type PrimaryCoaches = {
    existing: string;
    removed: string;
    added: string;
};

type OtherCoaches = {
    existing: string[];
    removed: string[];
    added: string[];
};

interface ViewAssignmentsPanelProps {
    sessionId: string;
    editedAssignedCoaches: SessionDetailAssignedCoach[];
}

const ViewAssignmentsPanel: React.FC<ViewAssignmentsPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const [participantStatus, setParticipantStatus] = React.useState<PaxAssignmentStatus[]>([]);
    const { data: allParticipants } = useGetCclParticipantsByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const { data: storedAssignedCoaches } = useGetAssignedCoachesBySessionKeyQuery(
        props.sessionId,
        { skip: props.sessionId === "" }
    );

    const getCoachStatus = (coach: SessionDetailAssignedCoach | undefined): string => {
        if (coach == null) return "";

        return `${coach.firstName} ${coach.lastName}`;
    };

    React.useEffect(() => {
        if (
            allParticipants == null ||
            allParticipants.length === 0 ||
            storedAssignedCoaches?.coaches == null ||
            storedAssignedCoaches.coaches.length === 0
        )
            return;

        const removedCoaches = storedAssignedCoaches.coaches.filter(
            (ac) =>
                props.editedAssignedCoaches.find(
                    (ec) => ec.imKey === ac.imKey && ec.paxImKey === ac.paxImKey
                ) == null
        );
        const addedCoaches = props.editedAssignedCoaches.filter(
            (ac) =>
                storedAssignedCoaches.coaches.find(
                    (ec) => ec.imKey === ac.imKey && ec.paxImKey === ac.paxImKey
                ) == null
        );
        const existingCoaches = storedAssignedCoaches.coaches.filter((ac) => {
            return !removedCoaches.includes(ac);
        });

        const paxStatus: PaxAssignmentStatus[] = allParticipants
            .filter((p) => p.registrationStatus === "Confirmed")
            .map((p) => {
                let pax: PaxAssignmentStatus = {
                    firstName: p.firstName,
                    lastName: p.lastName,
                    imkey: p.imKey,
                    primary: { existing: "", removed: "", added: "" },
                    other: { existing: [], removed: [], added: [] },
                };

                const existing = existingCoaches.filter((c) => c.paxImKey === p.imKey);
                const added = addedCoaches.filter((c) => c.paxImKey === p.imKey);
                const removed = removedCoaches.filter((c) => c.paxImKey === p.imKey);

                // get primaries
                pax.primary.existing = getCoachStatus(existing.find((e) => e.isPrimary));
                pax.primary.added = getCoachStatus(added.find((a) => a.isPrimary));
                pax.primary.removed = getCoachStatus(removed.find((r) => r.isPrimary));

                // get others
                pax.other.existing = existing
                    .filter((e) => !e.isPrimary)
                    .map((e) => {
                        return getCoachStatus(e);
                    });
                pax.other.added = added
                    .filter((a) => !a.isPrimary)
                    .map((a) => {
                        return getCoachStatus(a);
                    });
                pax.other.removed = removed
                    .filter((r) => !r.isPrimary)
                    .map((r) => {
                        return getCoachStatus(r);
                    });
                return pax;
            });
        console.log(paxStatus);
        setParticipantStatus(
            paxStatus.sort((a, b) => {
                return (
                    a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
                );
            })
        );
        console.log(paxStatus);
        // eslint-disable-next-line
    }, [storedAssignedCoaches, props.editedAssignedCoaches]);

    function getFullName(params: any) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    function getPrimary(params: any) {
        if (params.row.primary.existing.length > 0) {
            return params.row.primary.existing;
        }
        if (params.row.primary.added.length > 0) {
            return params.row.primary.added;
        }
        if (params.row.primary.removed.length > 0) {
            return params.row.primary.removed;
        }
        return "";
    }

    function getOther(params: any) {
        return params.row.other.existing
            .concat(params.row.other.added)
            .concat(params.row.other.removed)
            .join(", ");
    }

    const COLUMNS = [
        {
            field: "name",
            headerName: "Participant",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) => (
                <Typography variant={"body2"}>{params.value}</Typography>
            ),
            type: "string",
            width: 300,
        },
        {
            field: "primary",
            headerName: "Primary Coach",
            type: "string",
            valueGetter: getPrimary,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    <Typography variant={"body2"}>{params.row.primary.existing}</Typography>
                    <Typography variant={"body2"} color={"green"}>
                        {params.row.primary.added}
                    </Typography>
                    <Typography
                        variant={"body2"}
                        color={"red"}
                        sx={{ textDecoration: "line-through" }}
                    >
                        {params.row.primary.removed}
                    </Typography>
                </>
            ),
            width: 300,
        },
        {
            field: "other",
            headerName: "Other Assigned Resources",
            type: "string",
            valueGetter: getOther,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    <Typography variant={"body2"}>
                        {params.row.other.existing.join(", ")}
                    </Typography>
                    <Typography variant={"body2"} color={"green"}>
                        {params.row.other.added.join(", ")}
                    </Typography>
                    <Typography
                        variant={"body2"}
                        color={"red"}
                        sx={{ textDecoration: "line-through" }}
                    >
                        {params.row.other.removed.join(", ")}
                    </Typography>
                </>
            ),
            flex: 1,
        },
    ];

    return (
        <Stack height={1} width={1} sx={{ justify: "space-between", maxHeight: "100%" }}>
            <Grid container spacing={2} sx={{ justify: "space-between", flexGrow: 1 }}>
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}
                >
                    <DataGridPro
                        getRowId={(row) => row.imkey}
                        rows={participantStatus}
                        columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                        components={
                            claimsService.IsUserInGroups([eGroupIds.eClientAdmin])
                                ? {}
                                : {
                                      Toolbar: CclDataGridToolbar,
                                  }
                        }
                        componentsProps={{
                            toolbar: {
                                hideSettingsButtons: true,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default ViewAssignmentsPanel;
