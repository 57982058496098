export type SelectOption = {
    val: string;
    option: string;
};

export const getOptionFromVal = (val: string, options: SelectOption[]) => {
    const opt = options.find((o) => o.val === val);
    if (opt == null) return null;
    return opt.option;
};
