import * as React from "react";
import { Box, Stepper, Step, StepLabel, CardContent, styled, AlertColor } from "@mui/material";
import ProfileStepPanel from "./profileStepPanel";
import SignStepPanel from "./signStepPanel";
import EligibilityStepPanel from "./eligibilityStepPanel";
import HandbookStepPanel from "./handbookStepPanel";
import InterestsStepPanel from "./interestsStepPanel";
import ItStepPanel from "./itStepPanel";
import { UserClaimsService } from "../../../../../../services/currentUserService/currentUserService";
import {
    ContractDataPoints,
    GenerateContractPdfResponse,
    GenerateContractRequest,
    ProfileContract,
    SignContractRequest,
    UpdateContractEligibilityRequest,
} from "../../../../../../services/types/enterpriseCoachingApiTypes";
import {
    useGenerateSignedContractPdfMutation,
    useGetDemographicsByProfileKeyQuery,
    useSignContractMutation,
    useUpdateContractEligibilityMutation,
} from "../../../../../../services/cclTokenedEnterpriseCoachingApi";
import CclAlertDialog from "../../../../../../components/common/cclAlertDialog";
import { AccessEventIndividualDetails } from "../../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../../hooks/useLogAccessEvent";

export type EligibilityContractData = Pick<
    ContractDataPoints,
    | "isEmployed"
    | "employmentInfo"
    | "noAdvertisingPresence"
    | "haveLinkedIn"
    | "website"
    | "cclExclusively"
    | "stateRegistrationNumber"
    | "stateRegistrationState"
    | "newLegalEntity"
>;
export type ItContractData = Pick<
    ContractDataPoints,
    "encryptionId" | "otherEncryption" | "antiVirusId" | "otherAntiVirus" | "agreeToITPolicy"
>;
export type InterestsContractData = Pick<
    ContractDataPoints,
    "integratedCoachingId" | "executiveCoachingId"
>;
export type HandbookContractData = Pick<
    ContractDataPoints,
    "agreeToHandbook" | "agreeToHandbookChanges"
>;

export const ContractCardContent = styled(CardContent)(`
  padding: 8px;
  &:last-child {
    padding-bottom: 12px;
  }
`);

export interface SignContractStepperProps {
    onFinish: () => void;
    contract: ProfileContract | null;
}

export const SignContractStepper: React.FC<SignContractStepperProps> = (props) => {
    const claimsService = new UserClaimsService();
    const pkey = claimsService.GetCurrentResourceId();

    const { data: profileData } = useGetDemographicsByProfileKeyQuery(pkey.toString());
    const [isFirstTime] = React.useState<boolean>(props.contract?.eligibilityJSON == null);
    const [contractData, setContractData] = React.useState<ContractDataPoints>(
        props.contract?.eligibilityJSON == null ? {} : JSON.parse(props.contract.eligibilityJSON)
    );

    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [nextStep, setNextStep] = React.useState<number>(-1);
    const [contractValidated, setContractValidated] = React.useState<boolean>(false);
    const [signingContract, setSigningContract] = React.useState<boolean>(false);
    const [updateEligibility] = useUpdateContractEligibilityMutation();
    const [signProfileContract] = useSignContractMutation();
    const [generateSignedContract] = useGenerateSignedContractPdfMutation();
    const { logEvent } = useLogAccessEvent();

    const [resultOpen, setResultOpen] = React.useState<boolean>(false);
    const [resultMsg, setResultMsg] = React.useState<string>("");
    const [resultTitle, setResultTitle] = React.useState<string>("");
    const [resultSeverity, setResultSeverity] = React.useState<AlertColor>("success");

    React.useEffect(() => {
        if (
            contractData === undefined ||
            contractData?.isEmployed === undefined ||
            contractData?.cclExclusively === undefined ||
            contractData?.newLegalEntity === undefined ||
            contractData?.encryptionId === undefined ||
            contractData?.encryptionId < 0 ||
            contractData?.antiVirusId === undefined ||
            contractData?.antiVirusId < 0 ||
            contractData?.agreeToITPolicy !== true ||
            contractData?.agreeToHandbook !== true ||
            contractData?.agreeToHandbookChanges !== true
        ) {
            setContractValidated(false);
        } else if (
            (contractData?.encryptionId === 99 &&
                (contractData?.otherEncryption == null || contractData.otherEncryption === "")) ||
            (contractData?.antiVirusId === 99 &&
                (contractData?.otherAntiVirus == null || contractData.otherAntiVirus === ""))
        ) {
            setContractValidated(false);
        } else if (
            profileData?.mobilePhone == null ||
            profileData?.mobilePhone === "" ||
            profileData?.address.addressOne == null ||
            profileData?.address.addressOne === "" ||
            profileData?.address.postalCode == null ||
            profileData?.address.postalCode === "" ||
            profileData?.address.city == null ||
            profileData?.address.city === "" ||
            profileData?.address.cmKey == null ||
            profileData?.address.cmKey <= 0 ||
            profileData?.address.spmKey == null ||
            profileData?.address.spmKey <= 0
        ) {
            setContractValidated(false);
        } else {
            setContractValidated(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractData]);

    const saveEligibilityData = (
        newdata:
            | EligibilityContractData
            | ItContractData
            | InterestsContractData
            | HandbookContractData
            | null
    ) => {
        if (newdata === null) return;

        const newcd: ContractDataPoints =
            contractData !== null ? { ...contractData, ...newdata } : { ...newdata };

        setContractData(newcd);

        if (props.contract == null || props.contract?.profileContractId == null) return;
        const request: UpdateContractEligibilityRequest = {
            profileKey: pkey,
            payload: {
                profileContractId: props.contract.profileContractId,
                eligibilityJSON: JSON.stringify(newcd),
            },
        };
        updateEligibility(request);
    };

    const gotoNextStep = (
        newActiveStep: number,
        newdata:
            | EligibilityContractData
            | ItContractData
            | InterestsContractData
            | HandbookContractData
            | null
    ) => {
        setActiveStep(newActiveStep);
        setNextStep(-1);
        saveEligibilityData(newdata);
    };

    const gotoPreviousStep = () => {
        setActiveStep(activeStep - 1);
    };

    const signContract = (signature: string, businessName: string) => {
        if (
            signature === "" ||
            businessName === "" ||
            props.contract == null ||
            props.contract?.profileContractId == null
        )
            return;

        setSigningContract(true);
        const profileContractId = props.contract.profileContractId;
        const contractId = props.contract.contract.contractId;
        const contractAiKey = props.contract.contract.aiKey;

        const generateContractRequest: GenerateContractRequest = {
            imKey: +claimsService.GetCurrentUserId(),
            profileContractId: profileContractId,
            contractAiKey: contractAiKey,
            contractYear: props.contract.contract.year,
            signature: signature,
            company: businessName,
        };

        generateSignedContract(generateContractRequest)
            .unwrap()
            .then((response: GenerateContractPdfResponse) => {
                if (response.success) {
                    const request: SignContractRequest = {
                        profileKey: pkey,
                        payload: {
                            profileContractId: profileContractId,
                            contractId: contractId,
                            signature: signature,
                            company: businessName,
                            aiKey: response.aiKey,
                        },
                    };
                    signProfileContract(request)
                        .unwrap()
                        .then(() => {
                            const evtData: AccessEventIndividualDetails = {
                                imKey: claimsService.GetCurrentUserId(),
                                email: claimsService.GetCurrentUserEmail(),
                            };

                            logEvent("UserSignedContract", evtData);

                            setSigningContract(false);
                            setResultMsg("Contract signed successfully.");
                            setResultTitle("Success!");
                            setResultSeverity("success");
                            setResultOpen(true);
                        })
                        .catch((error) => {
                            setSigningContract(false);
                            const msg =
                                `Error signing contract: ${error.message}` ??
                                "Unknown error generating signed contract pdf.";
                            setResultMsg(msg);
                            setResultTitle("Error Signing Contract");
                            setResultSeverity("error");
                            setResultOpen(true);
                        });
                } else {
                    setSigningContract(false);
                    const msg = `Error generating signed contract pdf: ${response.errorMessage}`;
                    setResultMsg(msg);
                    setResultTitle("Error Signing Contract");
                    setResultSeverity("error");
                    setResultOpen(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setSigningContract(false);
                const msg =
                    `Error generating signed contract pdf: ${error.message}` ??
                    "Unknown error generating signed contract pdf.";
                setResultMsg(msg);
                setResultTitle("Error Signing Contract");
                setResultSeverity("error");
                setResultOpen(true);
            });
    };

    const errorOnStep = (step: number): boolean => {
        if (profileData == null || contractData == null) return false;

        switch (step) {
            case 0: // profile
                return (
                    profileData?.mobilePhone == null ||
                    profileData?.mobilePhone === "" ||
                    profileData?.address.addressOne == null ||
                    profileData?.address.addressOne === "" ||
                    profileData?.address.postalCode == null ||
                    profileData?.address.postalCode === "" ||
                    profileData?.address.city == null ||
                    profileData?.address.city === "" ||
                    profileData?.address.cmKey == null ||
                    profileData?.address.cmKey <= 0 ||
                    profileData?.address.spmKey == null ||
                    profileData?.address.spmKey <= 0
                );
            case 1: // eligibility
                return (
                    contractData?.isEmployed === undefined ||
                    contractData?.cclExclusively === undefined ||
                    contractData?.newLegalEntity === undefined
                );
            case 2: // it
                if (
                    contractData?.encryptionId === undefined ||
                    contractData?.encryptionId < 0 ||
                    contractData?.antiVirusId === undefined ||
                    contractData?.antiVirusId < 0 ||
                    contractData?.agreeToITPolicy !== true
                )
                    return true;

                // if encryption or virus is "other", the "Other" field must be filled out
                return (
                    (contractData?.encryptionId === 99 &&
                        (contractData?.otherEncryption == null ||
                            contractData.otherEncryption === "")) ||
                    (contractData?.antiVirusId === 99 &&
                        (contractData?.otherAntiVirus == null ||
                            contractData.otherAntiVirus === ""))
                );

            case 4: // handbook
                return (
                    contractData?.agreeToHandbook !== true ||
                    contractData?.agreeToHandbookChanges !== true
                );

            default:
                return false;
        }
    };

    const steps: [string, React.ReactElement][] = [
        [
            "Profile",
            <ProfileStepPanel
                pkey={+pkey}
                onNextPanel={gotoNextStep}
                activeStep={activeStep}
                nextStep={nextStep}
            />,
        ],
        [
            "Eligibility",
            <EligibilityStepPanel
                pkey={+pkey}
                eligibilityData={contractData as EligibilityContractData}
                onNextPanel={gotoNextStep}
                onPreviousPanel={gotoPreviousStep}
                activeStep={activeStep}
                nextStep={nextStep}
            />,
        ],
        [
            "IT",
            <ItStepPanel
                pkey={+pkey}
                itData={contractData as ItContractData}
                onNextPanel={gotoNextStep}
                onPreviousPanel={gotoPreviousStep}
                activeStep={activeStep}
                nextStep={nextStep}
            />,
        ],
        [
            "Interests",
            <InterestsStepPanel
                pkey={+pkey}
                interestData={contractData as InterestsContractData}
                onNextPanel={gotoNextStep}
                onPreviousPanel={gotoPreviousStep}
                activeStep={activeStep}
                nextStep={nextStep}
            />,
        ],
        [
            "Handbook",
            <HandbookStepPanel
                pkey={+pkey}
                handbookData={contractData as HandbookContractData}
                onNextPanel={gotoNextStep}
                onPreviousPanel={gotoPreviousStep}
                contract={props?.contract?.contract ?? null}
                activeStep={activeStep}
                nextStep={nextStep}
            />,
        ],
        [
            "Sign",
            <SignStepPanel
                pkey={+pkey}
                onSign={signContract}
                onPreviousPanel={gotoPreviousStep}
                okToSign={contractValidated}
                signingContract={signingContract}
                contract={props?.contract?.contract ?? null}
            />,
        ],
    ];

    return (
        <Box sx={{ width: "100%" }}>
            <CclAlertDialog
                open={resultOpen}
                msg={resultMsg}
                title={resultTitle}
                severity={resultSeverity}
                onOk={() => {
                    setResultOpen(false);
                    props.onFinish();
                }}
            />
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    const stepProps: { completed?: boolean; key: number; active?: boolean } = {
                        key: index,
                        active: activeStep === index,
                    };
                    const labelProps: { error?: boolean } = {};
                    labelProps.error = errorOnStep(index) && (!isFirstTime || activeStep > index);

                    return (
                        <Step {...stepProps} onClick={() => setNextStep(index)} key={index}>
                            <StepLabel {...labelProps}>{step[0]}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {React.cloneElement(steps[activeStep][1], {
                activeStep: activeStep,
                nextStep: nextStep,
            })}
        </Box>
    );
};

export default SignContractStepper;
