import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclPersistentDataGrid from "../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import {
    ApplicationRole,
    RemoveRoleFromUser,
    UserApplicationRoleDetailed,
} from "../../../../services/types/applicationRolesTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { useRemoveRolefromUserMutation } from "../../../../services/cclTokenedApplicationRolesApi ";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventUserApplicationRole } from "../../../../services/types/accessEventTypes";
import { updateApplicationRolesSearchGridState } from "../../../../app/slices/applicationRolesSearchListTableStateSlice";
import CclCommonLink from "../../../../components/common/cclCommonLink";
import { AlertColor } from "@mui/material";

export interface ApplicationRolesDataGridProps {
    users: UserApplicationRoleDetailed[];
    roles: ApplicationRole[];
    isLoading: boolean;
    startAddRoleFlow: () => void;
    showSnackBar: (message: string, severity: AlertColor) => void;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
}

export const ApplicationRolesDataGrid: React.FC<ApplicationRolesDataGridProps> = (props) => {
    const [selectedRows, setSelectedRows] = React.useState<UserApplicationRoleDetailed[]>([]);

    const [deleteUserRole, { isLoading }] = useRemoveRolefromUserMutation();
    const { logEvent } = useLogAccessEvent();
    const stateSelector = useSelector((state: any) => state.applicationRolesSearchListTableState);
    const claimservice = new UserClaimsService();

    function getFullName(params: GridRenderCellParams) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    const getUserRoles = (params: GridRenderCellParams) => {
        let rolesText = getRoleFromKey(params.row.applicationRoleKey);
        return rolesText;
    };

    const getRoleFromKey = (key: number) => {
        for (let i = 0; i < props.roles.length; i++) {
            if (key === props.roles[i].applicationRoleKey) {
                return props.roles[i].roleName;
            }
        }
        return "";
    };

    const handleDeleteRole = () => {
        const selectedRow = selectedRows[0];
        const payload: RemoveRoleFromUser = {
            roleKey: selectedRow.applicationRoleKey,
            imKey: selectedRow.imKey,
        };
        deleteUserRole(payload)
            .unwrap()
            .then((e) => {
                const evtData: AccessEventUserApplicationRole = {
                    imKey: selectedRow.imKey.toString(),
                    email: selectedRow.email,
                };
                logEvent("UserApplicationRoleDeleted", evtData);
                props.showSnackBar("Role Deleted", "success");
            })
            .catch((e) => {
                props.showSnackBar("Role Deletion Failed", "error");
            });
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "User",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) =>
                params.row.imKey !== "0" ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
                    />
                ) : (
                    ""
                ),
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1,
        },
        {
            field: "roles",
            headerName: "Role",
            flex: 1,
            valueGetter: getUserRoles,
        },
    ];

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.users}
            loading={props.isLoading}
            stateSelector={stateSelector}
            userPreferenceId={0}
            getRowIdFn={(row: UserApplicationRoleDetailed) => row.userApplicationRoleKey}
            actionCreator={updateApplicationRolesSearchGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "name", sort: "asc" }] },
            })}
            resultBar={false}
            resultBarText={""}
            showCheckboxes={true}
            hideSettingsButtons={true}
            hideFooter={false}
            miscButtons={
                claimservice.IsUserSuperAdmin()
                    ? [
                          {
                              id: "add-role-to-user-button",
                              caption: "Add Role",
                              onClick: props.startAddRoleFlow,
                              returnSelected: true,
                              restricted: true,
                          },
                          {
                              id: "delete-role-for-user-button",
                              caption: "Delete Role",
                              onClick: handleDeleteRole,
                              returnSelected: false,
                              restricted: true,
                              disable: selectedRows.length !== 1,
                              loadingBtn: true,
                              isLoading: isLoading,
                          },
                      ]
                    : []
            }
            onSelectedRowsChange={(selectedRows) =>
                setSelectedRows(selectedRows as UserApplicationRoleDetailed[])
            }
        />
    );
};

export default ApplicationRolesDataGrid;
