import * as React from "react";
import {
    Stack,
    TextField,
    Box,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import {
    useGetAllCountriesQuery,
    useGetDemographicsByProfileKeyQuery,
    useLazyGetStatesandProvincesByCMKeyQuery,
    usePostDemographicsByProfileKeyMutation,
} from "../../../../../../services/cclTokenedEnterpriseCoachingApi";
import { UserClaimsService } from "../../../../../../services/currentUserService/currentUserService";
import { SaveProfileDemographicsData } from "../../../../../../services/types/myAccessTypes";
import {
    SelectOption,
    getOptionFromVal,
} from "../../../../../../components/common/selectOptions/selectOption";
import {
    CountryLocation,
    StateLocation,
} from "../../../../../../services/types/enterpriseCoachingApiTypes";
import CclButton from "../../../../../../components/common/cclButtons/cclButton";

export interface ProfileStepPanelProps {
    pkey: number;
    onNextPanel: (nextStep: number, newdata: null) => void;
    activeStep: number;
    nextStep: number;
}

export const ProfileStepPanel: React.FC<ProfileStepPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const pkey = claimsService.GetCurrentResourceId();
    const { data: profileData } = useGetDemographicsByProfileKeyQuery(pkey.toString());
    const [officePhone, setOfficePhone] = React.useState<string | null>(
        profileData?.officePhone ?? null
    );
    const [mobilePhone, setMobilePhone] = React.useState<string | null>(
        profileData?.mobilePhone ?? null
    );
    const [homePhone, setHomePhone] = React.useState<string | null>(profileData?.homePhone ?? null);
    const [address1, setAddress1] = React.useState<string | null>(
        profileData?.address?.addressOne ?? null
    );
    const [address2, setAddress2] = React.useState<string | null>(
        profileData?.address?.addressTwo ?? null
    );
    const [city, setCity] = React.useState<string | null>(profileData?.address?.city ?? null);
    const [postalCode, setPostalCode] = React.useState<string | null>(
        profileData?.address?.postalCode ?? null
    );

    const [selectedState, setSelectedState] = React.useState<SelectOption | null>({
        val: "-1",
        option: "Nothing Selected",
    } as SelectOption);
    const [selectedCountry, setSelectedCountry] = React.useState<SelectOption | null>({
        val: "-1",
        option: "Nothing Selected",
    } as SelectOption);
    const [stateOptions, setStateOptions] = React.useState<SelectOption[]>([]);
    const [countryOptions, setCountryOptions] = React.useState<SelectOption[]>([]);

    const [saveProfileData] = usePostDemographicsByProfileKeyMutation();
    const { data: countryList } = useGetAllCountriesQuery();
    const [statesAndProvincesTrigger, { data: statesAndProvincesList }] =
        useLazyGetStatesandProvincesByCMKeyQuery();

    React.useEffect(() => {
        if (props.nextStep !== -1) handleNext(props.nextStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nextStep]);

    React.useEffect(() => {
        if (profileData?.address?.cmKey == null) return;
        statesAndProvincesTrigger(profileData.address.cmKey);
    }, [profileData, statesAndProvincesTrigger]);

    React.useEffect(() => {
        const ctryOpts =
            countryList != null
                ? countryList
                      ?.map((c: CountryLocation) => {
                          return { val: c.cmKey.toString(), option: c.name };
                      })
                      .sort((a, b) => {
                          return a.option > b.option ? 1 : -1;
                      })
                : [];
        setCountryOptions(ctryOpts);
        if (profileData != null) {
            const selopt = ctryOpts.find((co) => +co.val === profileData.address.cmKey);
            if (selopt) setSelectedCountry(selopt);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryList]);

    React.useEffect(() => {
        const stOpts =
            statesAndProvincesList != null
                ? statesAndProvincesList?.map((s: StateLocation) => {
                      return { val: s.spKey.toString(), option: s.name };
                  })
                : [];
        setStateOptions(stOpts);
        if (profileData != null) {
            const selopt = stOpts.find((so) => +so.val === profileData.address.spmKey);
            if (selopt) setSelectedState(selopt);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statesAndProvincesList]);

    const handleNext = (nxtStep?: number) => {
        let ns = nxtStep !== undefined ? nxtStep : props.activeStep + 1;
        const spmkey =
            (selectedCountry?.val !== "1" && selectedCountry?.val !== "2") ||
            selectedState?.val === undefined
                ? 293
                : +selectedState.val;
        const request: SaveProfileDemographicsData = {
            pKey: pkey,
            payload: {
                addressDTO: {
                    spmKey: spmkey,
                    cmKey: selectedCountry?.val == null ? null : +selectedCountry.val,
                    addressOne: address1,
                    addressTwo: address2,
                    city: city,
                    postalCode: postalCode,
                },
                preferredPhoneKey: profileData?.preferredCtKey ?? null, // no change
                officePhone: officePhone !== "" ? officePhone : null,
                homePhone: homePhone !== "" ? homePhone : null,
                mobilePhone: mobilePhone !== "" ? mobilePhone : null,
                originCmKey: profileData?.nationalityKey, // no change
                ianaTimeZoneId: profileData?.ianaTimeZoneId ?? null, // no change
            },
        };

        saveProfileData(request);
        props.onNextPanel(ns, null);
    };

    const handleCountryChange = (evt: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: evt.target.value,
            option: getOptionFromVal(evt.target.value, countryOptions) ?? "Unknown Country",
        };
        setSelectedCountry(newopt);
        statesAndProvincesTrigger(+evt.target.value);
    };

    const handleStateChange = (evt: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: evt.target.value,
            option: getOptionFromVal(evt.target.value, stateOptions) ?? "Unknown State or Province",
        };
        setSelectedState(newopt);
    };

    return (
        <Stack direction={"column"}>
            <Box
                component="form"
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <Typography variant="h5">
                        Please review and verify your profile information
                    </Typography>
                    <Typography variant="subtitle1" sx={{ pb: 3 }}>
                        Ensure Country/Province is correct as this will determine which contract you
                        will receive. Closing the screen will stop the Sign Contract process but you
                        will be able to restart it again.
                    </Typography>
                    <TextField
                        size="small"
                        id="office-phone"
                        label="Office Phone"
                        value={officePhone}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setOfficePhone(evt.target.value)
                        }
                    />
                    <TextField
                        required
                        size="small"
                        id="mobile-phone"
                        label="Mobile Phone"
                        value={mobilePhone}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setMobilePhone(evt.target.value)
                        }
                    />
                    <TextField
                        size="small"
                        id="home-phone"
                        label="Home Phone"
                        value={homePhone}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setHomePhone(evt.target.value)
                        }
                    />
                    <Stack direction={"row"} width={1} spacing={3}>
                        <TextField
                            size="small"
                            required
                            id="address1"
                            label="Address One"
                            value={address1}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                setAddress1(evt.target.value)
                            }
                            fullWidth
                        />
                        <TextField
                            size="small"
                            fullWidth
                            id="address2"
                            label="Address Two"
                            value={address2}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                setAddress2(evt.target.value)
                            }
                        />
                    </Stack>
                    <TextField
                        size="small"
                        required
                        id="city"
                        label="City"
                        value={city}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setCity(evt.target.value)
                        }
                    />
                    <Stack direction={"row"} width={1} spacing={3}>
                        <FormControl fullWidth>
                            <InputLabel id="select-country-label">Select Country *</InputLabel>
                            <Select
                                labelId="select-country-label"
                                id="select-country"
                                value={
                                    selectedCountry?.val == null || selectedCountry.val === "-1"
                                        ? ""
                                        : selectedCountry.val
                                }
                                label="Select Country *"
                                onChange={handleCountryChange}
                                size="small"
                            >
                                {countryOptions &&
                                    countryOptions.length > 0 &&
                                    countryOptions.map((opt, i) => {
                                        return (
                                            <MenuItem
                                                key={opt.val}
                                                value={opt.val}
                                                selected={opt.val === selectedCountry?.val}
                                            >
                                                {opt.option}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="select-state-label">
                                Select State or Province *
                            </InputLabel>
                            <Select
                                labelId="select-state-label"
                                label="Select State or Province *"
                                id="select-country"
                                value={
                                    selectedState?.val == null || selectedState.val === "-1"
                                        ? ""
                                        : selectedState.val
                                }
                                onChange={handleStateChange}
                                size="small"
                            >
                                {stateOptions &&
                                    stateOptions.length > 0 &&
                                    stateOptions.map((opt, i) => {
                                        return (
                                            <MenuItem
                                                key={opt.val}
                                                value={opt.val}
                                                selected={opt.val === selectedState?.val}
                                            >
                                                {opt.option}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <TextField
                        size="small"
                        required
                        id="postal-code"
                        label="Postal Code"
                        value={postalCode}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setPostalCode(evt.target.value)
                        }
                    />
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclButton disabled={true} mode={"secondary"} sx={{ mr: 1 }}>
                    Back
                </CclButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton mode={"primary"} onClick={() => handleNext()}>
                    Next
                </CclButton>
            </Box>
        </Stack>
    );
};

export default ProfileStepPanel;
