import * as React from "react";
import {
    Alert,
    Card,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import UpdateLoginApplicationCallbackDialog from "./updateLoginApplicationCallbackDialog";
import { GrandCentralCallbackUrl } from "../../../../services/types/cclGrandCentralApiTypes";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    useAddCclApplicationCallbackMutation,
    useDeleteCclApplicationCallbackMutation,
    useUpdateCclApplicationCallbackMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";

export interface CallbackUrlCardProps {
    systemId: string;
    systemName: string;
    applicationId: string;
    gcCallbackUrls: GrandCentralCallbackUrl[];
    readonly: boolean;
}

export const CallbackUrlCard: React.FC<CallbackUrlCardProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [errorTitle, setErrorTitle] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);
    const [showUpdateCallbackDialog, setShowUpdateCallbackDialog] = React.useState<boolean>(false);
    const [selectedCallbackUrl, setSelectedCallbackUrl] = React.useState<GrandCentralCallbackUrl>({
        applicationId: "",
        callbackUrlRegex: "",
        loginApplicationCallbackUrlId: "",
    });
    const [deleteUrl, { isLoading: deleteLoading }] = useDeleteCclApplicationCallbackMutation();
    const [updateCallback, { isLoading: updateLoading }] =
        useUpdateCclApplicationCallbackMutation();
    const [addCallback, { isLoading: addLoading }] = useAddCclApplicationCallbackMutation();
    const { logEvent } = useLogAccessEvent();

    const showError = (msg: string, title: string) => {
        setErrorMessage(msg);
        setErrorTitle(title);
        setShowErrorDialog(true);
    };

    const onAddCallback = () => {
        setSelectedCallbackUrl({
            applicationId: props.applicationId,
            callbackUrlRegex: "",
            loginApplicationCallbackUrlId: "",
        });
        setShowUpdateCallbackDialog(true);
    };

    const updateUrl = (url: GrandCentralCallbackUrl) => {
        setSelectedCallbackUrl(url);
        setShowUpdateCallbackDialog(true);
    };

    const onCallbackUpdate = (url: string, callbackUrlId: string) => {
        setLoading(true);
        if (callbackUrlId === "") {
            addCallback({
                url: url,
                applicationId: props.applicationId,
            })
                .unwrap()
                .then((res) => {
                    const evtData: AccessEventGCSystemDetails = {
                        systemId: props.systemId,
                        systemName: props.systemName,
                    };
                    logEvent("GCSystemChanged", evtData);
                    if (!res) {
                        showError("Failed to add callback url", "Error Adding Callback Url");
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    showError(`Add callback url error: ${err.data}`, "Error Adding Callback Url");
                    setLoading(false);
                });
        } else {
            updateCallback({
                url: url,
                loginApplicationUrlId: callbackUrlId,
                applicationId: props.applicationId,
            })
                .unwrap()
                .then((res) => {
                    const evtData: AccessEventGCSystemDetails = {
                        systemId: props.systemId,
                        systemName: props.systemName,
                    };
                    logEvent("GCSystemChanged", evtData);
                    if (!res) {
                        showError("Failed to update callback url", "Error Updating Callback Url");
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    showError(
                        `Update callback url error: ${err.data}`,
                        "Update Callback Url Error"
                    );
                    setLoading(false);
                });
        }
    };

    const onDeleteUrl = () => {
        setLoading(true);
        setShowDeleteConfirmation(false);
        deleteUrl({
            loginApplicationUrlId: selectedCallbackUrl.loginApplicationCallbackUrlId,
            applicationId: selectedCallbackUrl.applicationId,
            url: selectedCallbackUrl.callbackUrlRegex,
        })
            .unwrap()
            .then((res) => {
                const evtData: AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                if (!res) {
                    showError("Failed to delete callback url", "Error Deleting Callback Url");
                }
                setLoading(false);
            })
            .catch((err) => {
                showError(`Delete Application error: ${err.data}`, "Error Deleting Callback Url");
                setLoading(false);
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title={errorTitle}
            />
            <UpdateLoginApplicationCallbackDialog
                open={showUpdateCallbackDialog}
                onClose={() => setShowUpdateCallbackDialog(false)}
                onSave={onCallbackUpdate}
                url={selectedCallbackUrl.callbackUrlRegex}
                loginApplicationCallbackUrlId={selectedCallbackUrl.loginApplicationCallbackUrlId}
            />
            <CclGenericConfirmationDialog
                open={showDeleteConfirmation}
                onCancel={() => setShowDeleteConfirmation(false)}
                onOk={() => onDeleteUrl()}
            />
            <Card variant="outlined" sx={{ height: 1 }}>
                <CardHeader
                    title="Callback Urls"
                    action={
                        props.readonly ? null : (
                            <CclButton onClick={() => onAddCallback()} mode={"primary"}>
                                Add Callback Url
                            </CclButton>
                        )
                    }
                />
                {loading || addLoading || deleteLoading || updateLoading ? (
                    <Typography>Updating Callback Urls...</Typography>
                ) : props.gcCallbackUrls && props.gcCallbackUrls.length > 0 ? (
                    <Table>
                        <TableBody>
                            {props.gcCallbackUrls.map((url) => (
                                <TableRow key={url.loginApplicationCallbackUrlId}>
                                    <TableCell>{url.callbackUrlRegex}</TableCell>
                                    {props.readonly ? null : (
                                        <TableCell>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => updateUrl(url)}
                                                aria-label="edit-url"
                                            >
                                                {" "}
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => {
                                                    setSelectedCallbackUrl(url);
                                                    setShowDeleteConfirmation(true);
                                                }}
                                                aria-label="delete-url"
                                            >
                                                {" "}
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Alert severity="info">No Callback Urls Found</Alert>
                )}
            </Card>
        </>
    );
};

export default CallbackUrlCard;
