import * as React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import RaterStatusPill from "../raterStatusPill";
import { ProgramParticipantScoringStatus } from "../../../../../../../services/types/scoringStatusTypes";

export interface RaterDetailsTableProps {
    participant: ProgramParticipantScoringStatus;
    instrumentId: number;
}

const RaterDetailsTable: React.FC<RaterDetailsTableProps> = (props) => {
    const paxInstrument = props.participant.instruments.find(
        (i) => i.instrumentId === props.instrumentId
    );

    if (paxInstrument == null) return null;

    return (
        <Table size="small">
            <TableHead sx={{ bgcolor: "#EBF0F8" }}>
                <TableRow>
                    <TableCell align="center" colSpan={2} sx={{ fontWeight: "fontWeightBold" }}>
                        <span>{paxInstrument.name}</span>
                        <br />
                        <span>(required / recommended)</span>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {paxInstrument.forms
                    .filter((f) => f.raterTypeName.toLowerCase() !== "self")
                    .map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align="left" sx={{ fontWeight: "fontWeightBold" }}>
                                    {`${row.raterTypeName} (${row.minimumRequired} / ${row.minimumRecommended})`}
                                </TableCell>
                                <TableCell align="center">
                                    <RaterStatusPill
                                        participant={props.participant}
                                        instrumentId={props.instrumentId}
                                        raterId={row.raterTypeId}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default RaterDetailsTable;
