import * as React from "react";
import { Alert, Box, Stack } from "@mui/material";
import ScoringStatusPanel from "./scoringStatus/scoringStatusPanel";
import RaterManagementPanel from "../../../registrations/registrationDetail/raterManagement/raterManagementPanel";
import { SessionDetailType } from "../../../../services/types/sessionDetailTypes";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

interface AssessmentsPanelProps {
    sessionDetails: SessionDetailType | undefined;
    readOnly?: boolean;
}

const AssessmentsPanel: React.FC<AssessmentsPanelProps> = (props) => {
    if (props.sessionDetails === undefined) {
        return (
            <Box>
                <Alert severity="error">Error: Session data not found!</Alert>
            </Box>
        );
    }

    return (
        <Stack width={1} spacing={4}>
            <CclGenericAccordian headingText="Assessments" defaultExpanded={true} noGutter={true}>
                <ScoringStatusPanel
                    sessionKey={props.sessionDetails.sessionKey}
                    sessionCode={props.sessionDetails.sessionCode}
                    readOnly={props.readOnly}
                />
            </CclGenericAccordian>
            <CclGenericAccordian headingText="Raters" defaultExpanded={true} noGutter={true}>
                <RaterManagementPanel
                    sessionKey={props.sessionDetails.sessionKey}
                    programCode={props.sessionDetails.sessionCode}
                    readOnly={props.readOnly}
                />
            </CclGenericAccordian>
        </Stack>
    );
};

export default AssessmentsPanel;
