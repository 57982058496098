import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface UserSearchListConfigState {
  FirstName: string,
  LastName: string,
  Email: string,
  IsEmailSearchActive: boolean
};

// Define the initial state using that type
const initialState: UserSearchListConfigState = {
  FirstName: '',
  LastName: '',
  Email: '',
  IsEmailSearchActive: true
};

export const userSearchListConfigSlice = createSlice({
  name: 'userSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateUserSearchParams: (state, action: PayloadAction<UserSearchListConfigState>) => {
      state.FirstName = action.payload.FirstName;
      state.LastName = action.payload.LastName;
      state.Email = action.payload.Email;
      state.IsEmailSearchActive = action.payload.IsEmailSearchActive;
    }
  }
});

export const { updateUserSearchParams } = userSearchListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserSearchListConfig = (state: RootState) => state;
export default userSearchListConfigSlice.reducer;
