import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ScoreReportDownloadListConfigState {
    Keyword: string;
}

// Define the initial state using that type
const initialState: ScoreReportDownloadListConfigState = {
    Keyword: "",
};

export const scoreReportDownloadListConfigSlice = createSlice({
    name: "scoreReportDownloadListConfig",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateScoreReportDownloadSearchParams: (
            state,
            action: PayloadAction<ScoreReportDownloadListConfigState>
        ) => {
            state.Keyword = action.payload.Keyword;
        },
    },
});

export const { updateScoreReportDownloadSearchParams } = scoreReportDownloadListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectScoreReportDownloadListConfig = (state: RootState) => state;
export default scoreReportDownloadListConfigSlice.reducer;
